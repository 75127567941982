import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Col, Button, Row } from 'react-bootstrap';
import Strings from '../../../Strings';
import { getAddressString, timeOptions, shortDateOptions } from '../../../Utils';

function getOptions(status, language) {
  switch (status) {
    case 'PENDING': return [Strings.Pending_Order[language], 'success'];
    case 'MODIFIED': return [Strings.Awaiting_Customer_Approval[language], 'success'];
    case 'CHANGES_REJECTED':
    case 'CHANGES_ACCEPTED': return [Strings.Customer_Approved_Order[language], 'success'];
    case 'READY_TO_SHIP': return [Strings.Awaiting_Dispatch[language], 'success'];
    case 'OUT_FOR_DELIVERY': return [Strings.Out_For_Delivery[language], 'success'];
    case 'DELIVERED': return [Strings.Delivered[language], 'success'];
    case 'CANCELLED': return [Strings.Cancelled[language], 'danger'];
    default: return ['', 'primary'];
  }
}

function Order(props) {
  const { item, language, dashboard } = props;
  const [status, variant] = getOptions(item.deliveryStatus, language);
  return (
    <Col xs={24} md={12} lg={dashboard ? 24 : 12} className="py-2">
      <div className="zig-zag" />
      <Link to={`/orders/${item.orderId}`}>
        <div className="bg-white px-4 py-1">
          <Row>
            <Col className={`text-${variant} pr-0`}>
              {status}
              <img src="/Assets/paid.svg" alt="" className={item.payment.status === 'PAID' ? 'paid-img' : 'd-none'} />
            </Col>
            <Col xs="auto" className="text-muted text-right pl-0">
              {`#${item.orderId}`}
            </Col>
          </Row>
          <Row>
            <Col xs={14} className="text-black">
              <b>{item.customer.displayName}</b>
            </Col>
            <Col xs={10} className="text-muted text-right">
              {`${item.productCount} ${Strings.items[language]}`}
            </Col>
          </Row>
          <Row>
            <Col xs={14} className="text-truncate text-black">
              {
                item.pickupFromStore
                  ? Strings.Pickup_From_Store[language]
                  : getAddressString(item.deliveryDetails.deliveryAddress)
              }
            </Col>
            <Col xs={10} className="text-muted text-right">
              <b>{`\u20B9 ${item.payment.payableAmount}`}</b>
            </Col>
          </Row>
          <Row className="m-0">
            <Col className="border my-2" />
          </Row>
          <Row className="pt-1">
            <Col className="text-muted pr-2">
              {`${new Date(item.createdOn).toLocaleDateString(`${language}-in`, shortDateOptions)} | ${new Date(item.createdOn).toLocaleTimeString(`${language}-in`, timeOptions)}`}
            </Col>
            <Col xs="auto" className="text-right pl-2">
              <Button variant="link" className="p-0"><u>{Strings.View_Details[language]}</u></Button>
            </Col>
          </Row>
        </div>
      </Link>
      <div className="zig-zag bottom" />
    </Col>
  );
}

Order.defaultProps = {
  dashboard: false,
};

Order.propTypes = {
  item: PropTypes.shape({}).isRequired,
  language: PropTypes.string.isRequired,
  dashboard: PropTypes.bool,
};

export default Order;
