import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { List, ErrorHandler } from '../../components/common';
import { getSpecialOffers, getOffers, patchRetailerProduct } from '../../../api/api';
import Strings from '../../Strings';
import Offer from '../../components/derived/offer/Offer';
import SpecialBogoOffer from '../../components/derived/offer/SpecialBogoOffer';
import SpecialSpendOffer from '../../components/derived/offer/SpecialSpendOffer';

const { CancelToken } = Axios;
let loadRequest = null;
let lazyLoadRequest = null;
const limit = 20;

class OfferList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offers: [],
      loading: true,
      error: false,
      offset: 0,
      offerCount: 0,
      selectedOfferType: 'discount',
    };
    this.handleLazyLoad = this.handleLazyLoad.bind(this);
    this.handleChangeOfferType = this.handleChangeOfferType.bind(this);
    this.handleLazyLoad = this.handleLazyLoad.bind(this);
    this.loadOffers = this.loadOffers.bind(this);
    this.appendOffers = this.appendOffers.bind(this);
    this.handleInStockStatus = this.handleInStockStatus.bind(this);
    this.handleIsFreshStatus = this.handleIsFreshStatus.bind(this);
  }

  componentWillMount() {
    this.loadOffers();
  }

  handleLazyLoad(event) {
    const {
      loading, offerCount, offset,
    } = this.state;
    if (loading) return;
    const { offsetHeight, scrollTop, scrollHeight } = event.target;
    /*
    if (
      getIsApp() === 'true'
      && !this.expandingHeader
      && (
        (androidExpandHeader && scrollTop > 30)
        || (!androidExpandHeader && scrollTop < 30)
      )
    ) {
      this.expandingHeader = setTimeout(
        () => {
          this.setState(
            state => ({
              androidExpandHeader: !state.androidExpandHeader,
            }),
            () => {
              const { androidExpandHeader: expand } = this.state;
              window.expandHeader(expand);
              // this.expandingHeader = null;
            },
          );
        },
        200,
      );
    } */
    if (scrollHeight - offsetHeight - scrollTop < 100
      || offsetHeight + scrollTop === scrollHeight) {
      if (offset >= offerCount) {
        this.setState({ loading: false });
      } else {
        this.appendOffers();
      }
    }
  }

  loadOffers() {
    if (loadRequest) {
      loadRequest.cancel();
    }
    if (lazyLoadRequest) {
      lazyLoadRequest.cancel();
    }
    loadRequest = CancelToken.source();
    const { selectedOfferType } = this.state;
    let getOffersRequest;
    if (selectedOfferType === 'discount') {
      getOffersRequest = getOffers(0, limit, loadRequest);
    } else {
      getOffersRequest = getSpecialOffers(
        0,
        limit,
        selectedOfferType === 'bogo'
          ? 'SPECIAL_BOGO_OFFER'
          : 'SPECIAL_SPEND_OFFER',
        loadRequest,
      );
    }
    getOffersRequest.then((res) => {
      loadRequest = null;
      this.setState({
        offers: res.data.results,
        offerCount: res.data.count || 0,
        offset: limit,
        loading: false,
        error: false,
      });
    }).catch((error) => {
      if (Axios.isCancel(error)) {
        return;
      }
      this.setState({ loading: false, error: true });
    });
  }

  appendOffers() {
    if (lazyLoadRequest) {
      lazyLoadRequest.cancel();
    }
    lazyLoadRequest = CancelToken.source();
    const { selectedOfferType } = this.state;
    this.setState({ loading: true });
    const { offset } = this.state;
    let getOffersRequest;
    if (selectedOfferType === 'discount') {
      getOffersRequest = getOffers(offset, limit, lazyLoadRequest);
    } else {
      getOffersRequest = getSpecialOffers(
        0,
        limit,
        selectedOfferType === 'bogo'
          ? 'SPECIAL_BOGO_OFFER'
          : 'SPECIAL_SPEND_OFFER',
        lazyLoadRequest,
      );
    }
    getOffersRequest.then((res) => {
      lazyLoadRequest = null;
      this.setState(state => ({
        offers: state.offers.concat(res.data.results),
        offerCount: res.data.count || 0,
        offset: state.offset + limit,
        loading: false,
      }));
    }).catch((error) => {
      if (Axios.isCancel(error)) {
        return;
      }
      this.setState({ loading: false });
    });
  }

  handleInStockStatus(event, productId) {
    patchRetailerProduct(productId, { inStock: event.target.checked })
      .then((res) => {
        const updatedProduct = res.data;
        const { offers } = this.state;
        const index = offers.findIndex(product => product.retailerProduct.id === updatedProduct.id);
        offers[index].retailerProduct.sellingStores[0].inStock = updatedProduct.inStock;
        this.setState({ offers });
      });
  }

  handleIsFreshStatus(event, productId) {
    patchRetailerProduct(productId, { isFresh: event.target.checked })
      .then((res) => {
        const updatedProduct = res.data;
        const { offers } = this.state;
        const index = offers.findIndex(product => product.retailerProduct.id === updatedProduct.id);
        offers[index].retailerProduct.sellingStores[0].isFresh = updatedProduct.isFresh;
        this.setState({ offers });
      });
  }

  handleChangeOfferType(offerType) {
    this.setState({
      selectedOfferType: offerType,
      loading: true,
      offers: [],
      error: false,
      offset: 0,
      offerCount: 0,
    }, this.loadOffers);
  }

  render() {
    const {
      offers, loading, error, selectedOfferType,
    } = this.state;
    const { language } = this.props;
    return (
      <Container
        onScroll={this.handleLazyLoad}
        fluid
        className="pb-58p bg-white list-section"
      >
        <div className="sticky-top bg-white">
          <Row className={error ? 'd-none' : ''}>
            <Col className="p-0">
              <Link to="/products" replace>
                <Button variant="link text-muted rounded-0 py-3 border-bottom" block>
                  {Strings.Products[language]}
                </Button>
              </Link>
            </Col>
            <Col xs="auto" className="px-0 py-2">
              <div className="border h-100" />
            </Col>
            <Col className="p-0">
              <Button variant="link rounded-0 py-3 border-bottom-primary" block>
                {Strings.Offers[language]}
              </Button>
            </Col>
          </Row>
        </div>
        <Row className="mt-4">
          <Col xs="auto">
            <Button
              variant={
                selectedOfferType === 'discount'
                  ? 'success'
                  : 'outline-secondary'
              }
              onClick={() => this.handleChangeOfferType('discount')}
            >
              {Strings.Price_Discount[language]}
            </Button>
          </Col>
          <Col xs="auto" className="pl-0">
            <Button
              variant={
                selectedOfferType === 'bogo'
                  ? 'success'
                  : 'outline-secondary'
              }
              onClick={() => this.handleChangeOfferType('bogo')}
            >
              {Strings.Buy_One_Get_One[language]}
            </Button>
          </Col>
          <Col xs="auto" className="p-0">
            <Button
              variant={
                selectedOfferType === 'spend'
                  ? 'success'
                  : 'outline-secondary'
                }
              onClick={() => this.handleChangeOfferType('spend')}
            >
              {Strings.Spend_Base_Offer[language]}
            </Button>
          </Col>
        </Row>
        <Row className="m-0">
          <Col xs={24} className="border mt-4" />
        </Row>

        <ErrorHandler
          show={error}
          language={language}
          handleRetry={() => {
            this.setState({
              loading: true,
              error: false,
            }, this.loadOffers);
          }}
        />

        <Row className="mt-4">
          {
            offers.length === 0 ? (
              <div
                className={`text-center text-muted p-4 w-100
                  ${(loading || error) && 'd-none'}`}
              >
                <i><b>{Strings.No_Offers_Found[language]}</b></i>
              </div>
            ) : (
              <Col>
                {
                  selectedOfferType === 'discount'
                    ? (
                      <List
                        {...this.props}
                        Component={Offer}
                        idField="id"
                        list={offers}
                      />
                    ) : (
                      <List
                        {...this.props}
                        offerType={selectedOfferType}
                        mode="offer"
                        handleInStockStatus={this.handleInStockStatus}
                        handleIsFreshStatus={this.handleIsFreshStatus}
                        Component={selectedOfferType === 'bogo'
                          ? SpecialBogoOffer : SpecialSpendOffer}
                        idField="id"
                        list={offers}
                      />
                    )
                }
              </Col>
            )
          }
        </Row>
        <Row
          className={loading ? 'justify-content-center py-4' : 'd-none'}
        >
          <Col
            xs="auto"
            className="spinner-border text-primary p-0"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </Col>
        </Row>
        <Row className={loading && 'd-none'}>
          <div id="product-button" className="text-center">
            <Link
              to="/products/offers/add-bogo-offer"
            >
              <Button
                variant="success"
                className="left-rounded-pill shadow px-6 py-2"
              >
                <img src="/Assets/add-product.svg" alt="" />
                <b>
                  &nbsp;
                  {Strings.Add_Bogo[language]}
                </b>
              </Button>
            </Link>
            <Link
              to="/products/offers/add-spend-offer"
            >
              <Button
                variant="primary"
                className="right-rounded-pill shadow px-6 py-2"
              >
                <img src="/Assets/add-product.svg" alt="" />
                <b>
                  &nbsp;
                  {Strings.Add_Spend[language]}
                </b>
              </Button>
            </Link>
          </div>
        </Row>
      </Container>
    );
  }
}

OfferList.propTypes = {
  language: PropTypes.string.isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default OfferList;
