import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import Strings from '../../Strings';
import './styles.scss';
import events from '../../FirebaseEvents';
import clevertapEvents from '../../ClevertapEvents';
import { logFirebaseEvent, logClevertapEvents } from '../../Utils';

class Welcome extends React.Component {
  constructor() {
    super();
    this.state = {
      acceptTerms: false,
    };
    this.toggleAcceptTerms = this.toggleAcceptTerms.bind(this);
    this.acceptTermsnConditions = this.acceptTermsnConditions.bind(this);
  }

  toggleAcceptTerms() {
    this.setState(state => ({
      acceptTerms: !state.acceptTerms,
    }));
  }

  acceptTermsnConditions() {
    const { patchProfile, history } = this.props;
    logFirebaseEvent(events.GetStarted_OnlineDeliverySetup, null);
    patchProfile({ acceptedTnC: true, setupStage: 'sku' }).then(() => {
      logClevertapEvents(clevertapEvents.OO_SetupStart);
      history.replace('/setup?stage=sku');
    });
  }

  render() {
    const { language } = this.props;
    const { acceptTerms } = this.state;
    return (
      <Container fluid className="tnc-section py-lg-4">
        <Row className="m-0">
          <Col className="text-center bg-success-20 py-4">
            <div>
              <img src="/Assets/check-circle.svg" alt="" />
              <b>
                &nbsp;
                {Strings.Congratulations[language]}
              </b>
            </div>
            <div className="pt-2">
              {Strings.request_approved[language]}
            </div>
            <div className="pb-2">
              {Strings.Setup_your_store[language]}
            </div>
          </Col>
        </Row>
        <Row className="policy-list-row m-0">
          <Col className="py-4 h-100 policy-list">
            <h4>{Strings.important_sign_up_notes[1][language]}</h4>
            <ol className="text-justify">
              <li>{Strings.important_sign_up_notes[1][1][language]}</li>
              <li>{Strings.important_sign_up_notes[1][2][language]}</li>
              <li>{Strings.important_sign_up_notes[1][3][language]}</li>
              <li>{Strings.important_sign_up_notes[1][4][language]}</li>
            </ol>
            <h4>{Strings.important_sign_up_notes[2][language]}</h4>
            <ol className="text-justify">
              <li>{Strings.important_sign_up_notes[2][1][language]}</li>
            </ol>
            <h4>{Strings.important_sign_up_notes[3][language]}</h4>
            <ol className="text-justify">
              <li>{Strings.important_sign_up_notes[3][1][language]}</li>
            </ol>
            <h4 className="text-muted"><i>{Strings.Please_Read_Terms[language]}</i></h4>
          </Col>
        </Row>
        <div className="accept-terms">
          <input type="checkbox" checked={acceptTerms} onChange={this.toggleAcceptTerms} />
          <span>
            &nbsp;
            {Strings.I[language]}
            &nbsp;
            <a
              href="/terms-and-conditions"
              onClick={() => logFirebaseEvent(events.Terms_Conditions_Click, null)}
            >
              {Strings.Terms_and_Conditions[language]}
            </a>
            &nbsp;
            {Strings.and[language]}
            &nbsp;
            <a
              href="https://lovelocal.in/privacy-policy/"
              onClick={() => logFirebaseEvent(events.Privacy_Policy_Click, null)}
              // href="/returns-and-replacement-policy"
            >
              {Strings.Privacy_Policies[language]}
            </a>
            &nbsp;
            {Strings.accept[language]}
          </span>
        </div>
        <Row className="m-0 fixed-bottom">
          <Col xs={24} className="px-0">
            <Button
              variant="primary"
              block
              className="rounded-0 py-3 fs-3"
              disabled={!acceptTerms}
              onClick={this.acceptTermsnConditions}
            >
              {Strings.Get_Started[language]}
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

Welcome.propTypes = {
  patchProfile: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default Welcome;
