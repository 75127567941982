import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Container, Row, Col, Spinner, Button,
} from 'react-bootstrap';
import Strings from '../../../Strings';
import clevertapEvents from '../../../ClevertapEvents';
import { shortDateOptions, logClevertapEvents } from '../../../Utils';


function InventoryStats(props) {
  const {
    loading, inventoryStats, language, history,
  } = props;
  const date = new Date(inventoryStats.lastUpdated);
  return (
    <Container fluid className="bg-white h-100">
      <Row>
        <Col xs={24} className="text-center py-3">
          <b className="fs-3">
            <span className="mx-1"><img src="/Assets/file-tasks-checklist.svg" alt="" /></span>
            {Strings.My_Online_Inventory[language]}
          </b>
        </Col>
        <Col xs={24}>
          <div className="border border-medium" />
        </Col>
        { loading
          ? (
            <Col xs={24} className="text-center py-8">
              <Spinner animation="border" variant="primary" />
            </Col>
          )
          : (
            <Col className="py-3">
              <Container>
                <Row className="align-items-center justify-content-around py-2">
                  <Col xs={11} className="border-dashed border-radius p-2 border-secondary">
                    <div className="fs-1">{Strings.Product_Being_Sold[language]}</div>
                    <div className="d-flex align-items-center justify-content-between">
                      <b className="text-primary fs-2">{inventoryStats.productBeingSold}</b>
                      <Button
                        variant="link"
                        className={inventoryStats.productBeingSold ? 'p-0' : 'd-none'}
                        onClick={() => {
                          history.push('/products');
                          logClevertapEvents(clevertapEvents.OO_Dashboard_InventoryClicked);
                        }}
                      >
                        <img src="/Assets/green-arrow-circle.svg" alt="" className="icon" />
                      </Button>
                    </div>
                  </Col>
                  <Col xs={11} className="border-dashed border-radius p-2 border-secondary">
                    <div className="fs-1">{Strings.Offer_Live[language]}</div>
                    <div className="d-flex align-items-center justify-content-between">
                      <b className="text-primary fs-2">{inventoryStats.offerLive}</b>
                      <Link to="/products/offers" className={inventoryStats.offerLive ? '' : 'd-none'}>
                        <img src="/Assets/green-arrow-circle.svg" alt="" className="icon" />
                      </Link>
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-center justify-content-around py-2">
                  <Col xs={11} className="border-dashed border-radius p-2 border-secondary">
                    <div className="fs-1">{Strings.Out_of_Stock[language]}</div>
                    <div className="d-flex align-items-center justify-content-between">
                      <b className="text-primary fs-2">{inventoryStats.outOfStock}</b>
                      <Link to="/products/out-of-stock" className={inventoryStats.outOfStock ? '' : 'd-none'}>
                        <img src="/Assets/green-arrow-circle.svg" alt="" className="icon" />
                      </Link>
                    </div>
                  </Col>
                  <Col xs={11} className="border-dashed border-radius p-2 border-secondary">
                    <div className="fs-1">{Strings.Last_Updated[language]}</div>
                    <div>
                      <b className="text-primary fs-2">
                        {inventoryStats.lastUpdated
                          ? (
                            <span>
                              <span className={language === 'en' ? '' : 'd-none'}>
                                {date.toLocaleDateString('en-in', shortDateOptions).replace(/-/gi, ' ')}
                              </span>
                              <span className={language === 'en' ? 'd-none' : ''}>
                                { date.toLocaleDateString('hi-in', shortDateOptions)}
                              </span>
                            </span>
                          ) : (
                            <span>
                              <span>&#10005;</span>
                              <span>&#10005;</span>
                              <span>&#10005;</span>
                              <span>&#10005;</span>
                              <span>&#10005;</span>
                              <span>&#10005;</span>
                            </span>
                          )
                        }
                      </b>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          )
        }
      </Row>
    </Container>
  );
}

InventoryStats.propTypes = {
  loading: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  history: PropTypes.shape({}).isRequired,
  inventoryStats: PropTypes.shape({}).isRequired,
};

export default InventoryStats;
