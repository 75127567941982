import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button, Form,
} from 'react-bootstrap';
import { generateOTP, login, loginWithOTP } from '../../../api/api';
import { isAuthenticated } from '../../Utils';

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: '',
      pin: '',
      isLoading: false,
      otpSent: false,
      otp: '',
      requestId: '',
      errors: {},
    };

    this.onPhoneNumberChange = this.onPhoneNumberChange.bind(this);
    this.onPinChange = this.onPinChange.bind(this);
    this.onOTPChange = this.onOTPChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.sendOTP = this.sendOTP.bind(this);
  }

  componentWillMount() {
    const { history, location } = this.props;
    if (isAuthenticated()) {
      history.push(`/${location.search}`);
    } else if (JSON.parse(window.localStorage.getItem('profiles'))) {
      history.push(`/stores${location.search}`);
    }
  }

  onPhoneNumberChange(event) {
    const { value } = event.target;
    if (value.match('^[0-9]{0,10}$') === null) {
      return;
    }
    this.setState({ phoneNumber: value, errors: {} });
  }

  onPinChange(event) {
    const { value } = event.target;
    if (value.match('^[0-9]{0,4}$') === null) {
      return;
    }
    this.setState({ pin: value, errors: {} });
  }

  onOTPChange(event) {
    this.setState({ otp: event.target.value, errors: {} });
  }

  onFormSubmit(event) {
    event.preventDefault();
    this.setState({ isLoading: true });
    const {
      phoneNumber, pin, requestId, otp, otpSent,
    } = this.state;
    let tryLogin;
    if (otpSent) {
      tryLogin = loginWithOTP({ phone_number: phoneNumber }, requestId, otp);
    } else {
      tryLogin = login({ phone_number: phoneNumber, pin });
    }
    tryLogin.then((res) => {
      try {
        const { history, location } = this.props;
        const profiles = res.data;
        window.localStorage.setItem('profiles', JSON.stringify(profiles));
        history.push(`/stores${location.search}`);
      } catch (err) {
        // console.error(err);
      }
      this.setState({ isLoading: false, errors: {} });
    }).catch((res) => {
      this.setState({
        isLoading: false,
        errors: res.response
          ? res.response.data
          : { non_field_errors: [{ error_message: 'Please Check Your Internet' }] },
      });
    });
  }

  sendOTP() {
    this.setState({ isLoading: true });
    const { phoneNumber } = this.state;
    generateOTP({ phone_number: phoneNumber, reason: 'Login With OTP' })
      .then((res) => {
        this.setState({
          isLoading: false, otpSent: true, pin: '', requestId: res.data.request_id, errors: {},
        });
      }).catch((res) => {
        this.setState({
          isLoading: false,
          errors: res.response
            ? res.response.data
            : { non_field_errors: [{ error_message: 'Please Check Your Internet' }] },
        });
      });
  }

  renderErrorMessage(field) {
    const { errors } = this.state;
    if (!errors[field]) {
      return '';
    }
    return <div className="text-danger p-1">{errors[field][0].error_message}</div>;
  }

  render() {
    const {
      phoneNumber, pin, isLoading, otp, otpSent,
    } = this.state;

    return (
      <Container fluid className="h-100">
        <Row className="h-100 align-items-center p-4">
          <Col
            xs={24}
            lg={{ span: 10, offset: 7 }}
            md={{ span: 12, offset: 6 }}
            sm={{ span: 16, offset: 4 }}
            className="border shadow bg-white p-4"
          >
            <div className="py-2">
              <img src="/Assets/logo.png" alt="logo" className="mpaani-logo" />
            </div>
            <form onSubmit={this.onFormSubmit}>
              <Form.Group>
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={this.onPhoneNumberChange}
                />
                {this.renderErrorMessage('phone_number')}
              </Form.Group>
              <Form.Group>
                <Form.Label>{otpSent ? 'OTP' : 'Pin Password'}</Form.Label>
                <Form.Control
                  type="password"
                  minLength={4}
                  maxLength={4}
                  placeholder={otpSent ? 'OTP' : 'Pin Password'}
                  pattern="[0-9]*"
                  inputMode="numeric"
                  value={otpSent ? otp : pin}
                  onChange={otpSent ? this.onOTPChange : this.onPinChange}
                />
                {this.renderErrorMessage('pin')}
              </Form.Group>
              {this.renderErrorMessage('non_field_errors')}
              <Button
                type="submit"
                onClick={this.onFormSubmit}
                disabled={isLoading}
              >
                Login
              </Button>
              <Button
                variant="link"
                className={phoneNumber.length !== 10 ? 'd-none' : ''}
                onClick={this.sendOTP}
                disabled={isLoading}
              >
                { otpSent ? 'Resend OTP' : 'Forgot Password? Login with OTP' }
              </Button>
            </form>
          </Col>
        </Row>
      </Container>
    );
  }
}

Login.propTypes = {
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
};
