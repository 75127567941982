import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'react-bootstrap';
import Strings from '../../../Strings';
import { List } from '../../common';
import Order from './Order';
import './styles.scss';
import { longDateOptions } from '../../../Utils';

class SettledSettlement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  render() {
    const { show } = this.state;
    const { item, language } = this.props;
    return (
      <Col xs={24} className="py-4">
        <div className="bg-white shadow p-4">
          <Row>
            <Col>
              <b>
                {Strings.Amount_Settled[language]}
                <span
                  className="text-success"
                >
                  {` \u20B9 ${item.amountPaid}`}
                </span>
              </b>
            </Col>
            <Col
              xs="auto"
              className="text-success text-right"
            >
              {
                new Date(item.createdOn)
                  .toLocaleDateString(`${language}-in`, longDateOptions)
              }
            </Col>
          </Row>
          <Row className="m-0">
            <Col className="border my-2" />
          </Row>
          <Row>
            <Col>
              <b>{Strings.Transfer_ID[language]}</b>
            </Col>
            <Col>
              <b>{Strings.Total_Amount[language]}</b>
            </Col>
            <Col>
              <b>{Strings.Reference_To[language]}</b>
            </Col>
          </Row>
          <Row>
            <Col>
              {`#${item.reconciledId}`}
            </Col>
            <Col>
              {`\u20B9 ${item.totalAmount}`}
            </Col>
            <Col
              className="text-primary"
            >
              {`${item.orders.length} ${Strings.Transactions[language]}`}
            </Col>
          </Row>
          <Row className="m-0">
            <Col className="border my-2" />
          </Row>
          <Row
            className={`collapse ${show && 'show'}`}
          >
            <Col>
              <List
                {...this.props}
                Component={Order}
                idField="orderId"
                list={item.orders}
              />
            </Col>
          </Row>
          <Button
            variant="link"
            block
            onClick={() => {
              this.setState(state => ({ show: !state.show }));
            }}
            className={`${show ? 'rotate-180' : 'transition-all'} p-0`}
          >
            <img src="/Assets/arrow-down.svg" alt="" />
          </Button>
        </div>
      </Col>
    );
  }
}

SettledSettlement.propTypes = {
  item: PropTypes.shape({}).isRequired,
  language: PropTypes.string.isRequired,
};

export default SettledSettlement;
