import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

function CustomSwitch(props) {
  const {
    displayTextLeft, displayTextRight, checked, onChange, id,
  } = props;
  return (
    <React.Fragment>
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <label htmlFor={id} className={displayTextLeft === '' ? 'd-none' : `px-2 m-0${checked ? '' : ' font-weight-bold'}`}>{displayTextLeft}</label>
      <input type="checkbox" id={id} name="set-name" onChange={onChange} className="switch-input" checked={checked} />
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <label htmlFor={id} className={`switch-label m-0 mr-n3${checked ? ' font-weight-bold' : ''}`}>{displayTextRight}</label>
    </React.Fragment>
  );
}

CustomSwitch.propTypes = {
  displayTextLeft: PropTypes.string,
  displayTextRight: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

CustomSwitch.defaultProps = {
  displayTextLeft: '',
  displayTextRight: '',
  checked: false,
};

export default CustomSwitch;
