import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Strings from '../../../Strings';
import { CustomSwitch } from '../../../components/common';
import { getIsApp } from '../../../Utils';

function StoreCard(props) {
  const {
    storeProfile, language, onChange, isDelivering,
  } = props;
  return (
    <Container
      fluid
      className="bg-white shadow h-100"
    >
      <Row
        className="py-2"
      >
        <Col
          xs={24}
          className="text-center"
        >
          <b
            className="fs-4 text-primary"
          >
            {storeProfile ? storeProfile.displayName : ''}
          </b>
        </Col>
        <Col
          xs={24}
        >
          <div
            className="border my-3"
          />
        </Col>
        <Col
          xs={24}
          className="d-flex justify-content-between align-items-center"
        >
          <div
            className="text-muted fs-3"
          >
            {Strings.Online_Delivery[language]}
          </div>
          <div
            className="text-muted text-center"
          >
            <CustomSwitch
              {...props}
              id="home-delivery"
              displayTextLeft={Strings.Closed[language]}
              displayTextRight={Strings.Open[language]}
              onChange={onChange}
              checked={isDelivering}
            />
          </div>
        </Col>
        {
          getIsApp() === 'true'
            ? (
              <Col
                xs={24}
              >
                <Button
                  variant="outline-primary"
                  block
                  className="mt-3"
                  onClick={() => {
                    window.launchAndroidScreen('app://mpaani-partner/share-card/');
                  }}
                >
                  <div
                    className="d-flex align-items-center justify-content-center font-weight-normal fs-2"
                  >
                    <img
                      src="/Assets/business_card.svg"
                      alt=""
                    />
                    &nbsp;
                    {Strings.Share_Business_Card[language]}
                  </div>
                </Button>
              </Col>
            ) : ''
        }
      </Row>
    </Container>
  );
}

StoreCard.defaultProps = {
  storeProfile: null,
};

StoreCard.propTypes = {
  language: PropTypes.string.isRequired,
  storeProfile: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  isDelivering: PropTypes.bool.isRequired,
};

export default StoreCard;
