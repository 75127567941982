import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Badge } from 'react-bootstrap';
import Strings from '../../../Strings';

function SetupStepper(props) {
  const { stage, language } = props;
  return (
    <Row className="px-4 py-4 align-items-center">
      <Col
        xs="auto"
        className={`text-${stage === 'sku' || stage === 'product-library'
          || stage === 'product-add' || stage === 'product-retailer'
          ? 'primary' : 'secondary'} fs-1 px-1  `}
      >
        <b className="d-flex align-items-center">
          <Badge
            className={`bg-${stage === 'sku' || stage === 'product-library'
              || stage === 'product-add' || stage === 'product-retailer'
              ? 'primary' : 'secondary'} text-white rounded-circle`}
          >
            1
          </Badge>
          &nbsp;
          {Strings.Select_SKU[language]}
        </b>
      </Col>
      <Col className="border p-0 h-0" />
      <Col
        xs="auto"
        className={`text-${stage === 'store-details' ? 'primary' : 'secondary'} fs-1 px-1`}
      >
        <b className="d-flex align-items-center">
          <Badge
            className={`bg-${stage === 'store-details' ? 'primary' : 'secondary'}
            text-white rounded-circle`}
          >
            2
          </Badge>
          &nbsp;
          {Strings.Store_Details[language]}
        </b>
      </Col>
      <Col className="border p-0 h-0" />
      <Col
        xs="auto"
        className={`text-${stage === 'account-details' ? 'primary' : 'secondary'} fs-1 px-1`}
      >
        <b className="d-flex align-items-center">
          <Badge
            className={`bg-${stage === 'account-details' ? 'primary' : 'secondary'}
            text-white rounded-circle`}
          >
            3
          </Badge>
          &nbsp;
          {Strings.Account_Details[language]}
        </b>
      </Col>
    </Row>
  );
}

SetupStepper.propTypes = {
  stage: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
};

export default SetupStepper;
