import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Row, Container, Button,
} from 'react-bootstrap';
import Strings from '../../../Strings';
import { getIsApp } from '../../../Utils';

function CustomerDetails(props) {
  const {
    customer, address, description, language,
  } = props;
  return (
    <Container fluid className="py-4">
      <Row className="align-items-center">
        <Col
          className="pr-1"
        >
          <h4 className="m-0">{Strings.Customer_Details[language]}</h4>
        </Col>
        {
          getIsApp() !== 'true' && (
            <Col
              xs="auto"
              className="no-print pl-1"
            >
              <Button
                variant="link"
                onClick={window.print}
                className="d-flex align-items-center p-0"
              >
                <img
                  alt=""
                  src="/Assets/print.svg"
                />
                &nbsp;
                {Strings.Print_Order_List[language]}
              </Button>
            </Col>
          )
        }
      </Row>
      <Row className="px-4 py-2">
        <Col className="border" />
      </Row>
      <Row className="py-1">
        <Col xs={7} className="pr-1">{Strings.Name[language]}</Col>
        <Col xs={17} className="pl-1"><b>{customer.displayName}</b></Col>
      </Row>
      <Row className="py-1">
        <Col xs={7} className="pr-1">{Strings.Contact_Details[language]}</Col>
        <Col xs={17} className="pl-1">
          <a href={`tel:${customer.phoneNumber}`}>
            <b>
              <img src="/Assets/phone-outgoing.svg" alt="" />
              &nbsp;&nbsp;
              {customer.phoneNumber}
            </b>
          </a>
        </Col>
      </Row>
      <Row className="py-1">
        <Col
          xs={7}
          className="pr-1"
        >
          {Strings.Address[language]}
        </Col>
        <Col
          xs={17}
          className="pl-1 text-justify"
        >
          <b>
            {address || Strings.Pickup_From_Store[language]}
          </b>
        </Col>
      </Row>
      <Row className={description === '' ? 'd-none' : ''}>
        <Col className="py-2">
          <div className="bg-light p-4">
            <h5 className="m-0">{Strings.Note[language]}</h5>
            {description}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

CustomerDetails.propTypes = {
  customer: PropTypes.shape({}).isRequired,
  address: PropTypes.string,
  description: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
};

CustomerDetails.defaultProps = {
  address: null,
};

export default CustomerDetails;
