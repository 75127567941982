import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

function CustomModal(props) {
  const {
    title, body, footer, show, onHide, reset, closeButton,
  } = props;
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      enforceFocus
      backdrop="static"
      onExit={reset}
    >
      <Modal.Header closeButton={closeButton}>
        <Modal.Title>
          <h3 className="m-0">{title}</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {body}
      </Modal.Body>
      {
        footer && (
          <Modal.Footer>
            {footer}
          </Modal.Footer>
        )
      }
    </Modal>
  );
}

CustomModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  body: PropTypes.element.isRequired,
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  reset: PropTypes.func,
  closeButton: PropTypes.bool,
};

CustomModal.defaultProps = {
  footer: null,
  reset: () => {},
  closeButton: false,
};

export default CustomModal;
