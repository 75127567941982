import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import {
  setLanguage, getLanguage, logFirebaseEvent, logClevertapEvents, getIsApp,
} from './Utils';
import Strings from './Strings';
import Wrapper from './Wrapper';
import events from './FirebaseEvents';
import clevertapEvents from './ClevertapEvents';
import {
  Login, PendingSettlements, SettledSettlements, Dashboard, OfferList, OfferAdd,
  OrderList, OrderDetails, RetailerProductList, ProductAdd, RequestDelivery, Reviews,
  Settings, Setup, StoreList, TermsAndConditions, BulkUpload, LibraryProductList, RetailerReports,
  MarketReports,
} from './pages';
import './styles/theme.scss';
import SetExclusionArea from './pages/retailerServicableArea/SetExclusionArea';
import UpdateLocation from './pages/retailerServicableArea/UpdateLocation';
import TermsAndConditions3PL from './pages/tnc/TermsAndConditions3PL';
import CatalogueManagement from './pages/catalogueManagement/CatalogueManagement';
// import ViewServicableArea from './pages/retailerServicableArea/ViewServicableArea';

class App extends React.Component {
  constructor(props) {
    super(props);
    const language = getLanguage();
    if (!language) {
      setLanguage('en');
    }
    this.state = {
      language: language || 'en',
    };
    this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
  }

  handleChangeLanguage(language) {
    logFirebaseEvent(events.OO_ChangeLanguage, JSON.stringify({ language }));
    logClevertapEvents(
      clevertapEvents.OO_Dashboard_LanguageSwitch,
      { Language: language === 'en' ? 'ENGLISH' : 'HINDI' },
    );
    setLanguage(language);
    this.setState({ language });
  }

  render() {
    const { language } = this.state;
    const isApp = getIsApp() === 'true';
    return (
      <BrowserRouter>
        <Switch>
          <Route
            path="/login"
            render={props => (
              <Login
                language={language}
                {...props}
              />
            )}
          />
          <Route
            path="/stores"
            render={props => (
              <StoreList {...props} />
            )}
          />
          <Route
            path="/online-request"
            render={props => (
              <Wrapper
                Component={RequestDelivery}
                heading={Strings.Online_Ordering[language]}
                language={language}
                handleChangeLanguage={this.handleChangeLanguage}
                {...props}
                setup
              />
            )}
          />
          <Route
            path="/terms-and-conditions"
            render={props => (
              <TermsAndConditions
                language={language}
                handleChangeLanguage={this.handleChangeLanguage}
                {...props}
              />
            )}
          />
          <Route
            path="/terms-and-conditions-3-p-l"
            render={props => (
              <TermsAndConditions3PL
                language={language}
                handleChangeLanguage={this.handleChangeLanguage}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/settings"
            render={props => (
              <Wrapper
                Component={Settings}
                heading={Strings.Setting[language]}
                language={language}
                handleChangeLanguage={this.handleChangeLanguage}
                {...props}
                setup
              />
            )}
          />
          <Route
            exact
            path="/catalogue-management"
            render={props => (
              <Wrapper
                Component={CatalogueManagement}
                heading={Strings.Catalogue_Management[language]}
                language={language}
                handleChangeLanguage={this.handleChangeLanguage}
                {...props}
                setup
              />
            )}
          />
          <Route
            path="/update-exclusion-area"
            render={props => (
              <Wrapper
                Component={SetExclusionArea}
                heading={Strings.Setting[language]}
                language={language}
                handleChangeLanguage={this.handleChangeLanguage}
                {...props}
                setup
              />
            )}
          />
          <Route
            path="/update-location"
            render={props => (
              <Wrapper
                Component={UpdateLocation}
                heading={Strings.Setting[language]}
                language={language}
                handleChangeLanguage={this.handleChangeLanguage}
                {...props}
                setup
              />
            )}
          />
          <Route
            path="/setup"
            render={props => (
              <Wrapper
                Component={Setup}
                heading={Strings.Online_Ordering[language]}
                language={language}
                handleChangeLanguage={this.handleChangeLanguage}
                {...props}
                setup
              />
            )}
          />
          <Route
            path="/settlements"
            render={props => (
              <Wrapper
                Component={SettledSettlements}
                heading={Strings.Successful_Settlements[language]}
                language={language}
                handleChangeLanguage={this.handleChangeLanguage}
                {...props}
              />
            )}
          />
          <Route
            path="/pending-settlements"
            render={props => (
              <Wrapper
                Component={PendingSettlements}
                heading={Strings.Pending_Settlement[language]}
                language={language}
                handleChangeLanguage={this.handleChangeLanguage}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/orders"
            render={props => (
              <Wrapper
                Component={OrderList}
                heading={Strings.Orders[language]}
                language={language}
                handleChangeLanguage={this.handleChangeLanguage}
                {...props}
              />
            )}
          />
          <Route
            path="/orders/:id"
            render={props => (
              <Wrapper
                Component={OrderDetails}
                heading={Strings.Orders[language]}
                language={language}
                handleChangeLanguage={this.handleChangeLanguage}
                {...props}
              />
            )}
          />
          <Route
            path="/products/out-of-stock"
            render={props => (
              <Wrapper
                Component={RetailerProductList}
                heading={Strings.Products_Out_Of_Stock[language]}
                language={language}
                handleChangeLanguage={this.handleChangeLanguage}
                productListType="outOfStock"
                {...props}
                appDisplay={isApp}
              />
            )}
          />
          <Route
            path="/products/library"
            render={props => (
              <Wrapper
                Component={LibraryProductList}
                heading={Strings.Add_a_Product[language]}
                language={language}
                handleChangeLanguage={this.handleChangeLanguage}
                {...props}
                appDisplay={isApp}
              />
            )}
          />
          <Route
            path="/products/bulk-upload"
            render={props => (
              <Wrapper
                Component={BulkUpload}
                heading={Strings.Bulk_Upload[language]}
                language={language}
                handleChangeLanguage={this.handleChangeLanguage}
                {...props}
                appDisplay={isApp}
              />
            )}
          />
          <Route
            path="/products/add"
            render={props => (
              <Wrapper
                Component={ProductAdd}
                heading={Strings.Add_a_Product[language]}
                language={language}
                handleChangeLanguage={this.handleChangeLanguage}
                {...props}
                appDisplay={isApp}
              />
            )}
          />
          <Route
            path="/products/offers/add-bogo-offer"
            render={props => (
              <Wrapper
                Component={OfferAdd}
                heading={Strings.Add_Bogo_Offer[language]}
                language={language}
                handleChangeLanguage={this.handleChangeLanguage}
                {...props}
                offerType="bogo"
                appDisplay={isApp}
              />
            )}
          />
          <Route
            path="/products/offers/add-spend-offer"
            render={props => (
              <Wrapper
                Component={OfferAdd}
                heading={Strings.Add_Spend_Offer[language]}
                language={language}
                handleChangeLanguage={this.handleChangeLanguage}
                {...props}
                offerType="spend"
                appDisplay={isApp}
              />
            )}
          />
          <Route
            path="/products/offers"
            render={props => (
              <Wrapper
                Component={OfferList}
                heading={Strings.Offers[language]}
                language={language}
                handleChangeLanguage={this.handleChangeLanguage}
                {...props}
                appDisplay={isApp}
              />
            )}
          />
          <Route
            path="/products"
            render={props => (
              <Wrapper
                Component={RetailerProductList}
                heading={Strings.Products[language]}
                language={language}
                handleChangeLanguage={this.handleChangeLanguage}
                productListType="all"
                {...props}
                appDisplay={isApp}
              />
            )}
          />
          <Route
            path="/reviews"
            render={props => (
              <Wrapper
                Component={Reviews}
                heading={Strings.Reviews_and_Ratings[language]}
                language={language}
                handleChangeLanguage={this.handleChangeLanguage}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/performance-report"
            render={props => (
              <Wrapper
                {...props}
                Component={RetailerReports}
                heading={Strings.Online_Store_Performance[language]}
                language={language}
                handleChangeLanguage={this.handleChangeLanguage}
              />
            )}
          />
          <Route
            path="/performance-report/:code"
            render={props => (
              <RetailerReports
                {...props}
                language={language}
                handleChangeLanguage={this.handleChangeLanguage}
              />
            )}
          />
          <Route
            path="/market-report/:code"
            render={props => (
              <MarketReports
                {...props}
                language={language}
                handleChangeLanguage={this.handleChangeLanguage}
              />
            )}
          />
          <Route
            path="/"
            render={props => (
              <Wrapper
                Component={Dashboard}
                heading={Strings.Dashboard[language]}
                language={language}
                handleChangeLanguage={this.handleChangeLanguage}
                {...props}
              />
            )}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;

/**
 * Dashboard                => /
 * login                    => /login
 * Store List               => /stores
 * online store request     => /online-request
 * TnC                      => /terms-and-conditions
 * setup                    => /setup
                                => /setup?stage=welcome
                                => /setup?stage=sku
                                => /setup?stage=product-library
                                => /setup?stage=product-add
                                => /setup?stage=product-retailer
                                => /setup?stage=store-details
                                => /setup?stage=account-details
 * settings                 => /settings
                                => /settings?state=store-details
                                => /settings?state=account-details
 * Update Exclusion Area    => /update-exclusion-area
 * Order List               => /orders
                                => /orders?status=in_progress
                                => /orders?status=previous
                                => /orders?status=pending
                                => /orders?status=modified
                                => /orders?status=customer_changes
                                => /orders?status=ready_to_ship
                                => /orders?status=out_for_delivery
                                => /orders?status=delivered
                                => /orders?status=cancelled
 * Order Details            => /order/:id
 * Retailer Products        => /products
 * Out of stock products    => /products/out-of-stock
 * mpaani library products  => /products/library
 * Bulk Upload              => /products/bulk-upload
 * Add New Product          => /products/add
 * Offers                   => /products/offers
 * Add bogo offer           => /products/offers/add-bogo-offer
 * Add spend offer          => /products/offers/add-spend-offer
 * Pending settlements      => /pending-settlements
 * Reconciled settlements   => /settlements
 * Reviews                  => /reviews
 * New Analytics            => /performance-report
 * Analytics bypass login   => /performance-report/:retailer-code
 * Market Report bypass login => /market-report/:retailer-code
*/
