import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import Strings from '../../Strings';
import { OrderList, CustomerDetails, PaymentPreference } from '../../components/derived/orders';
import {
  getAddressString, longDateOptions, timeOptions,
} from '../../Utils';
import './styles.scss';

function DeliveredOrderDetails(props) {
  const {
    orderDetails, language, orderHistory,
  } = props;
  return (
    <Container fluid className="bg-light position-relative py-3">
      <Row className="mb-4 text-center">
        <Col>
          <Row className="border shadow bg-white p-4 m-0">
            <Col xs={24} className="text-success"><h3 className="m-0">{Strings.Delivered_Order[language]}</h3></Col>
            <Col xs={24} className="text-muted text-center">{`${Strings.Order_Id[language]}: #${orderDetails.orderId}`}</Col>
            <Col xs={24} className="text-muted">
              {`${new Date(orderHistory[3].createdOn).toLocaleDateString(`${language}-in`, longDateOptions)}, ${new Date(orderHistory[3].createdOn).toLocaleTimeString(`${language}-in`, timeOptions)}`}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <div className="shadow bg-white">
            <CustomerDetails
              customer={orderDetails.customer}
              address={
                orderDetails.pickupFromStore
                  ? null
                  : getAddressString(orderDetails.deliveryDetails.deliveryAddress)
              }
              description={orderDetails.description}
              language={language}
            />
          </div>
        </Col>
      </Row>
      <PaymentPreference
        payment={orderDetails.payment}
        language={language}
        settlement={orderDetails.settlement}
        delivered
      />
      <Row className="m-0">
        <Col xs={24} className="zig-zag" />
      </Row>
      <Row>
        <Col>
          <OrderList
            {...props}
            orderDetails={orderDetails}
          />
        </Col>
      </Row>
      <Row className="mx-0 mb-4">
        <Col xs={24} className="zig-zag bottom" />
      </Row>
    </Container>
  );
}

DeliveredOrderDetails.propTypes = {
  orderDetails: PropTypes.shape({}).isRequired,
  orderHistory: PropTypes.arrayOf(PropTypes.object).isRequired,
  language: PropTypes.string.isRequired,
};

export default DeliveredOrderDetails;
