/* eslint-disable max-len */
import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';

function TermsAndConditions3PL() {
  return (
    <div
      className="bg-white"
    >
      <Container>
        <Row className="sticky-top bg-white">
          <Col className="bg-white py-2">
            <h3 className="text-center m-0">Third-Party Logistics Terms & Conditions</h3>
            <div className="text-center text-muted fs-1">MPAANI Solutions Private Limited</div>
            <div className="fs-1 text-muted text-center">Last updated: Sep, 2021</div>
          </Col>
        </Row>
        <Row className="py-4">
          <Col xs={24} className="text-justify">
            <b
              className="fs-5"
            >
              Delivery Support Service:
            </b>
            <p
              className="pt-2"
            >
              Delivery Support Service is delivery option provided by LoveLocal to their retail partner with approved KYC to help deliver home delivery orders to their customers
            </p>
            <p>
              Retail partners doesn’t have to pay for the service, it’s free of cost. The delivery charge will be paid by the customer while placing the order either by online payment or give cash to the delivery boy on delivery
            </p>
            <p>
              Below are the steps on the entire delivery support service:
            </p>
            <ol>
              <li>
                Select ‘Need Delivery Support’ on the Delivery Details page in the App
              </li>
              <li
                className="pt-1"
              >
                When customer places an order, retail partner needs to confirm the order to assign a delivery boy for a pickup. Please note, delivery boy will usually arrive within 20 mins after you confirm the order. Make sure to packed the product and keep it ready for immediate pickup
              </li>
              <li
                className="pt-1"
              >
                When delivery boy arrives, handover the product to the delivery boy as per the order details mentioned in your and delivery boys app. Note, cash on delivery order payment will be settled within 48 hours by LoveLocal into your bank account.
              </li>
              <li
                className="pt-1"
              >
                Delivery boy delivers the order to the customer on the address shared by the customer
              </li>
            </ol>
            <p
              className="pt-2"
            >
              Delivery Support Service is available for the orders placed within the range of 5kms from the customers location
            </p>

            <b
              className="fs-5"
            >
              Delivery Charges:
            </b>
            <p
              className="pt-2"
            >
              The delivery charge will be paid by the customer on an order basis and the cost calculation will be done based on the distance between the retail store (Pickup) and customer location (Drop).
            </p>
            <div>
              <img
                src="/Assets/tnc-3pl-charges.png"
                alt=""
                className="w-100"
              />
            </div>
            <p>
              Please make sure to add bank details in the bank details section to settle online, coupons or cash on delivery order payment
            </p>
            <p>
              Settlement charges and all taxes applicable will be deducted while settlement
            </p>

            <b
              className="fs-5"
            >
              Delivery Conditions:
            </b>
            <p
              className="pt-2"
            >
              If in case delivery boy is not assigned then retailer can cancel the order or delivery themselves.
            </p>
            <p>
              Delivery boy once assigned cannot be cancelled or sent back without order unless cancelled by LoveLocal. In such case, if in case delivery is not done because of delay or product not available with retail partner then the delivery cost will be deduct from the future settlements
            </p>
            <p>
              Customer cancelling the order will be liable to pay for the delivery charges on digital transaction or will be deducted from their future transactions.
            </p>
            <p>
              Delivery charge on the order will be purely based on the charges as mentioned by the delivery support partners. LoveLocal doesn’t take any margin or commission from it.
            </p>
            <p>
              Under no circumstances LoveLocal will be liable for the order picked up by the delivery boy in case of damage or loss. However, LoveLocal will assist to get in connect with the delivery partner
            </p>
            <p>
              Retail partner needs to take back the products from the delivery boy in case customer returns, not available or wants delivery later. Return charges will be applicable and deducted from customers online payment or cash in future transactions.
            </p>
            <p>
              Late hours delivery is not preferred and hence confirm orders between 10am and 8pm if delivery option is selected as Delivery Support Service.
            </p>
            <p>
              Delivery boys are not employed by LoveLocal or Delivery Partners, they work as partner with the delivery company. Any type payment transaction, tips, cash, gifts or rewards in any way given or taken from the delivery boy without LoveLocal knowledge or permission then LoveLocal won’t be neither liable nor responsible for any kind of loss.
            </p>
            <p>
              Any issue or dispute between delivery boy, customer or retail partner with respect to delivery needs to be addressed immediately to LoveLocal. LoveLocal won’t be liable for any action or out of process steps taken by either stakeholder without our knowledge.
            </p>
            <p>
              Any claims raised by any of the Parties shall be adjusted in the next invoice after settlement
            </p>
            <p>
              Parties agree that Merchant can deduct the tax (TDS) as per provisions of Income Tax Act, 1961 wherever applicable.
            </p>
            <p>
              Service Provider reserves the right to change the Rates & Terms of Payments at any given point of time with 10 days of prior notice about the changes.
            </p>
            <p>
              The retail partner shall not book / handover or allow to be handed over any Product which is banned, restricted, illegal, prohibited, stolen, infringing of any third party rights, hazardous or dangerous or in breach of any tax laws. In the event Merchant hands over or provides the aforesaid Products to the Service Provider then the Service Provider shall not be responsible and liable for any loss, damage, theft or misappropriation of such Products
            </p>
            <p>
              The retail partner shall ensure that the packaging of the Products is safe, proper and adequate to withstand the normal transportation and environmental hazards and in compliance with applicable laws. LoveLocal or the delivery partner shall not be liable or responsible for any loss or damages to the retail partner if the partner is not in strict compliance with this clause
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default TermsAndConditions3PL;
