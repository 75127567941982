import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import QueryString from 'query-string';
import Strings from '../../Strings';
import { getMarketReport } from '../../../api/api';
import { ErrorHandler } from '../../components/common';
import { getIsApp } from '../../Utils';

const config = {
  'Daily Shop Views': {
    visibleHeaders: ['Date', 'Total Views', 'Total Customer', 'Orders', 'Total Sales'],
    showHeader: true,
  },
  'Daily Offers': {
    visibleHeaders: ['Date', 'Value Offer Active', 'Spend Offer Active', 'Special Bogo Offer Active', 'Coupon Active', 'Product Offer Active'],
    showHeader: true,
  },
  'Shop Views Report': {
    visibleHeaders: ['display_name', 'value'],
    showHeader: false,
  },
  'Scratch Cards Summary (Lovelocal Funded)': {
    visibleHeaders: ['display_name', 'value'],
    showHeader: false,
  },
  'Coupons Summary (Lovelocal Funded)': {
    visibleHeaders: ['display_name', 'value'],
    showHeader: false,
  },
  'Product Based Offers Summary (Lovelocal Funded)': {
    visibleHeaders: ['display_name', 'value'],
    showHeader: false,
  },
  'Bogo Offers Summary (Shop Funded)': {
    visibleHeaders: ['display_name', 'value'],
    showHeader: false,
  },
  'Spend Offers Summary (Shop Funded)': {
    visibleHeaders: ['display_name', 'value'],
    showHeader: false,
  },
  'Value Offers Summary (Shop Funded)': {
    visibleHeaders: ['display_name', 'value'],
    showHeader: false,
  },
  'Shop Details': {
    visibleHeaders: ['display_name', 'value'],
    showHeader: false,
  },
};

const TableData = tableData => (
  <div className="m-2 p-2">
    <table border="1">
      {tableData.showHeader && (
      <thead>
        <tr>
          {tableData.headers.map(field => <th className="p-2">{field}</th>)}
        </tr>
      </thead>
      )}
      {tableData.rows.map(row => (
        <tr>
          {row.map(fieldValue => <td className="p-2">{fieldValue}</td>)}
        </tr>
      ))}
    </table>
  </div>
);

const PlotTable = (tableJson) => {
  const components = tableJson.map(tableData => (
    <div>
      <h3>{tableData.title}</h3>
      {TableData(tableData)}
    </div>
  ));
  return components;
};

const monthsMap = [
  {
    monthId: 0,
    month: 'January',
  },
  {
    monthId: 1,
    month: 'February',
  },
  {
    monthId: 2,
    month: 'March',
  },
  {
    monthId: 3,
    month: 'April',
  },
  {
    monthId: 4,
    month: 'May',
  },
  {
    monthId: 5,
    month: 'June',
  },
  {
    monthId: 6,
    month: 'July',
  },
  {
    monthId: 7,
    month: 'August',
  },
  {
    monthId: 8,
    month: 'September',
  },
  {
    monthId: 9,
    month: 'October',
  },
  {
    monthId: 10,
    month: 'November',
  },
  {
    monthId: 11,
    month: 'December',
  },
];

const date = new Date();

const yearMap = [];


for (let year = 2019; year <= date.getFullYear(); year += 1) {
  yearMap.push(year);
}

class MarketReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tables: [],
      loadingReport: true,
      error: false,
      month: date.getMonth(),
      year: date.getFullYear(),
    };
    this.handleLoad = this.handleLoad.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.handleLoad();
  }

  arrayDictTable = (data) => {
    let tableData = [];
    data.sort((a, b) => a.index - b.index);
    for (let i = 0; i < data.length; i += 1) {
      const record = data[i];
      if (record.type === 'table') {
        const tableEntry = this.parseTableData(record);
        tableData.push(tableEntry);
      } else {
        const resp = this.arrayDictTable(record.value);
        if (Array.isArray(resp)) {
          tableData = [...tableData, ...resp];
        }
      }
    }
    return tableData;
  }

  parseTableData = (record) => {
    const { visibleHeaders, showHeader } = config[record.display_name];
    const rows = [];
    record.value.forEach((entry) => {
      const row = [];
      visibleHeaders.forEach((key) => {
        let value = entry[key];
        if (entry.type === 'amount' && key === 'value') {
          value = `₹ ${value}`;
        }
        row.splice(visibleHeaders.indexOf(key), 0, value);
      });
      rows.push(row);
    });
    return {
      headers: visibleHeaders,
      title: record.display_name,
      rows,
      showHeader,
    };
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      loadingReport: true,
    }, this.handleLoad);
  }

  handleLoad() {
    const { match } = this.props;
    const retailerCode = match.params.code;
    const { month, year } = this.state;
    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 0);

    getMarketReport(retailerCode, startDate.getTime() / 1000, endDate.getTime() / 1000)
      .then((res) => {
        const data = this.arrayDictTable(res.data.results);
        this.setState({
          tables: data,
          loadingReport: false,
          error: false,
        });
      }).catch((err) => {
        console.error(err);
        this.setState({
          loadingReport: false,
          error: true,
        });
      });
  }

  render() {
    const {
      language, match, location,
    } = this.props;
    const retailerCode = match.params.code;
    const hideHeader = QueryString.parse(location.search)['hide-header'];
    const {
      loadingReport, report, error,
      month, year, tables,
    } = this.state;

    const isApp = getIsApp() === 'true';
    return (
      <>
        {
          (!isApp && retailerCode && !hideHeader)
            ? (
              <Row
                className="mx-0 bg-primary py-3 text-white"
              >
                <Col
                  className="d-flex align-items-center"
                >
                  <img
                    src="/Assets/love-local.png"
                    alt=""
                    className="mh-40p"
                  />
                  <h3
                    className="m-0 px-4"
                  >
                    {report ? report.store_name : ''}
                  </h3>
                </Col>
              </Row>
            ) : ''
        }
        <Container
          fluid
          className={`${retailerCode ? 'mw-1200p' : ''} p-4`}
        >
          <Row
            className="mb-4 px-2"
          >
            <Col
              xs={24}
              className="fs-4 font-weight-bold mb-4"
            >
              {Strings.Online_Store_Marketing[language]}
            </Col>
            <Col
              xs="auto"
              className="pr-1"
            >
              <select
                name="month"
                value={month}
                onChange={this.handleChange}
                className="form-control border-primary"
              >
                {
                  monthsMap.map(item => (
                    <option
                      key={item.monthId}
                      value={item.monthId}
                    >
                      {item.month}
                    </option>
                  ))
                }
              </select>
            </Col>
            <Col
              xs="auto"
              className="pl-1"
            >
              <select
                name="year"
                value={year}
                onChange={this.handleChange}
                className="form-control border-primary"
              >
                {
                  yearMap.map(item => (
                    <option
                      key={item}
                      value={item}
                    >
                      {item}
                    </option>
                  ))
                }
              </select>
            </Col>
          </Row>

          {
            loadingReport
              ? (
                <Row
                  className="justify-content-center py-4 mx-0"
                >
                  <Col
                    xs="auto"
                    className="spinner-border text-primary p-0"
                    role="status"
                  >
                    <span
                      className="sr-only"
                    >
                      Loading...
                    </span>
                  </Col>
                </Row>
              ) : ''
          }
          {
            error
              ? (
                <ErrorHandler
                  show={error}
                  language={language}
                  handleRetry={() => {
                    this.setState(
                      {
                        loadingReport: true,
                        error: false,
                      },
                      () => {
                        this.handleLoad(retailerCode);
                      },
                    );
                  }}
                />
              ) : ''
          }
          {
            tables && PlotTable(tables)
          }

        </Container>
      </>
    );
  }
}

MarketReports.propTypes = {
  language: PropTypes.string.isRequired,
  match: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default MarketReports;
