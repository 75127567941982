import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import { catalogueManagement } from '../../../api/api';
import { catalogueMappingTemplate } from '../../../api/urls';
import Strings from '../../Strings';


class CatalogueManagement extends React.Component {
  constructor(props) {
    super(props);
    const storeProfile = JSON.parse(window.localStorage.getItem('profiles'));
    const retailerCode = storeProfile[0].code;
    this.state = {
      retailerCode,
      uploadStatus: {},
    };
  }

  handleCatalogueUpdate = (event, action) => {
    const { retailerCode } = this.state;
    const formData = new FormData();
    formData.append('data', event.target.files[0]);
    formData.append('action', action);
    catalogueManagement(
      'POST',
      retailerCode,
      formData,
    ).then((res) => {
      this.setState({
        uploadStatus: {
          [action]: { status: 'SUCCESS', msg: res.data.status },
        },
      });
    }).catch((err) => {
      let errorMsg = 'Oops Something Went Wrong!! Please Try Again!!';
      if (
        err
        && err.response
        && err.response.data
        && err.response.data.server_message
      ) {
        errorMsg = err.response.data.server_message;
      }
      this.setState({
        uploadStatus: {
          [action]: { status: 'ERROR', msg: `ERROR! ${errorMsg}` },
        },
      });
    }).finally(() => {
      setTimeout(() => {
        document.getElementById(action).value = '';
        this.setState({
          uploadStatus: {},
        });
      }, 5000);
    });
  }

  downloadProductCatalogue = async () => {
    const { retailerCode } = this.state;
    catalogueManagement(
      'GET',
      retailerCode,
    ).then((res) => {
      if (res.data.catalogueFileLink) {
        const hiddenElement = document.createElement('a');
        hiddenElement.href = `${res.data.catalogueFileLink}`;
        hiddenElement.target = '_blank';
        hiddenElement.download = 'product_catalogue.csv';
        hiddenElement.rel = 'noopener noreferrer';
        hiddenElement.click();
      }
    }).catch(() => {
      this.setState({
        uploadStatus: {
          DOWNLOAD_CATALOGUE: {
            status: 'ERROR', msg: 'Error While Downloading.',
          },
        },
      });
      setTimeout(() => {
        this.setState({
          uploadStatus: {},
        });
      }, 5000);
    });
  }

  render() {
    const { language } = this.props;
    const { uploadStatus } = this.state;
    return (
      <Container fluid className="d-flex flex-column fs-2 my-4">
        <Container fluid className="py-4 border-bottom-primary">
          <Row className="py-4">
            <Col xs={14} className="font-weight-bold">
              {Strings.Download_Your_Product_Catalogue[language]}
            </Col>
            <Col xs={10} className="d-flex">
              <Button onClick={this.downloadProductCatalogue} className="mr-2">
                {Strings.Download[language]}
              </Button>
              {
                !!uploadStatus.DOWNLOAD_CATALOGUE && (
                  <div className={`${uploadStatus.DOWNLOAD_CATALOGUE.status === 'SUCCESS' ? 'text-success' : 'text-danger'} justify-content-center`}>
                    {uploadStatus.DOWNLOAD_CATALOGUE.msg}
                  </div>
                )
              }
            </Col>
          </Row>
          <Row className="py-4">
            <Col xs={14}>
              <span className="font-weight-bold">{Strings.Upload_file_to_Update_Prices[language]}</span>
              <br />
              <i>
                <span className="text-danger">*</span>
                <span className="fs-1">
                The fields (Product ID or LL Product ID) and
                Selling Price are mandatory. Only csv files are supported
                </span>
              </i>
            </Col>
            <Col xs={10} className="d-flex">
              <input
                type="file"
                accept={`.csv,
                  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                  application/vnd.ms-excel`}
                id="UPDATE_PRICE"
                onChange={e => this.handleCatalogueUpdate(e, 'UPDATE_PRICE')}
              />
              <a
                className="btn btn-link py-0 px-2 fs-1"
                href={catalogueMappingTemplate()}
                target="_blank"
                rel="noopener noreferrer"
              >
                {Strings.Download_Template[language]}
              </a>
            </Col>
          </Row>
          {
            !!uploadStatus.UPDATE_PRICE && (
              <Row className={`${uploadStatus.UPDATE_PRICE.status === 'SUCCESS' ? 'text-success' : 'text-danger'} justify-content-center`}>
                {uploadStatus.UPDATE_PRICE.msg}
              </Row>
            )
          }
        </Container>
        <Container fluid className="mt-auto">
          <Row className="py-4">
            <Col xs={24} className="font-weight-bold">
              {Strings.Do_you_want_to_map_your_Billing_POS_export[language]}
            </Col>
          </Row>
          <Row className="py-4">
            <Col xs={14}>
              <span className="font-weight-bold">{Strings.Upload_a_mapping_with[language]}</span>
              <br />
              <i>
                <span className="text-danger">*</span>
                <span className="fs-1">Expected fields - Product ID, LL Product ID</span>
              </i>
            </Col>
            <Col xs={10}>
              <input
                type="file"
                accept={`.csv,
                  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                  application/vnd.ms-excel`}
                id="UPLOAD_MAPPING"
                onChange={e => this.handleCatalogueUpdate(e, 'UPLOAD_MAPPING')}
              />
            </Col>
          </Row>
          {
            !!uploadStatus.UPLOAD_MAPPING && (
              <Row className={`${uploadStatus.UPLOAD_MAPPING.status === 'SUCCESS' ? 'text-success' : 'text-danger'} justify-content-center`}>
                {uploadStatus.UPLOAD_MAPPING.msg}
              </Row>
            )
          }
        </Container>
      </Container>
    );
  }
}

CatalogueManagement.propTypes = {
  language: PropTypes.string.isRequired,
};

export default CatalogueManagement;
