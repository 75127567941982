import React from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import { Redirect } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import Strings from '../../Strings';
import Welcome from './Welcome';
import StoreDetails from './StoreDetails';
import AccountDetails from './AccountDetails';
import BulkUpload from '../products/BulkUpload';
import LibraryProductList from '../products/LibraryProductList';
import RetailerProductList from '../products/RetailerProductList';
import SetupStepper from '../../components/derived/setup/SetupStepper';
import './styles.scss';
import ProductAdd from '../products/ProductAdd';


function renderComponent(stage = 'welcome', props) {
  switch (stage) {
    default:
    case 'welcome': return <Welcome {...props} />;
    case 'sku': return <BulkUpload {...props} />;
    case 'product-library': return <LibraryProductList {...props} />;
    case 'product-add': return <ProductAdd {...props} />;
    case 'product-retailer': return <RetailerProductList {...props} />;
    case 'store-details': return <StoreDetails {...props} />;
    case 'account-details': return <AccountDetails {...props} />;
  }
}

class Setup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 'welcome',
      loading: true,
    };
    this.handleLoad = this.handleLoad.bind(this);
  }

  componentWillMount() {
    this.handleLoad(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.handleLoad(nextProps);
  }

  handleLoad(props) {
    const { storeProfile, location } = props;
    const params = QueryString.parse(location.search);
    if (storeProfile) {
      this.setState({
        stage: storeProfile.acceptedTnC ? params.stage || 'sku' : 'welcome',
        loading: false,
      });
    }
  }

  render() {
    const {
      language, handleChangeLanguage, storeProfile, deliveryRequests,
    } = this.props;
    const { loading, stage } = this.state;
    if (storeProfile && storeProfile.onHomeDelivery) {
      return <Redirect to="/" />;
    }
    if ((deliveryRequests && !deliveryRequests[0]) || (deliveryRequests && deliveryRequests[0] && deliveryRequests[0].status !== 'ACCEPTED')) {
      return <Redirect to="/online-request" />;
    }
    return loading ? (
      <Row className="justify-content-center w-100 py-4">
        <Col xs="auto" className="spinner-border text-primary p-0" role="status">
          <span className="sr-only">Loading...</span>
        </Col>
      </Row>
    ) : (
      <Col className="bg-white h-100">
        <Row className="h-100">
          <Col xs={24} className="py-2 h-42p d-lg-none">
            <Row>
              <Col className="pr-1">
                <b>{Strings.Setup_Your_Online_Store[language]}</b>
              </Col>
              <Col xs="auto" className="text-right pl-1">
                <div className="bg-secondary d-inline-block rounded">
                  <Button
                    variant={language === 'en' ? 'primary shadow-sm' : 'secondary'}
                    className="text-white py-0 px-4"
                    onClick={() => handleChangeLanguage('en')}
                  >
                    ENG
                  </Button>
                  <Button
                    variant={language === 'hi' ? 'primary shadow-sm' : 'secondary'}
                    className="text-white py-0 px-4"
                    onClick={() => handleChangeLanguage('hi')}
                  >
                    हिंदी
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={24} className={stage === 'welcome' ? 'd-none' : 'bg-light'}>
            <SetupStepper {...this.props} stage={stage} />
          </Col>
          <Col xs={24} className={`p-0 ${stage === 'welcome' ? 'welcome-section' : 'setup-section'}`}>
            {renderComponent(stage, this.props)}
          </Col>
        </Row>
      </Col>
    );
  }
}

Setup.propTypes = {
  language: PropTypes.string.isRequired,
  handleChangeLanguage: PropTypes.func.isRequired,
  storeProfile: PropTypes.shape({}),
  deliveryRequests: PropTypes.arrayOf(PropTypes.object),
  acceptedTnC: PropTypes.bool.isRequired,
  location: PropTypes.shape({}).isRequired,
};

Setup.defaultProps = {
  storeProfile: null,
  deliveryRequests: null,
};

export default Setup;
