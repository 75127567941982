import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import Strings from '../../Strings';

export default function ErrorHandler(props) {
  const {
    show, handleRetry, language, autoRefresh,
  } = props;
  return (
    <div className={show ? 'text-center p-4 w-100' : 'd-none'}>
      <div>
        <img alt="" src="/Assets/error-placeholder.png" className="error-placeholder" />
      </div>
      <div><b>{Strings.Error_Message[language]}</b></div>
      <div><b>{Strings.Check_internet[language]}</b></div>
      <div className={autoRefresh ? '' : 'd-none'}>{Strings.Refresh_Message[language]}</div>
      <div className={handleRetry ? 'mt-3' : 'd-none'}>
        <Button
          variant="outline-primary rounded-0"
          onClick={handleRetry}
        >
          {Strings.Retry[language]}
        </Button>
      </div>
    </div>
  );
}

ErrorHandler.propTypes = {
  show: PropTypes.bool,
  handleRetry: PropTypes.func,
  language: PropTypes.string,
  autoRefresh: PropTypes.bool,
};

ErrorHandler.defaultProps = {
  show: false,
  handleRetry: null,
  language: 'en',
  autoRefresh: false,
};
