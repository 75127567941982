export default {
  Online_Delivery: {
    en: 'Online Delivery',
    hi: 'ऑनलाइन डिलीवरी',
  },
  Share_Business_Card: {
    en: 'SHARE BUSINESS CARD',
    hi: 'शेयर बिज़नेस कार्ड',
  },
  Order_Search_Placeholder: {
    hi: 'नाम या नंबर या आर्डर आईडी से सर्च करें',
    en: 'Search By Name / Mobile No. / OrderId',
  },
  In_Progress: {
    en: 'In Progress',
    hi: 'आर्डर प्रोसेसिंग में है',
  },
  Previous_Orders: {
    en: 'Previous Orders',
    hi: 'पिछले ऑर्डर्स',
  },
  My_Online_Inventory: {
    en: 'My Online Inventory',
    hi: 'मेरी ऑनलाइन सूची',
  },
  Online_Store_Report: {
    en: 'Online Store Report',
    hi: 'ऑनलाइन स्टोर की रिपोर्ट',
  },
  Product_Being_Sold: {
    en: 'Products Being Sold',
    hi: 'बिकने वाले प्रोडक्ट्स',
  },
  Offer_Live: {
    en: 'Offers Live',
    hi: 'सक्रिय ऑफर्स',
  },
  Total_Earning: {
    en: 'Total Earning',
    hi: 'कुल कमाई',
  },
  Credited_For: {
    en: 'Credited For',
    hi: '',
  },
  Average_Store_Rating: {
    en: 'Average Store Rating',
    hi: 'स्टोर की औसत रेटिंग',
  },
  No_of_Reviews_Rating: {
    en: 'No. of Reviews/Rating',
    hi: 'रिव्यु या रेटिंग की संख्या',
  },
  Products_Out_Of_Stock: {
    en: 'Products Out of Stock',
    hi: 'प्रोडक्ट्स आउट ऑफ़ स्टॉक',
  },
  Logout: {
    en: 'Logout',
    hi: 'लॉग आउट',
  },
  Visit_Analytics: {
    en: 'Visit Analytics',
    hi: 'एनालिटिक्स देखें',
  },
  Request_Online_Ordering: {
    en: 'Request Online Ordering',
    hi: 'ऑनलाइन ऑर्डर फीचर की मांग करें',
  },
  Online_Ordering: {
    en: 'Online Ordering',
    hi: 'ऑनलाइन ऑर्डरिंग',
  },
  Online_Ordering_Request: {
    en: 'Online Ordering Request',
    hi: 'ऑनलाइन ऑर्डरिंग रिक्वेस्ट',
  },
  Online_Ordering_Requests: {
    en: 'Online Ordering Requests',
    hi: 'ऑनलाइन ऑर्डरिंग रिक्वेस्ट्स',
  },
  Show_All: {
    en: 'Show All',
    hi: 'सभी दिखाएं',
  },
  Show_Less: {
    en: 'Show Less',
    hi: 'कम दिखाएं',
  },
  Request_Status: {
    en: 'Request Status',
    hi: 'रिक्वेस्ट की स्थिति',
  },
  Send_Request: {
    en: 'Confirm & Send Request',
    hi: 'अनुरोध भेजें',
  },
  Help: {
    en: 'Help',
    hi: 'मदद',
  },
  Setup_Your_Store: {
    en: 'Setup Your Store',
    hi: 'ऑनलाइन स्टोर सेट अप',
  },
  Terms_and_Conditions: {
    en: 'Terms and Conditions',
    hi: 'नियम और शर्तें',
  },
  Return_Requests: {
    en: 'Return Requests',
    hi: 'वापसी के अनुरोध',
  },
  Setup_Your_Online_Store: {
    en: 'Setup Your Online Store',
    hi: 'अपना ऑनलाइन स्टोर सेटअप करें',
  },
  Select_SKU: {
    en: 'Select SKU',
    hi: 'प्रोडक्ट्स चुनें',
  },
  Store_Details: {
    en: 'Store Details',
    hi: 'स्टोर की जानकारी',
  },
  Account_Details: {
    en: 'Bank Details',
    hi: 'बैंक की जानकारी',
  },
  Optional: {
    en: 'Optional',
    hi: 'वैकल्पिक',
  },
  Next: {
    en: 'Next',
    hi: 'अगला',
  },
  Back: {
    en: 'Back',
    hi: 'पीछे',
  },
  Delivery_Timings: {
    en: 'Delivery Timings',
    hi: 'डिलीवरी का समय',
  },
  Set_Delivery_Timings: {
    en: 'Set Delivery Timings',
    hi: 'डिलीवरी का समय निर्धारित करें',
  },
  Delivery_Timing_24_Hrs: {
    en: '24 Hours Delivery',
    hi: '24 घंटे डिलीवरी',
  },
  From: {
    en: 'From',
    hi: 'से',
  },
  To: {
    en: 'To',
    hi: 'तक',
  },
  Store_Type: {
    en: 'Store Category',
    hi: 'स्टोर केटेगरी',
  },
  Shop_Address: {
    en: 'Store Address',
    hi: 'स्टोर का पता',
  },
  Change_Location: {
    en: 'Change Location',
    hi: 'पता बदलें',
  },
  Pin_Code: {
    en: 'PIN Code',
    hi: 'पिनकोड',
  },
  Landmark: {
    en: 'Landmark',
    hi: 'लैंडमार्क',
  },
  Location_Search_By: {
    en: 'Search by Landmark, PIN Code, or Location',
    hi: 'लैंडमार्क, पिन कोड या स्थान के आधार पर खोजें',
  },
  Update: {
    en: 'Update',
    hi: 'अपडेट करें',
  },
  Set_Store_Type: {
    en: 'Set Store Category',
    hi: 'स्टोर केटेगरी सेट करें',
  },
  Select_Store_Category: {
    en: '--- Select Store Category ---',
    hi: '--- स्टोर केटेगरी चुनें ---',
  },
  Offer_Name_empty_error: {
    en: 'Offer Name is required',
    hi: 'ऑफर का नाम आवश्यक है',
  },
  Offer_Name_short_error: {
    en: 'Offer Name should contain atleast 3 characters',
    hi: 'ऑफर का नाम में कम से कम 3 अक्षर होने चाहिए',
  },
  Description_empty_error: {
    en: 'Description is required',
    hi: 'विवरण आवश्यक है',
  },
  Description_short_error: {
    en: 'Description Name should contain atleast 3 characters',
    hi: 'विवरण में कम से कम 3 अक्षर होने चाहिए',
  },
  Spend_Amount_empty_error: {
    en: 'Spend Amount is required',
    hi: 'स्पेंड का मूल्य आवश्यक है',
  },
  Vaild_Till_time_error: {
    en: 'Validity is required',
    hi: 'वैधता आवश्यक है',
  },
  Store_Type_empty_error: {
    en: 'Store Category is required',
    hi: 'स्टोर केटेगरी आवश्यक है',
  },
  Select_Action: {
    en: '-- Select Action --',
    hi: '-- कार्य चुनें --',
  },
  Action_empty_error: {
    en: 'Action is required',
    hi: 'कार्य आवश्यक है',
  },
  Select_Category: {
    en: '--- Select Category ---',
    hi: '--- केटेगरी चुनें ---',
  },
  Select_Category_empty_error: {
    en: 'Primary Category is required',
    hi: 'मुख्य केटेगरी आवश्यक है',
  },
  Primary_Category: {
    en: 'Primary Category',
    hi: 'मुख्य केटेगरी',
  },
  Secondary_Category: {
    en: 'Secondary Category',
    hi: 'माध्यमिक केटेगरी',
  },
  Change_Store_Type_Message: {
    en: 'To change the store category you can call us on +91-8448448906.',
    hi: 'स्टोर केटेगरी बदलने के लिए आप हमें +91-8448448906 पर कॉल कर सकते हैं',
  },
  Promise_To_retailers: {
    en: 'Our Promise to you - Your customers remain yours under "Digital Exclusivity"',
    hi: 'आपसे हमारा वादा - "डिजिटल विशिष्टता" के तहत आपके ग्राहक हमेशा आपके रहेंगे।',
  },
  Digital_Exclusivity: {
    en: `We offer you "Digital Exclusivity" on LoveLocal Online Ordering program, which means that
      the customers you have added in your store's loyalty program will not see any other store
      of the same category in your neighborhood, as defined by LoveLocal. However, if the customer
      is already a member of another same category store then the customer would get a choice
      to shop online from both the stores.`,
    hi: `हम आपको LoveLocal के ऑनलाइन ऑर्डरिंग सुविधा पर एक "डिजिटल विशिष्टता" प्रदान करते हैं जिसका अर्थ है कि आपने अपने स्टोर
      की लॉयल्टी प्रोग्राम में जो ग्राहक जोड़े हैं, वह आपके छेत्र में उसी श्रेणी का कोई अन्य स्टोर नहीं देख पाएंगे। हालाँकि यदि ग्राहक पहले
      sसे ही समान श्रेणी के स्टोर का सदस्य है तो ग्राहक को दोनों दुकानों से ऑनलाइन खरीदारी करने का विकल्प मिलेगा।`,
  },
  Delivery_Radius: {
    en: 'Delivery Radius',
    hi: 'डिलीवरी की सीमा',
  },
  Help_Video: {
    en: 'Help Video',
    hi: 'हेल्प वीडियो',
  },
  Set_Delivery_Radius: {
    en: 'Set Delivery Radius',
    hi: 'डिलीवरी की सीमा निर्धारित करें',
  },
  Delivery_Radius_empty_error: {
    en: 'Delivery Radius is required',
    hi: 'डिलीवरी की सीमा आवश्यक है',
  },
  Delivery_Amount_empty_error: {
    en: 'Delivery Amount is required',
    hi: 'डिलीवरी शुल्क आवश्यक है',
  },
  Accepted_Payment_Methods: {
    en: 'Accepted Payment Methods',
    hi: 'स्वीकृत भुगतान विधियां',
  },
  Set_Accepted_Payment_Methods: {
    en: 'Set Accepted Payment Methods',
    hi: 'स्वीकृत भुगतान विधियां निर्धारित करें',
  },
  Accepted_Payment_Methods_empty_error: {
    en: 'Atleast one payment method is required',
    hi: 'कम से कम एक भुगतान विधि आवश्यक है',
  },
  Delivery_Radius_format_error: {
    en: 'Please provide a valid delivery radius (eg. 2.5 KM)',
    hi: 'कृपया एक वैध डिलीवरी की सीमा प्रदान करें (उदा. 2.5 KM)',
  },
  Minimum_Amount: {
    en: 'Minimum Shopping Amount',
    hi: 'न्यूनतम खरीदारी राशि',
  },
  If_Not_Meet: {
    en: 'If Not Minimum Amount',
    hi: 'अगर न्यूनतम राशि नहीं है',
  },
  Min_Order_Value: {
    en: 'Min. Order Value',
    hi: 'न्यूनतम खरीदारी राशि',
  },
  Free_Delivery_Radius: {
    en: 'Free Delivery Range',
    hi: 'नि:शुल्क डिलीवरी सीमा',
  },
  Add_Delivery_Fees: {
    en: 'Add Delivery Fees',
    hi: 'डिलिवरी शुल्क जोड़ें',
  },
  Extend_Radius: {
    en: '+ Extend radius with delivery Fees',
    hi: '+ डिलीवरी शुल्क के साथ रेडियस बढ़ाएँ',
  },
  Delivery_Charges: {
    en: 'Delivery Charges',
    hi: 'डिलीवरी शुल्क',
  },
  Set_Minimum_Amount: {
    en: 'Set Minimum Shopping Amount',
    hi: 'न्यूनतम खरीदारी राशि निर्धारित करें',
  },
  Min_Shopping_empty_error: {
    en: 'Minimum Shopping Amount is required',
    hi: 'न्यूनतम खरीदारी राशि आवश्यक है',
  },
  Why_Account_Details: {
    en: 'Bank account details are needed so as to transfer all digital payments from your customers to you.',
    hi: 'बैंक खाते के विवरण की आवश्यकता है ताकि आपके ग्राहकों से सभी डिजिटल भुगतानों को आप तक पहुँचाया जा सके.',
  },
  Account_Name: {
    en: 'Account Name',
    hi: 'अकाउंट का नाम',
  },
  Account_Name_empty_error: {
    en: 'Account Name is required',
    hi: 'अकाउंट का नाम आवश्यक है',
  },
  Account_Name_short_error: {
    en: 'Account Name should contain atleast 3 characters',
    hi: 'अकाउंट के नाम में कम से कम 3 अक्षर होने चाहिए',
  },
  Account_Number: {
    en: 'Account Number',
    hi: 'अकाउंट नंबर',
  },
  Account_Number_empty_error: {
    en: 'Account Number is required',
    hi: 'अकाउंट नंबर आवश्यक है',
  },
  Account_Number_short_error: {
    en: 'Account Number should contain atleast 5 digits',
    hi: 'अकाउंट के नंबर में कम से कम 5 अंक होने चाहिए',
  },
  Confirm_Account_Number_empty_error: {
    en: 'Confirm Account Number is required',
    hi: 'कन्फर्म अकाउंट नंबर आवश्यक है',
  },
  Confirm_Account_Number: {
    en: 'Confirm Account Number',
    hi: 'कन्फर्म अकाउंट नंबर',
  },
  Confirm_Account_Number_Not_Matching: {
    en: 'Account Number and Confirm Account Number does not match',
    hi: 'अकाउंट नंबर और कन्फर्म अकाउंट नंबर मैच नहीं कर रहा हैं',
  },
  IFSC_Code: {
    en: 'IFSC Code',
    hi: 'IFSC कोड',
  },
  IFSC_Code_empty_error: {
    en: 'IFSC Code is required',
    hi: 'IFSC कोड आवश्यक है',
  },
  IFSC_Code_format_error: {
    en: 'IFSC code should contain 11 characters (eg. SBIN0000121)',
    hi: 'IFSC कोड में 11 अक्षर होने चाहिए (उदा. SBIN0000121)',
  },
  Upload_Cancel_Cheque: {
    en: 'Upload Cancelled Cheque',
    hi: 'कैंसलेड चेक अपलोड करें',
  },
  All_SET: {
    en: 'All SET!',
    hi: 'आप तैयार हैं',
  },
  GST_Number: {
    en: 'GST Number',
    hi: 'GST नंबर',
  },
  GST_Number_format_error: {
    en: 'GST Number should contain 15 characters (eg. 27AAAPL1234C1Z4)',
    hi: 'GST नंबर में 15 अक्षर होने चाहिए (उदा. 27AAAPL1234C1Z4)',
  },
  PAN_Number: {
    en: 'PAN Number',
    hi: 'PAN नंबर',
  },
  PAN_Number_empty_error: {
    en: 'PAN Number is required',
    hi: 'PAN नंबर आवश्यक है',
  },
  PAN_Number_format_error: {
    en: 'PAN Number should contain 10 characters (eg. AAAPL1234C)',
    hi: 'PAN नंबर में 10 अक्षर होने चाहिए (उदा. AAAPL1234C)',
  },
  Dashboard: {
    en: 'Dashboard',
    hi: 'डैशबोर्ड',
  },
  Daily: {
    en: 'Daily',
    hi: 'दैनिक',
  },
  Weekly: {
    en: 'Weekly',
    hi: 'साप्ताहिक',
  },
  Monthly: {
    en: 'Monthly',
    hi: 'मासिक',
  },
  Today: {
    en: 'Today',
    hi: 'आज',
  },
  This_Week: {
    en: 'This Week',
    hi: 'इस सप्ताह',
  },
  This_Month: {
    en: 'This Month',
    hi: 'इस महीने',
  },
  Load_More: {
    en: 'Load More',
    hi: 'और लोड करें',
  },
  Orders: {
    en: 'Orders',
    hi: 'ऑर्डर्स',
  },
  Order: {
    en: 'Order',
    hi: 'ऑर्डर्',
  },
  Orders_Heading: {
    en: 'ORDERS',
    hi: 'ऑर्डर्स',
  },
  Details: {
    en: 'Details',
    hi: 'विवरण',
  },
  View: {
    en: 'View',
    hi: 'देखें',
  },
  Cash: {
    en: 'Cash',
    hi: 'नगद',
  },
  Digital: {
    en: 'Digital',
    hi: 'डिजिटल',
  },
  No_Records_Found: {
    en: 'No Records Found',
    hi: 'कोई रिकॉर्ड नहीं',
  },
  No_Ratio_Set: {
    en: 'No Ratio Set',
    hi: 'कोई रेश्यो सेट नहीं',
  },
  Store_Name: {
    en: 'STORE NAME',
    hi: 'स्टोर का नाम',
  },
  Home_Delivery: {
    en: 'HOME DELIVERY',
    hi: 'होम डिलीवरी',
  },
  Open: {
    en: 'Open',
    hi: 'चालू',
  },
  Closed: {
    en: 'Closed',
    hi: 'बंद',
  },
  Products: {
    en: 'Products',
    hi: 'प्रोडक्ट्स',
  },
  Products_Offers: {
    en: 'Products & Offers',
    hi: 'प्रोडक्ट्स और ऑफर्स',
  },
  Your_Products: {
    en: 'My Online Products',
    hi: 'मेरे ऑनलाइन प्रोडक्ट्स',
  },
  See_All: {
    en: 'See All',
    hi: 'सभी देखें',
  },
  Category: {
    en: 'Category',
    hi: 'केटेगरी',
  },
  Prepare_Order: {
    en: 'Prepare Order',
    hi: 'ऑर्डर तैयार करें',
  },
  Delivery_Details: {
    en: 'Delivery Details',
    hi: 'डिलिवरी का विवरण',
  },
  start_with_uploading_products: {
    en: 'Let\'s get started with uploading products',
    hi: 'प्रोडक्ट्स अपलोड करने के साथ आरंभ करें',
  },
  Upload_Choice: {
    en: 'How you would like to upload your products',
    hi: 'आप अपने प्रोडक्ट्स कैसे अपलोड करना चाहेंगे',
  },
  Bulk_Upload_From_PC: {
    en: 'Please log into your account on a computer with a chrome browser to complete bulk upload',
    hi: 'बल्क अपलोड को पूरा करने के लिए कृपया कंप्यूटर पर क्रोम ब्राउजर से अपने अकाउंट में लॉग इन करें',
  },
  Bulk_Upload_Success_Message: {
    en: 'Your bulk upload request has been received and will reflect within 24hrs. You will be redirected in a few seconds',
    hi: 'आपका बल्क अपलोड रिक्वेस्ट मिल गया है और 24 घंटों में दिखने लगेगा. यह पेज कुछ ही सेकंड में रिडिरेक्ट हो जाएगा',
  },
  Products_Upload_Success_Message: {
    en: 'The products shown below have been added to your online store, you can now change the selling price if needed.',
    hi: 'आपके ऑनलाइन स्टोर में जोड़े गए प्रोडक्ट्स को नीचे दिखाया गया है, यदि आवश्यक हो तो आप बिक्री मूल्य बदल सकते हैं.',
  },
  Products_Update_Success_Message: {
    en: 'Products Updated Successfully',
    hi: 'प्रोडक्ट्स सफलतापूर्वक अपडेट कर दिए हैं',
  },
  Library_Products_Note: {
    en: 'Pick the products you want to sell, Prices can be changed once added',
    hi: 'उन प्रोडक्ट्स को चुनें जिन्हें आप बेचना चाहते हैं, एक बार जोड़े जाने के बाद कीमतों में बदलाव किया जा सकता है',
  },
  Top_Selling: {
    en: 'Top Selling',
    hi: 'सबसे अधिक बिकने वाले',
  },
  Setup_Complete_Message: {
    en: 'Setup Completed Successfully',
    hi: 'आपने सेटअप सफलतापूर्वक पूर्ण कर दिया हैं',
  },
  OR: {
    en: 'OR',
    hi: 'या',
  },
  OK: {
    en: 'Ok',
    hi: 'ठीक है',
  },
  Pick_Products_from_List: {
    en: 'Pick your Products from List',
    hi: 'अपने प्रोडक्ट्स को सूची से चुनें',
  },
  Skip_For_Now: {
    en: 'Skip For Now',
    hi: 'अभी के लिए छोड़ दे',
  },
  Products_Selected: {
    en: 'Products Selected',
    hi: 'चयनित प्रोडक्ट्स',
  },
  Uploading_File: {
    en: 'Uploading File',
    hi: 'फ़ाइल अपलोड हो रहा है',
  },
  Upload_File: {
    en: 'Upload File',
    hi: 'फ़ाइल अपलोड करें',
  },
  Upload_CSV: {
    en: 'Upload your CSV file (.csv)',
    hi: 'अपनी CSV फ़ाइल (.csv) अपलोड करें',
  },
  CSV_File: {
    en: 'CSV file (.csv)',
    hi: 'CSV फ़ाइल (.csv)',
  },
  Bulk_Upload_Add: {
    en: 'To Add New Online Products',
    hi: 'नए ऑनलाइन प्रोडक्ट्स जोड़ने के लिए',
  },
  New_Bulk_Upload: {
    en: 'New Upload',
    hi: 'नया अपलोड',
  },
  Bulk_Upload_Update: {
    en: 'To Update The Prices of Online Products',
    hi: 'ऑनलाइन प्रोडक्ट्स की कीमतें बदलने के लिए',
  },
  Bulk_Upload_Update_Message: {
    en: 'Update the prices or names of your Online Products and upload a CSV file (.csv)',
    hi: 'अपने ऑनलाइन प्रोडक्ट्स की कीमतों या नामों को अपडेट करें और CSV फ़ाइल (.csv) अपलोड करें',
  },
  Update_Bulk_Upload: {
    en: 'Update List',
    hi: 'नवीनीकृत सूची',
  },
  Bulk_Upload_note: {
    en: 'Products uploaded will reflect within 24hrs.',
    hi: 'अपलोड किए गए प्रोडक्ट्स 24 घंटों में प्रदर्शित होंगे',
  },
  File_size_limit: {
    en: 'File size limit is 5MB',
    hi: 'फ़ाइल की साइज़ सीमा है 5MB',
  },
  File_format: {
    en: 'File should be in .csv format',
    hi: 'फ़ाइल .csv प्रारूप में होनी चाहिए',
  },
  Download_Template: {
    en: 'Download Template',
    hi: 'टेम्पलेट डाउनलोड ',
  },
  To_get_started: {
    en: 'To get started you can',
    hi: 'आरंभ करने के लिए आप',
  },
  you_can: {
    en: 'you can',
    hi: 'कर सकते हैं',
  },
  Address: {
    en: 'Address',
    hi: 'पता',
  },
  Order_Id: {
    en: 'Order ID',
    hi: 'ऑर्डर आईडी',
  },
  Know_More: {
    en: 'Know More',
    hi: 'अधिक जानिए',
  },
  Account_Settlement: {
    en: 'PAYMENT SETTLEMENTS',
    hi: 'भुगतान निपटान',
  },
  Pending_Settlement: {
    en: 'Pending Settlements',
    hi: 'लंबित निपटान',
  },
  Settlements: {
    en: 'Settlements',
    hi: 'निपटान',
  },
  Successful_Settlements: {
    en: 'Successful Settlements',
    hi: 'सफल निपटान',
  },
  No_Settlements_Found: {
    en: 'No Settlements Found',
    hi: 'कोई डिजिटल भुगतान निपटान नहीं',
  },
  No_Pending_Settlements_Found: {
    en: 'No Pending Settlements Found',
    hi: 'कोई लंबित डिजिटल भुगतान निपटान नहीं',
  },
  Settled_For: {
    en: 'Digital Payment Orders Settled',
    hi: 'डिजिटल भुगतान ऑर्डर्स का निपटान',
  },
  Pending_For: {
    en: 'Pending Settlement',
    hi: 'डिजिटल भुगतान लंबित निपटान',
  },
  Settlement_Pending: {
    en: 'Settlement Pending',
    hi: 'निपटान लंबित',
  },
  Settlement_Successful: {
    en: 'Settlement Successful',
    hi: 'निपटान सफल',
  },
  Total_Settlement: {
    en: 'Total Settlement',
    hi: 'कुल निपटान',
  },
  Ratings: {
    en: 'Ratings',
    hi: 'रेटिंग',
  },
  Reviews: {
    en: 'Reviews',
    hi: 'रिव्यु',
  },
  Reviews_and_Ratings: {
    en: 'Reviews and Ratings',
    hi: 'रिव्यु और रेटिंग',
  },
  Reviews_Ratings: {
    en: 'REVIEWS AND RATINGS',
    hi: 'रिव्यु और रेटिंग',
  },
  Add_Comment: {
    en: 'Add a Comment',
    hi: 'टिप्पणी करें',
  },
  Send_Reply: {
    en: 'Send Reply',
    hi: 'जवाब भेजें',
  },
  View_All_Reply: {
    en: 'View All / Reply',
    hi: 'सभी देखें / जवाब दें',
  },
  No_Reviews_Found: {
    en: 'No Reviews Found',
    hi: 'कोई रिव्यु नहीं',
  },
  Reply: {
    en: 'Reply',
    hi: 'जवाब दें',
  },
  Loyalty_Ratio: {
    en: 'LOYALTY RATIO',
    hi: 'लॉयल्टी रेश्यो',
  },
  Loyalty_Ratio_Note: {
    en: 'Please log on to your account on the LoveLocal Partner Android application to change the earn ratio.',
    hi: 'लॉयल्टी रेश्यो को बदलने के लिए कृपया LoveLocal Partner Android एप्लिकेशन पर लॉगिन करें.',
  },
  Change: {
    en: 'Change',
    hi: 'बदलें',
  },
  No_Products_Found: {
    en: 'No Product Found',
    hi: 'कोई प्रोडक्ट नहीं',
  },
  Product_Add_Request: {
    en: 'Product Uploaded Successfully',
    hi: 'प्रोडक्ट सफलतापूर्वक अपलोड किया गया है',
  },
  Offer_Add_Request: {
    en: 'Offer Uploaded Successfully',
    hi: 'ऑफर सफलतापूर्वक अपलोड किया गया है',
  },
  Product_Add_Request_Message: {
    en: 'The Product has been added to your store. Would you like to add another product?',
    hi: 'प्रोडक्ट आपके स्टोर में जोड़ दिया गया है. क्या आप कोई अन्य प्रोडक्ट जोड़ना चाहेंगे?',
  },
  Offer_Add_Request_Message: {
    en: 'The Offer has been added to your store. Would you like to add another offer?',
    hi: 'ऑफर आपके स्टोर में जोड़ दिया गया है. क्या आप कोई अन्य ऑफर जोड़ना चाहेंगे?',
  },
  Add: {
    en: 'Add',
    hi: 'जोड़ें',
  },
  Add_Another_Product: {
    en: 'Add Another Product',
    hi: 'अन्य प्रोडक्ट जोड़ें',
  },
  Add_Another_Offer: {
    en: 'Add Another Offer',
    hi: 'अन्य ऑफर जोड़ें',
  },
  Cancel_Order_Message: {
    en: 'Looks like you are unable to fulfil this order. Do you want to cancel this order?',
    hi: 'लगता है आप इस आर्डर की पूर्ती नहीं कर सकते. क्या आप इस आर्डर को कैंसिल करना चाहते हैं?',
  },
  Not_Now: {
    en: 'Not Now',
    hi: 'अभी नहीं',
  },
  Cancel_Now: {
    en: 'Cancel Now',
    hi: 'कैंसिल करें',
  },
  Cancel: {
    en: 'Cancel',
    hi: 'कैंसिल करें',
  },
  Back_To_Setting: {
    en: 'Back To Settings',
    hi: 'सेटिंग्स पर वापस जाएं',
  },
  Back_To_Setup: {
    en: 'Back To Setup',
    hi: 'सेटअप पर वापस जाएं',
  },
  Oops: {
    en: 'Oops!',
    hi: 'उफ़!',
  },
  Something_Went_Wrong: {
    en: 'Oops! Something went wrong, Please try again',
    hi: 'उफ़! कुछ गलत हो गया, कृपया पुन: प्रयास करें',
  },
  Area_Not_Selected: {
    en: 'You have not selected any region!',
    hi: 'आपने किसी क्षेत्र का चयन नहीं किया है!',
  },
  Non_Serviceable_Msg: {
    en: 'Currently you do not have any selected serviceable area. Please set your serviceable area',
    hi: 'वर्तमान में आपके पास कोई चयनित सेवा क्षेत्र नहीं है। कृपया अपना सेवा क्षेत्र निर्धारित करें।',
  },
  Error_Message: {
    en: 'Oh no! Something went wrong!',
    hi: 'उफ़! कुछ गलत हो गया है!',
  },
  Check_internet: {
    en: 'Please check your internet connection',
    hi: 'कृपया अपना इंटरनेट कनेक्शन जांचें',
  },
  Refresh_Message: {
    en: 'This page will auto-refresh in 10 seconds',
    hi: 'यह पेज 10 सेकंड में स्वतः रीफ़्रेश हो जाएगा',
  },
  Retry: {
    en: 'Retry',
    hi: 'फिर कोशिश करें',
  },
  Done: {
    en: 'Done',
    hi: 'डन',
  },
  Proceed: {
    en: 'Proceed',
    hi: 'आगे बढ़ें',
  },
  Wait: {
    en: 'Wait',
    hi: 'इंतज़ार करें',
  },
  Pending_Payment: {
    en: 'Payment Pending',
    hi: 'भुगतान लंबित',
  },
  Payment_Pending: {
    en: 'Digital Payment is Pending!',
    hi: 'डिजिटल भुगतान नहीं किया गया है!',
  },
  Payment_Pending_Message: {
    en: 'Digital Payment for this order is still pending. Wait till the payment is completed.',
    hi: 'इस ऑर्डर के लिए डिजिटल भुगतान अभी तक नहीं किया गया है. भुगतान पूरा होने तक प्रतीक्षा करें.',
  },
  In_Store: {
    en: 'In Store',
    hi: 'स्टोर में',
  },
  Online: {
    en: 'Online',
    hi: 'ऑनलाइन',
  },
  Bulk_Upload: {
    en: 'Bulk Upload',
    hi: 'बल्क अपलोड',
  },
  Congratulations: {
    en: 'Congratulations',
    hi: 'बधाई हो',
  },
  Search_from_products: {
    en: 'Search Product',
    hi: 'प्रोडक्ट खोजें',
  },
  Setting: {
    en: 'Delivery Settings',
    hi: 'डिलीवरी सेटिंग्स',
  },
  Change_Settings: {
    en: 'Change Settings',
    hi: 'सेटिंग्स बदलें',
  },
  Settings_Saved: {
    en: 'Settings Saved',
    hi: 'सेटिंग्स सेव्ड',
  },
  Area_Saved: {
    en: 'Area Saved',
    hi: 'क्षेत्र सेव्ड',
  },
  Buy_Get_Offer: {
    en: 'Buy One Get One Free Offer',
    hi: 'एक खरीदें एक मुफ्त पायें ऑफर',
  },
  off: {
    en: 'off',
    hi: 'छूट',
  },
  Same_Product: {
    en: 'Same Product',
    hi: 'वही प्रोडक्ट',
  },
  Other_Product: {
    en: 'Other Product',
    hi: 'अन्य प्रोडक्ट',
  },
  Offers: {
    en: 'Offers',
    hi: 'ऑफर्स',
  },
  Offer: {
    en: 'Offer',
    hi: 'ऑफर',
  },
  No_Offers_Found: {
    en: 'No Offers Found',
    hi: 'कोई ऑफर नहीं',
  },
  Offer_Created: {
    en: 'Offer has been created successfully',
    hi: 'ऑफर सफलतापूर्वक बन गया है',
  },
  All: {
    en: 'All',
    hi: 'सभी',
  },
  Fresh: {
    en: 'Fresh',
    hi: 'ताज़ा',
  },
  Out_of_Stock: {
    en: 'Out of Stock',
    hi: 'आउट ऑफ़ स्टॉक',
  },
  In_Stock: {
    en: 'In Stock',
    hi: 'स्टॉक में',
  },
  with_offers: {
    en: 'with offers',
    hi: 'ऑफर्स के साथ',
  },
  Edit: {
    en: 'Edit',
    hi: 'एडिट',
  },
  Add_Products: {
    en: 'Add Products',
    hi: 'प्रोडक्ट्स जोड़ें',
  },
  Add_New_Products: {
    en: 'Add New Products',
    hi: 'नया प्रोडक्ट्स जोड़ें',
  },
  Add_New_Product: {
    en: '+ Add New Product',
    hi: '+ नया प्रोडक्ट जोड़ें',
  },
  Add_More_Products: {
    en: 'Add More Products',
    hi: 'अधिक प्रोडक्ट्स जोड़ें',
  },
  Add_To_List: {
    en: 'Add to list',
    hi: 'सूची में जोड़ें',
  },
  Customer_Approval: {
    en: 'Send for Customer Approval',
    hi: 'ग्राहक की सहमति के लिए भेजें',
  },
  Suggested_Product: {
    en: 'Suggested Product',
    hi: 'सुझाए गए प्रोडक्ट',
  },
  Qty_Updated: {
    en: 'Updated Quantity',
    hi: 'अपडेट की गई मात्रा',
  },
  Add_a_Product: {
    en: 'Add Product',
    hi: 'प्रोडक्ट जोड़ें',
  },
  Add_Bogo: {
    en: 'Add Bogo',
    hi: 'बोगो जोड़ें',
  },
  Add_Bogo_Offer: {
    en: 'Create Bogo Offer',
    hi: 'बोगो ऑफर बनाएँ',
  },
  Bogo_Offer_Info: {
    en: 'Buy One Get One (BOGO) Offers. The offer may be applicable for the same product or for a different product.',
    hi: 'बाय वन गेट वन (BOGO) ऑफर. यह ऑफर समान या अन्य प्रकार के प्रोडक्ट पर लागू किया जा सकता है',
  },
  Spend_Offer_Info: {
    en: 'The offer is applicable only on or above the defined "Spend Amount"',
    hi: 'यह ऑफर केवल निर्धारित "स्पेंड अमाउंट" के ऊपर या अधिक राशि पर लागू होगा',
  },
  Bogo_Offer_Name_Info: {
    en: 'eg. buy 3 bourbon biscuit and get 1 bourbon biscuit free!',
    hi: 'जैसे 3 बर्बन बिस्किट खरीदें और 1 बर्बन बिस्किट मुफ्त पाएं!',
  },
  Spend_Offer_Name_Info: {
    en: 'eg. spend Rs. 2000 and get 1 kg sugar free!',
    hi: 'जैसे 2000 रुपये खर्च करें और 1 किलो चीनी मुफ्त पाएं!',
  },
  Bogo_Description_Info: {
    en: 'eg. buy 3 bourbon biscuit and get 1 bourbon biscuit free worth of Rs 35 hurry now!',
    hi: 'जैसे 3 बर्बन बिस्किट खरीदें और 1 बर्बन बिस्किट मुफ्त पाएं, जो अब 35 रुपये का है!',
  },
  Spend_Description_Info: {
    en: 'eg. spend Rs. 2000 on one bill at "Mumbai Local Store"  & get 1 Kg sugar free',
    hi: 'जैसे "मुंबई लोकल स्टोर" पर एक बिल पर 2000 रुपये का खरीदारी करें और 1 किलोग्राम चीनी मुफ्त पाएं',
  },
  Bogo_Selling_Price_Info: {
    en: 'eg. 3 bourbon biscuits (3x30 = 90) Enter total price',
    hi: 'जैसे 3 बर्बन बिस्कुट (3x30 = 90) का कुल मूल्य दर्ज करें',
  },
  Spend_Offer_Spend_Amount_Info: {
    en: 'eg. Rs. 2000',
    hi: 'जैसे 2000 रुपये',
  },
  Bogo_UOM_Info: {
    en: 'eg. 100 gram',
    hi: 'जैसे 100 ग्राम',
  },
  Add_Spend: {
    en: 'Add Spend',
    hi: 'स्पेंड जोड़ें',
  },
  Add_Spend_Offer: {
    en: 'Create Spend Offer',
    hi: 'स्पेंड ऑफर बनाएँ',
  },
  Bogo_Offer: {
    en: 'Bogo Offer',
    hi: 'बोगो ऑफर',
  },
  Spend_Offer: {
    en: 'Spend Base Offer',
    hi: 'ऑफर स्पेंड के आधार पर',
  },
  Update_Save: {
    en: 'Update & Save',
    hi: 'एडिट करके सेव करें',
  },
  MRP: {
    en: 'MRP',
    hi: 'अधिकतम मूल्य',
  },
  MRP_empty_error: {
    en: 'MRP is required',
    hi: 'MRP आवश्यक है',
  },
  Selling_Price: {
    en: 'Selling Price',
    hi: 'बिक्री मूल्य',
  },
  Selling_Price_empty_error: {
    en: 'Selling Price is required',
    hi: 'बिक्री मूल्य आवश्यक है',
  },
  SP_GT_MRP_error: {
    en: 'Selling Price should not be greater than MRP',
    hi: 'बिक्री मूल्य MRP से अधिक नहीं होना चाहिए',
  },
  Last_Updated: {
    en: 'Last Updated',
    hi: 'आखरी अपडेट',
  },
  Product_Name: {
    en: 'Product Name',
    hi: 'प्रोडक्ट का नाम',
  },
  Product_Name_empty_error: {
    en: 'Product Name is required',
    hi: 'प्रोडक्ट का नाम आवश्यक है',
  },
  Product_Name_short_error: {
    en: 'Product Name should contain atleast 3 characters',
    hi: 'प्रोडक्ट का नाम में कम से कम 3 अक्षर होने चाहिए',
  },
  Description: {
    en: 'Description',
    hi: 'विवरण',
  },
  Barcode: {
    en: 'Barcode',
    hi: 'बारकोड',
  },
  Quantity: {
    en: 'Quantity',
    hi: 'मात्रा',
  },
  Unit_Quantity: {
    en: 'Unit Qty',
    hi: 'मात्रा',
  },
  Unit_Quantity_empty_error: {
    en: 'Unit Qty is Required',
    hi: 'मात्रा आवश्यक है',
  },
  Unit_Parameter_empty_error: {
    en: 'Unit is Required',
    hi: 'मात्रक आवश्यक है',
  },
  Unit: {
    en: 'Unit',
    hi: 'मात्रक',
  },
  Product_Image: {
    en: 'Product Image',
    hi: 'प्रोडक्ट का चित्र',
  },
  Image_Not_Upload: {
    en: 'Image could not be uploaded',
    hi: 'चित्र अपलोड नहीं किया जा सका',
  },
  Camera: {
    en: 'Camera',
    hi: 'कैमरा',
  },
  Gallery: {
    en: 'Gallery',
    hi: 'गेलरी',
  },
  Price_Discount: {
    en: 'Price Discount',
    hi: 'डिस्काउंट',
  },
  Buy_One_Get_One: {
    en: 'Bogo Offer',
    hi: 'बोगो ऑफर',
  },
  Special_Offer: {
    en: 'Special Offer',
    hi: 'Special Offer',
  },
  Spend_Base_Offer: {
    en: 'Spend Base',
    hi: 'स्पेंड के आधार पर',
  },
  Offer_Name: {
    en: 'Offer Name',
    hi: 'ऑफर का नाम',
  },
  Spend_Amount: {
    en: 'Spend Amount',
    hi: 'स्पेंड की राशि',
  },
  Add_Offer: {
    en: 'Add Offer',
    hi: 'ऑफर जोड़ें',
  },
  Valid_Till: {
    en: 'Valid Till',
    hi: 'वैधता',
  },
  Expired: {
    en: 'Expired',
    hi: 'वैधता समाप्त',
  },
  Price_Worth: {
    en: 'Price Worth',
    hi: 'अग्रिम मूल्य',
  },
  Mobile: {
    en: 'Mobile',
    hi: 'मोबाइल',
  },
  Mobile_Number: {
    en: 'Mobile Number',
    hi: 'मोबाइल नंबर',
  },
  Mobile_No: {
    en: 'Mobile No.',
    hi: 'मोबाइल नंबर',
  },
  Mobile_Number_empty_error: {
    en: 'Mobile Number is required',
    hi: 'मोबाइल नंबर आवश्यक है',
  },
  Mobile_Number_validation_error: {
    en: 'Please Enter Valid Mobile Number',
    hi: 'कृपया मान्य मोबाइल नंबर दर्ज करें',
  },
  Mobile_Number_format_error: {
    en: 'Mobile Number should contain 10 digits',
    hi: 'मोबाइल नंबर में 10 अंक होने चाहिए',
  },
  Confirm_Mobile_Number: {
    en: 'Confirm Mobile Number',
    hi: 'मोबाइल नंबर की पुष्टि करें',
  },
  Alternate_Mobile_Number: {
    en: 'Alternate Mobile Number',
    hi: 'वैकल्पिक मोबाइल नंबर',
  },
  Alternate_Mobile_Number_validation_error: {
    en: 'Please Enter Alternate Valid Mobile Number',
    hi: 'कृपया मान्य वैकल्पिक मोबाइल नंबर दर्ज करें',
  },
  Alternate_Mobile_Number_empty_error: {
    en: 'Alternate Mobile Number is required',
    hi: 'वैकल्पिक मोबाइल नंबर आवश्यक है',
  },
  Alternate_Mobile_Number_format_error: {
    en: 'Alternate Mobile Number should contain 10 digits',
    hi: 'वैकल्पिक मोबाइल नंबर में 10 अंक होने चाहिए',
  },
  Pincode_Empty_Error: {
    en: 'Pincode is required',
    hi: 'पिन कोड आवश्यक है',
  },
  Pincode_Format_Error: {
    en: 'Pincode should contain 6 digits',
    hi: 'पिन कोड में 6 अंक होने चाहिए',
  },
  Save: {
    en: 'Save',
    hi: 'सेव करें',
  },
  Mark_As: {
    en: 'Mark As',
    hi: 'चिन्हित करें',
  },
  Order_Time: {
    en: 'Order Time',
    hi: 'ऑर्डर का समय',
  },
  Time_Left: {
    en: 'Time Left',
    hi: 'शेष समय',
  },
  Call_Customer: {
    en: 'Call Customer',
    hi: 'ग्राहक को कॉल करें',
  },
  Past_Orders: {
    en: 'Past Orders',
    hi: 'पहले के ऑर्डर्स',
  },
  Order_Details: {
    en: 'Order Details',
    hi: 'ऑर्डर का विवरण',
  },
  Name: {
    en: 'Name',
    hi: 'नाम',
  },
  Contact_Details: {
    en: 'Contact',
    hi: 'संपर्क करें',
  },
  Customer_Details: {
    en: 'CUSTOMER DETAILS',
    hi: 'ग्राहक का विवरण',
  },
  Note: {
    en: 'Note',
    hi: 'ध्यान दें',
  },
  Read_Out_Full_Order: {
    en: 'Read Out Full Order',
    hi: 'पूरा ऑर्डर देखें',
  },
  Bill_Amount: {
    en: 'Bill Amount',
    hi: 'बिल की राशि',
  },
  Discount: {
    en: 'Discount',
    hi: 'डिस्काउंट',
  },
  Coupon_Discount: {
    en: 'Coupon Discount',
    hi: 'कूपन डिस्काउंट',
  },
  Price_Adjustment: {
    en: 'Price Adjustment',
    hi: 'प्राइस एडजस्टमेंट',
  },
  Mpaani_Adjustments: {
    en: 'Other LoveLocal Discounts',
    hi: 'अन्य LoveLocal डिस्काउंट',
  },
  App_Exlusive_Discount: {
    en: 'App Exlusive Discount',
    hi: 'एप एक्सक्लुसिव डिस्काउंट',
  },
  Digital_Transaction_Fee: {
    en: 'Digital Transaction Fee',
    hi: 'डिजिटल लेनदेन शुल्क',
  },
  Points_Redeemed: {
    en: 'Points Redeemed',
    hi: 'पॉइंट्स रिडीम किए गए',
  },
  Points_Given: {
    en: 'Points Given',
    hi: 'दिए गए पॉइंट्स',
  },
  Paid_With_Points: {
    en: 'Paid With Points',
    hi: 'पॉइंट्स से किया गया भुगतान',
  },
  Total_Amount: {
    en: 'Total Amount',
    hi: 'कुल रकम',
  },
  Confirm_Order: {
    en: 'Confirm Order',
    hi: 'ऑर्डर की पुष्टि करें',
  },
  Order_Received: {
    en: 'Order Received',
    hi: 'ऑर्डर प्राप्त हुआ',
  },
  Preparing_Order: {
    en: 'Preparing Order',
    hi: 'आदेश तैयार हो रहा है',
  },
  Dispatch_Order: {
    en: 'Dispatch Order',
    hi: 'ऑर्डर डिस्पैच',
  },
  Order_Delivered: {
    en: 'Order Delivered',
    hi: 'ऑर्डर डिलीवर्ड',
  },
  Attach_Order_Invoice_Here: {
    en: 'Attach Order Invoice Here*',
    hi: 'यहाँ बिल संलग्न करें *',
  },
  Will_Deliver_Order: {
    en: 'will deliver your order',
    hi: 'आपका ऑर्डर डिलीवर करेंगे',
  },
  SMS_Delivery_Boy: {
    en: 'SMS Address Details to Delivery Boy',
    hi: 'डिलीवरी बॉय को एसएमएस पर एड्रेस भेजें',
  },
  Send_SMS: {
    en: 'Send SMS',
    hi: 'संदेश भेजो',
  },
  Payment_Preference: {
    en: 'PAYMENT PREFERENCE',
    hi: 'भुगतान प्राथमिकताएँ',
  },
  Payment_Received: {
    en: 'PAYMENT RECEIVED',
    hi: 'भुगतान प्राप्त',
  },
  Payment_Mode: {
    en: 'Payment Mode',
    hi: 'भुगतान का प्रकार',
  },
  Payment_Note: {
    en: 'Payment Pending, You will be informed once payment is done',
    hi: 'भुगतान लंबित, भुगतान हो जाने के बाद आपको सूचित किया जाएगा',
  },
  Cash_On_Delivery: {
    en: 'Cash On Delivery',
    hi: 'कैश ओंन डिलीवरी',
  },
  Digital_Payment: {
    en: 'Digital Payment',
    hi: 'डिजिटल भुगतान',
  },
  Is_order_delivered: {
    en: 'Is this order delivered?',
    hi: 'क्या यह ऑर्डर डिलीवर हो गया है?',
  },
  Yes: {
    en: 'Yes',
    hi: 'हाँ',
  },
  Working_on_it: {
    en: 'Working on it',
    hi: 'ऑर्डर तैयार हो रहा है',
  },
  No_Orders_Found: {
    en: 'No Orders Found',
    hi: 'कोई ऑर्डर नहीं',
  },
  All_Orders: {
    en: 'All Orders',
    hi: 'सब ऑर्डर्स',
  },
  Pending_Orders: {
    en: 'Pending Orders',
    hi: 'रुके हुए ऑर्डर्स',
  },
  Pending_Order: {
    en: 'Pending Order',
    hi: 'रूका हुआ ऑर्डर',
  },
  Awaiting_Customer_Approval: {
    en: 'Awaiting Customer Approval',
    hi: 'ग्राहक की सहमति की प्रतीक्षा हो रही है',
  },
  Awaiting_Customer_Approval_Message: {
    en: 'Waiting for customer approval, You can still make changes in order',
    hi: 'ग्राहक की सहमति की प्रतीक्षा हो रही है, आप अभी भी ऑर्डर में बदलाव कर सकते हैं',
  },
  Customer_Approved_Order: {
    en: 'Customer Approved Order',
    hi: 'ग्राहक स्वीकृत ऑर्डर',
  },
  Order_Accepted: {
    en: 'Order Accepted',
    hi: 'ऑर्डर स्वीकार कर लिया गया है',
  },
  Accept_Order: {
    en: 'Accept Order',
    hi: 'ऑर्डर स्वीकार करें',
  },
  Order_Pickup_Ready: {
    en: 'Order Ready For Pickup',
    hi: 'ऑर्डर पिकअप के लिए तैयार है',
  },
  Ready_To_Pickup: {
    en: 'Ready To Pickup',
    hi: 'पिकअप करने के लिए तैयार है',
  },
  Order_Picked_Up: {
    en: 'Order Picked Up',
    hi: 'आर्डर पिक कर लिया गया',
  },
  Awaiting_Dispatch: {
    en: 'Awaiting Dispatch',
    hi: 'प्रेषण की प्रतीक्षा में',
  },
  Complete_Order: {
    en: 'Complete Order',
    hi: 'ऑर्डर पूर्ण हुआ',
  },
  Out_For_Delivery: {
    en: 'Out For Delivery',
    hi: 'ऑर्डर डिलीवरी के लिए निकल चुका है',
  },
  items: {
    en: 'items',
    hi: 'प्रोडक्ट्स',
  },
  View_Details: {
    en: 'View Details',
    hi: 'जानकारी देखें',
  },
  Deliver_Order: {
    en: 'Deliver Order',
    hi: 'ऑर्डर डिलीवर करें',
  },
  Order_Status: {
    en: 'Order Status',
    hi: 'ऑर्डर की स्थिति',
  },
  Delivered: {
    en: 'Delivered',
    hi: 'डिलीवर्ड',
  },
  Delivered_Order: {
    en: 'Order Delivered',
    hi: 'ऑर्डर डिलीवर्ड',
  },
  Delivered_Orders: {
    en: 'Delivered Orders',
    hi: 'डिलीवर्ड ऑर्डर्स',
  },
  Cancelled: {
    en: 'Cancelled',
    hi: 'कैंसलेड',
  },
  Cancelled_Order: {
    en: 'Order Cancelled',
    hi: 'ऑर्डर कैंसलेड',
  },
  Cancel_Order: {
    en: 'Cancel Order',
    hi: 'ऑर्डर कैंसल',
  },
  Cancelled_Orders: {
    en: 'Cancelled Orders',
    hi: 'कैंसलेड ऑर्डर्स',
  },
  Returned: {
    en: 'Returned',
    hi: 'लौटा हुआ ऑर्डर',
  },
  Product_List: {
    en: 'Product List',
    hi: 'प्रोडक्ट्स की सूची',
  },
  No_Requests_Found: {
    en: 'No Requests Found',
    hi: 'कोई रिक्वेस्ट नहीं मिले',
  },
  Status: {
    en: 'Status',
    hi: 'स्थिति',
  },
  Solved: {
    en: 'Solved',
    hi: 'हल की गई समस्या',
  },
  Not_Solved: {
    en: 'Not_Solved',
    hi: 'अनसुलझी समस्या',
  },
  View_Image: {
    en: 'View Image',
    hi: 'चित्र देखें',
  },
  Hide_Image: {
    en: 'Hide Image',
    hi: 'चित्र छिपाएँ',
  },
  No_Image_Available: {
    en: 'No Image Available',
    hi: 'कोई चित्र उपलब्ध नहीं',
  },
  Amount_Settled: {
    en: 'Amount Settled',
    hi: 'राशि भुगतान',
  },
  Reference_To: {
    en: 'Reference To',
    hi: 'संदर्भित',
  },
  Transactions: {
    en: 'Transaction',
    hi: 'ट्रांसेक्शन',
  },
  Transactions_ID: {
    en: 'Transaction ID',
    hi: 'ट्रांसेक्शन आईडी',
  },
  Transfer_ID: {
    en: 'Transfer ID',
    hi: 'ट्रांस्फर आईडी',
  },
  request_approved: {
    en: 'Your request has been approved',
    hi: 'आपका अनुरोध स्वीकृत हो गया है.',
  },
  Setup_your_store: {
    en: 'Now Setup your store in few steps',
    hi: 'अब अपने स्टोर को कुछ चरणों में सेट अप करें.',
  },
  important_sign_up_notes: {
    1: {
      en: 'Customer is King for our Retail Partners & LoveLocal',
      hi: 'ग्राहक हमारे और आपके लिए सबसे ज्यादा महत्त्वपूर्ण है.',
      1: {
        en: 'Retailer guarantees fulfillment of all online orders.',
        hi: 'रिटेलर सभी ऑनलाइन ऑर्डर की पूर्ति की गारंटी देता है.',
      },
      2: {
        en: 'If particular products are out of stock, on a best effort basis, the retailer shall try to procure the product from elsewhere but service the order.',
        hi: 'यदि विशेष उत्पाद स्टॉक में नहीं हैं, तो सर्वोत्तम प्रयास के आधार पर, रिटेलर उत्पाद को कहीं और से खरीदेगा और ऑर्डर को पूरा करेगा.',
      },
      3: {
        en: 'If any products are out of stock, or unserviceable, it would be the Retailer’s responsibility to ensure the same is marked out of stock within the inventory management section.',
        hi: 'यदि कोई उत्पाद स्टॉक में नहीं है या सर्विस नहीं किया जा सकता, तो यह सुनिश्चित करना रिटेलर की ज़िम्मेदारी होगी कि उसे ऑनलाइन दिखने वाले प्रोडक्ट्स की सूची के भीतर आउट ऑफ स्टॉक चिह्नित करे.',
      },
      4: {
        en: 'It is the Retailer’s responsibility to update any products out of stock within the LoveLocal app.',
        hi: 'LoveLocal ऐप के भीतर किसी भी उत्पाद को आउट ऑफ स्टॉक चिह्नित करना रिटेलर की ज़िम्मेदारी है.',
      },
    },
    2: {
      en: 'Card, UPI or any other digital payment',
      hi: 'कार्ड, यूपीआई या कोई अन्य डिजिटल भुगतान.',
      1: {
        en: 'On all digital payments, a flat 2.5% payment gateway transaction fee will be deducted.',
        hi: 'सभी डिजिटल भुगतानों पर एक फ्लैट 2.5% भुगतान गेटवे लेनदेन शुल्क काटा जाएगा.',
      },
    },
    3: {
      en: 'Commercials',
      hi: 'विज्ञापन',
      1: {
        en: 'LoveLocal’s online ordering feature is absolutely free of charge (other than digital transactions fee) till further notice. Post that, charges will be shared with you.',
        hi: 'LoveLocal की ऑनलाइन ऑर्डरिंग सुविधा अगली सूचना तक बिल्कुल मुफ्त (डिजिटल लेनदेन शुल्क लागू होगा) है. उसके बाद सेवा का उपयोग करने के लिए शुल्क लागू किये जाएंगे.',
      },
    },
  },
  Privacy_Policies: {
    en: 'Privacy Policies',
    hi: 'गोपनीयता नीतियों',
  },
  and: {
    en: 'and',
    hi: 'और',
  },
  I: {
    en: 'I accept',
    hi: 'मैं',
  },
  accept: {
    en: '',
    hi: 'से सहमत हूँ',
  },
  Get_Started: {
    en: 'Let\'s Get Started',
    hi: 'आएँ शुरू करें',
  },
  Please_Read_Terms: {
    en: 'Please read LoveLocal Terms & Conditions and Privacy Policy carefully before accepting and moving forward',
    hi: 'कृपया स्वीकार करने और आगे बढ़ने से पहले LoveLocal के नियम और शर्तें और गोपनीयता नीति को ध्यान से पढ़ें.',
  },
  REJECT_ORDER: {
    en: 'Reject Order',
    hi: 'ऑर्डर अस्वीकार करें',
  },
  ARE_YOU_SURE: {
    en: 'Are you sure?',
    hi: 'आप निश्चित हैं?',
  },
  MODIFY_ORDER: {
    en: 'Modify Order',
    hi: 'ऑर्डर सुधारें',
  },
  ORDER_CANCEL_DESC: {
    en: 'You can modify the order by suggesting alternative products or changing the price. Higher cancellation might lead to lower down your store credibility.',
    hi: 'आप वैकल्पिक उत्पादों का सुझाव देकर या कीमत बदलकर ऑर्डर को संशोधित कर सकते हैं। ज़्यादा ऑर्डर रद्द करनेसे आपके स्टोर की विश्वसनीयता कम ही सकती है।',
  },
  ORDER_CANCEL_DESCRIPTION2: {
    en: 'Rejecting an order creates a bad experience for the customer. They may stop ordering from your shop!',
    hi: 'एक ऑर्डर को अस्वीकार करना ग्राहक के लिए एक बुरा अनुभव बनाता है। वे आपकी दुकान से ऑर्डर देना बंद कर सकते हैं!',
  },
  CANCEL_ORDER_REASON_HEADER: {
    en: 'Select Reason',
    hi: 'कारण का चयन करें',
  },
  CANCEL_ORDER_REASON1: {
    en: 'Multiple products are out of stock',
    hi: 'मल्टीपल उत्पाद स्टॉक से बाहर हैं',
  },
  CANCEL_ORDER_REASON2: {
    en: 'Delivery boy not available',
    hi: 'डिलिव्हरी बाय उपलब्ध नहीं है',
  },
  CANCEL_ORDER_REASON3: {
    en: 'Delivery place is far away',
    hi: 'डिलिव्हरी प्लेस दूर है',
  },
  CANCEL_ORDER_REASON4: {
    en: 'Bill amount is very less',
    hi: 'बिल की रक्कम बहुत कम है',
  },
  CANCEL_ORDER_REASON5: {
    en: 'Shop is closed temporarily',
    hi: 'दुकान अस्थायी रूप से बंद है',
  },
  CANCEL_ORDER_REASON6: {
    en: 'Product pricing issue',
    hi: 'उत्पाद मूल्य निर्धारण मुद्दा',
  },
  OTHER: {
    en: 'Other',
    hi: 'अन्य',
  },
  IMPORTANT: {
    en: 'Important!',
    hi: 'महत्वपूर्ण!',
  },
  GO_BACK: {
    en: 'GO BACK',
    hi: 'वापस जाएँ',
  },
  I_KNOW_THE_RISK: {
    en: 'I KNOW THE RISK',
    hi: 'मैं जोखिम जानता हूं',
  },
  WRITE_YOUR_REASON_HERE: {
    en: 'Write your reason here…',
    hi: 'अपना कारण यहाँ लिखें…',
  },
  Request_received: {
    en: 'Your Request has been received!',
    hi: 'आपका रिक्वेस्ट प्राप्त हो गया है!',
  },
  Request_received_message: {
    en: 'Great! You\'re one step closer to delighting your customers with online ordering. Our service expert will get in touch with you soon. For any Help or Support call on +91-8448448906',
    hi: 'वाह! आप ऑनलाइन ऑर्डर सुविधा देने के साथ अपने ग्राहकों को प्रसन्न करने के लिए एक कदम करीब हैं. हमारे सेवा विशेषज्ञ जल्द ही आपसे संपर्क करेंगे. किसी भी मदद या सहायता के लिए +91-8448448906 पर कॉल करें.',
  },
  Request_denied: {
    en: 'Your Request has been denied!',
    hi: 'आपके अनुरोध को अस्वीकार कर दिया गया है.',
  },
  Request_denied_message: {
    en: 'Unfortunately, we can not service your request at the moment. For more details, you can call us on +91-8448448906',
    hi: 'दुर्भाग्य से, हम इस समय आपके अनुरोध को स्वीकार नहीं कर सकते. अधिक जानकारी के लिए आप हमें +91-8448448906 पर कॉल कर सकते हैं.',
  },
  flyer_message_1: {
    en: 'Your customers can order online',
    hi: 'आपके ग्राहक ऑनलाइन ऑर्डर कर सकते हैं',
  },
  flyer_message_2: {
    en: 'Take digital payments for home delivery',
    hi: 'होम डिलीवरी के लिए डिजिटल पेमेंट लें',
  },
  flyer_message_3: {
    en: 'Exclusive offers for your customers',
    hi: 'आपके ग्राहकों के लिए विशेष ऑफर',
  },
  Pickup_From_Store: {
    en: 'Store Pickup',
    hi: 'स्टोर से उठाना',
  },
  Print_Order_List: {
    en: 'Print Order List',
    hi: 'प्रिंट ऑर्डर सूची',
  },
  Online_Store_Marketing: {
    en: 'Marketing Report',
    hi: 'Marketing Report',
  },
  Online_Store_Performance: {
    en: 'Performance Report',
    hi: 'Performance Report',
  },
  Shoppers_Behaviour: {
    en: 'Payment Mode',
    hi: 'Payment Mode',
  },
  Unique_Shoppers: {
    en: 'Total Unique Shoppers',
    hi: 'Total Unique Shoppers',
  },
  Top_Delivery_Localities: {
    en: 'Top Delivery Localities',
    hi: 'Top Delivery Localities',
  },
  Total_Transaction_Report: {
    en: 'Total Transaction Report',
    hi: 'Total Transaction Report',
  },
  Sales: {
    en: 'Sales',
    hi: 'Sales',
  },
  Average_Delivery_Time: {
    en: 'Average Delivery Time',
    hi: 'Average Delivery Time',
  },
  Day_to_Day_Analysis: {
    en: 'Day to Day Analysis',
    hi: 'Day to Day Analysis',
  },
  Week_to_Week_Analysis: {
    en: 'Week to Week Analysis',
    hi: 'Week to Week Analysis',
  },
  No_of_Orders: {
    en: 'No. of Orders',
    hi: 'No. of Orders',
  },
  Gross_Merchandise_Value: {
    en: 'Gross Merchandise Value',
    hi: 'Gross Merchandise Value',
  },
  Orders_by_Time: {
    en: 'Orders by Time of Day',
    hi: 'Orders by Time of Day',
  },
  Offers_Promotions: {
    en: 'LoveLocal funded Offers and Promotions',
    hi: 'LoveLocal funded Offers and Promotions',
  },
  Top_Campaigns: {
    en: 'Top Campaigns',
    hi: 'Top Campaigns',
  },
  Basket_Analysis: {
    en: 'Basket Analysis',
    hi: 'Basket Analysis',
  },
  Top_Products: {
    en: 'Top Products',
    hi: 'Top Products',
  },
  Top_Categories: {
    en: 'Top Categories',
    hi: 'Top Categories',
  },
  Weekly_Cancelled_Orders: {
    en: 'Weekly Cancelled Orders',
    hi: 'Weekly Cancelled Orders',
  },
  Day_of_the_Month: {
    en: 'Day of the Month',
    hi: 'Day of the Month',
  },
  Hours_of_the_Day: {
    en: 'Hour of the Day',
    hi: 'Hour of the Day',
  },
  Top_Shoppers: {
    en: 'Top Shoppers ( Till Date )',
    hi: 'Top Shoppers ( Till Date )',
  },
  Top_Spending_Shoppers: {
    en: 'Top Spending Shoppers',
    hi: 'Top Spending Shoppers',
  },
  Top_Repeating_Shoppers: {
    en: 'Top Repeat Shoppers',
    hi: 'Top Repeat Shoppers',
  },
  View_All: {
    en: 'View All',
    hi: 'View All',
  },
  Your_Serviceable_Area: {
    en: 'Your Serviceable Area',
    hi: 'आपका सेवा क्षेत्र',
  },
  Setting_Exclusions: {
    en: 'Non Delivery Area',
    hi: 'नॉन डिलीवरी एरिया',
  },
  Sorry_No_Data_Found: {
    en: 'Sorry, no data found for this month :(',
    hi: 'क्षमा करें, इस महीने के लिए कोई डेटा नहीं मिला :(',
  },
  Set_Location_First: {
    en: 'Set your location first to set Non Delivery Area',
    hi: 'नॉन डिलीवरी एरिया सेट करने के लिए सबसे पहले अपना लोकेशन निर्धारित करें',
  },
  Cant_Set_Exclusion_Area: {
    en: 'You can\'t set your non delivery area because it seems you haven\'t set your location yet or you have set wrong location. Please set your correct location.',
    hi: 'आप अपना नॉन डिलीवरी क्षेत्र सेट नहीं कर सकते क्योंकि ऐसा लगता है कि आपने अपना स्थान अभी तक निर्धारित नहीं किया है या आपने गलत स्थान निर्धारित किया है। कृपया अपना सही स्थान निर्धारित करें।',
  },
  Updating_Exclusions: {
    en: 'View And Update Area',
    hi: 'क्षेत्र देखें और अपडेट करें',
  },
  Set_Exclusions: {
    en: 'Set Area',
    hi: 'क्षेत्र सेट करें',
  },
  View_Area: {
    en: 'View Area',
    hi: 'क्षेत्र देखें',
  },
  Set_By_Circle: {
    en: 'Set By Circle',
    hi: 'सर्कल द्वारा सेट करें',
  },
  Set_By_Polygon: {
    en: 'Set By Polygon',
    hi: 'पॉलीगन द्वारा सेट करें',
  },
  Set_Exclusions_Area: {
    en: 'Set Your Non Delivery Area',
    hi: 'आपका नॉन डिलीवरी क्षेत्र सेट करें',
  },
  Set_Your_Area: {
    en: 'Set Your Area',
    hi: 'आपका क्षेत्र सेट करें',
  },
  Update_Your_Location: {
    en: 'Update Your Serviceable Area',
    hi: 'अपना सेवा क्षेत्र अपडेट करें',
  },
  Catalogue_Management: {
    en: 'Catalogue Management',
    hi: 'कैटलॉग प्रबंधन',
  },
  Download_Your_Product_Catalogue: {
    en: 'Download Your Product Catalogue',
    hi: 'अपना उत्पाद कैटलॉग डाउनलोड करें',
  },
  Download: {
    en: 'Download',
    hi: 'डाउनलोड',
  },
  Price_Update_Msg: {
    en: 'The fields (Product ID or LL Product ID) and Selling Price are mandatory. Only csv files are supported',
    hi: 'फ़ील्ड (उत्पाद आईडी या एलएल उत्पाद आईडी) और बिक्री मूल्य अनिवार्य हैं। केवल csv फ़ाइलें समर्थित हैं',
  },
  Do_you_want_to_map_your_Billing_POS_export: {
    en: 'Do you want to map your Billing POS export?',
    hi: 'क्या आप अपने बिलिंग पीओएस निर्यात को मैप करना चाहते हैं?',
  },
  Upload_a_mapping_with: {
    en: 'Upload a mapping with your Product ID and LoveLocal Product ID',
    hi: 'अपनी उत्पाद आईडी और लवलोकल उत्पाद आईडी के साथ मैपिंग अपलोड करें',
  },
  Upload_file_to_Update_Prices: {
    en: 'Upload file to Update Prices',
    hi: 'कीमतों को अपडेट करने के लिए फ़ाइल अपलोड करें',
  },
};
