import Axios from 'axios';
import * as url from './urls';
import { getLanguage, getToken } from '../app/Utils';

const setHeaders = (headers = {}) => ({
  headers: Object.assign(
    {},
    {
      'Content-Type': 'application/json',
      'Client-Code': 'vulpix',
      'Accept-Language': getLanguage(),
    }, headers,
  ),
});

const setAuthorization = () => ({
  Authorization: `Token ${getToken()}`,
  'Client-User': 'retailer',
});

export const generateOTP = data => Axios.post(url.otp(), data, setHeaders());

export const login = data => Axios.post(url.login(), data, setHeaders());

export const loginWithOTP = (data, requestId, otp) => (
  Axios.post(url.loginWithOTP(), data, setHeaders({ 'request-id': requestId, otp })));

export const getDashboardStats = (startDate, endDate, pendingRequest) => Axios.get(
  url.dashboardStats(startDate, endDate),
  { ...setHeaders(setAuthorization()), cancelToken: pendingRequest ? pendingRequest.token : null },
);

export const getInventoryStats = () => (
  Axios.get(url.inventoryStats(), setHeaders(setAuthorization())));

export const getStoreProfile = () => Axios.get(url.storeProfile(), setHeaders(setAuthorization()));

export const patchStore = data => (
  Axios.patch(url.storeProfile(), data, setHeaders(setAuthorization())));

export const getOrders = (offset, limit, status, searchText, pendingRequest) => Axios.get(
  url.order(offset, limit, status, searchText),
  { ...setHeaders(setAuthorization()), cancelToken: pendingRequest ? pendingRequest.token : null },
);

export const getOrderDetails = orderId => (
  Axios.get(url.orderDetails(orderId), setHeaders(setAuthorization())));

export const patchOrderDetails = (orderId, data) => (
  Axios.patch(url.orderDetails(orderId), data, setHeaders(setAuthorization())));

export const getReviews = retailerCode => (
  Axios.get(url.reviews(retailerCode), setHeaders(setAuthorization())));

export const replyToReview = data => (
  Axios.post(url.replyToReview(), data, setHeaders(setAuthorization())));

export const getAlerts = () => Axios.get(url.alerts(), setHeaders(setAuthorization()));

export const getRetailerProducts = (offset, limit, status, searchText, pendingRequest) => (
  Axios.get(
    url.retailerProduct(offset, limit, status, searchText),
    {
      ...setHeaders(setAuthorization()),
      cancelToken: pendingRequest ? pendingRequest.token : null,
    },
  ));

export const postRetailerProduct = data => (
  Axios.post(url.postRetailerProduct(), data, setHeaders(setAuthorization())));

export const patchRetailerProduct = (productId, data) => (
  Axios.patch(url.retailerProductDetails(productId), data, setHeaders(setAuthorization())));

export const getLibraryProducts = (
  offset, limit, searchText, category, subCategory, sortBy, pendingRequest,
) => Axios.get(
  url.libraryProducts(offset, limit, searchText, category, subCategory, sortBy),
  {
    ...setHeaders(setAuthorization()),
    cancelToken: pendingRequest ? pendingRequest.token : null,
  },
);

export const getDeliveryRequests = () => (
  Axios.get(url.deliveryRequest(), setHeaders(setAuthorization())));

export const postDeliveryRequest = data => (
  Axios.post(url.deliveryRequest(), data, setHeaders(setAuthorization())));

export const getCustomerDeliveryRequests = () => (
  Axios.get(url.customerDeliveryRequest(), setHeaders()));

export const retailerProductMapping = data => (
  Axios.post(url.productMapping(), data, setHeaders(setAuthorization())));

export const uploadProductsFile = (data, uploadType) => Axios.post(
  url.bulkUpload(uploadType),
  data,
  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      Authorization: `Token ${getToken()}`,
    },
  },
);

export const getReturnRequests = () => (
  Axios.get(url.returnRequests(), setHeaders(setAuthorization())));

export const getReadOutString = orderId => (
  Axios.get(url.readOut(orderId), setHeaders(setAuthorization())));

export const getProductCategories = () => (
  Axios.get(url.productCategory(), setHeaders(setAuthorization())));

export const getReconciliations = () => (
  Axios.get(url.reconciliations(), setHeaders(setAuthorization())));

export const getPendingSettlements = () => (
  Axios.get(url.pendingSettlements(), setHeaders(setAuthorization())));

export const getOffers = (offset, limit, pendingRequest) => (
  Axios.get(
    url.offer(offset, limit),
    {
      ...setHeaders(setAuthorization()),
      cancelToken: pendingRequest ? pendingRequest.token : null,
    },
  ));

export const postOffer = data => Axios.post(url.offer(), data, setHeaders(setAuthorization()));

export const syncRetailer = () => Axios.get(url.sync(), setHeaders());

export const getStoreTypes = () => Axios.get(url.storeType(), setHeaders(setAuthorization()));

export const imageUpload = data => Axios.post(url.imageUpload(), data, setHeaders());

export const getAlternateProductSuggestions = (orderId, searchText, offset, limit) => (
  Axios.get(
    url.alternateProductSuggestions(orderId, searchText, offset, limit),
    setHeaders(setAuthorization()),
  )
);

export const postLibraryRetailerProduct = data => (
  Axios.post(url.libraryRetailerProduct(), data, setHeaders(setAuthorization())));

export const getRecommendedProducts = filter => (
  Axios.get(url.recommendedProducts(filter), setHeaders(setAuthorization())));

export const getPerformanceReport = (retailerCode, month, year) => (
  Axios.get(url.performanceReport(retailerCode, month, year), setHeaders(setAuthorization())));

export const retailerProductBulkEdit = data => (
  Axios.post(url.retailerProductBulkEdit(), data, setHeaders(setAuthorization())));

export const getSpecialOffers = (offset, limit, offerType, pendingRequest) => (
  Axios.get(
    url.specialOffers(offset, limit, offerType),
    {
      ...setHeaders({
        ...setAuthorization(),
        'Client-Code': 'bulbasaur',
      }),
      cancelToken: pendingRequest ? pendingRequest.token : null,
    },
  ));

export const postSpecialOffers = data => (
  Axios.post(url.specialOffers(), data, setHeaders({
    ...setAuthorization(),
    'Client-Code': 'bulbasaur',
  })));

export const getBanners = () => (
  Axios.get(url.banners(), setHeaders(setAuthorization())));

export const getMarketReport = (retailerCode, startTime, endTime) => (
  Axios.get(
    url.marketReport(retailerCode, startTime, endTime),
    setHeaders(setAuthorization()),
  )
);

export const catalogueManagement = (method, retailerCode, data) => (
  Axios({
    method,
    url: url.catalogueManagement(retailerCode),
    headers: setHeaders(setAuthorization()),
    data,
  })
);
