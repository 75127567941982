import React from 'react';
import { Carousel } from 'react-bootstrap';
import PropTypes from 'prop-types';

class CustomCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      itemList: [],
    };
  }

  componentDidMount() {
    const {
      itemsPerCarousel, list,
    } = this.props;
    const itemList = list.map((item, itemIndex) => {
      const newList = [];
      for (let i = 0; i < itemsPerCarousel; i += 1) {
        newList.push(list[(itemIndex + i) % list.length]);
      }
      return newList;
    });
    this.setState({
      itemList,
    });
  }

  render() {
    const {
      itemsPerCarousel, CarouselItem, idField, interval, noControl,
    } = this.props;
    const { activeIndex, itemList } = this.state;
    return (
      <Carousel
        className={`${noControl ? 'no-control' : ''} custom-carousel custom-carousel-${itemsPerCarousel}`}
        activeIndex={activeIndex}
        interval={interval}
        onSelect={(index) => {
          this.setState({
            activeIndex: index,
          });
        }}
        wrap
      >
        {
          itemList.map(items => (
            <Carousel.Item
              key={items[0][idField]}
            >
              {
                items.map(item => (
                  <CarouselItem
                    key={item[idField]}
                    item={item}
                    itemsPerCarousel={itemsPerCarousel}
                    idField={idField}
                  />
                ))
              }
            </Carousel.Item>
          ))
        }
      </Carousel>
    );
  }
}

CustomCarousel.propTypes = {
  itemsPerCarousel: PropTypes.number.isRequired,
  CarouselItem: PropTypes.oneOfType([
    PropTypes.element, PropTypes.func]).isRequired,
  idField: PropTypes.string.isRequired,
  interval: PropTypes.number,
  noControl: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

CustomCarousel.defaultProps = {
  noControl: false,
  interval: 5000,
};

export default CustomCarousel;
