import React from 'react';
import { Link } from 'react-router-dom';
import {
  Container, Col, Row, Button, Accordion,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { removeAuthentication, getToken, getUUID } from '../../Utils';
import Strings from '../../Strings';
import './styles.scss';

const Sidebar = (props) => {
  const { language, history, toggleSideNav } = props;
  const retailer = JSON.parse(window.localStorage.getItem('retailer'));
  if (!retailer) {
    removeAuthentication();
    history.push('/login');
    return '';
  }
  const storeLogoObj = retailer.images.find(retailerImage => retailerImage.image_type === 'logo-pic');
  const storeLogo = storeLogoObj ? storeLogoObj.image : '/Assets/member.svg';
  return (
    <Container fluid className="h-100 bg-primary no-print">
      <Row className="h-100">
        <Col
          xs={24}
          className="py-3 border-bottom h-71p"
        >
          <img
            src="/Assets/love-local.png"
            alt="logo"
            className="h-100"
          />
          <Button
            variant="link"
            className="close p-3 text-white fs-3 d-lg-none"
            onClick={toggleSideNav}
          >
            x
          </Button>
        </Col>
        <Col
          xs={24}
          className="text-white sidebar-content"
        >
          <Row className="mt-4 align-items-center">
            <Col xs="auto" className="pr-0">
              <img src={storeLogo} alt="logo" className="w-50p rounded-circle" />
            </Col>
            <Col>
              <div className="fs-3"><b>{retailer.store_name}</b></div>
            </Col>
          </Row>
          <Row className="mt-2"><Col><small><b>{retailer.address.street_address}</b></small></Col></Row>
          <Row className="mt-4">
            <Col xs={24} className="p-0">
              <Accordion defaultActiveKey="OnlineOrdering">
                <Accordion.Toggle as={Button} variant="link" block className="text-white text-left bg-primary-dark" eventKey="OnlineOrdering">
                  <img src="/Assets/cart.svg" alt="" className="icon" />
                  &nbsp;
                  {Strings.Online_Ordering[language]}
                  <span className="float-right"><img src="/Assets/arrow-down-light.svg" alt="" /></span>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="OnlineOrdering" className="px-5 py-2">
                  <Row>
                    <Col xs={24}>
                      <Link to="/">
                        <Button
                          variant="link"
                          block
                          className="text-white text-left font-weight-normal pt-1 pb-2"
                          onClick={toggleSideNav}
                        >
                          {Strings.Dashboard[language]}
                        </Button>
                      </Link>
                    </Col>
                    <Col xs={24}>
                      <Link to="/orders">
                        <Button
                          variant="link"
                          block
                          className="text-white text-left font-weight-normal pt-1 pb-2"
                          onClick={toggleSideNav}
                        >
                          {Strings.Orders[language]}
                        </Button>
                      </Link>
                    </Col>
                    <Col xs={24}>
                      <Link to="/settlements">
                        <Button
                          variant="link"
                          block
                          className="text-white text-left font-weight-normal pt-1 pb-2"
                          onClick={toggleSideNav}
                        >
                          {Strings.Settlements[language]}
                        </Button>
                      </Link>
                    </Col>
                    <Col xs={24}>
                      <Link to="/pending-settlements">
                        <Button
                          variant="link"
                          block
                          className="text-white text-left font-weight-normal pt-1 pb-2"
                          onClick={toggleSideNav}
                        >
                          {Strings.Pending_Settlement[language]}
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </Accordion.Collapse>
                <Accordion.Toggle as={Button} variant="link" block className="text-white text-left bg-primary-dark mt-2" eventKey="ProductCatalog">
                  <img src="/Assets/product.svg" alt="" className="icon" />
                  &nbsp;
                  {Strings.Products_Offers[language]}
                  <span className="float-right"><img src="/Assets/arrow-down-light.svg" alt="" /></span>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="ProductCatalog" className="px-5 py-2">
                  <Row>
                    <Col xs={24}>
                      <Link to="/products">
                        <Button
                          variant="link"
                          block
                          className="text-white text-left font-weight-normal pt-1 pb-2"
                          onClick={toggleSideNav}
                        >
                          {Strings.Your_Products[language]}
                        </Button>
                      </Link>
                    </Col>
                    <Col xs={24}>
                      <Link to="/products/library">
                        <Button
                          variant="link"
                          block
                          className="text-white text-left font-weight-normal pt-1 pb-2"
                          onClick={toggleSideNav}
                        >
                          {Strings.Add_Products[language]}
                        </Button>
                      </Link>
                    </Col>
                    <Col xs={24}>
                      <Link to="/products/bulk-upload">
                        <Button
                          variant="link"
                          block
                          className="text-white text-left font-weight-normal pt-1 pb-2"
                          onClick={toggleSideNav}
                        >
                          {Strings.Bulk_Upload[language]}
                        </Button>
                      </Link>
                    </Col>
                    <Col xs={24}>
                      <Link to="/products/offers">
                        <Button
                          variant="link"
                          block
                          className="text-white text-left font-weight-normal pt-1 pb-2"
                          onClick={toggleSideNav}
                        >
                          {Strings.Offers[language]}
                        </Button>
                      </Link>
                    </Col>
                    {/* <Col xs={24}>
                      <Link to="/products/offers/add">
                        <Button
                          variant="link"
                          block
                          className="text-white text-left font-weight-normal pt-1 pb-2"
                          onClick={toggleSideNav}
                        >
                          {Strings.Add_Offer[language]}
                        </Button>
                      </Link>
                    </Col> */}
                  </Row>
                </Accordion.Collapse>
              </Accordion>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={24} className="px-0 pb-4">
              <Link to="/catalogue-management">
                <Button
                  block
                  variant="link"
                  className="text-white text-left"
                  onClick={toggleSideNav}
                >
                  <b className="d-flex align-items-center">
                    <img src="/Assets/trolley.svg" alt="" className="icon" />
                    &nbsp;&nbsp;
                    {Strings.Catalogue_Management[language]}
                  </b>
                </Button>
              </Link>
              <a
                className="btn btn-link btn-block text-white d-flex"
                href={`https://retailer-analytics.lovelocal.in/?token=${getToken()}&code=${getUUID()}`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={toggleSideNav}
              >
                <b className="d-flex align-items-center">
                  <img src="/Assets/redirect.svg" alt="" className="icon" />
                  &nbsp;&nbsp;
                  {Strings.Visit_Analytics[language]}
                </b>
              </a>
              <Link to="/settings">
                <Button
                  block
                  variant="link"
                  className="text-white text-left"
                  onClick={toggleSideNav}
                >
                  <b className="d-flex align-items-center">
                    <img src="/Assets/settings.svg" alt="" className="icon" />
                    &nbsp;&nbsp;
                    {Strings.Setting[language]}
                  </b>
                </Button>
              </Link>
              <Button
                block
                variant="link"
                className="text-white text-left"
                onClick={() => removeAuthentication()}
                href="/login"
              >
                <b className="d-flex align-items-center">
                  <img src="/Assets/logout.svg" alt="" className="icon" />
                  &nbsp;&nbsp;
                  {Strings.Logout[language]}
                </b>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

Sidebar.propTypes = {
  language: PropTypes.string.isRequired,
  history: PropTypes.shape({}).isRequired,
  toggleSideNav: PropTypes.func,
};

Sidebar.defaultProps = {
  toggleSideNav: null,
};

export default Sidebar;
