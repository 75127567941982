import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import QueryString from 'query-string';
import Strings from '../../Strings';
import HighCharts from '../../HighCharts';
import { getPerformanceReport } from '../../../api/api';
import { ErrorHandler } from '../../components/common';
import { getIsApp } from '../../Utils';

const metricCard = (metric, full) => (
  <Col
    xs={12}
    md={full ? 24 : 8}
    className="px-2 pb-4"
    key={metric.key}
  >
    <div
      className="bg-white px-3 px-md-4 py-4 shadow-sm"
    >
      <div
        className="mb-1 text-truncate"
      >
        {metric.display_name}
      </div>
      <div
        className="fs-5 font-weight-bold"
      >
        {metric.type === 'amount' && '\u20B9 '}
        {metric.value}
      </div>
    </div>
  </Col>
);

const monthsMap = [
  {
    monthId: 0,
    month: 'January',
  },
  {
    monthId: 1,
    month: 'February',
  },
  {
    monthId: 2,
    month: 'March',
  },
  {
    monthId: 3,
    month: 'April',
  },
  {
    monthId: 4,
    month: 'May',
  },
  {
    monthId: 5,
    month: 'June',
  },
  {
    monthId: 6,
    month: 'July',
  },
  {
    monthId: 7,
    month: 'August',
  },
  {
    monthId: 8,
    month: 'September',
  },
  {
    monthId: 9,
    month: 'October',
  },
  {
    monthId: 10,
    month: 'November',
  },
  {
    monthId: 11,
    month: 'December',
  },
];

const date = new Date();

const yearMap = [];

for (let year = 2019; year <= date.getFullYear(); year += 1) {
  yearMap.push(year);
}

class RetailerReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      report: null,
      loadingReport: true,
      error: false,
      month: date.getMonth(),
      year: date.getFullYear(),
    };
    this.handleLoad = this.handleLoad.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.handleLoad();
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      loadingReport: true,
    }, this.handleLoad);
  }

  handleLoad() {
    const { match } = this.props;
    const retailerCode = match.params.code;
    const { month, year } = this.state;
    getPerformanceReport(retailerCode, Number(month) + 1, year)
      .then((res) => {
        const report = res.data;
        if (
          report
          && report.basic_report
          && report.basic_report.payment_metrics
          && report.basic_report.overall_metrics
        ) {
          const cashMetric = report.basic_report.payment_metrics.find(metric => metric.key === 'Cash');
          const digitalMetric = report.basic_report.payment_metrics.find(metric => metric.key === 'Digital');
          const totalGmvMetric = report.basic_report.overall_metrics.find(metric => metric.key === 'total_gmv');
          if (cashMetric) {
            const totalGmv = totalGmvMetric ? totalGmvMetric.value : 0;
            const digitalAmount = digitalMetric ? digitalMetric.value : 0;
            cashMetric.value = parseFloat((totalGmv - digitalAmount).toFixed(2));
          }
        }
        this.setState({
          report,
          loadingReport: false,
          error: false,
        });

        // HighCharts(
        //   'top-delivery-localities-chart',
        //   report.basic_report.shopper_metrics.top_delivery_locations,
        // );

        const salesChart = HighCharts(
          'transaction-report-chart',
          report.basic_report.payment_metrics,
        );
        const textX = salesChart.plotLeft + (salesChart.plotWidth * 0.5);
        const textY = salesChart.plotTop + (salesChart.plotHeight * 0.5);
        const salesInfoText = window.document.getElementById('sales-info-text');
        let { width, height } = window.getComputedStyle(salesInfoText);
        width = parseInt(width.slice(0, -2), 10);
        height = parseInt(height.slice(0, -2), 10);
        salesInfoText.style.left = `${textX + (width * -0.5)}px`;
        salesInfoText.style.top = `${textY + (height * -0.5)}px`;

        HighCharts(
          'delivery-time-per-day-chart',
          report.basic_report.delivery_time_details.average_delivery_time_per_day,
        );

        HighCharts(
          'weekly-orders-chart',
          report.basic_report.weekly_order_details.week_on_week_orders_count,
        );

        HighCharts(
          'weekly-gmv-chart',
          report.basic_report.weekly_order_details.week_on_week_orders_gmv,
        );

        if (report.advanced_report.daily_order_metrics) {
          HighCharts(
            'daily-orders-chart',
            report.advanced_report.daily_order_metrics.daily_orders_count,
          );

          HighCharts(
            'daily-gmv-chart',
            report.advanced_report.daily_order_metrics.daily_orders_gmv,
          );
        }

        HighCharts(
          'hourly-orders-chart',
          report.advanced_report.hourly_order_metrics.orders_per_hour,
        );

        HighCharts(
          'top-categories-chart',
          report.advanced_report.basket_metrics.top_categories,
        );

        HighCharts(
          'top-products-chart',
          report.advanced_report.basket_metrics.top_products,
        );

        HighCharts(
          'weekly-cancelled-orders-chart',
          report.advanced_report.cancelled_order_metrics.wow_cancelled_orders,
        );
      }).catch((err) => {
        console.error(err);
        this.setState({
          loadingReport: false,
          error: true,
        });
      });
  }

  render() {
    const {
      language, match, location,
    } = this.props;
    const retailerCode = match.params.code;
    const hideHeader = QueryString.parse(location.search)['hide-header'];
    const {
      loadingReport, report, error,
      month, year,
    } = this.state;

    const isApp = getIsApp() === 'true';
    return (
      <>
        {
          (!isApp && retailerCode && !hideHeader)
            ? (
              <Row
                className="mx-0 bg-primary py-3 text-white"
              >
                <Col
                  className="d-flex align-items-center"
                >
                  <img
                    src="/Assets/love-local.png"
                    alt=""
                    className="mh-40p"
                  />
                  <h3
                    className="m-0 px-4"
                  >
                    {report ? report.store_name : ''}
                  </h3>
                </Col>
              </Row>
            ) : ''
        }
        <Container
          fluid
          className={`${retailerCode ? 'mw-1200p' : ''} p-4`}
        >
          <Row
            className="mb-4 px-2"
          >
            <Col
              xs={24}
              className="fs-4 font-weight-bold mb-4"
            >
              {Strings.Online_Store_Performance[language]}
              {/* &nbsp;
              <span className="fs-3">
                {
                  report
                  && report.report_start_date
                  && report.report_end_date
                    ? `(${
                      new Date(report.report_start_date * 1000)
                        .toLocaleDateString(`${language}-in`, dateMonthOptions)
                    }-${
                      new Date(report.report_end_date * 1000)
                        .toLocaleDateString(`${language}-in`, dateMonthOptions)
                    })`
                    : ''
                }
              </span> */}
            </Col>
            <Col
              xs="auto"
              className="pr-1"
            >
              <select
                name="month"
                value={month}
                onChange={this.handleChange}
                className="form-control border-primary"
              >
                {
                  monthsMap.map(item => (
                    <option
                      key={item.monthId}
                      value={item.monthId}
                    >
                      {item.month}
                    </option>
                  ))
                }
              </select>
            </Col>
            <Col
              xs="auto"
              className="pl-1"
            >
              <select
                name="year"
                value={year}
                onChange={this.handleChange}
                className="form-control border-primary"
              >
                {
                  yearMap.map(item => (
                    <option
                      key={item}
                      value={item}
                    >
                      {item}
                    </option>
                  ))
                }
              </select>
            </Col>
          </Row>
          {
            loadingReport
              ? (
                <Row
                  className="justify-content-center py-4 mx-0"
                >
                  <Col
                    xs="auto"
                    className="spinner-border text-primary p-0"
                    role="status"
                  >
                    <span
                      className="sr-only"
                    >
                      Loading...
                    </span>
                  </Col>
                </Row>
              ) : ''
          }
          {
            error
              ? (
                <ErrorHandler
                  show={error}
                  language={language}
                  handleRetry={() => {
                    this.setState(
                      {
                        loadingReport: true,
                        error: false,
                      },
                      () => {
                        this.handleLoad(retailerCode);
                      },
                    );
                  }}
                />
              ) : ''
          }
          {
            !loadingReport && !error && !report.is_report_fetched
              ? (
                <Row>
                  <Col
                    xs={24}
                    className="pt-6 text-center"
                  >
                    <img
                      src="/Assets/no-data.svg"
                      alt=""
                    />
                  </Col>
                  <Col
                    xs={24}
                    className="pt-3 text-center"
                  >
                    <b>{Strings.Sorry_No_Data_Found[language]}</b>
                  </Col>
                </Row>
              ) : ''
          }
          {
            !loadingReport && !error
              ? (
                <Container
                  fluid
                  className={
                    `px-0 ${report.is_report_fetched ? '' : 'd-none'}`
                  }
                >
                  <Row
                    className="mb-2 px-3"
                  >
                    {
                      report
                      && report.basic_report
                      && report.basic_report.overall_metrics
                      && report.basic_report.overall_metrics.slice(0, 3)
                        .map(metric => metricCard(metric))
                    }
                    {
                      metricCard({
                        display_name: Strings.Unique_Shoppers[language],
                        key: 'unique_shoppers',
                        value: report
                        && report.basic_report
                        && report.basic_report.shopper_metrics
                        && report.basic_report.shopper_metrics.unique_shoppers,
                      })
                    }
                    {
                      report
                      && report.basic_report
                      && report.basic_report.overall_metrics
                      && report.basic_report.overall_metrics.slice(3)
                        .map(metric => metricCard(metric))
                    }
                  </Row>
                  <Row
                    className="px-2 mb-2 fs-3"
                  >
                    <Col
                      xs={24}
                    >
                      <b>
                        {Strings.Shoppers_Behaviour[language]}
                      </b>
                    </Col>
                  </Row>
                  <Row
                    className="mb-4 px-3"
                  >
                    {/* <Col
                      xs={{
                        span: 24,
                        order: 0,
                      }}
                      md={{
                        span: 6,
                        order: 1,
                      }}
                      className="px-2 pb-4"
                    >
                      <Row
                        className="mb-2 px-3"
                      >
                        {
                          metricCard({
                            display_name: Strings.Unique_Shoppers[language],
                            key: 'unique_shoppers',
                            value: report
                            && report.basic_report
                            && report.basic_report.shopper_metrics
                            && report.basic_report.shopper_metrics.unique_shoppers,
                          }, true)
                        }
                        {
                          report && (
                            metricCard(
                              {
                                ...report.basic_report.payment_metrics
                                .find(metric => metric.key === 'Cash'),
                                display_name: 'Cash GMV',
                                type: 'amount',
                              }, true,
                            )
                          )
                        }
                        {
                          report && (
                            metricCard(
                              {
                                ...report.basic_report.payment_metrics
                                .find(metric => metric.key === 'Digital'),
                                display_name: 'Digital GMV',
                                type: 'amount',
                              }, true,
                            )
                          )
                        }
                        {
                          report && (
                            metricCard(
                              report.basic_report.overall_metrics
                              .find(metric => metric.key === 'total_gmv'), true,
                            )
                          )
                        }
                      </Row>
                      <div
                        className="bg-white p-4 shadow-sm h-100"
                      >
                        <Row
                          className="mb-4 mx-0"
                        >
                          <Col
                            xs={24}
                            className="text-muted font-weight-bold px-0"
                          >
                            {Strings.Unique_Shoppers[language]}
                          </Col>
                          <Col
                            xs={24}
                            className="fs-5 font-weight-bold px-0"
                          >
                            {
                              report
                              && report.basic_report
                              && report.basic_report.shopper_metrics
                              && report.basic_report.shopper_metrics.unique_shoppers}
                          </Col>
                        </Row>
                        <Row
                          className="mx-0"
                        >
                          <Col
                            xs={24}
                            className="text-muted font-weight-bold px-0"
                          >
                            {Strings.Top_Delivery_Localities[language]}
                          </Col>
                          <Col
                            id="top-delivery-localities-chart"
                            xs={24}
                            className="px-0"
                          />
                        </Row>
                      </div>
                    </Col> */}
                    <Col
                      xs={{
                        span: 24,
                        order: 1,
                      }}
                      md={{
                        span: 12,
                        order: 0,
                      }}
                      className="px-2 pb-4"
                    >
                      <div
                        className="bg-white p-4 shadow-sm h-100"
                      >
                        <Row
                          className="mx-0"
                        >
                          <Col
                            id="transaction-report-chart"
                            xs={24}
                            className="px-0"
                          />
                          <div
                            id="total-sales-text"
                            className="position-absolute"
                          >
                            <div
                              id="sales-info-text"
                              className="fs-1 position-absolute text-center font-weight-bold bg-white"
                            >
                              <div>
                                {
                                  report
                                  && report.basic_report
                                  && report.basic_report.payment_metrics
                                  && `\u20B9${
                                    Math.round(
                                      report.basic_report.payment_metrics
                                        .reduce((acc, item) => acc + item.value, 0) * 100,
                                    ) / 100
                                  }`
                                }
                              </div>
                              <div>
                                {Strings.Sales[language]}
                              </div>
                            </div>
                          </div>
                          {/* <Col
                            xs={24}
                            className="border my-3"
                          />
                          <Col
                            xs={24}
                            className="fs-3 font-weight-bold text-muted text-center"
                          >
                            {Strings.Total_Transaction_Report[language]}
                          </Col> */}
                        </Row>
                      </div>
                    </Col>
                    <Col
                      xs={{
                        span: 24,
                        order: 2,
                      }}
                      className="px-2 pb-4"
                    >
                      <div
                        className="bg-white p-4 shadow-sm h-100"
                      >
                        <Row
                          className="mx-0"
                        >
                          <Col
                            xs={24}
                            md={6}
                            className="font-weight-bold px-0"
                          >
                            <div
                              className="text-muted"
                            >
                              {Strings.Average_Delivery_Time[language]}
                            </div>
                            <div
                              className="fs-5"
                            >
                              {
                                report
                                && report.basic_report
                                && report.basic_report.delivery_time_details
                                && report.basic_report.delivery_time_details.average_delivery_time
                                && `${Math.round(
                                  report.basic_report.delivery_time_details.average_delivery_time,
                                )}min`}
                            </div>
                          </Col>
                          <Col
                            id="delivery-time-per-day-chart"
                            xs={24}
                            md={18}
                            className="px-0"
                          />
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  {
                    report
                    && report.basic_report
                    && report.basic_report.top_shopper_details
                    && (
                      <>
                        <Row
                          className="px-2 mb-2 fs-3"
                        >
                          <Col
                            xs={24}
                          >
                            <b>
                              {Strings.Top_Shoppers[language]}
                            </b>
                          </Col>
                        </Row>
                        <Row
                          className="mb-4 px-3"
                        >
                          <Col
                            xs={24}
                            md={12}
                            className="px-2 pb-4"
                          >
                            <div
                              className="bg-white p-4 shadow-sm h-100"
                            >
                              <Row
                                className="mx-0"
                              >
                                <Col
                                  xs={24}
                                  className="text-muted font-weight-bold px-0"
                                >
                                  {Strings.Top_Repeating_Shoppers[language]}
                                </Col>
                              </Row>
                              <Row
                                className="mx-0"
                              >
                                <Col
                                  className="border my-2"
                                />
                              </Row>
                              <Row
                                className="mx-0"
                              >
                                <Col>
                                  <ol
                                    className="pl-3"
                                  >
                                    {
                                      report.basic_report.top_shopper_details
                                        .top_shoppers_order_count.map(shooper => (
                                          <li
                                            key={shooper.key}
                                          >
                                            <Row
                                              className="py-1"
                                            >
                                              <Col
                                                className="text-muted font-weight-bold"
                                              >
                                                {shooper.key}
                                              </Col>
                                              <Col
                                                xs="auto font-weight-bold"
                                              >
                                                {`${shooper.value} Orders`}
                                              </Col>
                                            </Row>
                                          </li>
                                        ))
                                    }
                                  </ol>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col
                            xs={24}
                            md={12}
                            className="px-2 pb-4"
                          >
                            <div
                              className="bg-white p-4 shadow-sm h-100"
                            >
                              <Row
                                className="mx-0"
                              >
                                <Col
                                  xs={24}
                                  className="text-muted font-weight-bold px-0"
                                >
                                  {Strings.Top_Spending_Shoppers[language]}
                                </Col>
                              </Row>
                              <Row
                                className="mx-0"
                              >
                                <Col
                                  className="border my-2"
                                />
                              </Row>
                              <Row
                                className="mx-0"
                              >
                                <Col>
                                  <ol
                                    className="pl-3"
                                  >
                                    {
                                      report.basic_report.top_shopper_details
                                        .top_shoppers_spend_value.map(shooper => (
                                          <li
                                            key={shooper.key}
                                          >
                                            <Row
                                              className="py-1"
                                            >
                                              <Col
                                                className="font-weight-bold text-muted"
                                              >
                                                {shooper.key}
                                              </Col>
                                              <Col
                                                xs="auto font-weight-bold"
                                              >
                                                {`\u20B9 ${shooper.value}`}
                                              </Col>
                                            </Row>
                                          </li>
                                        ))
                                    }
                                  </ol>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )
                  }
                  <Row
                    className="px-2 mb-2 fs-3"
                  >
                    <Col
                      xs={24}
                    >
                      <b>
                        {Strings.Week_to_Week_Analysis[language]}
                      </b>
                    </Col>
                  </Row>
                  <Row
                    className="mb-4 px-3"
                  >
                    <Col
                      xs={24}
                      md={12}
                      className="px-2 pb-4"
                    >
                      <div
                        className="bg-white p-4 shadow-sm h-100"
                      >
                        <Row
                          className="mx-0"
                        >
                          <Col
                            id="weekly-orders-chart"
                            xs={24}
                            className="px-0"
                          />
                          <Col
                            className="border my-3"
                          />
                          <Col
                            xs={24}
                            className="text-muted font-weight-bold px-0"
                          >
                            {Strings.No_of_Orders[language]}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col
                      xs={24}
                      md={12}
                      className="px-2 pb-4"
                    >
                      <div
                        className="bg-white p-4 shadow-sm h-100"
                      >
                        <Row
                          className="mx-0"
                        >
                          <Col
                            id="weekly-gmv-chart"
                            xs={24}
                            className="px-0"
                          />
                          <Col
                            className="border my-3"
                          />
                          <Col
                            xs={24}
                            className="text-muted font-weight-bold px-0"
                          >
                            {Strings.Gross_Merchandise_Value[language]}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row
                    className="px-2 mb-2 fs-3"
                  >
                    <Col
                      xs={24}
                    >
                      <b>
                        {Strings.Day_to_Day_Analysis[language]}
                      </b>
                    </Col>
                  </Row>
                  <Row
                    className="mb-4 px-3"
                  >
                    <Col
                      xs={24}
                      className="px-2 pb-4"
                    >
                      <div
                        className="bg-white p-4 shadow-sm h-100"
                      >
                        <Row
                          className="mx-0"
                        >
                          <Col
                            xs={24}
                            md={6}
                            className="px-0 text-muted"
                          >
                            <div>
                              <b>
                                {Strings.No_of_Orders[language]}
                              </b>
                            </div>
                            <div
                              className="fs-1"
                            >
                              {Strings.Day_of_the_Month[language]}
                            </div>
                          </Col>
                          <Col
                            id="daily-orders-chart"
                            xs={24}
                            md={18}
                            className="px-0"
                          />
                        </Row>
                        <Row
                          className="mx-0"
                        >
                          <Col
                            className="border my-3"
                          />
                        </Row>
                        <Row
                          className="mx-0"
                        >
                          <Col
                            xs={24}
                            md={6}
                            className="px-0 text-muted"
                          >
                            <div>
                              <b>
                                {Strings.Gross_Merchandise_Value[language]}
                              </b>
                            </div>
                            <div
                              className="fs-1"
                            >
                              {Strings.Day_of_the_Month[language]}
                            </div>
                          </Col>
                          <Col
                            id="daily-gmv-chart"
                            xs={24}
                            md={18}
                            className="px-0"
                          />
                        </Row>
                        <Row
                          className="mx-0"
                        >
                          <Col
                            className="border my-3"
                          />
                        </Row>
                        <Row
                          className="mx-0"
                        >
                          <Col
                            xs={24}
                            md={6}
                            className="px-0 text-muted"
                          >
                            <div>
                              <b>
                                {Strings.Orders_by_Time[language]}
                              </b>
                            </div>
                            <div
                              className="fs-1"
                            >
                              {Strings.Hours_of_the_Day[language]}
                            </div>
                          </Col>
                          <Col
                            id="hourly-orders-chart"
                            xs={24}
                            md={18}
                            className="px-0"
                          />
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row
                    className="px-2 mb-2 fs-3"
                  >
                    <Col xs={24}>
                      <b>
                        {Strings.Basket_Analysis[language]}
                      </b>
                    </Col>
                  </Row>
                  <Row
                    className="px-3"
                  >
                    {
                      report
                      && report.advanced_report
                      && report.advanced_report.basket_metrics
                      && report.advanced_report.basket_metrics.overall_metrics
                      && report.advanced_report.basket_metrics.overall_metrics
                        .map(metric => metricCard(metric))
                    }
                  </Row>
                  <Row
                    className="mb-4 px-3"
                  >
                    <Col
                      xs={24}
                      md={12}
                      className="px-2 pb-4"
                    >
                      <div
                        className="bg-white p-4 shadow-sm h-100"
                      >
                        <Row
                          className="mx-0"
                        >
                          <Col
                            xs={24}
                            className="text-muted font-weight-bold px-0"
                          >
                            {Strings.Top_Products[language]}
                          </Col>
                          <Col
                            id="top-products-chart"
                            xs={24}
                            className="px-0"
                          />
                        </Row>
                      </div>
                    </Col>
                    <Col
                      xs={24}
                      md={12}
                      className="px-2 pb-4"
                    >
                      <div
                        className="bg-white p-4 shadow-sm h-100"
                      >
                        <Row
                          className="mx-0"
                        >
                          <Col
                            xs={24}
                            className="text-muted font-weight-bold px-0"
                          >
                            {Strings.Top_Categories[language]}
                          </Col>
                          <Col
                            id="top-categories-chart"
                            xs={24}
                            className="px-0"
                          />
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row
                    className="px-2 mb-2 fs-3"
                  >
                    <Col
                      xs={24}
                    >
                      <b>
                        {Strings.Weekly_Cancelled_Orders[language]}
                      </b>
                    </Col>
                  </Row>
                  <Row
                    className="mb-4 px-3"
                  >
                    <Col
                      xs={24}
                      className="px-2 pb-4"
                    >
                      <div
                        className="bg-white p-4 shadow-sm h-100"
                      >
                        <Row
                          className="mx-0"
                        >
                          <Col
                            id="weekly-cancelled-orders-chart"
                            xs={24}
                            className="px-0"
                          />
                          <Col
                            className="border my-3"
                          />
                          <Col
                            xs={24}
                            className="text-muted font-weight-bold px-0"
                          >
                            {Strings.Weekly_Cancelled_Orders[language]}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row
                    className="px-2 mb-2 fs-3"
                  >
                    <Col
                      xs={24}
                    >
                      <b>
                        {Strings.Offers_Promotions[language]}
                      </b>
                    </Col>
                  </Row>
                  <Row
                    className="px-3"
                  >
                    {
                      report
                      && report.advanced_report
                      && report.advanced_report.promotional_campaign_metrics
                      && report.advanced_report.promotional_campaign_metrics.overall
                      && report.advanced_report.promotional_campaign_metrics.overall
                        .map(metric => metricCard(metric))
                    }
                  </Row>
                  <Row
                    className="mb-4 px-3"
                  >
                    <Col
                      xs={24}
                      className="px-2"
                    >
                      <div
                        className="bg-white p-4 shadow-sm h-100"
                      >
                        <Row
                          className="mx-0"
                        >
                          <Col
                            xs={24}
                            className="text-muted font-weight-bold px-0"
                          >
                            {Strings.Top_Campaigns[language]}
                          </Col>
                        </Row>
                        <Row
                          className="mx-0"
                        >
                          <Col
                            className="border my-2"
                          />
                        </Row>
                        <Row
                          className="mx-0"
                        >
                          <Col>
                            <ol
                              className="pl-3"
                            >
                              {
                                report
                                && report.advanced_report
                                && report.advanced_report.promotional_campaign_metrics
                                && report.advanced_report.promotional_campaign_metrics.top_campaigns
                                && report.advanced_report.promotional_campaign_metrics.top_campaigns
                                  .map(campaign => (
                                    <li key={campaign}>
                                      {campaign}
                                    </li>
                                  ))
                              }
                            </ol>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Container>
              ) : ''
          }
        </Container>
      </>
    );
  }
}

RetailerReports.propTypes = {
  language: PropTypes.string.isRequired,
  match: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default RetailerReports;
