import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import Strings from '../../Strings';
import './styles.scss';
import events from '../../FirebaseEvents';
import clevertapEvents from '../../ClevertapEvents';
import { logFirebaseEvent, logClevertapEvents } from '../../Utils';
import { CustomModal } from '../../components/common';

function notValid(name, value) {
  switch (name) {
    case 'accountName': {
      if (value.match('^[a-zA-Z0-9 ]{0,40}$') === null) {
        return true;
      }
      return false;
    }
    case 'accountNumber': {
      if (value.match('^[0-9]{0,18}$') === null) {
        return true;
      }
      return false;
    }
    case 'confirmAccountNumber': {
      if (value.match('^[0-9]{0,18}$') === null) {
        return true;
      }
      return false;
    }
    case 'ifscCode': {
      if (value.match('^[0-9a-zA-Z]{0,11}$') === null) {
        return true;
      }
      return false;
    }
    case 'panNumber': {
      if (value.match('^[0-9a-zA-Z]{0,10}$') === null) {
        return true;
      }
      return false;
    }
    case 'gstNumber': {
      if (value.match('^[0-9a-zA-Z]{0,15}$') === null) {
        return true;
      }
      return false;
    }
    default: return false;
  }
}

let isOptional = false;

class AccountDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      accountName: '',
      accountNumber: '',
      confirmAccountNumber: '',
      ifscCode: '',
      panNumber: '',
      gstNumber: '',
      errors: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setupComplete = this.setupComplete.bind(this);
    this.validated = this.validated.bind(this);

    const { storeProfile } = props;
    const cashPaymentMode = storeProfile.paymentMode.find(mode => mode.code === 'CASH');
    const selectedPaymentModes = storeProfile.paymentMode.filter(mode => mode.isEnabled === true);
    isOptional = cashPaymentMode && cashPaymentMode.isEnabled && selectedPaymentModes.length === 1;
  }

  setupComplete() {
    const { patchProfile } = this.props;
    patchProfile({
      setupCompleted: true,
      onHomeDelivery: true,
      setupStage: '',
    });
    this.setState({ showDialog: false });
  }

  handleChange(event) {
    const { name, value } = event.target;
    if (notValid(name, value)) {
      return;
    }
    const { errors } = this.state;
    errors[name] = null;
    this.setState({ [name]: value, errors });
  }

  validated(required) {
    const {
      accountName, accountNumber, confirmAccountNumber, ifscCode, panNumber, gstNumber,
    } = this.state;
    const errors = {};
    if (required && accountName === '') {
      errors.accountName = Strings.Account_Name_empty_error;
    } else if (accountName !== '' && accountName.length < 3) {
      errors.accountName = Strings.Account_Name_short_error;
    }
    if (required && accountNumber === '') {
      errors.accountNumber = Strings.Account_Number_empty_error;
    } else if (accountNumber !== '' && accountNumber.length < 5) {
      errors.accountNumber = Strings.Account_Number_short_error;
    }
    if (required && confirmAccountNumber === '') {
      errors.confirmAccountNumber = Strings.Confirm_Account_Number_empty_error;
    } else if (confirmAccountNumber !== accountNumber) {
      errors.confirmAccountNumber = Strings.Confirm_Account_Number_Not_Matching;
    }
    if (required && ifscCode === '') {
      errors.ifscCode = Strings.IFSC_Code_empty_error;
    } else if (ifscCode !== '' && ifscCode.match('^[a-zA-Z]{4}[0-9a-zA-Z]{7}$') === null) {
      errors.ifscCode = Strings.IFSC_Code_format_error;
    }
    if (required && panNumber === '') {
      errors.panNumber = Strings.PAN_Number_empty_error;
    } else if (panNumber !== '' && panNumber.match('^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$') === null) {
      errors.panNumber = Strings.PAN_Number_format_error;
    }
    if (gstNumber !== '' && gstNumber.match('^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[0-9a-zA-Z]{1}$') === null) {
      errors.gstNumber = Strings.GST_Number_format_error;
    }
    if (Object.entries(errors).length === 0) {
      return true;
    }
    this.setState({ errors });
    return false;
  }

  handleSubmit() {
    const {
      accountName, accountNumber, ifscCode, panNumber, gstNumber,
    } = this.state;
    if (!this.validated(!isOptional)) {
      return;
    }
    const { patchProfile } = this.props;
    patchProfile({
      account: {
        accountName,
        accountNumber,
        ifscCode,
        panNumber,
        gstNumber,
        cancelledChequeUrl: '',
      },
    }).then(() => {
      this.setState({ showDialog: true });
      logClevertapEvents(clevertapEvents.OO_SetupStart_BankDetails_Successful);
    });
  }

  render() {
    const {
      accountName, accountNumber, confirmAccountNumber,
      ifscCode, panNumber, gstNumber, showDialog, errors,
    } = this.state;
    const { language, history } = this.props;
    const body = (
      <Row>
        <Col xs={24} className="text-right">
          <Button onClick={this.setupComplete}>{Strings.OK[language]}</Button>
        </Col>
      </Row>
    );
    return (
      <Container fluid>
        <CustomModal
          show={showDialog}
          title={<span className="text-success">{Strings.Setup_Complete_Message[language]}</span>}
          body={body}
          onHide={this.setupComplete}
          closeButton
        />
        <Row className="mx-0 my-3">
          <Col className="bg-light p-3 text-justify setup-input">
            <Row>
              <Col xs="auto" className="pr-0">
                <img src="/Assets/info.svg" alt="" />
              </Col>
              <Col>{Strings.Why_Account_Details[language]}</Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <div className="mb-2">
              <b>
                {`1. ${Strings.Account_Name[language]} `}
                {
                  isOptional ? `(${Strings.Optional[language]})` : <span className="text-danger">*&nbsp;&nbsp;</span>
                }
              </b>
              {/* <img src={currentState > 1 ? '/Assets/check-circle.svg'
                : '/Assets/check-circle-light.svg'} alt="" /> */}
            </div>
            <input
              required
              type="text"
              name="accountName"
              className={`form-control bg-white setup-input${errors.accountName ? ' border-danger' : ''}`}
              value={accountName}
              onChange={this.handleChange}
            />
            <div className="fs-1 text-danger mb-4 pt-1 setup-input">{errors.accountName ? errors.accountName[language] : ''}</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mb-2">
              <b>
                {`2. ${Strings.Account_Number[language]} `}
                {
                  isOptional ? `(${Strings.Optional[language]})` : <span className="text-danger">*&nbsp;&nbsp;</span>
                }
              </b>
              {/* <img src={currentState > 2 ? '/Assets/check-circle.svg'
                : '/Assets/check-circle-light.svg'} alt="" /> */}
            </div>
            <input
              required
              type="text"
              name="accountNumber"
              className={`form-control bg-white setup-input${errors.accountNumber ? ' border-danger' : ''}`}
              value={accountNumber}
              onChange={this.handleChange}
            />
            <div className="fs-1 text-danger mb-4 pt-1 setup-input">{errors.accountNumber ? errors.accountNumber[language] : ''}</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mb-2">
              <b>
                {`3. ${Strings.Confirm_Account_Number[language]} `}
                {
                  isOptional ? `(${Strings.Optional[language]})` : <span className="text-danger">*&nbsp;&nbsp;</span>
                }
              </b>
              {/* <img src={currentState > 3 ? '/Assets/check-circle.svg'
                : '/Assets/check-circle-light.svg'} alt="" /> */}
            </div>
            <input
              required
              type="text"
              name="confirmAccountNumber"
              className={`form-control bg-white setup-input${errors.confirmAccountNumber ? ' border-danger' : ''}`}
              value={confirmAccountNumber}
              onChange={this.handleChange}
            />
            <div className="fs-1 text-danger mb-4 pt-1 setup-input">{errors.confirmAccountNumber ? errors.confirmAccountNumber[language] : ''}</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mb-2">
              <b>
                {`4. ${Strings.IFSC_Code[language]} `}
                {
                  isOptional ? `(${Strings.Optional[language]})` : <span className="text-danger">*&nbsp;&nbsp;</span>
                }
              </b>
              {/* <img src={currentState > 4 ? '/Assets/check-circle.svg'
                : '/Assets/check-circle-light.svg'} alt="" /> */}
            </div>
            <input
              required
              type="text"
              name="ifscCode"
              className={`form-control bg-white setup-input${errors.ifscCode ? ' border-danger' : ''}`}
              value={ifscCode}
              onChange={this.handleChange}
            />
            <div className="fs-1 text-danger mb-4 pt-1 setup-input">{errors.ifscCode ? errors.ifscCode[language] : ''}</div>
          </Col>
        </Row>
        {/* <Row className={currentState === 5 ? '' : 'opacity-50'}>
          <Col xs={24}>
            <div className="mb-2">
              <b>{`5. ${Strings.Upload_Cancel_Cheque[language]} `}</b>
              <img src={currentState > 5 ? '/Assets/check-circle.svg'
                : '/Assets/check-circle-light.svg'} alt="" />
            </div>
          </Col>
          <Col xs={12} className="mb-4">
            <Button variant="outline-primary border-dashed" block>
              <img src="/Assets/camera.svg" alt="" />
              &nbsp;
              {Strings.Camera[language]}
            </Button>
          </Col>
          <Col xs={12} className="mb-4">
            <Button variant="outline-primary border-dashed" block>
              <img src="/Assets/gallery.svg" alt="" />
              &nbsp;
              {Strings.Gallery[language]}
            </Button>
          </Col>
          <Col>
            <Button
              className={currentState === 5 ? 'px-6 rounded-0 mb-4' : 'd-none'}
              onClick={() => {
                this.handleChangeState(6);
                logFirebaseEvent(events.OO_setup3_upload_Cancelled_Cheque, null);
              }}
            >
              {Strings.Next[language]}
            </Button>
          </Col>
        </Row> */}
        <Row>
          <Col>
            <div className="mb-2">
              <b>
                {`5. ${Strings.PAN_Number[language]} `}
                {
                  isOptional ? `(${Strings.Optional[language]})` : <span className="text-danger">*&nbsp;&nbsp;</span>
                }
              </b>
              {/* <img src={currentState > 5 ? '/Assets/check-circle.svg'
                : '/Assets/check-circle-light.svg'} alt="" /> */}
            </div>
            <input
              required
              type="text"
              name="panNumber"
              className={`form-control bg-white setup-input${errors.panNumber ? ' border-danger' : ''}`}
              value={panNumber}
              onChange={this.handleChange}
            />
            <div className="fs-1 text-danger mb-4 pt-1 setup-input">{errors.panNumber ? errors.panNumber[language] : ''}</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mb-2">
              <b>
                {`6. ${Strings.GST_Number[language]} (${Strings.Optional[language]})`}
                &nbsp;&nbsp;
              </b>
              {/* <img src={currentState > 6 ? '/Assets/check-circle.svg'
                : '/Assets/check-circle-light.svg'} alt="" /> */}
            </div>
            <input
              type="text"
              name="gstNumber"
              className={`form-control bg-white setup-input${errors.gstNumber ? ' border-danger' : ''}`}
              value={gstNumber}
              onChange={this.handleChange}
            />
            <div className="fs-1 text-danger mb-4 pt-1 setup-input">{errors.gstNumber ? errors.gstNumber[language] : ''}</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              className="px-6 rounded-0 mb-4"
              onClick={() => {
                this.handleSubmit();
                logFirebaseEvent(events.OO_setup3_GSTnumber, null);
              }}
              // disabled={
              //   (gstNumber.length > 0 && gstNumber.length < 15) || gstNumber.length > 15}
            >
              {Strings.Next[language]}
            </Button>
            <Button
              variant="light"
              className="ml-4 mb-4 px-6 rounded-0"
              onClick={() => {
                history.replace('/setup?stage=store-details');
              }}
            >
              {Strings.Back[language]}
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

AccountDetails.propTypes = {
  history: PropTypes.shape({}).isRequired,
  patchProfile: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  storeProfile: PropTypes.shape({}).isRequired,
};

export default AccountDetails;
