import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import Strings from '../../Strings';
import { List, ErrorHandler } from '../../components/common';
import Order from '../../components/derived/accountSettlements/Order';
import events from '../../FirebaseEvents';
import { logFirebaseEvent } from '../../Utils';
import { getPendingSettlements } from '../../../api/api';

class PendingSettlements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pendingSettlements: null,
      loading: true,
      error: false,
    };
    this.handleLoad = this.handleLoad.bind(this);
  }

  componentWillMount() {
    this.handleLoad();
  }

  handleLoad() {
    getPendingSettlements().then((res) => {
      this.setState({
        loading: false,
        error: false,
        pendingSettlements: res.data,
      });
    }).catch(() => {
      this.setState({ loading: false, error: true });
    });
    logFirebaseEvent(events.OO_pending_settlements_details, null);
  }

  render() {
    const { pendingSettlements, loading, error } = this.state;
    const { language } = this.props;
    if (error) {
      return (
        <ErrorHandler
          show={error}
          language={language}
          handleRetry={() => {
            this.setState({
              loading: true,
              error: false,
            }, this.handleLoad);
          }}
        />
      );
    }
    return (
      <Container
        fluid
        className="bg-light"
      >
        {
          // eslint-disable-next-line
          loading ? (
            <Row
              className="justify-content-center py-4"
            >
              <Col
                xs="auto"
                className="spinner-border text-primary p-0"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </Col>
            </Row>
          ) : pendingSettlements.amountPending === 0 ? (
            <div
              className="text-center text-muted p-4 w-100"
            >
              <i><b>{Strings.No_Pending_Settlements_Found[language]}</b></i>
            </div>
          ) : (
            <Row
              className="m-0"
            >
              <Col
                className="bg-white shadow p-4"
              >
                <Row>
                  <Col>
                    <b>
                      {Strings.Total_Amount[language]}
                    </b>
                  </Col>
                  <Col
                    className="text-right"
                  >
                    <b>
                      {Strings.Reference_To[language]}
                    </b>
                  </Col>
                </Row>
                <Row>
                  <Col
                    className="text-success"
                  >
                    <b>
                      {`\u20B9 ${pendingSettlements.amountPending}`}
                    </b>
                  </Col>
                  <Col
                    className="text-right text-primary"
                  >
                    {`${pendingSettlements.payments.length} ${Strings.Transactions[language]}`}
                  </Col>
                </Row>
                <Row
                  className="m-0"
                >
                  <Col
                    className="border my-2"
                  />
                </Row>
                <List
                  {...this.props}
                  Component={Order}
                  idField="orderId"
                  list={pendingSettlements.payments}
                />
              </Col>
            </Row>
          )
        }
      </Container>
    );
  }
}

PendingSettlements.propTypes = {
  language: PropTypes.string.isRequired,
};

export default PendingSettlements;
