import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Button, Row, FormControl,
} from 'react-bootstrap';
import './styles.scss';
import { CustomSwitch } from '../../common';
import Strings from '../../../Strings';
import { shortDateOptions } from '../../../Utils';

const RetailerProduct = (props) => {
  const {
    item, language, offerSearch, clearText, handleEditingProduct,
    editingProduct, sellingPrice, mrp, handleChange, handleUpdate, setup,
    handleInStockStatus, handleIsFreshStatus, selectedProducts, updateSelectedProducts,
  } = props;
  const edit = editingProduct === item.id;
  if (offerSearch && !item.libraryProduct) {
    return '';
  }
  const checked = selectedProducts.includes(item.id);
  return (
    <Col
      xs={24}
      lg={12}
      className="py-2"
      onClick={offerSearch ? () => {
        offerSearch(item);
        clearText('');
      } : null}
    >
      <Row
        className="mx-0"
      >
        {
          !setup && !offerSearch && (
            <Col
              xs="auto"
              className="px-1"
              onClick={() => updateSelectedProducts(item.id, !checked)}
            >
              <div
                className={`border rounded checkbox ${checked && 'checked'}`}
              />
            </Col>
          )
        }
        <Col xs={6} sm={5} className="text-center px-1">
          <Row>
            <Col
              xs={24}
              className="product-image-div"
            >
              <img
                src={
                  // eslint-disable-next-line no-nested-ternary
                  item.libraryProduct ? item.libraryProduct.image === ''
                    ? '/Assets/thumbnail.svg' : item.libraryProduct.image
                    : (item.imageUrl && item.imageUrl !== '') ? item.imageUrl : '/Assets/thumbnail.svg'
                }
                alt=""
                className="product-image"
              />
            </Col>
            <Col xs={24} className={offerSearch ? 'd-none' : ''}>
              <Button
                variant="outline-primary"
                className={edit ? 'd-none' : 'w-90p mw-100 rounded-0 p-1 px-md-2'}
                onClick={() => handleEditingProduct(item)}
              >
                <img src="/Assets/pencil.svg" alt="" />
                <b>
                  &nbsp;
                  {Strings.Edit[language]}
                </b>
              </Button>
            </Col>
          </Row>
        </Col>
        <Col className="pl-1">
          <Row>
            <Col xs={24} className="py-1">
              <b>
                {item.displayName}
                &nbsp;
                -
                &nbsp;
                {item.unit}
                &nbsp;
                {item.uom}
                &nbsp;
              </b>
            </Col>
            <Col xs={24}>
              <b>
                <span className="text-success mr-4">{`\u20B9 ${item.sellingPrice}`}</span>
                <span className={`text-secondary${item.mrp === -1 || item.mrp === item.sellingPrice ? ' d-none' : ''}`}>
                  <del>{`\u20B9 ${item.mrp}`}</del>
                </span>
                <span className={`ml-3 text-success${item.mrp === -1 || item.mrp === item.sellingPrice ? ' d-none' : ''}`}>
                  {
                    item.mrp !== -1 ? (
                      ` ${Math.round((100 - (item.sellingPrice * 100 / item.mrp)) * 10) / 10}%`
                    ) : ''
                  }
                </span>
              </b>
            </Col>
            <Col xs={24} className={offerSearch ? 'd-none' : ''}>
              <span>
                <CustomSwitch
                  id={`${item.id}-inStock`}
                  displayTextRight={Strings.In_Stock[language]}
                  checked={item.inStock}
                  onChange={event => handleInStockStatus(event, item.id)}
                />
              </span>
              <span className={item.freshnessApplicable ? '' : 'd-none'}>
                <CustomSwitch
                  id={`${item.id}-isFresh`}
                  displayTextRight={Strings.Fresh[language]}
                  checked={item.isFresh}
                  onChange={event => handleIsFreshStatus(event, item.id)}
                />
              </span>
            </Col>
            <Col xs={24} className={offerSearch ? 'd-none' : 'py-1 fs-1 text-muted'}>
              {`${Strings.Last_Updated[language]}: ${new Date(item.modifiedOn).toLocaleDateString(`${language}-in`, shortDateOptions)}`}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={`transition-all ${edit ? 'bg-light m-0 pt-3 mt-2' : 'd-none'}`}>
        <Col xs={item.mrpApplicable ? 8 : 24} className="pl-4 pr-1 py-1">{Strings.Selling_Price[language]}</Col>
        <Col xs={8} className={`px-1 py-1${item.mrpApplicable ? '' : ' d-none'}`}>{Strings.MRP[language]}</Col>
        <Col xs={8} className={`pl-1 pr-4 py-1${item.mrpApplicable ? '' : ' d-none'}`}>{Strings.Offer[language]}</Col>
        <Col xs={item.mrpApplicable ? 8 : 12} className="pl-4 pr-1 mb-3">
          <FormControl
            name="sellingPrice"
            type="number"
            placeholder={Strings.Selling_Price[language]}
            value={sellingPrice}
            onChange={handleChange}
          />
        </Col>
        <Col xs={8} className={`px-1${item.mrpApplicable ? '' : ' d-none'}`}>
          <FormControl
            name="mrp"
            type="number"
            placeholder={Strings.MRP[language]}
            value={mrp}
            onChange={handleChange}
          />
        </Col>
        <Col xs={8} className={`pl-1 pr-4${item.mrpApplicable ? '' : ' d-none'}`}>
          <div className="text-success bg-transparent form-control">
            <b>
              {
                item.mrp !== -1 && parseFloat(mrp) !== 0
                  && parseFloat(mrp) >= parseFloat(sellingPrice)
                  ? `${Math.round((100 - (sellingPrice * 100 / mrp)) * 10) / 10}%` : 'NA'
              }
            </b>
          </div>
        </Col>
        <Col xs={item.mrpApplicable ? 24 : 12} className={item.mrpApplicable ? 'px-0' : 'pl-1'}>
          <Button
            variant="success rounded-0"
            onClick={() => handleUpdate(item)}
            block
            disabled={
              (item.mrpApplicable && (mrp === '' || parseFloat(mrp) < parseFloat(sellingPrice)))
              || parseFloat(sellingPrice) < 1 || sellingPrice === ''
            }
          >
            {Strings.Update_Save[language]}
          </Button>
        </Col>
      </Row>
      <Row className="m-0">
        <Col className="border mt-4" />
      </Row>
    </Col>
  );
};

RetailerProduct.propTypes = {
  item: PropTypes.shape({}).isRequired,
  offerSearch: PropTypes.func,
  language: PropTypes.string.isRequired,
  clearText: PropTypes.func.isRequired,
  editingProduct: PropTypes.number.isRequired,
  handleEditingProduct: PropTypes.func.isRequired,
  sellingPrice: PropTypes.number.isRequired,
  mrp: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleInStockStatus: PropTypes.func.isRequired,
  handleIsFreshStatus: PropTypes.func.isRequired,
  selectedProducts: PropTypes.arrayOf(PropTypes.number).isRequired,
  updateSelectedProducts: PropTypes.func.isRequired,
  setup: PropTypes.bool,
};

RetailerProduct.defaultProps = {
  offerSearch: null,
  setup: false,
};

export default RetailerProduct;
