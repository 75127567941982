import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import Strings from '../../Strings';
import { OrderList, CustomerDetails } from '../../components/derived/orders';
import {
  getAddressString, longDateOptions, timeOptions,
} from '../../Utils';
import './styles.scss';

function CancelledOrderDetails(props) {
  const { orderDetails, language } = props;
  return (
    <Container fluid className="bg-light position-relative py-3">
      <Row className="mb-4 text-center">
        <Col>
          <Row className="border shadow bg-white p-4 m-0">
            <Col xs={24} className="text-danger"><h3 className="m-0">{Strings.Cancelled_Order[language]}</h3></Col>
            <Col xs={24} className="text-muted text-center">{`${Strings.Order_Id[language]}: #${orderDetails.orderId}`}</Col>
            <Col xs={24} className="text-muted">
              {`${new Date(orderDetails.modifiedOn).toLocaleDateString(`${language}-in`, longDateOptions)}, ${new Date(orderDetails.modifiedOn).toLocaleTimeString(`${language}-in`, timeOptions)}`}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <div className="shadow bg-white">
            <CustomerDetails
              customer={orderDetails.customer}
              address={
                orderDetails.pickupFromStore
                  ? null
                  : getAddressString(orderDetails.deliveryDetails.deliveryAddress)
              }
              description={orderDetails.description}
              language={language}
            />
          </div>
        </Col>
      </Row>
      <Row className="m-0"><Col xs={24} className="zig-zag" /></Row>
      <Row>
        <Col>
          <OrderList {...props} orderDetails={orderDetails} />
        </Col>
      </Row>
      <Row className="mx-0 mb-4"><Col xs={24} className="zig-zag bottom" /></Row>
    </Container>
  );
}

CancelledOrderDetails.propTypes = {
  orderDetails: PropTypes.shape({}).isRequired,
  language: PropTypes.string.isRequired,
};

export default CancelledOrderDetails;
