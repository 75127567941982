import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { List, ErrorHandler } from '../../components/common';
import SettledSettlement from '../../components/derived/accountSettlements/SettledStatement';
import { logFirebaseEvent } from '../../Utils';
import events from '../../FirebaseEvents';
import { getReconciliations } from '../../../api/api';
import Strings from '../../Strings';

class SettledSettlements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reconciliations: [],
      loading: true,
      error: false,
    };
    this.handleLoad = this.handleLoad.bind(this);
  }

  componentWillMount() {
    this.handleLoad();
  }

  handleLoad() {
    getReconciliations().then((res) => {
      this.setState({
        loading: false,
        error: false,
        reconciliations: res.data.results,
      });
    }).catch(() => {
      this.setState({ loading: false, error: true });
    });
    logFirebaseEvent(events.OO_successful_settlements_details, null);
  }

  render() {
    const { reconciliations, loading, error } = this.state;
    const { language } = this.props;
    return (
      <Container
        fluid
        className="bg-light"
      >
        {
          reconciliations.length === 0 ? (
            <div
              className={`text-center text-muted p-4 w-100
                ${(loading || error) && 'd-none'}`}
            >
              <i><b>{Strings.No_Settlements_Found[language]}</b></i>
            </div>
          ) : (
            <List
              {...this.props}
              Component={SettledSettlement}
              idField="reconciledId"
              list={reconciliations}
            />
          )
        }
        <ErrorHandler
          show={error}
          language={language}
          handleRetry={() => {
            this.setState({
              loading: true,
              error: false,
            }, this.handleLoad);
          }}
        />
        <Row
          className={loading ? 'justify-content-center py-4' : 'd-none'}
        >
          <Col
            xs="auto"
            className="spinner-border text-primary p-0"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </Col>
        </Row>
      </Container>
    );
  }
}

SettledSettlements.propTypes = {
  language: PropTypes.string.isRequired,
};

export default SettledSettlements;
