import { getUUID } from '../app/Utils';

function appendCategoryFilter(category, subCategory) {
  if (category === 'all') {
    return '';
  }
  if (subCategory !== 'all') {
    return `&categoryId=${subCategory}`;
  }
  return `&categoryId=${category}`;
}

// let apiHost = 'https://api.staging.mpaani.com/';
let apiHost = 'https://apionlinedelivery-staging.mpaani.com/';
let deliveryHost = 'https://apionlinedelivery-staging.mpaani.com/';
// let deliveryHost = 'http://34.93.140.247/';
let imageUploadBase = 'https://saggregation.mpaani.com/auxiliaryservice/v1';

if (process.env.REACT_APP_ENV === 'production') {
  apiHost = 'https://api.mpaani.com/';
  deliveryHost = 'https://apionlinedelivery.mpaani.com/';
  imageUploadBase = 'https://apianalytics.mpaani.com/auxiliaryservice/v1';
}

if (process.env.REACT_APP_ENV === 'release') {
  apiHost = 'https://api.mpaani.com/';
  deliveryHost = 'https://apionlinedelivery-release.mpaani.com/';
  imageUploadBase = 'https://apianalytics.mpaani.com/auxiliaryservice/v1';
}

const loginBase = 'papi/v8/partners/auth/login/';
const homeDeliveryV1 = 'homedelivery/v1';

export const otp = () => `${apiHost}otp/v1/generate/`;

export const login = () => `${apiHost}${loginBase}`;

export const loginWithOTP = () => `${apiHost}${loginBase}otp/`;

export const dashboardStats = (startDate, endDate) => `
${deliveryHost}${homeDeliveryV1}/retailerstats/
?storeId=${getUUID()}&startDate=${startDate}&endDate=${endDate}`;

export const inventoryStats = () => `${deliveryHost}${homeDeliveryV1}
/inventorystats/?storeId=${getUUID()}`;

export const storeProfile = () => `${deliveryHost}${homeDeliveryV1}/store/${getUUID()}`;

export const order = (offset, limit, status, searchText) => `
${deliveryHost}${homeDeliveryV1}/order/
?storeId=${getUUID()}&limit=${limit}&offset=${offset}
${status && status !== 'all' ? `&deliveryStatus=${status}` : ''}
${searchText && searchText !== '' ? `&searchText=${searchText}` : ''}`;

export const orderDetails = orderId => `${deliveryHost}${homeDeliveryV1}/order/${orderId}`;

export const reviews = retailerCode => `${apiHost}papi/v1/partners/
${retailerCode || getUUID()}/reviews/${retailerCode ? 'paginated/?page=1&count=4' : ''}`;

export const replyToReview = () => `${apiHost}papi/v1/partners/${getUUID()}/reviews/reply/`;

export const alerts = () => `${deliveryHost}${homeDeliveryV1}/orderalert/?storeId=${getUUID()}`;

export const retailerProduct = (offset, limit, status, searchText) => `
${deliveryHost}${homeDeliveryV1}/retailerproduct/
?storeId=${getUUID()}&offset=${offset}&limit=${limit}
${status && status !== 'all' ? `&inStock=${status === 'inStock'}` : ''}
${searchText && searchText !== '' ? `&searchText=${searchText}` : ''}`;

export const postRetailerProduct = () => `${deliveryHost}${homeDeliveryV1}/retailerproduct/`;

export const retailerProductDetails = productId => `${deliveryHost}${homeDeliveryV1}
/retailerproduct/${productId}?storeId=${getUUID()}`;

export const libraryProducts = (offset, limit, searchText, category, subCategory, sortBy) => `
${deliveryHost}${homeDeliveryV1}/libraryproduct/
?storeId=${getUUID()}&offset=${offset}&limit=${limit}
${searchText && searchText !== '' ? `&searchText=${searchText}` : ''}
${appendCategoryFilter(category, subCategory)}
${sortBy && sortBy !== '' ? `&sortBy=${sortBy}` : ''}`;

export const deliveryRequest = () => `${deliveryHost}${homeDeliveryV1}
/retailerhomedeliveryrequest/?storeId=${getUUID()}`;

export const customerDeliveryRequest = () => `${deliveryHost}${homeDeliveryV1}
/customerhomedeliverystorerequest/?storeId=${getUUID()}`;

export const productMapping = () => `${deliveryHost}${homeDeliveryV1}/retailerrequest/`;

export const bulkUpload = uploadType => `${deliveryHost}${homeDeliveryV1}
/bulkuploadrequest/?uploadType=${uploadType}`;

export const returnRequests = () => `${deliveryHost}${homeDeliveryV1}
/replacement/?storeId=${getUUID()}`;

export const readOut = orderId => `${deliveryHost}${homeDeliveryV1}
/orderreadstring/${orderId}`;

export const productCategory = () => `${deliveryHost}${homeDeliveryV1}/category/`;

export const template = () => `${deliveryHost}bulk-upload-sample.csv`;

export const reconciliations = () => `${deliveryHost}${homeDeliveryV1}
/reconciledpayments/?storeId=${getUUID()}`;

export const pendingSettlements = () => `${deliveryHost}${homeDeliveryV1}
/pendingsettlement/${getUUID()}`;

export const offer = (offset, limit) => `${deliveryHost}${homeDeliveryV1}
/productoffer/?storeId=${getUUID()}&offset=${offset}&limit=${limit}`;

export const sync = () => `${deliveryHost}${homeDeliveryV1}/syncretailer/${getUUID()}`;

export const storeType = () => `${deliveryHost}${homeDeliveryV1}/reststoretype/`;

export const imageUpload = () => `${imageUploadBase}/imageupload/`;

export const alternateProductSuggestions = (orderId, searchText, offset, limit) => `
${deliveryHost}${homeDeliveryV1}/alternatestoreproduct/
?orderId=${orderId}&searchText=${searchText}&offset=${offset}&limit=${limit}`;

export const libraryRetailerProduct = () => `${deliveryHost}${homeDeliveryV1}
/librarystoreproduct/`;

export const recommendedProducts = filter => `${deliveryHost}${homeDeliveryV1}
/recommendproduct/?storeId=${getUUID()}&filterCode=${filter}`;

export const performanceReport = (retailerCode, month, year) => `${deliveryHost}${homeDeliveryV1}
/retaileranalytics/${retailerCode || getUUID()}?month=${month}&year=${year}`;

export const retailerProductBulkEdit = () => `${deliveryHost}${homeDeliveryV1}
/retailerproductbulkedit/`;

export const specialOffers = (offset, limit, offerType) => `${deliveryHost}${homeDeliveryV1}
/specialoffers/?storeId=${getUUID()}&offerType=${offerType}&offset=${offset}&limit=${limit}`;

export const banners = () => `${deliveryHost}${homeDeliveryV1}/banners/?banner_type=B2B_HOME`;


export const marketReport = (retailerCode, startTime, endTime) => `${deliveryHost}${homeDeliveryV1}
/marketingreport/?storeCode=${retailerCode || getUUID()}&startTime=${startTime}&endTime=${endTime}`;

export const catalogueManagement = retailerCode => `${deliveryHost}${homeDeliveryV1}/retailercataloguemanangement/${retailerCode}`;

export const catalogueMappingTemplate = () => 'https://generic-bulk-upload.s3.amazonaws.com/template.csv';
