import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import {
  Row, Col, Button,
} from 'react-bootstrap';
import './styles.scss';
import { postDeliveryRequest, getCustomerDeliveryRequests } from '../../../api/api';
import Strings from '../../Strings';
import { logFirebaseEvent, getIsApp, logClevertapEvents } from '../../Utils';
import events from '../../FirebaseEvents';
import clevertapEvents from '../../ClevertapEvents';

class RequestDelivery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openForm: false,
      phoneNumber: '',
      alternatePhoneNumber: '',
      customerRequests: [],
      showAll: false,
      loading: false,
      errors: {},
    };
    this.handleLoad = this.handleLoad.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validated = this.validated.bind(this);
  }

  componentWillMount() {
    this.handleLoad(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.handleLoad(nextProps);
  }

  handleLoad(props) {
    const { storeProfile, requestDelivery } = props;
    const { phoneNumber, alternatePhoneNumber } = this.state;
    if (storeProfile && !requestDelivery) {
      this.setState({
        phoneNumber: storeProfile.phoneNumber === '' ? phoneNumber : storeProfile.phoneNumber,
        alternatePhoneNumber: storeProfile.alternatePhoneNumber === '' ? alternatePhoneNumber : storeProfile.alternatePhoneNumber,
      });
      getCustomerDeliveryRequests().then((res) => {
        this.setState({ customerRequests: res.data.results });
      });
    }
  }

  handleChange(event) {
    const { value, name } = event.target;
    if (value.match('^[0-9]{0,10}$') === null) {
      return;
    }
    const { errors } = this.state;
    errors[name] = null;
    errors.nonFieldError = null;
    this.setState({ [name]: value, errors });
  }

  validated() {
    const { phoneNumber, alternatePhoneNumber } = this.state;
    const errors = {};
    if (phoneNumber === '') {
      errors.phoneNumber = Strings.Mobile_Number_empty_error;
    } else if (phoneNumber.toString().length < 10) {
      errors.phoneNumber = Strings.Mobile_Number_format_error;
    } else if (phoneNumber.toString().match('^[1-9]{1}[0-9]{9}$') === null) {
      errors.phoneNumber = Strings.Mobile_Number_validation_error;
    }
    if (alternatePhoneNumber === '') {
      errors.alternatePhoneNumber = Strings.Alternate_Mobile_Number_empty_error;
    } else if (alternatePhoneNumber.toString().length < 10) {
      errors.alternatePhoneNumber = Strings.Alternate_Mobile_Number_format_error;
    } else if (alternatePhoneNumber.toString().match('^[1-9]{1}[0-9]{9}$') === null) {
      errors.alternatePhoneNumber = Strings.Alternate_Mobile_Number_validation_error;
    }
    if (Object.entries(errors).length === 0) {
      return true;
    }
    this.setState({ errors, loading: false });
    return false;
  }


  handleSubmit() {
    this.setState({ loading: true });
    if (!this.validated()) {
      return;
    }
    const { storeProfile } = this.props;
    const { phoneNumber, alternatePhoneNumber } = this.state;
    const data = {
      phoneNumber,
      alternatePhoneNumber,
      retailerName: storeProfile.displayName,
      city: '',
      lead: getIsApp() === 'true' ? 'ANDROID' : 'WEB',
      address: storeProfile.address,
      retailerCode: storeProfile.code,
    };
    logFirebaseEvent(events.Click_Online_Ordering, JSON.stringify({
      phone_number: phoneNumber,
      alternate_phone_number: alternatePhoneNumber,
    }));
    postDeliveryRequest(data).then((res) => {
      const { updateDeliveryRequests } = this.props;
      updateDeliveryRequests(res.data);
      logClevertapEvents(clevertapEvents.OO_Request_Submitted);
      this.setState({ loading: false, errors: {} });
    }).catch(() => {
      this.setState({ loading: false, errors: { nonFieldError: Strings.Something_Went_Wrong } });
    });
  }

  render() {
    const {
      openForm, phoneNumber, alternatePhoneNumber, customerRequests, showAll, loading, errors,
    } = this.state;
    const {
      deliveryRequests, storeProfile, language, requestDelivery, handleChangeLanguage,
    } = this.props;
    if (!deliveryRequests || !storeProfile) {
      return (
        <Row className="justify-content-center py-4 w-100">
          <Col xs="auto" className="spinner-border text-primary p-0" role="status">
            <span className="sr-only">Loading...</span>
          </Col>
        </Row>
      );
    }
    if (storeProfile.onHomeDelivery) {
      return <Redirect to="/" />;
    }
    if (requestDelivery && deliveryRequests[0].status === 'ACCEPTED') {
      return <Redirect to={`/setup${storeProfile.setupStage === '' ? '' : `?stage=${storeProfile.setupStage}`}`} />;
    }
    return (
      <Col
        xs={24}
        lg={{ span: 8, offset: 8 }}
        md={{ span: 12, offset: 6 }}
        sm={{ span: 16, offset: 4 }}
        className="p-0 bg-light"
      >
        <Row className="m-0 py-2 h-42p d-lg-none">
          <Col className="pr-1">
            <b>
              {
                requestDelivery
                  ? Strings.Request_Status[language]
                  : Strings.Online_Ordering_Request[language]
              }
            </b>
          </Col>
          <Col xs="auto" className="text-right pl-1">
            <div className="bg-secondary d-inline-block rounded">
              <Button
                variant={language === 'en' ? 'primary shadow-sm' : 'secondary'}
                className="text-white py-0 px-4"
                onClick={() => handleChangeLanguage('en')}
              >
                ENG
              </Button>
              <Button
                variant={language === 'hi' ? 'primary shadow-sm' : 'secondary'}
                className="text-white py-0 px-4"
                onClick={() => handleChangeLanguage('hi')}
              >
                हिंदी
              </Button>
            </div>
          </Col>
        </Row>
        <Row className={requestDelivery ? 'd-none' : 'bg-light py-4 m-0'}>
          <Col xs={{ span: 24 }}>
            {
              openForm ? (
                <React.Fragment>
                  <div className="mb-2 font-weight-bold">{Strings.Confirm_Mobile_Number[language]}</div>
                  <input
                    type="text"
                    name="phoneNumber"
                    className={`form-control bg-white text-center ${errors.phoneNumber ? 'border-danger' : 'border-0'}`}
                    placeholder={Strings.Mobile_Number[language]}
                    value={phoneNumber}
                    onChange={this.handleChange}
                    disabled={storeProfile.phoneNumber !== ''}
                  />
                  <div className="fs-1 d-block text-danger mb-4 pt-1">{errors.phoneNumber ? errors.phoneNumber[language] : ''}</div>
                  <input
                    type="text"
                    name="alternatePhoneNumber"
                    className={`form-control bg-white text-center ${errors.alternatePhoneNumber ? 'border-danger' : 'border-0'}`}
                    placeholder={Strings.Alternate_Mobile_Number[language]}
                    value={alternatePhoneNumber}
                    onChange={this.handleChange}
                  />
                  <div className="fs-1 d-block text-danger pt-1">{errors.alternatePhoneNumber ? errors.alternatePhoneNumber[language] : ''}</div>
                  <div className="fs-1 d-block text-danger mb-4">{errors.nonFieldError ? errors.nonFieldError[language] : ''}</div>
                  <Button
                    variant="success"
                    block
                    onClick={this.handleSubmit}
                    disabled={loading}
                  >
                    {Strings.Send_Request[language]}
                  </Button>
                </React.Fragment>
              ) : (
                <Button
                  variant="success"
                  block
                  onClick={() => {
                    this.setState({ openForm: true });
                  }}
                >
                  {Strings.Request_Online_Ordering[language]}
                </Button>
              )
            }
          </Col>
        </Row>
        <Row className="m-0">
          <Col xs={24} className={requestDelivery || customerRequests.length === 0 ? 'd-none' : ''}>
            <Row className="py-4">
              <Col><b>{`${Strings.Online_Ordering_Requests[language]} (${customerRequests.length})`}</b></Col>
              <Col xs="auto" className={customerRequests.length === 1 ? 'd-none' : ''}>
                <Button
                  variant="link p-0"
                  onClick={() => {
                    this.setState({ showAll: !showAll });
                  }}
                >
                  {showAll ? Strings.Show_Less[language] : Strings.Show_All[language]}
                </Button>
              </Col>
            </Row>
            <Row className="m-0">
              <Col className="border" />
            </Row>
            {
              (showAll ? customerRequests : customerRequests.slice(0, 1)).map((request, index) => (
                // eslint-disable-next-line
                <Row className="pt-4" key={index}>
                  <Col xs={8} className="text-primary"><b>{request.customer}</b></Col>
                  <Col xs={16}>{request.message}</Col>
                </Row>
              ))
            }
          </Col>
          <Col xs={24} className={requestDelivery && deliveryRequests[0].status === 'CREATED' ? 'pt-1 mb-3 request-message' : 'd-none'}>
            <div className="text-center bg-success-20 py-4 px-7 rounded">
              <div>
                <b>{Strings.Request_received[language]}</b>
              </div>
              <div className="pt-2 text-justify">
                {Strings.Request_received_message[language]}
              </div>
            </div>
          </Col>
          <Col xs={24} className={requestDelivery && deliveryRequests[0].status === 'REJECTED' ? 'pt-1 mb-3 request-message' : 'd-none'}>
            <div className="text-center bg-danger py-4 px-7 rounded">
              <div>
                <b>{Strings.Request_denied[language]}</b>
              </div>
              <div className="pt-2 text-justify">
                {Strings.Request_denied_message[language]}
              </div>
              {/* <div>For any Help or Support: <a href={
                `tel: 8448448906`}>+91-8448448906</a></div> */}
            </div>
          </Col>
          <Col xs={24} className="px-1">
            <img src={language === 'en' ? '/Assets/Onboarding_Flyer.png' : '/Assets/Onboarding_Flyer_Hindi.png'} alt="" className="w-100" />
          </Col>
          {/* <Col xs={24} className="onboarding-div pt-4">
            <div className="onboarding-bg">
              <img src="/Assets/onboarding-bg.png" alt="" className="bg-image" />
              <Row className="align-items-center onboarding-content p-4 m-0">
                <Col className="text-center">
                  <img src="/Assets/onboarding-1.png" alt="" className="onboarding-img" /></Col>
                <Col className="px-5 px-sm-6"><b>{Strings.flyer_message_1[language]}</b></Col>
              </Row>
            </div>
          </Col>
          <Col xs={24} className="onboarding-div pt-4">
            <div className="onboarding-bg inverse">
              <img src="/Assets/onboarding-bg.png" alt="" className="bg-image" />
              <Row className="align-items-center onboarding-content p-4 m-0">
                <Col className="px-5"><b>{Strings.flyer_message_2[language]}</b></Col>
                <Col className="text-center">
                  <img src="/Assets/onboarding-2.png" alt="" className="onboarding-img" /></Col>
              </Row>
            </div>
          </Col>
          <Col xs={24} className="onboarding-div py-4">
            <div className="onboarding-bg">
              <img src="/Assets/onboarding-bg.png" alt="" className="bg-image" />
              <Row className="align-items-center onboarding-content p-4 m-0">
                <Col className="text-center">
                  <img src="/Assets/onboarding-3.png" alt="" className="onboarding-img" /></Col>
                <Col className="px-5"><b>{Strings.flyer_message_3[language]}</b></Col>
              </Row>
            </div>
          </Col> */}
        </Row>
      </Col>
    );
  }
}

RequestDelivery.propTypes = {
  storeProfile: PropTypes.shape({}),
  deliveryRequests: PropTypes.arrayOf(PropTypes.object),
  updateDeliveryRequests: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  requestDelivery: PropTypes.bool.isRequired,
  handleChangeLanguage: PropTypes.func.isRequired,
};

RequestDelivery.defaultProps = {
  storeProfile: null,
  deliveryRequests: null,
};

export default RequestDelivery;
