import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Strings from '../../Strings';
import {
  getUUID, logFirebaseEvent, getIsApp, logClevertapEvents,
} from '../../Utils';
import { uploadProductsFile } from '../../../api/api';
import events from '../../FirebaseEvents';
import clevertapEvents from '../../ClevertapEvents';
import { template } from '../../../api/urls';
import { CustomModal } from '../../components/common';

class BulkUpload extends React.Component {
  constructor(props) {
    super(props);
    const { setup } = props;
    this.state = {
      uploadCSV: !setup,
      showSuccessMessage: false,
      file: null,
      uploadingCSV: false,
      uploadType: setup ? 'ADD' : '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSetupStage = this.handleSetupStage.bind(this);
    this.updateUploadType = this.updateUploadType.bind(this);
  }

  handleChange(event) {
    this.setState({ file: event.target.files[0] });
  }

  handleSubmit() {
    logFirebaseEvent(events.OO_setup5_SKU_bulkUpload, null);
    this.setState({ uploadingCSV: true });
    const { file, uploadType } = this.state;
    const formData = new FormData();
    formData.append('data', file);
    formData.append('storeId', getUUID());
    uploadProductsFile(formData, uploadType).then(() => {
      const { setup, history } = this.props;
      if (setup) {
        this.handleSetupStage();
      } else {
        this.setState({ uploadingCSV: false, showSuccessMessage: true }, () => {
          setTimeout(() => {
            history.push('/');
          }, 7000);
        });
      }
    });
  }

  handleSetupStage(event) {
    const { patchProfile, history } = this.props;
    patchProfile({ setupStage: 'store-details' }).then(() => {
      if (event) {
        logClevertapEvents(event);
      }
      history.replace('/setup?stage=store-details');
    });
  }

  updateUploadType(uploadType) {
    this.setState({ uploadType });
  }

  render() {
    const {
      uploadCSV, uploadingCSV, file, showSuccessMessage, uploadType,
    } = this.state;
    const { language, setup, history } = this.props;
    const mobileView = window.screen.width < 768;
    const selectedTab = mobileView ? 'border-bottom-primary' : 'border-primary px-7';
    const unselectedTab = mobileView ? 'border-bottom text-muted' : 'border text-muted px-7';
    const uploadSelection = (
      <>
        <Row
          className="mx-0 mb-4 border-primary border-dashed border-radius text-center py-4 rounded"
          onClick={() => this.updateUploadType('ADD')}
        >
          <Col xs="auto">
            <Button className="h-100 p-4">
              <img alt="" src="/Assets/upload.svg" className="w-50p" />
            </Button>
          </Col>
          <Col className="text-left py-2">
            <Row>
              <Col xs={24} className="fs-3">
                <b>{Strings.Bulk_Upload_Add[language]}</b>
              </Col>
              <Col xs={24} className="fs-1">
                {Strings.CSV_File[language]}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          className="mx-0 border-success border-dashed border-radius text-center py-4 rounded"
          onClick={() => this.updateUploadType('UPDATE')}
        >
          <Col xs="auto">
            <Button variant="success" className="h-100 p-4">
              <img alt="" src="/Assets/upload.svg" className="w-50p" />
            </Button>
          </Col>
          <Col className="text-left py-2">
            <Row>
              <Col xs={24} className="fs-3">
                <b>{Strings.Bulk_Upload_Update[language]}</b>
              </Col>
              <Col xs={24} className="fs-1">
                {Strings.CSV_File[language]}
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
    return (
      <Container fluid className="h-100 bg-white">
        <CustomModal
          show={!setup && uploadType === ''}
          title={Strings.Bulk_Upload[language]}
          body={uploadSelection}
          onHide={() => {
            history.goBack();
          }}
          closeButton
        />
        <Row className={uploadCSV ? 'd-none' : 'pt-7'}>
          <Col
            xs={{ span: 20, offset: 2 }}
            md={{ span: 14, offset: 5 }}
            lg={{ span: 12, offset: 6 }}
            className="text-center"
          >
            <b>{Strings.start_with_uploading_products[language]}</b>
            <p className="m-0">{Strings.Upload_Choice[language]}</p>
          </Col>
        </Row>
        <Row>
          <Col
            xs={uploadCSV ? { span: 24, offset: 0 } : { span: 20, offset: 2 }}
            md={uploadCSV ? { span: 20, offset: 2 } : { span: 16, offset: 4 }}
            lg={uploadCSV ? { span: 12, offset: 6 } : { span: 12, offset: 6 }}
          >
            {
              // eslint-disable-next-line
              getIsApp() === 'true' ? (
                <Row className="pt-7">
                  <Col xs={{ span: 20, offset: 2 }} className="text-primary text-center">
                    <b>{Strings.Upload_CSV[language]}</b>
                  </Col>
                  <Col xs={{ span: 20, offset: 2 }} className="py-2 text-justify">
                    <b>{Strings.Bulk_Upload_From_PC[language]}</b>
                    <span className="text-primary">
                      <a
                        href="https://myshop.lovelocal.in/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <b>https://myshop.lovelocal.in/</b>
                      </a>
                    </span>
                  </Col>
                </Row>
              ) : uploadCSV ? (
                <React.Fragment>
                  <Row className={setup ? 'd-none' : 'justify-content-center'}>
                    <Col xs={mobileView ? null : 'auto pt-5'} className="p-0">
                      <Button
                        variant="link"
                        className={`rounded-0 py-3 ${mobileView ? '' : 'left-rounded-pill'}
                          ${uploadType === 'ADD' ? selectedTab : unselectedTab}`}
                        block={mobileView}
                        onClick={() => this.updateUploadType('ADD')}
                      >
                        {Strings.New_Bulk_Upload[language]}
                      </Button>
                    </Col>
                    {
                      mobileView && (
                        <Col xs="auto" className="px-0 py-2">
                          <div className="border h-100" />
                        </Col>
                      )
                    }
                    <Col xs={mobileView ? null : 'auto pt-5'} className="p-0">
                      <Button
                        variant="link"
                        className={`rounded-0 py-3 ${mobileView ? '' : 'right-rounded-pill'}
                          ${uploadType === 'UPDATE' ? selectedTab : unselectedTab}`}
                        block={mobileView}
                        onClick={() => this.updateUploadType('UPDATE')}
                      >
                        {Strings.Update_Bulk_Upload[language]}
                      </Button>
                    </Col>
                  </Row>
                  {
                    showSuccessMessage ? (
                      <Row className="m-0 pt-7">
                        <Col className="bg-success-20 p-4">
                          <b>{Strings.Bulk_Upload_Success_Message[language]}</b>
                        </Col>
                      </Row>
                    ) : (
                      <React.Fragment>
                        <Row className="mx-0 p-5">
                          <Col className="text-center">
                            {
                              uploadType === 'UPDATE'
                                ? Strings.Bulk_Upload_Update_Message[language] : ''
                            }
                          </Col>
                        </Row>
                        <Row
                          className={`mx-0 border-${uploadType === 'UPDATE'
                            ? 'success' : 'primary'} border-dashed border-radius text-center py-4`}
                        >
                          <Col xs={24}>{Strings.Upload_CSV[language]}</Col>
                          <Col xs={24} className="text-center py-2">
                            {/* <Button variant="link">Upload File Here</Button> */}
                            {
                              uploadingCSV ? (
                                <Row className="justify-content-center">
                                  <Col
                                    xs="auto"
                                    className="spinner-border text-primary p-0"
                                    role="status"
                                  >
                                    <span className="sr-only">Loading...</span>
                                  </Col>
                                  <Col xs="auto">{`${Strings.Uploading_File[language]}...`}</Col>
                                </Row>
                              ) : (
                                <input
                                  type="file"
                                  accept={`.csv,
                                    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                                    application/vnd.ms-excel`}
                                  onChange={this.handleChange}
                                />
                              )
                            }
                          </Col>
                          <Col xs={24} className={file && !uploadingCSV ? '' : 'd-none'}>
                            <Button block onClick={this.handleSubmit}>
                              {Strings.Upload_File[language]}
                            </Button>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )
                  }
                  <Row className="text-center py-4">
                    <Col xs={24}>
                      {Strings.To_get_started[language]}
                      <a
                        className="btn btn-link py-0 px-2"
                        href={template()}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {Strings.Download_Template[language]}
                      </a>
                      {language === 'hi' ? Strings.you_can[language] : ''}
                    </Col>
                  </Row>
                  <Row className="m-0">
                    <Col className="border p-4">
                      <Row>
                        <Col xs="auto" className="px-2">
                          <img src="/Assets/info.svg" alt="" />
                        </Col>
                        <Col>
                          <ol className="m-0 pl-2">
                            <li>{Strings.Bulk_Upload_note[language]}</li>
                            <li>{Strings.File_format[language]}</li>
                            <li>{Strings.File_size_limit[language]}</li>
                          </ol>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </React.Fragment>
              ) : (
                <Button
                  block
                  onClick={() => {
                    this.setState({ uploadCSV: true });
                    logClevertapEvents(clevertapEvents.OO_SetupStart_ProductAdding_Selected);
                    logFirebaseEvent(events.OO_setup4_bulk_upload_click, null);
                  }}
                >
                  {`${Strings.Bulk_Upload[language]} (.csv)`}
                </Button>
              )
            }
          </Col>
        </Row>
        <Row>
          <Col
            xs={uploadCSV ? { span: 24, offset: 0 } : { span: 20, offset: 2 }}
            md={uploadCSV ? { span: 20, offset: 2 } : { span: 16, offset: 4 }}
            lg={uploadCSV ? { span: 12, offset: 6 } : { span: 12, offset: 6 }}
          >
            <Row className="align-items-center m-0 py-2">
              <Col className="border h-0" />
              <Col xs="auto">{Strings.OR[language]}</Col>
              <Col className="border h-0" />
            </Row>
          </Col>
        </Row>
        <Row>
          <Col
            xs={uploadCSV ? { span: 24, offset: 0 } : { span: 20, offset: 2 }}
            md={uploadCSV ? { span: 20, offset: 2 } : { span: 16, offset: 4 }}
            lg={uploadCSV ? { span: 12, offset: 6 } : { span: 12, offset: 6 }}
          >
            <Link to={setup ? '/setup?stage=product-library' : '/products/library'}>
              <Button
                variant="success"
                block
                onClick={() => {
                  logClevertapEvents(clevertapEvents.OO_SetupStart_ProductAdding_Selected);
                  logFirebaseEvent(events.OO_setup4_Pick_List_click, null);
                }}
              >
                {Strings.Pick_Products_from_List[language]}
              </Button>
            </Link>
          </Col>
        </Row>
        <Row className={setup ? '' : 'd-none'}>
          <Col className="text-center py-4">
            <Button
              variant="link"
              onClick={() => this.handleSetupStage(
                clevertapEvents.OO_SetupStart_ProductAdding_Skipped,
              )}
            >
              <u>{Strings.Skip_For_Now[language]}</u>
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

BulkUpload.propTypes = {
  language: PropTypes.string.isRequired,
  setup: PropTypes.bool,
  history: PropTypes.shape({}).isRequired,
  patchProfile: PropTypes.func.isRequired,
};

BulkUpload.defaultProps = {
  setup: false,
};

export default BulkUpload;
