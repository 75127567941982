import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button, Spinner,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Strings from '../../../Strings';
import { Order } from '../../../components/derived/orders';
import { List } from '../../../components/common';


function OrderList(props) {
  const {
    loading, error, language, orders, orderCount, orderStatusType, searchText, handleLazyLoad,
    handleChangeSearchText, handleChangeOrderStatusType,
  } = props;
  return (
    <Container fluid className="bg-light shadow">
      <Row>
        <Col xs={24} className="bg-white d-flex align-items-center py-4 px-4">
          <img src="/Assets/search.svg" alt="" />
          <input
            type="text"
            value={searchText}
            onChange={handleChangeSearchText}
            className="border-0 bg-transparent w-100 pl-2 fs-1"
            placeholder={Strings.Order_Search_Placeholder[language]}
          />
        </Col>
        <Col xs={24} className="d-flex align-items-center pt-3 px-0">
          <div className="w-50">
            <Button variant="link" className="rounded-0 w-100" onClick={() => handleChangeOrderStatusType('inProgress')}>
              <div className={`fs-1 ${orderStatusType === 'inProgress'
                ? 'text-primary' : 'font-weight-light text-muted'}`}
              >
                {Strings.In_Progress[language]}
                <span className={orderCount && orderStatusType === 'inProgress' ? '' : 'd-none'}>{` (${orderCount})`}</span>
              </div>
            </Button>
            <div className={orderStatusType === 'inProgress' ? 'pt-1 bg-primary' : 'border'} />
          </div>
          <div className="w-50">
            <Button variant="link" className="rounded-0 w-100" onClick={() => handleChangeOrderStatusType('previous')}>
              <div className={`fs-1 ${orderStatusType === 'previous'
                ? 'text-primary' : 'font-weight-light text-muted'}`}
              >
                {Strings.Previous_Orders[language]}
              </div>
            </Button>
            <div className={orderStatusType === 'previous' ? 'pt-1 bg-primary' : 'border'} />
          </div>
        </Col>
        <Col onScroll={handleLazyLoad} xs={24} className="dashboard-order-list py-2">
          {
              orders.length === 0
                ? (
                  <div className={`text-center text-muted px-4 py-8${loading || error ? ' d-none' : ''}`}>
                    <b>
                      <i>
                        {Strings.No_Orders_Found[language]}
                      </i>
                    </b>
                  </div>
                ) : (
                  <div>
                    <div className="d-none d-lg-block">
                      <List {...props} Component={Order} idField="orderId" list={orders} dashboard />
                    </div>
                    <div className="d-lg-none">
                      <List {...props} Component={Order} idField="orderId" list={orders.slice(0, 4)} dashboard />
                    </div>
                  </div>
                )
            }
          {loading ? (
            <div className="text-center py-8">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : ''}
        </Col>
        <Col xs={24} className={`text-center d-lg-none mb-2 ${orders.length ? '' : 'd-none'}`}>
          <Link
            to={
              orderStatusType === 'inProgress'
                ? '/orders?status=in_progress'
                : '/orders?status=previous'
            }
            className="border-bottom-primary"
          >
            <b className="text-primary fs-1">{Strings.Load_More[language]}</b>
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

OrderList.propTypes = {
  language: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  orders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  orderCount: PropTypes.number.isRequired,
  orderStatusType: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  handleChangeSearchText: PropTypes.func.isRequired,
  handleChangeOrderStatusType: PropTypes.func.isRequired,
  handleLazyLoad: PropTypes.func.isRequired,
};

export default OrderList;
