import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import Strings from '../../../Strings';
import { timeOptions } from '../../../Utils';

function Timer(props) {
  const {
    orderTime, timeLeft, language, remMin,
  } = props;
  return (
    <Row className="text-center text-muted p-2">
      <Col className="border-right no-print">
        {Strings.Order_Time[language]}
        <h2 className="text-black m-0">{new Date(orderTime).toLocaleTimeString(`${language}-in`, timeOptions)}</h2>
      </Col>
      <Col className="border-left no-print">
        {Strings.Time_Left[language]}
        <h2 className={`text-${remMin < 5 ? 'danger' : 'success'} m-0`}>{timeLeft}</h2>
      </Col>
    </Row>
  );
}

Timer.propTypes = {
  orderTime: PropTypes.string.isRequired,
  timeLeft: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  remMin: PropTypes.number.isRequired,
};

export default Timer;
