import React from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import { Row, Col, Container } from 'react-bootstrap';
import {
  setToken, setUUID, setIsApp, setAppClientVersion, setIsAdmin,
  logClevertapOnUserLogin,
} from '../../Utils';

function StoreList(props) {
  const { history, location } = props;
  const params = QueryString.parse(location.search);
  const profiles = JSON.parse(window.localStorage.getItem('profiles'));
  return (
    <Container fluid className="h-100">
      <Row className="h-100 align-items-center p-4">
        <Col
          xs={24}
          lg={{ span: 10, offset: 7 }}
          md={{ span: 12, offset: 6 }}
          sm={{ span: 16, offset: 4 }}
          className="border shadow bg-white p-4 h-100 overflow-y-scroll"
        >
          <b className="fs-3 d-block text-center text-primary pb-4">Select Store</b>
          <Row className="m-0">
            {
              profiles.map(profile => (
                <Col
                  key={profile.code}
                  xs={24}
                  className="w-100 bg-light p-4 mb-4 d-flex align-items-center"
                  onClick={() => {
                    setToken(profile.token);
                    setUUID(profile.code);
                    setIsApp(false);
                    setIsAdmin(false);
                    setAppClientVersion(-1);
                    window.localStorage.setItem('retailer', JSON.stringify(profile));
                    logClevertapOnUserLogin({
                      Identity: profile.code,
                      Name: profile.store_name,
                      Phone: profile.phone_number,
                      Email: profile.email,
                    });
                    history.replace(`${params.n ? params.n : '/'}${params.s ? params.s : ''}`);
                  }}
                >
                  <div className="w-85">
                    <b className="d-block">{profile.store_name}</b>
                    <div className="text-truncate">{profile.address.device_address}</div>
                  </div>
                  <img src="/Assets/arrow-down.svg" alt="" className="rotate-270 ml-auto" />
                </Col>
              ))
            }
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

StoreList.propTypes = {
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default StoreList;
