import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { timeOptions } from '../../../Utils';


function CustomStepper(props) {
  const { orderHistory, language } = props;
  return (
    <Container className="no-print">
      <Row className="justify-content-center">
        <Col xs="auto">
          {
            orderHistory.map(orderStatus => (
              <Row key={orderStatus.status.name} className={`step ${orderStatus.status.status}`}>
                <Col xs="auto" className="v-stepper p-0">
                  <div className="circle" />
                  <div className="line" />
                </Col>
                <Col xs="auto" className="time p-0">
                  <b>
                    {
                      orderStatus.status.status === 'completed'
                        ? new Date(orderStatus.createdOn).toLocaleTimeString(`${language}-in`, timeOptions)
                        : ''
                    }
                  </b>
                </Col>
                <Col xs="auto" className="content p-0">
                  <b>{orderStatus.status.displayName}</b>
                </Col>
              </Row>
            ))
          }
        </Col>
      </Row>
    </Container>
  );
}

CustomStepper.propTypes = {
  orderHistory: PropTypes.arrayOf(PropTypes.object).isRequired,
  language: PropTypes.string.isRequired,
};

export default CustomStepper;

/*
<div className={`step ${orderHistory}`}>
        <div className="v-stepper">
          <div className="circle" />
          <div className="line" />
        </div>

        <div className="content">
          Lorem Ipsum is simply dummy
        </div>
      </div>

      <div className="step active">
        <div className="v-stepper">
          <div className="circle" />
          <div className="line" />
        </div>

        <div className="content">
          Lorem Ipsum is simply dummy text
        </div>
      </div>

      <div className="step">
        <div className="v-stepper">
          <div className="circle" />
          <div className="line" />
        </div>

        <div className="content">
          Lorem Ipsum is simply dummy text of
        </div>
      </div>

      <div className="step">
        <div className="v-stepper">
          <div className="circle" />
          <div className="line" />
        </div>

        <div className="content">
          Lorem Ipsum is simply dummy text of
        </div>
      </div>
*/
