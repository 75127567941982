import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import Strings from '../../../Strings';


function PaymentPreference(props) {
  const {
    payment, language, delivered, settlement,
  } = props;
  return (
    <Row
      className="mb-4"
    >
      <Col>
        <Row
          className="shadow bg-white text-center p-4 m-0"
        >
          {
            payment.status === 'PAID' ? (
              <Col
                xs={24}
              >
                <div
                  className="text-primary fs-3"
                >
                  <b>
                    <img
                      src="/Assets/check-circle.svg"
                      alt=""
                      className="mr-1"
                    />
                    {Strings.Payment_Received[language]}
                  </b>
                </div>
                <div>
                  {
                    `${Strings.Payment_Mode[language]}: ${
                      payment.mode === 'CASH'
                        ? Strings.Cash_On_Delivery[language]
                        : Strings.Digital_Payment[language]
                    }`
                  }
                </div>
                <div
                  className={payment.mode === 'CASH' ? 'd-none' : ''}
                >
                  {`${Strings.Transactions_ID[language]}: ${payment.razorpay.paymentId}`}
                </div>
              </Col>
            ) : (
              <Col
                xs={24}
              >
                <div
                  className="fs-3 text-danger-light"
                >
                  <b>{Strings.Pending_Payment[language]}</b>
                </div>
                <div>
                  {
                    `${Strings.Payment_Mode[language]}: ${
                      payment.mode === 'CASH'
                        ? Strings.Cash_On_Delivery[language]
                        : Strings.Digital_Payment[language]
                    }`
                  }
                </div>
                <div
                  className={
                    payment.mode === 'CASH'
                      ? 'd-none' : 'py-2 text-muted'
                  }
                >
                  <i>
                    &quot;
                    {Strings.Payment_Note[language]}
                    &quot;
                  </i>
                </div>
              </Col>
            )
          }
          {
            settlement
            && settlement !== ''
            && delivered
            && (
              settlement.status === 'PENDING'
              || settlement.status === 'RECONCILED'
              || settlement.status === 'FAILED'
            )
            && (
              <>
                <Col
                  xs={24}
                  className="border my-2 no-print"
                />
                <Col
                  xs={24}
                  className="no-print"
                >
                  <Row>
                    <Col
                      xs={24}
                    >
                      {
                        settlement.status === 'RECONCILED'
                          ? (
                            <b className="text-success">
                              {Strings.Settlement_Successful[language]}
                            </b>
                          ) : (
                            <b className="text-danger-light">
                              {Strings.Settlement_Pending[language]}
                            </b>
                          )
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      className="text-left pl-0 pr-1"
                    >
                      {`${Strings.Digital_Payment[language]} ${Strings.Settlements[language]}`}
                    </Col>
                    <Col
                      xs="auto"
                      className="text-right pl-1 pr-0"
                    >
                      {`\u20B9 ${settlement.payableAmount}`}
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      className="text-left pl-0 pr-1"
                    >
                      {`${Strings.Discount[language]} ${Strings.Settlements[language]}`}
                    </Col>
                    <Col
                      xs="auto"
                      className="text-right pl-1 pr-0"
                    >
                      {`+ \u20B9 ${settlement.discountAmount}`}
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      className="text-left pl-0 pr-1"
                    >
                      {Strings.Digital_Transaction_Fee[language]}
                    </Col>
                    <Col
                      xs="auto"
                      className="text-right pl-1 pr-0"
                    >
                      {`- \u20B9 ${
                        Math.round(
                          (
                            settlement.payableAmount
                            + settlement.discountAmount
                          ) * 2.5,
                        ) / 100
                      }`}
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      className="border my-1"
                    />
                  </Row>
                  <Row
                    className={`fs-3 ${
                      settlement.status === 'PENDING'
                        ? 'text-danger-light'
                        : 'text-success'
                    }`}
                  >
                    <Col
                      className="text-left pl-0 pr-1"
                    >
                      <b>{Strings.Total_Settlement[language]}</b>
                    </Col>
                    <Col
                      xs="auto"
                      className="text-right pl-1 pr-0"
                    >
                      <b>
                        {
                          `\u20B9 ${
                            Math.round(
                              (
                                settlement.payableAmount
                                + settlement.discountAmount
                                - Math.round(
                                  (
                                    settlement.payableAmount
                                    + settlement.discountAmount
                                  ) * 2.5,
                                ) / 100
                              ) * 100,
                            ) / 100
                          }`
                        }
                      </b>
                    </Col>
                  </Row>
                </Col>
              </>
            )
          }
        </Row>
      </Col>
    </Row>
  );
}

PaymentPreference.propTypes = {
  payment: PropTypes.shape({}).isRequired,
  language: PropTypes.string.isRequired,
  settlement: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  delivered: PropTypes.bool,
};

PaymentPreference.defaultProps = {
  settlement: null,
  delivered: false,
};

export default PaymentPreference;
