import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import Strings from '../../../Strings';
import {
  shortDateOptions, timeOptions,
} from '../../../Utils';

function getOrderTag(order, language) {
  const { payment } = order;

  const digitalPayment = (
    <div
      className="text-primary border border-primary py-1 px-3 mr-2"
    >
      <b
        className="text-dark"
      >
        {`\u20B9 ${payment.payableAmount}`}
      </b>
      &nbsp;&nbsp;
      {Strings.Digital_Payment[language]}
    </div>
  );
  const discount = (
    <div
      className="text-success border border-success py-1 px-3 mr-2"
    >
      <b
        className="text-dark"
      >
        {`\u20B9 ${payment.couponDiscount + payment.appExclusiveDiscount}`}
      </b>
      &nbsp;&nbsp;
      {Strings.Discount[language]}
    </div>
  );
  const priceAdjustment = (
    <div
      className="text-danger border border-danger py-1 px-3"
    >
      <b
        className="text-dark"
      >
        {`\u20B9 ${
          payment.overallDiscount
          - payment.couponDiscount
          - payment.appExclusiveDiscount}`}
      </b>
      &nbsp;&nbsp;
      {Strings.Mpaani_Adjustments[language]}
    </div>
  );

  return (
    <div
      className="d-flex flex-wrap"
    >
      {
        payment.mode === 'DIGITAL'
          ? (
            <div
              className="mb-2"
            >
              {digitalPayment}
            </div>
          ) : ''
      }
      {
        payment.couponDiscount !== 0
        || payment.appExclusiveDiscount !== 0
          ? (
            <div
              className="mb-2"
            >
              {discount}
            </div>
          ) : ''
      }
      {
        payment.overallDiscount
        - payment.couponDiscount
        - payment.appExclusiveDiscount !== 0
          ? (
            <div
              className="mb-2"
            >
              {priceAdjustment}
            </div>
          ) : ''
      }
    </div>
  );
}

function Order(props) {
  const { item, language, history } = props;
  return (
    <Col
      xs={24}
      className="pb-3 cursor-pointer"
      onClick={() => {
        history.push(`/orders/${item.orderId}`);
      }}
    >
      <Row>
        <Col
          xs={14}
          className="text-muted"
        >
          {
            new Date(item.createdOn)
              .toLocaleDateString(`${language}-in`, shortDateOptions)
          }
          &nbsp;|&nbsp;
          {
            new Date(item.createdOn)
              .toLocaleTimeString(`${language}-in`, timeOptions)
          }
        </Col>
        <Col
          xs={10}
          className="text-right"
        >
          {`#${item.orderId}`}
        </Col>
      </Row>
      <Row>
        <Col
          xs={14}
        >
          <b>{item.customer.displayName}</b>
        </Col>
        <Col
          xs={10}
          className="text-right"
        >
          <b>
            {`\u20B9 ${
              (item.payment.mode === 'DIGITAL'
                ? item.payment.payableAmount : 0)
              + item.payment.overallDiscount
            }`}
          </b>
        </Col>
      </Row>
      {/* <Row>
        <Col
          xs={14}
          className="text-truncate"
        >
          {
            item.pickupFromStore
              ? Strings.Pickup_From_Store[language]
              : getAddressString(item.deliveryDetails.deliveryAddress)
          }
        </Col>
        <Col
          xs={10}
          className="text-muted text-right"
        >
          {`${item.productCount} ${Strings.items[language]}`}
        </Col>
      </Row> */}
      <Row>
        <Col
          xs={24}
          className="fs-1 mt-2 mb-1"
        >
          {getOrderTag(item, language)}
        </Col>
      </Row>
      <Row className="m-0">
        <Col className="border my-2" />
      </Row>
    </Col>
  );
}

Order.propTypes = {
  item: PropTypes.shape({}).isRequired,
  language: PropTypes.string.isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default Order;
