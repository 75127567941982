import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import Strings from '../../../Strings';
import { formatDate } from '../../../Utils';

function SpecialSpendOffer(props) {
  const { item, mode, language } = props;
  return (
    <Col
      xs={24}
      lg={mode === 'order' ? 24 : 12}
      className="px-4 px-md-6 py-2"
    >
      <Row className="m-0">
        <Col
          xs={6}
          sm={mode === 'order' ? 4 : 5}
          className={`p-1 text-center product-image-div${mode === 'offer' && ' border'}`}
        >
          <img
            src={(item.image ? item.image === ''
              ? '/Assets/thumbnail.svg' : item.image : '/Assets/thumbnail.svg')}
            alt=""
            className="product-image"
          />
        </Col>
        <Col xs={18} sm={19}>
          <Row>
            <Col xs={24} className="py-1">
              {mode === 'offer'
              && (
              <b className="text-success">
                {Strings.Special_Offer[language]}
                &nbsp;
              </b>
              )}
              <b>
                {item.name}
              </b>
            </Col>
            {item.fundedBy === 'MPAANI'
            && (
            <Col xs={24}>
              <span>
                <b>Funded By - </b>
                <b className="text-danger">
                  LoveLocal
                </b>
                <b className="text-success">
                  {` (\u20B9 ${item.productPrice})`}
                </b>
              </span>
            </Col>
            )}
            {mode === 'order'
              && (
              <Col xs="auto" className="ml-3 text-white bg-success">
                <span>
                  <img src="/Assets/special-offer-star.svg" alt="" />
                  <b>
                    &nbsp;&nbsp;
                    {Strings.Special_Offer[language]}
                  </b>
                </span>
              </Col>
              )
            }
            <Col xs={24} className="py-1">
              {item.description}
            </Col>
            {(mode === 'offer' && item.validUntil)
              && (
                <Col xs={24} className="pt-1">
                  <span>
                    {Strings.Valid_Till[language]}
                  &nbsp;-&nbsp;
                  </span>
                  <span className={new Date(item.validUntil) > new Date() ? '' : 'text-danger'}>
                    {new Date(item.validUntil) > new Date()
                      ? formatDate(new Date(item.validUntil))
                      : `${formatDate(new Date(item.validUntil))}(${Strings.Expired[language]})`
                    }
                  </span>
                </Col>
              )}
          </Row>
        </Col>
      </Row>
      <Row className="m-0">
        {mode === 'offer' && <Col xs={24} className="border mt-2" />}
      </Row>
    </Col>
  );
}

SpecialSpendOffer.propTypes = {
  item: PropTypes.shape({}).isRequired,
  language: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
};

export default SpecialSpendOffer;
