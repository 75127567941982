import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import {
  OrderList, CustomerDetails, PaymentPreference, Timer,
} from '../../components/derived/orders';
import { CustomStepper, CustomModal } from '../../components/common';
import './styles.scss';
import { patchOrderDetails } from '../../../api/api';
import Strings from '../../Strings';
import {
  getDateDiff, logFirebaseEvent, getAddressString,
} from '../../Utils';
import events from '../../FirebaseEvents';

class DispatchedOrderDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      remMin: 0,
      remSec: 0,
      showDialog: false,
    };
    this.patchOrderStatus = this.patchOrderStatus.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleDialog = this.toggleDialog.bind(this);
  }

  componentWillMount() {
    const { orderDetails } = this.props;
    let ms = 900000 - getDateDiff(new Date(), new Date(orderDetails.modifiedOn));
    if (ms < 0) {
      return;
    }
    const mm = Math.floor(ms / 1000 / 60);
    ms -= mm * 1000 * 60;
    const ss = Math.floor(ms / 1000);
    this.setState({ remSec: ss, remMin: mm }, () => {
      this.timer = setInterval(() => {
        const { remSec, remMin } = this.state;
        if (remMin === 0 && remSec === 0) {
          clearInterval(this.timer);
          return;
        }
        if (remSec === 0) {
          this.setState({
            remMin: remMin === 0 ? 0 : remMin - 1,
            remSec: 59,
          });
        } else {
          this.setState({ remSec: remSec - 1 });
        }
      }, 1000);
    });
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  patchOrderStatus() {
    logFirebaseEvent(events.OO_od_mark_order_delivered, null);
    const { orderDetails, orderDetailsChange } = this.props;
    patchOrderDetails(orderDetails.orderId, {
      action: 'PATCH_DELIVERY_STATUS',
      body: { deliveryStatus: 'DELIVERED' },
    }).then((res) => {
      orderDetailsChange(res.data);
    });
  }

  toggleDialog() {
    this.setState(state => ({ showDialog: !state.showDialog }));
  }

  handleSubmit() {
    const { orderDetails } = this.props;
    if (orderDetails.payment.mode === 'DIGITAL' && orderDetails.payment.status === 'PENDING') {
      this.toggleDialog();
    } else {
      this.patchOrderStatus();
    }
  }

  render() {
    const {
      orderDetails, orderHistory, language,
    } = this.props;
    const { remMin, remSec, showDialog } = this.state;
    const body = (
      <Row>
        <Col xs={24} className="pb-4">{Strings.Payment_Pending_Message[language]}</Col>
        <Col xs={24} className="text-right">
          <Button onClick={this.toggleDialog}>{Strings.OK[language]}</Button>
        </Col>
      </Row>
    );
    return (
      <Container fluid className="bg-light position-relative pb-58p">
        <CustomModal
          show={showDialog}
          title={<span className="text-primary">{Strings.Payment_Pending[language]}</span>}
          body={body}
          onHide={this.toggleDialog}
          closeButton
        />
        <Row className="mb-4">
          <Col className="no-print">
            <Row className="bg-white text-center p-4">
              <Col className="d-flex justify-content-center align-items-center">
                <h3 className="m-0 px-4">{Strings.Is_order_delivered[language]}</h3>
                <Button variant="success rounded-0" onClick={this.handleSubmit}>
                  <img src="/Assets/smiley-smile.svg" alt="" />
                  &nbsp;
                  {Strings.Yes[language]}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <div className="border shadow bg-white">
              <Col xs={24} className="text-muted text-center pt-2">{`${Strings.Order_Id[language]}: #${orderDetails.orderId}`}</Col>
              <Timer
                {...this.props}
                orderTime={orderDetails.createdOn}
                timeLeft={`${remMin < 10 ? `0${remMin}` : remMin}:${remSec < 10 ? `0${remSec}` : remSec}`}
                remMin={remMin}
              />
              <Col className="border my-2 no-print" />
              <CustomStepper {...this.props} orderHistory={orderHistory} />
            </div>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <div className="shadow bg-white">
              <CustomerDetails
                customer={orderDetails.customer}
                address={
                  orderDetails.pickupFromStore
                    ? null
                    : getAddressString(orderDetails.deliveryDetails.deliveryAddress)
                }
                description={orderDetails.description}
                language={language}
              />
            </div>
          </Col>
        </Row>
        <PaymentPreference
          payment={orderDetails.payment}
          language={language}
          settlement={orderDetails.settlement}
        />
        <Row className="m-0">
          <Col xs={24} className="zig-zag" />
        </Row>
        <Row>
          <Col xs={24}>
            <OrderList
              {...this.props}
              orderDetails={orderDetails}
            />
          </Col>
        </Row>
        <Row className="m-0">
          <Col xs={24} className="zig-zag bottom" />
        </Row>
      </Container>
    );
  }
}

DispatchedOrderDetails.propTypes = {
  history: PropTypes.shape({}).isRequired,
  orderDetails: PropTypes.shape({}).isRequired,
  orderHistory: PropTypes.arrayOf(PropTypes.object).isRequired,
  orderDetailsChange: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
};

export default DispatchedOrderDetails;
