import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button, Form,
} from 'react-bootstrap';
import { CustomStepper } from '../../components/common';
import { patchOrderDetails } from '../../../api/api';
import Strings from '../../Strings';
import {
  getDateDiff, logFirebaseEvent, getAddressString, logClevertapEvents,
} from '../../Utils';
import events from '../../FirebaseEvents';
import clevertapEvents from '../../ClevertapEvents';
import {
  OrderList, CustomerDetails, PaymentPreference, Timer,
} from '../../components/derived/orders';
import './styles.scss';


function notValid(name, value) {
  switch (name) {
    case 'name': {
      if (value.match('^[0-9a-zA-Z ]{0,30}$') === null) {
        return true;
      }
      return false;
    }
    case 'phoneNumber': {
      if (value.match('^[0-9]{0,10}$') === null) {
        return true;
      }
      return false;
    }
    default: return false;
  }
}

class ReadyOrderDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      phoneNumber: '',
      remMin: 0,
      remSec: 0,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.patchOrderStatus = this.patchOrderStatus.bind(this);
  }

  componentWillMount() {
    const { orderDetails } = this.props;
    let ms = 900000 - getDateDiff(new Date(), new Date(orderDetails.modifiedOn));
    if (ms < 0) {
      return;
    }
    const mm = Math.floor(ms / 1000 / 60);
    ms -= mm * 1000 * 60;
    const ss = Math.floor(ms / 1000);
    this.setState({ remSec: ss, remMin: mm }, () => {
      this.timer = setInterval(() => {
        const { remSec, remMin } = this.state;
        if (remMin === 0 && remSec === 0) {
          clearInterval(this.timer);
          return;
        }
        if (remSec === 0) {
          this.setState({
            remMin: remMin === 0 ? 0 : remMin - 1,
            remSec: 59,
          });
        } else {
          this.setState({ remSec: remSec - 1 });
        }
      }, 1000);
    });
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  patchOrderStatus() {
    logFirebaseEvent(events.OO_od_mark_order_out_for_delivery, null);
    const { orderDetails, orderDetailsChange } = this.props;
    patchOrderDetails(orderDetails.orderId, {
      action: 'PATCH_DELIVERY_STATUS',
      body: { deliveryStatus: 'OUT_FOR_DELIVERY' },
    }).then((res) => {
      orderDetailsChange(res.data);
    });
  }

  handleChange(event) {
    const { name, value } = event.target;
    if (notValid(name, value)) {
      return;
    }
    this.setState({ [name]: value });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { name, phoneNumber } = this.state;
    logFirebaseEvent(events.OO_od_sendSMS, null);
    const { orderDetails, orderDetailsChange } = this.props;
    patchOrderDetails(orderDetails.orderId, {
      action: 'PATCH_DELIVERY_BOY',
      body: this.state,
    }).then((res) => {
      logClevertapEvents(
        clevertapEvents.OO_OrderDetails_SMStoDelivery,
        {
          DeliveryBoy: name,
          PhoneNumber: phoneNumber,
        },
      );
      orderDetailsChange(res.data);
    });
  }

  render() {
    const {
      orderDetails, orderHistory, language,
    } = this.props;
    const {
      name, phoneNumber, remMin, remSec,
    } = this.state;
    // console.log(orderDetails);
    return (
      <Container fluid className="bg-light position-relative pb-70p pt-3">
        <Row className="mb-4">
          <Col>
            <div className="border shadow bg-white">
              <Col xs={24} className="text-muted text-center pt-2">{`${Strings.Order_Id[language]}: #${orderDetails.orderId}`}</Col>
              <Timer
                {...this.props}
                orderTime={orderDetails.createdOn}
                timeLeft={`${remMin < 10 ? `0${remMin}` : remMin}:${remSec < 10 ? `0${remSec}` : remSec}`}
                remMin={remMin}
              />
              <Col className="border my-2 no-print" />
              <CustomStepper {...this.props} orderHistory={orderHistory} />
            </div>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <div className="shadow bg-white">
              <CustomerDetails
                customer={orderDetails.customer}
                address={
                  orderDetails.pickupFromStore
                    ? null
                    : getAddressString(orderDetails.deliveryDetails.deliveryAddress)
                }
                description={orderDetails.description}
                language={language}
              />
            </div>
          </Col>
        </Row>
        {/* <Row className="mb-4">
          <Col>
            <Row className="shadow bg-white text-center p-4 m-0">
              <Col xs={24} className="pb-2">
                {Strings.Attach_Order_Invoice_Here[language]}
              </Col>
              <Col xs={12} className="px-2">
                <Button
                  variant="outline-primary border-dashed"
                  block
                  onClick={launchImageClicker}
                >
                  <img src="/Assets/camera.svg" alt="" />
                  &nbsp;
                  {Strings.Camera[language]}
                </Button>
              </Col>
              <Col xs={12} className="px-2">
                <Button
                  variant="outline-primary border-dashed"
                  block
                  onClick={launchImagePicker}
                >
                  <img src="/Assets/gallery.svg" alt="" />
                  &nbsp;
                  {Strings.Gallery[language]}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row> */}
        <PaymentPreference
          payment={orderDetails.payment}
          language={language}
          settlement={orderDetails.settlement}
        />
        {
          !orderDetails.pickupFromStore && (
            <Row className="mb-4">
              <Col className="no-print">
                {
                  orderDetails.deliveryDetails.deliveryBoy.name ? (
                    <Row className="shadow bg-white text-center p-4 m-0">
                      <Col xs={24}>
                        <b>
                          {orderDetails.deliveryDetails.deliveryBoy.name}
                          &nbsp;
                          <Button
                            variant="link"
                            href={`tel:${orderDetails.deliveryDetails.deliveryBoy.phoneNumber}`}
                            className="text-primary"
                          >
                            {`(+91-${orderDetails.deliveryDetails.deliveryBoy.phoneNumber})`}
                          </Button>
                        </b>
                      </Col>
                      <Col xs={24}><b>{Strings.Will_Deliver_Order[language]}</b></Col>
                    </Row>
                  ) : (
                    <Row className="shadow bg-white text-center p-4 m-0">
                      <Col xs={24}>{Strings.SMS_Delivery_Boy[language]}</Col>
                      <Col xs={12} className="p-2">
                        <Form.Control
                          name="name"
                          type="text"
                          placeholder={Strings.Name[language]}
                          value={name}
                          onChange={this.handleChange}
                        />
                      </Col>
                      <Col xs={12} className="p-2">
                        <Form.Control
                          name="phoneNumber"
                          type="text"
                          placeholder={Strings.Mobile_No[language]}
                          value={phoneNumber}
                          onChange={this.handleChange}
                        />
                      </Col>
                      <Col className="px-2">
                        <Button
                          variant="primary rounded-0"
                          block
                          onClick={this.handleSubmit}
                          disabled={phoneNumber.length !== 10 || name.length < 3}
                        >
                          {Strings.Send_SMS[language]}
                        </Button>
                      </Col>
                    </Row>
                  )
                }
              </Col>
            </Row>
          )
        }
        <Row className="m-0"><Col xs={24} className="zig-zag" /></Row>
        <Row>
          <Col xs={24}>
            <OrderList
              {...this.props}
              orderDetails={orderDetails}
            />
          </Col>
        </Row>
        <Row className="m-0"><Col xs={24} className="zig-zag bottom" /></Row>
        <Row className="m-0 fixed-bottom">
          <Col xs={24} className="px-0 no-print">
            <Button
              variant="success"
              block
              className="rounded-0 py-3 fs-3"
              onClick={this.patchOrderStatus}
            >
              {
                orderDetails.pickupFromStore
                  ? Strings.Order_Pickup_Ready[language]
                  : Strings.Dispatch_Order[language]
              }
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

ReadyOrderDetails.propTypes = {
  history: PropTypes.shape({}).isRequired,
  orderDetails: PropTypes.shape({}).isRequired,
  orderHistory: PropTypes.arrayOf(PropTypes.object).isRequired,
  orderDetailsChange: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
};

export default ReadyOrderDetails;
