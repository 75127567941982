import React from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import {
  Container, Row, Col, Button, Accordion,
} from 'react-bootstrap';
import QueryString from 'query-string';
import { Link } from 'react-router-dom';
import Strings from '../../Strings';
import {
  getLibraryProducts, retailerProductMapping, getProductCategories, getRecommendedProducts,
} from '../../../api/api';
import LibraryProduct from '../../components/derived/product/LibraryProduct';
import {
  getUUID, logFirebaseEvent, getIsApp, logClevertapEvents,
} from '../../Utils';
import events from '../../FirebaseEvents';
import { CustomModal, ErrorHandler } from '../../components/common';
import clevertapEvents from '../../ClevertapEvents';

function toggleNav() {
  const open = document.getElementById('bottom-nav').style.height.trim() === '300px';
  const navOverlay = document.getElementById('nav-overlay');
  if (open) {
    navOverlay.classList.remove('active-nav');
  } else {
    navOverlay.classList.add('active-nav');
  }
  document.getElementById('bottom-nav').style.height = open ? '0px' : '300px';
}

const { CancelToken } = Axios;
let loadRequest = null;
let lazyLoadRequest = null;
const limit = 20;

class LibraryProductList extends React.Component {
  constructor(props) {
    super(props);
    const { location, setup } = props;
    const params = QueryString.parse(location.search);
    this.state = {
      products: [],
      productCount: 0,
      categories: [],
      offset: 0,
      loading: true,
      error: false,
      selectedProducts: [],
      searchText: '',
      activeTimeout: -1,
      showUploadMessage: false,
      selectedCategory: params.category || 'all',
      selectedSubCategory: params.subCategory || 'all',
      currentAccordion: 'null',
      savingProducts: false,
      recommendedProducts: [],
      topSelling: setup,
    };
    this.handleLazyLoad = this.handleLazyLoad.bind(this);
    this.updateSelectedProducts = this.updateSelectedProducts.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.changeCategory = this.changeCategory.bind(this);
    this.handleCategoryChanged = this.handleCategoryChanged.bind(this);
    this.loadProducts = this.loadProducts.bind(this);
    this.appendProducts = this.appendProducts.bind(this);
    this.toggleUploadMessage = this.toggleUploadMessage.bind(this);
    this.toggleTopSelling = this.toggleTopSelling.bind(this);
  }

  componentWillMount() {
    this.loadProducts();
    getProductCategories().then((res) => {
      this.setState({ categories: res.data.results });
    });
    const { setup } = this.props;
    getRecommendedProducts('TOPSELLING')
      .then((res) => {
        const recommendedProducts = res.data.results;
        this.setState({
          selectedProducts: setup ? recommendedProducts : [],
          recommendedProducts,
        });
      });
  }

  componentWillReceiveProps(nextProps) {
    const { location } = this.props;
    const nextLocation = nextProps.location;
    if (nextLocation.search !== location.search) {
      this.handleCategoryChanged(nextProps);
    }
  }

  handleLazyLoad(event) {
    const { loading, productCount, offset } = this.state;
    if (loading) return;
    const {
      offsetHeight, scrollTop, scrollHeight, id,
    } = event.target;
    if (id === 'main-container' && (scrollHeight - offsetHeight - scrollTop < 100
      || offsetHeight + scrollTop === scrollHeight)) {
      if (offset >= productCount) {
        this.setState({ loading: false });
      } else {
        this.appendProducts();
      }
    }
  }

  handleCategoryChanged(props) {
    const { location } = props;
    const params = QueryString.parse(location.search);
    this.setState({
      products: [],
      productCount: 0,
      offset: 0,
      loading: true,
      selectedCategory: params.category || 'all',
      selectedSubCategory: params.subCategory || 'all',
      currentAccordion: params.category || 'all',
    }, this.loadProducts);
  }

  updateSelectedProducts(id, checked) {
    const { selectedProducts } = this.state;
    if (checked) {
      selectedProducts.push(id);
    } else {
      const index = selectedProducts.findIndex(productId => productId === id);
      selectedProducts.splice(index, 1);
    }
    this.setState({ selectedProducts });
  }

  handleSubmit() {
    this.setState({ savingProducts: true });
    const { selectedProducts } = this.state;
    logFirebaseEvent(events.OO_setup6_SKU_Pick_List_Upload, null);
    retailerProductMapping({
      storeId: getUUID(),
      productIds: selectedProducts,
    }).then(() => {
      const { setup, history } = this.props;
      if (setup) {
        logClevertapEvents(
          clevertapEvents.OO_SetupStart_ProductAdding_Successful,
          { 'No of products added': selectedProducts.length },
        );
        history.push('/setup?stage=product-retailer');
      } else {
        logClevertapEvents(
          clevertapEvents.OO_Inventory_Products_Added,
          { 'No of products added': selectedProducts.length },
        );
        history.push('/products?products-added=t');
      }
    }).catch(() => {
      this.setState({ loading: false, savingProducts: false });
    });
  }

  handleSearch(event) {
    const { value } = event.target;
    const { activeTimeout } = this.state;
    if (activeTimeout !== -1) {
      clearTimeout(activeTimeout);
    }
    this.setState({
      searchText: value, products: [], productCount: 0, offset: 0, loading: true,
    }, () => {
      const timeoutId = setTimeout(() => {
        logFirebaseEvent(events.OO_setup6_SKU_search, null);
        this.loadProducts();
        this.setState({ activeTimeout: -1 });
      }, 350);
      this.setState({ activeTimeout: timeoutId });
    });
  }

  loadProducts() {
    if (loadRequest) {
      loadRequest.cancel();
    }
    if (lazyLoadRequest) {
      lazyLoadRequest.cancel();
    }
    loadRequest = CancelToken.source();
    const { searchText, selectedCategory, selectedSubCategory } = this.state;
    getLibraryProducts(0, limit, searchText, selectedCategory,
      selectedSubCategory, 'TOPSELLING', loadRequest)
      .then((res) => {
        loadRequest = null;
        this.setState({
          products: res.data.results,
          productCount: res.data.count,
          offset: limit,
          loading: false,
          error: false,
        });
      }).catch((error) => {
        if (Axios.isCancel(error)) {
          return;
        }
        this.setState({ loading: false, error: true });
      });
  }

  appendProducts() {
    if (lazyLoadRequest) {
      lazyLoadRequest.cancel();
    }
    lazyLoadRequest = CancelToken.source();
    this.setState({ loading: true });
    const {
      searchText, offset, selectedCategory, selectedSubCategory,
    } = this.state;
    getLibraryProducts(offset, limit, searchText,
      selectedCategory, selectedSubCategory, 'TOPSELLING', lazyLoadRequest)
      .then((res) => {
        lazyLoadRequest = null;
        this.setState(state => ({
          products: state.products.concat(res.data.results),
          productCount: res.data.count,
          offset: state.offset + limit,
          loading: false,
        }));
      }).catch((error) => {
        if (Axios.isCancel(error)) {
          return;
        }
        this.setState({ loading: false });
      });
  }

  changeCategory(selectedCategory, selectedSubCategory) {
    this.setState({ selectedCategory, selectedSubCategory, currentAccordion: selectedCategory });
  }

  toggleUploadMessage() {
    this.setState(state => ({ showUploadMessage: !state.showUploadMessage }));
  }

  toggleTopSelling(event) {
    event.currentTarget.blur();
    this.setState((state) => {
      const { topSelling, selectedProducts, recommendedProducts } = state;
      return ({
        topSelling: !topSelling,
        selectedProducts: topSelling ? selectedProducts.filter(productId => (
          !recommendedProducts.includes(productId)
        )) : [...selectedProducts, ...recommendedProducts],
      });
    });
  }

  render() {
    const {
      products, loading, selectedProducts, searchText, categories,
      selectedCategory, selectedSubCategory, currentAccordion, error,
      showUploadMessage, savingProducts, topSelling,
    } = this.state;
    const {
      language, setup, history, appDisplay,
    } = this.props;
    return (
      <Container
        id="main-container"
        onScroll={this.handleLazyLoad}
        fluid
        className=" pb-58p bg-white position-relative product-list-library"
      >
        <CustomModal
          show={showUploadMessage}
          title={Strings.Bulk_Upload[language]}
          body={(
            <Row>
              <Col
                xs={{ span: 20, offset: 2 }}
                className="py-2 text-justify"
              >
                <b>{Strings.Bulk_Upload_From_PC[language]}</b>
              </Col>
              <Col
                xs={{ span: 20, offset: 2 }}
                className="text-center text-primary"
              >
                <a
                  href="https://myshop.lovelocal.in/"
                >
                  <b>https://myshop.lovelocal.in</b>
                </a>
              </Col>
              <Col xs={24} className="text-right">
                <Button onClick={this.toggleUploadMessage}>{Strings.OK[language]}</Button>
              </Col>
            </Row>
          )}
          onHide={this.toggleUploadMessage}
          closeButton
        />
        <Row className="mx-n3 px-3 mt-4 mb-2 sticky-top bg-white">
          {
            appDisplay ? (
              <Col
                xs={24}
                className="d-flex align-items-center px-0 pt-2 pb-3 fs-3"
              >
                <Button
                  variant="link"
                  className="p-0 d-flex align-items-center"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <img
                    src="/Assets/left-arrow.svg"
                    alt=""
                    className="rotate-180"
                    width="18px"
                  />
                </Button>
                &nbsp;&nbsp;
                <b>Add Product</b>
              </Col>
            ) : ''
          }
          <Col
            xs={24}
            className={`p-0 ${error ? 'd-none' : 'd-flex align-items-center'}`}
          >
            <div
              className="form-control d-flex align-items-center"
            >
              <img src="/Assets/search.svg" alt="" />
              <input
                type="text"
                className="w-100 border-0"
                placeholder={Strings.Search_from_products[language]}
                value={searchText}
                onChange={this.handleSearch}
              />
            </div>
            <Button
              variant="success ml-1 d-flex"
              onClick={toggleNav}
            >
              <img src="/Assets/filter.svg" alt="" />
              {Strings.Category[language]}
            </Button>
          </Col>
          <Col
            xs={selectedProducts.length === 0 ? 24 : 12}
            className="py-2 px-0"
          >
            <Button
              variant={topSelling ? 'primary' : 'white border'}
              className="d-flex align-items-center py-1 px-4 rounded-0"
              onClick={this.toggleTopSelling}
            >
              <span className="fs-2 font-weight-normal d-flex align-items-center">
                <span>{Strings.Top_Selling[language]}</span>
                {
                  topSelling && (
                    <span className="fs-4 text-white d-flex align-items-center">
                      &nbsp;&nbsp;&times;
                    </span>
                  )
                }
              </span>
            </Button>
          </Col>
          <Col
            xs={12}
            className={selectedProducts.length === 0
              ? 'd-none' : 'py-4 px-4 text-primary text-right'}
          >
            <b>{`${selectedProducts.length} ${Strings.Products_Selected[language]}`}</b>
          </Col>
          <ErrorHandler
            show={error}
            language={language}
            handleRetry={() => {
              this.setState({
                loading: true,
                error: false,
              }, this.handleLoad);
            }}
          />
        </Row>
        {
          products.length === 0 ? (
            <div
              className={`text-center text-muted p-4 w-100${loading || error ? ' d-none' : ''}`}
            >
              <i><b>{Strings.No_Products_Found[language]}</b></i>
            </div>
          ) : (
            <React.Fragment>
              <Row className="mx-0 mb-2 p-3 bg-light">
                <Col xs="auto" className="p-0">
                  <img src="/Assets/info.svg" alt="" />
                </Col>
                <Col className="pr-0 text-justify">{Strings.Library_Products_Note[language]}</Col>
              </Row>
              <Row className="m-0">
                {
                  products.map(product => (
                    <LibraryProduct
                      {...this.props}
                      key={product.id}
                      product={product}
                      selectedProducts={selectedProducts}
                      updateSelectedProducts={this.updateSelectedProducts}
                    />
                  ))
                }
              </Row>
            </React.Fragment>
          )
        }
        <Row className={loading ? 'justify-content-center py-4' : 'd-none'}>
          <Col xs="auto" className="spinner-border text-primary p-0" role="status">
            <span className="sr-only">Loading...</span>
          </Col>
        </Row>
        <Row className={loading ? 'd-none' : ''}>
          {
            setup ? (
              <div id="add-product-button" className="text-center">
                <Button
                  variant="success"
                  className="left-rounded-pill shadow px-6 py-2"
                  onClick={() => { history.push('/setup?stage=product-add'); }}
                >
                  <img src="/Assets/add-product.svg" alt="" />
                  <b>
                    &nbsp;
                    {Strings.Add_a_Product[language]}
                  </b>
                </Button>
                <Button
                  variant="primary"
                  className="right-rounded-pill shadow px-6 py-2"
                  onClick={
                    getIsApp() === 'true'
                      ? this.toggleUploadMessage
                      : () => { history.push('/setup?stage=sku'); }
                  }
                >
                  <img src="/Assets/upload.svg" alt="" />
                  <b>
                    &nbsp;
                    {Strings.Bulk_Upload[language]}
                  </b>
                </Button>
              </div>
            ) : (
              <div id="add-product-button" className="text-center">
                <Link to="/products/add">
                  <Button
                    variant="success"
                    className="left-rounded-pill shadow px-6 py-2"
                  >
                    <img src="/Assets/add-product.svg" alt="" />
                    <b>
                      &nbsp;
                      {Strings.Add_a_Product[language]}
                    </b>
                  </Button>
                </Link>
                {
                  getIsApp() === 'true' ? (
                    <Button
                      variant="primary"
                      className="right-rounded-pill shadow px-6 py-2"
                      onClick={this.toggleUploadMessage}
                    >
                      <img src="/Assets/upload.svg" alt="" />
                      <b>
                        &nbsp;
                        {Strings.Bulk_Upload[language]}
                      </b>
                    </Button>
                  ) : (
                    <Link to="/products/bulk-upload">
                      <Button
                        variant="primary"
                        className="right-rounded-pill shadow px-6 py-2"
                      >
                        <img src="/Assets/upload.svg" alt="" />
                        <b>
                          &nbsp;
                          {Strings.Bulk_Upload[language]}
                        </b>
                      </Button>
                    </Link>
                  )
                }
              </div>
            )
          }
        </Row>
        <Row
          className="fixed-bottom"
        >
          <Col
            className="px-4"
          >
            <Button
              block
              className="rounded-0 py-3 fs-3"
              onClick={this.handleSubmit}
              disabled={savingProducts || selectedProducts.length === 0}
            >
              {Strings.Save[language]}
            </Button>
          </Col>
        </Row>
        <Row id="bottom-nav" className="m-0">
          <Col
            xs={{ span: 20, offset: 2 }}
            sm={{ span: 16, offset: 4 }}
            className="bg-white pb-4"
          >
            <Button
              block
              className="p-0 pb-1 rounded-pill sticky-top"
              onClick={toggleNav}
            />
            <Link
              to={setup ? '/setup?stage=product-library' : '/products/library'}
              replace
              className="w-100"
            >
              <Button
                variant="link"
                className={`mt-2 text-left text-${selectedCategory === 'all'
                  ? 'primary' : 'muted'}`}
                block
                onClick={() => {
                  toggleNav();
                  this.changeCategory('all', 'all');
                }}
              >
                {Strings.All[language]}
              </Button>
            </Link>
            <Accordion activeKey={currentAccordion}>
              {
                categories.map(category => (
                  <React.Fragment key={category.categoryId}>
                    <div className="d-flex">
                      <Link
                        to={
                          setup
                            ? `/setup?stage=product-library&category=${category.categoryId}`
                            : `/products/library?category=${category.categoryId}`}
                        replace
                        className="w-100"
                      >
                        <Button
                          variant="link"
                          className={`mt-2 text-left text-${
                            selectedCategory === category.categoryId ? 'primary' : 'muted'}`}
                          block
                          onClick={() => {
                            toggleNav();
                            this.changeCategory(category.categoryId, 'all');
                          }}
                        >
                          {category.displayName}
                        </Button>
                      </Link>
                      <Accordion.Toggle
                        as={Button}
                        variant="link"
                        className={currentAccordion === category.categoryId
                          ? 'rotate-180' : 'transition-all'}
                        eventKey={category.categoryId}
                        onClick={() => (
                          this.setState({
                            currentAccordion: currentAccordion === category.categoryId
                              ? null : category.categoryId,
                          })
                        )}
                      >
                        <img src="/Assets/arrow-down.svg" alt="" />
                      </Accordion.Toggle>
                    </div>
                    <Accordion.Collapse eventKey={category.categoryId}>
                      <div>
                        {
                          category.children.map(subCategory => (
                            <Link
                              key={subCategory.categoryId}
                              to={
                                setup
                                  ? `/setup?stage=product-library&category=
                                    ${category.categoryId}&subCategory=${subCategory.categoryId}`
                                  : `/products/library?category=${category.categoryId}
                                    &subCategory=${subCategory.categoryId}`
                              }
                              className={`d-block pl-7 mt-1 text-${
                                selectedSubCategory === subCategory.categoryId
                                || (selectedSubCategory === 'all'
                                && selectedCategory === category.categoryId)
                                  ? 'primary' : 'muted'}`}
                              onClick={() => {
                                toggleNav();
                                this.changeCategory(
                                  category.categoryId, subCategory.categoryId,
                                );
                              }}
                            >
                              {subCategory.displayName}
                            </Link>
                          ))
                        }
                      </div>
                    </Accordion.Collapse>
                  </React.Fragment>
                ))
              }
            </Accordion>
          </Col>
        </Row>
        {/* eslint-disable-next-line */}
        <div id="nav-overlay" onClick={toggleNav} />
        {/* </React.Fragment>
          )
        } */}
      </Container>
    );
  }
}

LibraryProductList.propTypes = {
  patchProfile: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  setup: PropTypes.bool,
  appDisplay: PropTypes.bool,
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
};

LibraryProductList.defaultProps = {
  setup: false,
  appDisplay: false,
};

export default LibraryProductList;
