import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';

function List({
  Component, idField, list, ...props
}) {
  return (
    <Row>
      {
        list.map(item => (
          <Component {...props} key={item[idField]} item={item} />
        ))
      }
    </Row>
  );
}

List.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  idField: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default List;
