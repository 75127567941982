import 'rc-time-picker/assets/index.css';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import { Link } from 'react-router-dom';
import Strings from '../../Strings';
import './styles.scss';
import events from '../../FirebaseEvents';
import clevertapEvents from '../../ClevertapEvents';
import {
  logFirebaseEvent, logClevertapEvents, genUUID,
} from '../../Utils';
import { getStoreTypes } from '../../../api/api';
import { CustomModal } from '../../components/common';

const storeIcon = 'https://saggregation.mpaani.com/20191228-031749.svg';

function notValid(name, value) {
  switch (name) {
    case 'radius': {
      if (value.match('^[0-9]{0,3}([.][0-9]{0,1})?$') === null) {
        return true;
      }
      return false;
    }
    case 'amount': {
      if (value.match('^[0-9]{0,3}$') === null) {
        return true;
      }
      return false;
    }
    case 'minimumShoppingAmount': {
      if (value.match('^[0-9]{0,4}$') === null) {
        return true;
      }
      return false;
    }
    case 'minDeliveryPenalty': {
      if (value.match('^[0-9]{0,3}$') === null) {
        return true;
      }
      return false;
    }
    default: return false;
  }
}

class StoreDetails extends React.Component {
  constructor(props) {
    super(props);
    const { language, storeProfile } = props;
    const { paymentMode } = storeProfile;
    for (
      let paymentModeIndex = 0;
      paymentModeIndex < paymentMode.length;
      paymentModeIndex += 1
    ) {
      paymentMode[paymentModeIndex].isEnabled = true;
    }
    this.state = {
      storeTypes: [{
        description: Strings.Select_Store_Category[language],
        id: -1,
      }],
      isDelivering24hrs: false,
      storeTypeId: -1,
      deliveryTimeFrom: moment().hour(10).minute(0).second(0),
      deliveryTimeTo: moment().hour(21).minute(0).second(0),
      // deliveryRadius: '',
      minimumShoppingAmount: '',
      paymentMode: storeProfile.paymentMode,
      errors: {},
      center: {
        lat: storeProfile.latitude,
        lng: storeProfile.longitude,
      },
      deliveryRadii:
        storeProfile.deliveryRadii.length === 0
          ? [{
            radius: 2,
            amount: 0,
          }]
          : storeProfile.deliveryRadii.map(radii => (
            {
              ...radii,
              radius: radii.radius / 1000,
            }
          )),
      minDeliveryPenalty: '',
      nonServiceableAreasPolygons: storeProfile.nonServiceableAreasPolygons,
      playVideo: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validated = this.validated.bind(this);
    this.handleChangePaymentMode = this.handleChangePaymentMode.bind(this);
    this.handle24hourDelivery = this.handle24hourDelivery.bind(this);
    this.handleExtendRadii = this.handleExtendRadii.bind(this);
    this.handleDeleteRadius = this.handleDeleteRadius.bind(this);
    this.handleChangeRadii = this.handleChangeRadii.bind(this);
  }

  componentDidMount() {
    const { storeTypes } = this.state;
    const { location } = this.props;
    getStoreTypes().then((res) => {
      this.setState({ storeTypes: storeTypes.concat(res.data.results) }, this.initMap);
    });
    if (location.updatedProfile) {
      this.setState({
        ...location.updatedProfile,
      });
    }
  }

  initMap() {
    const {
      deliveryRadii, nonServiceableAreasPolygons, center,
    } = this.state;

    function getZoomLevel(radius) {
      const zoomLevel = Math.round(14 - Math.log(radius) / Math.LN2);
      if (zoomLevel > 18) {
        return 18;
      }
      return zoomLevel;
    }
    if (center.lat !== 0 && center.lng !== 0) {
      const mapDiv = document.getElementById('setup-view-map');
      const mapOption = {
        center,
        zoom: getZoomLevel(deliveryRadii.slice(-1)[0].radius),
        disableDefaultUI: true,
      };
      const map = new window.google.maps.Map(mapDiv, mapOption);

      const circleOption = {
        strokeColor: '#2b2ba0',
        strokeOpacity: 0.5,
        strokeWeight: 2,
        fillColor: '#2b2ba0',
        fillOpacity: 0.2,
        map,
      };

      const polygonOption = {
        strokeColor: '#FD003A',
        strokeOpacity: 0.7,
        strokeWeight: 2,
        fillColor: '#FD003A',
        fillOpacity: 0.3,
        map,
      };

      deliveryRadii.map(radii => new window.google.maps.Circle({
        ...circleOption,
        center,
        radius: radii.radius * 1000,
      }));

      if (nonServiceableAreasPolygons.length !== 0) {
        nonServiceableAreasPolygons.map(coordinate => (
          new window.google.maps.Polygon({
            ...polygonOption,
            path: coordinate,
          })
        ));
      }
      // eslint-disable-next-line no-unused-vars
      const storeMarker = new window.google.maps.Marker({
        position: center,
        map,
        icon: storeIcon,
      });
    }
  }

  handleChange(event) {
    const { name, value } = event.target;
    if (notValid(name, value)) {
      return;
    }
    const { errors } = this.state;
    errors[name] = null;
    this.setState({ [name]: value, errors });
  }

  handleChangeRadii(index, event) {
    const { name, value } = event.target;
    if (notValid(name, value)) {
      return;
    }
    const { deliveryRadii } = this.state;
    const newDeliveryRedii = deliveryRadii
      .map((delivery, newIndex) => (
        (index !== newIndex)
          ? delivery
          : { ...delivery, [name]: value }
      ));
    this.setState(
      {
        deliveryRadii: newDeliveryRedii,
      },
      this.initMap,
    );
  }

  handleExtendRadii(radii) {
    const { deliveryRadii: newDelivery } = this.state;
    newDelivery.push(radii);
    this.setState(
      {
        deliveryRadii: newDelivery,
      },
      this.initMap,
    );
  }

  handleDeleteRadius(index) {
    const { deliveryRadii: newDelivery } = this.state;
    newDelivery.splice(index, 1);
    this.setState({
      deliveryRadii: newDelivery,
    }, this.initMap);
  }

  handleTimeChange(name, time) {
    this.setState({ [name]: time });
  }

  handleChangePaymentMode(code) {
    const { paymentMode, errors } = this.state;
    const selectedMode = paymentMode.find(mode => mode.code === code);
    selectedMode.isEnabled = !selectedMode.isEnabled;
    errors.paymentMode = null;
    this.setState({ paymentMode, errors });
  }

  handle24hourDelivery() {
    const { isDelivering24hrs } = this.state;
    this.setState({ isDelivering24hrs: !isDelivering24hrs });
  }

  validated() {
    const {
      deliveryRadii, storeTypeId, paymentMode,
    } = this.state;
    const selectedPaymentModes = paymentMode.filter(mode => mode.isEnabled === true);
    const errors = {};
    if (storeTypeId === -1) {
      errors.storeTypeId = Strings.Store_Type_empty_error;
    }
    deliveryRadii.forEach((radii) => {
      if (radii.radius === '') {
        errors.radius = Strings.Delivery_Radius_empty_error;
      } else if (radii.radius.toString().match('^[0-9]{1,3}([.][0-9]{1})?$') === null) {
        errors.radius = Strings.Delivery_Radius_format_error;
      } else if (parseFloat(radii.radius) === 0) {
        errors.radius = Strings.Delivery_Radius_empty_error;
      }
      if (radii.amount === '') {
        errors.amount = Strings.Delivery_Amount_empty_error;
      }
    });
    if (selectedPaymentModes.length === 0) {
      errors.paymentMode = Strings.Accepted_Payment_Methods_empty_error;
    }
    if (Object.entries(errors).length === 0) {
      return true;
    }
    this.setState({ errors });
    return false;
  }

  handleSubmit() {
    const {
      deliveryTimeFrom, deliveryTimeTo, deliveryRadii, minDeliveryPenalty,
      storeTypeId, minimumShoppingAmount, paymentMode, isDelivering24hrs,
      nonServiceableAreasPolygons,
    } = this.state;
    const cod = paymentMode.find(mode => mode.code === 'CASH');
    const digital = paymentMode.find(mode => mode.code === 'DIGITAL');
    const clevertapEventData = {
      Category: storeTypeId,
      'Delivery Hours Set': isDelivering24hrs ? '24 Hours Delivery'
        : `${deliveryTimeFrom.format('HH:mm:ss')} to ${deliveryTimeTo.format('HH:mm:ss')}`,
      Radius: parseFloat(deliveryRadii, 10) * 1000,
      'Min. Shopping Amount': minimumShoppingAmount,
      COD: cod.isEnabled ? 'YES' : 'NO',
      'Digital Payments': digital.isEnabled ? 'YES' : 'NO',
    };
    if (!this.validated()) {
      return;
    }
    const { patchProfile, history } = this.props;
    const newDeliveryRadii = deliveryRadii.map(radii => (
      {
        ...radii,
        radius: parseFloat(radii.radius) * 1000,
        amount: parseInt(radii.amount, 10),
      }
    ));
    let patchProfilePayload = {
      deliveryRadii: newDeliveryRadii,
      minDeliveryPenalty: (parseInt(minimumShoppingAmount, 10) === 0 || !minDeliveryPenalty)
        ? 0 : parseInt(minDeliveryPenalty, 10),
      homeDeliveryMinAmount: !minimumShoppingAmount ? 0 : parseInt(minimumShoppingAmount, 10),
      storeTypeId,
      paymentMode,
      setupStage: 'account-details',
      isDelivering24hrs,
      nonServiceableAreasPolygons,
    };
    if (!isDelivering24hrs) {
      patchProfilePayload = {
        ...patchProfilePayload,
        deliveryTiming: {
          startTime: deliveryTimeFrom.format('HH:mm:ss'),
          endTime: deliveryTimeTo.format('HH:mm:ss'),
        },
      };
    }
    patchProfile(patchProfilePayload).then(() => {
      logClevertapEvents(
        clevertapEvents.OO_SetupStart_DetailsAdded_Successful,
        clevertapEventData,
      );
      history.replace('/setup?stage=account-details');
    });
  }

  render() {
    const {
      deliveryTimeFrom, deliveryTimeTo, paymentMode, deliveryRadii,
      errors, storeTypeId, storeTypes, minimumShoppingAmount, isDelivering24hrs, minDeliveryPenalty,
      center, playVideo,
    } = this.state;
    const { language, history } = this.props;
    return (
      <Container fluid>
        <CustomModal
          show={playVideo}
          title={Strings.Help_Video[language]}
          body={(
            <iframe
              title="Help Video"
              className="help-video"
              allow="autoplay; encrypted-media"
              width="100%"
              height="100%"
              frameBorder="0"
              src="https://www.youtube.com/embed/2fbk6MbM5Us?autoplay=1&playlist=2fbk6MbM5Us&loop=1"
            />
          )}
          onHide={() => this.setState({ playVideo: false })}
          closeButton={playVideo}
        />
        <Row>
          <Col>
            <div className="mb-2 mt-3">
              <b>{`1. ${Strings.Set_Store_Type[language]} `}</b>
              <span className="text-danger">*</span>
            </div>
            <select
              name="storeTypeId"
              className={`form-control rounded-0 setup-input${errors.storeTypeId ? ' border-danger' : ''}`}
              value={storeTypeId}
              onChange={this.handleChange}
            >
              {
                storeTypes.map(type => (
                  <option key={type.id} value={type.id}>{type.description}</option>
                ))
              }
            </select>
            <div className="fs-1 text-danger mb-4 pt-1 setup-input">{errors.storeTypeId ? errors.storeTypeId[language] : ''}</div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="mb-2">
              <b>
                {`2. ${Strings.Set_Delivery_Timings[language]} `}
                <span className="text-danger">*</span>
              </b>
              {/* <img src={currentState > 1 ? '/Assets/check-circle.svg'
              : '/Assets/check-circle-light.svg'} alt="" /> */}
            </div>
            <Row
              className="mb-3"
            >
              <Col
                xs="auto"
                className="pr-1 d-flex align-items-center"
                onClick={this.handle24hourDelivery}
              >
                <div
                  className={`border rounded checkbox ${
                    isDelivering24hrs ? 'checked' : ''}`}
                />
                <div
                  className="px-3"
                >
                  {Strings.Delivery_Timing_24_Hrs[language]}
                </div>
              </Col>
            </Row>
            <Row
              className={`mb-4 ${isDelivering24hrs ? 'opacity-50' : ''}`}
            >
              <Col
                xs="auto"
                className="pr-1"
              >
                <div
                  className={`d-flex border border-radius p-2 align-items-center ${
                    language === 'en' ? '' : 'flex-row-reverse text-right'}`}
                >
                  <b
                    className="w-50p text-muted"
                  >
                    {Strings.From[language]}
                  </b>
                  <TimePicker
                    showSecond={false}
                    onChange={time => this.handleTimeChange('deliveryTimeFrom', time)}
                    format="hh:mm a"
                    value={deliveryTimeFrom}
                    use12Hours
                    inputReadOnly
                    allowEmpty={false}
                    disabled={isDelivering24hrs}
                  />
                </div>
              </Col>
              <Col
                xs="auto"
                className="pl-1"
              >
                <div
                  className={`d-flex border border-radius p-2 align-items-center ${
                    language === 'en' ? '' : 'flex-row-reverse text-right'}`}
                >
                  <b
                    className="w-50p text-muted"
                  >
                    {Strings.To[language]}
                  </b>
                  <TimePicker
                    showSecond={false}
                    onChange={time => this.handleTimeChange('deliveryTimeTo', time)}
                    format="hh:mm a"
                    value={deliveryTimeTo}
                    use12Hours
                    inputReadOnly
                    allowEmpty={false}
                    disabled={isDelivering24hrs}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              className="mb-2 d-flex align-items-center"
            >
              <span>
                <b>{`3. ${Strings.Set_Delivery_Radius[language]} `}</b>
                <span className="text-danger">*</span>
              </span>
              <span
                className="pl-3"
              >
                <Button
                  variant="primary-dark"
                  className="px-4 d-flex align-items-center py-1"
                  onClick={() => this.setState({ playVideo: true })}
                >
                  <b
                    className="fs-1"
                  >
                    {Strings.Help_Video[language]}
                  </b>
                  &nbsp;&nbsp;
                  <img
                    src="/Assets/help-video.svg"
                    alt=""
                  />
                </Button>
              </span>
            </div>
            <Row>
              <Col
                xs="auto"
                className="pr-1"
              >
                {
                  deliveryRadii.map((radii, index) => (
                    <div
                      key={genUUID()}
                      className="d-flex align-items-center"
                    >
                      <span>
                        <p
                          className="fs-1 m-0"
                        >
                          {
                            index === 0
                              ? Strings.Free_Delivery_Radius[language]
                              : Strings.Add_Delivery_Fees[language]}
                          &nbsp;
                        </p>
                      </span>
                      <span
                        className={`d-flex py-2 ${index === 0 ? 'pl-2' : 'pl-5'}`}
                      >
                        <span
                          className={`d-flex border-radius p-2 align-items-center ${
                            errors.radius ? ' border-danger' : ''} border`}
                        >
                          <input
                            name="radius"
                            className="border-0 font-weight-bold text-primary w-50p bg-white"
                            type="text"
                            value={radii.radius}
                            onChange={event => this.handleChangeRadii(index, event)}
                          />
                          <b
                            className="text-muted border-left px-2"
                          >
                            KM
                          </b>
                        </span>
                        {
                          index !== 0
                            ? (
                              <span
                                className={`d-flex border border-radius align-items-center ${
                                  errors.amount ? ' border-danger' : ''} ml-2 p-2`}
                              >
                                <input
                                  name="amount"
                                  className="border-0 font-weight-bold text-primary w-50p bg-white"
                                  type="text"
                                  value={radii.amount}
                                  onChange={event => this.handleChangeRadii(index, event)}
                                />
                                <b
                                  className="text-muted border-left px-2"
                                >
                                  {'\u20B9'}
                                </b>
                              </span>
                            ) : ''
                          }
                        {
                          index > 0
                            ? (
                              <span
                                className="pt-2 pl-3"
                              >
                                <Button
                                  className="rounded-button text-white p-0"
                                  variant="secondary"
                                  onClick={() => this.handleDeleteRadius(index)}
                                >
                                  &times;
                                </Button>
                              </span>
                            ) : ''
                        }
                      </span>
                    </div>
                  ))}
              </Col>
            </Row>
            <div
              className="fs-1 d-block text-danger pt-1 setup-input"
            >
              {
                errors.radius
                  ? errors.radius[language] : ''
              }
            </div>
            <div
              className="fs-1 d-block text-danger pt-1 setup-input"
            >
              {
                errors.amount
                  ? errors.amount[language] : ''
              }
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            className="pb-4"
          >
            <Button
              className="p-0 text-success"
              variant="link"
              onClick={() => this.handleExtendRadii(
                {
                  radius: parseInt(deliveryRadii.slice(-1)[0].radius, 10) + 2,
                  amount: parseInt(deliveryRadii.slice(-1)[0].amount, 10) + 10,
                },
              )}
            >
              {Strings.Extend_Radius[language]}
            </Button>
          </Col>
        </Row>
        <Row
          className={
            center.lat === 0 && center.lng === 0
              ? 'bg-light py-2 mb-4 text-secondary' : ''
          }
        >
          <Col>
            <div
              className="mb-2"
            >
              <b>
                {`4. ${Strings.Setting_Exclusions[language]} `}
              </b>
            </div>
            {
              center.lat === 0
              && center.lng === 0
                ? (
                  <Row
                    className="mb-2"
                  >
                    <Col>
                      <i>{Strings.Set_Location_First[language]}</i>
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Row
                      className="mb-2"
                    >
                      <Col
                        xs="auto"
                        id="setup-view-map"
                        className="ml-3 border google-map-div"
                      />
                      <Col
                        xs="auto"
                        className="ml-3 map-div-override"
                      />
                    </Row>
                    <div
                      className="mb-4"
                    >
                      <Link
                        to={{
                          pathname: '/update-exclusion-area',
                          setup: true,
                          updatedProfile: this.state,
                        }}
                      >
                        <Button
                          variant="outline-primary"
                          className="rounded-0 px-6"
                        >
                          {Strings.Updating_Exclusions[language]}
                        </Button>
                      </Link>
                    </div>
                  </>
                )
            }
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              className="mb-2"
            >
              <b>
                {`5. ${Strings.Set_Minimum_Amount[language]} `}
                {/* <span className="text-danger">*&nbsp;&nbsp;</span> */}
              </b>
              {/* <img src={currentState > 3 ? '/Assets/check-circle.svg'
              : '/Assets/check-circle-light.svg'} alt="" /> */}
            </div>
            <Row>
              <Col
                xs="auto"
                className="pr-1"
              >
                <div
                  className="d-flex"
                >
                  <span
                    className="pt-2 pr-3"
                  >
                    <p
                      className="fs-1"
                    >
                      {`${Strings.Min_Order_Value[language]} `}
                    </p>
                  </span>
                  <span
                    className={`d-flex border border-radius p-2 align-items-center ${
                      errors.minimumShoppingAmount ? 'border-danger' : ''}`}
                  >
                    <b
                      className="text-muted border-right px-2"
                    >
                      {'\u20B9'}
                    </b>
                    <input
                      name="minimumShoppingAmount"
                      className="border-0 font-weight-bold text-primary w-100p px-4 bg-white"
                      type="text"
                      value={minimumShoppingAmount}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                  </span>
                </div>
              </Col>
            </Row>
            <div
              className="fs-1 text-danger mb-2 pt-1 setup-input"
            >
              {
                errors.minimumShoppingAmount
                  ? errors.minimumShoppingAmount[language] : ''
              }
            </div>
          </Col>
        </Row>
        <Row
          className={
            parseInt(minimumShoppingAmount, 10) === 0
            || !minimumShoppingAmount
              ? 'd-none' : ''
          }
        >
          <Col>
            <div
              className="mb-2"
            >
              <b>
                {`${Strings.If_Not_Meet[language]} `}
              </b>
            </div>
            <Row>
              <Col
                xs="auto"
                className="pr-1 mb-4"
              >
                <div
                  className="d-flex"
                >
                  <span
                    className="pt-2 pr-3"
                  >
                    <p
                      className="fs-1"
                    >
                      {Strings.Delivery_Charges[language]}
                    </p>
                  </span>
                  <span
                    className="d-flex border border-radius p-2 align-items-center"
                  >
                    <b
                      className="text-muted border-right px-2"
                    >
                      {'\u20B9'}
                    </b>
                    <input
                      name="minDeliveryPenalty"
                      disabled={
                        parseInt(minimumShoppingAmount, 10) === 0
                        || !minimumShoppingAmount
                      }
                      className="text-primary border-0 font-weight-bold w-100p px-4 bg-white"
                      type="text"
                      value={
                        parseInt(minimumShoppingAmount, 10) === 0
                        || !minimumShoppingAmount
                          ? '0' : minDeliveryPenalty
                      }
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              className="mb-2"
            >
              <b>
                {`6. ${Strings.Set_Accepted_Payment_Methods[language]} `}
              </b>
              <span className="text-danger">*</span>
            </div>
            <Row
              className="py-3"
            >
              {
                paymentMode.map(mode => (
                  <Col
                    xs="auto"
                    className="pr-1 d-flex align-items-center"
                    key={mode.code}
                    onClick={() => this.handleChangePaymentMode(mode.code)}
                  >
                    <div
                      className={`border rounded checkbox ${
                        mode.isEnabled ? ' checked' : ''
                      }${
                        errors.paymentMode ? ' border-danger' : ''
                      }`}
                    />
                    <div
                      className="px-3"
                    >
                      {mode.name}
                    </div>
                  </Col>
                ))
              }
            </Row>
            <div
              className="fs-1 d-block text-danger mb-4 pt-1 setup-input"
            >
              {
                errors.paymentMode
                  ? errors.paymentMode[language] : ''
              }
            </div>
          </Col>
        </Row>
        <Row
          className="mb-4"
        >
          <Col
            className="d-flex align-items-center"
          >
            <Button
              className="px-6 rounded-0"
              onClick={() => {
                this.handleSubmit();
                logFirebaseEvent(events.OO_Setup2_Radius, null);
              }}
            >
              {Strings.Next[language]}
            </Button>
            <Button
              variant="light"
              className="ml-4 px-6 rounded-0"
              onClick={() => {
                history.replace('/setup?stage=sku');
              }}
            >
              {Strings.Back[language]}
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

StoreDetails.propTypes = {
  patchProfile: PropTypes.func.isRequired,
  storeProfile: PropTypes.shape({}).isRequired,
  language: PropTypes.string.isRequired,
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default StoreDetails;
