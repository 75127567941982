import React, { Component } from 'react';
import {
  Container, Row, Col, Button, Spinner,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import './styles.scss';
import { Redirect } from 'react-router-dom';
import Strings from '../../Strings';
import { getIsApp } from '../../Utils';

const storeIcon = 'https://saggregation.mpaani.com/20191228-031749.svg';

class UpdateLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      addressText: '',
      error: null,
      latitude: 0,
      longitude: 0,
      processing: false,
      failure: false,
    };
    this.searchPlacesRef = React.createRef();
  }

  componentDidMount = () => {
    const { location } = this.props;
    if (location.updatedProfile) {
      this.setState({
        address: location.updatedProfile.address,
        latitude: location.updatedProfile.center.lat,
        longitude: location.updatedProfile.center.lng,
      }, this.initMap);
    }
  }

  handleChange = (address) => {
    this.setState({ address });
  }

  handleSelect = (address) => {
    this.setState({ address });
    geocodeByAddress(address)
      .then((results) => {
        this.setState({
          addressText: results[0].formatted_address,
        });
        // return getLatLng(results[0]);
        getLatLng(results[0])
          .then((res) => {
            this.setState({
              latitude: res.lat,
              longitude: res.lng,
            }, this.initMap);
          });
      })
      // .then((location) => {
      //   this.setState({ location });
      // })
      .catch(() => {
        this.setState({
          error: 'something went wrong',
        });
      });
  }

  updateLocation = () => {
    const { address, latitude, longitude } = this.state;
    const { patchProfile } = this.props;
    this.setState({ processing: true });
    patchProfile({ address, latitude, longitude })
      .then(() => {
        this.setState({
          processing: false,
        }, () => {
          const { history } = this.props;
          history.push('/settings');
        });
      })
      .catch(() => {
        this.setState({
          failure: true,
          processing: false,
        });
      });
  }

  initMap() {
    const { latitude, longitude } = this.state;
    const mapDiv = document.getElementById('view-map-update');
    const mapOption = {
      center: { lat: latitude, lng: longitude },
      zoom: 12,
      disableDefaultUI: true,
    };
    const map = new window.google.maps.Map(mapDiv, mapOption);

    // eslint-disable-next-line no-unused-vars
    const storeMarker = new window.google.maps.Marker({
      position: { lat: latitude, lng: longitude },
      map,
      icon: storeIcon,
    });
    // }
  }

  render() {
    const {
      address, addressText, error,
      processing, failure,
    } = this.state;
    const { language, handleChangeLanguage, location } = this.props;
    if (!location.updatedProfile) {
      return <Redirect to="/settings" />;
    }
    return (
      <Container fluid className="bg-white">
        <Row className={`bg-light py-2 d-lg-none settings-header${getIsApp() === 'true' ? ' app' : ''}`}>
          <Col className="pr-1">
            <b>{Strings.Change_Location[language]}</b>
          </Col>
          <Col xs="auto" className="text-right pl-1">
            <div className="bg-secondary d-inline-block rounded">
              <Button
                variant={language === 'en' ? 'primary shadow-sm' : 'secondary'}
                className="text-white py-0 px-4 shadow-none"
                onClick={() => handleChangeLanguage('en')}
              >
                ENG
              </Button>
              <Button
                variant={language === 'hi' ? 'primary shadow-sm' : 'secondary'}
                className="text-white py-0 px-4 shadow-none"
                onClick={() => handleChangeLanguage('hi')}
              >
                हिंदी
              </Button>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={24} className="text-center fs-5 pt-3">
            <b>{Strings.Change_Location[language]}</b>
          </Col>

          <Col xs={24} className="py-3">
            <PlacesAutocomplete
              value={address}
              onChange={this.handleChange}
              onSelect={this.handleSelect}
              debounce={500}
              shouldFetchSuggestions={address.length > 3}
            >
              {({
                getInputProps, suggestions, getSuggestionItemProps, loading,
              }) => (
                <div
                  className="position-relative"
                >
                  <div
                    className={`form-control form-control-lg d-flex ${error
                      ? 'border-danger' : ''} align-items-center`}
                  >
                    <span
                      className="d-flex align-items-center"
                    >
                      <img src="/Assets/pin-black.svg" alt="" />
                    </span>
                    <input
                      {...getInputProps({
                        ref: this.searchPlacesRef,
                        placeholder: `${Strings.Location_Search_By[language]}`,
                        className: 'pl-2 location-search-input w-100 fs-4 border-0 shadow-none',
                      })}
                    />
                    <Button
                      variant="link"
                      className={address.length === 0 ? 'd-none' : 'text-secondary px-1 fs-2'}
                      onClick={() => {
                        this.setState({
                          address: '',
                          addressText: '',
                        });
                      }}
                    >
                  &times;
                    </Button>
                  </div>
                  {
                error ? (
                  <div
                    className="fs-6 text-danger pt-1"
                  >
                    {Strings.Something_Went_Wrong[language]}
                  </div>
                ) : ''
              }
                  <div
                    className="autocomplete-dropdown-container position-absolute"
                  >
                    {
                  loading && (
                    <div className="loading">Loading...</div>
                  )
                }
                    {
                  suggestions.map(suggestion => (
                    <div
                      {
                        ...getSuggestionItemProps(
                          suggestion,
                          {
                            className: `suggestion-item ${suggestion.active ? ' active' : ''}`,
                          },
                        )
                      }
                    >
                      <span>
                        <img src="/Assets/pin-black.svg" alt="" />
                        &nbsp;&nbsp;
                        {suggestion.description}
                      </span>
                    </div>
                  ))
                }
                  </div>

                </div>
              )}
            </PlacesAutocomplete>
          </Col>
        </Row>
        <Row className="h-75 mx-0">
          <Col id="view-map-update" xs={24} className="h-100 w-100" />
        </Row>
        <Row className="bg-white">
          <Col xs={24} className="show-selected-addres">
            <div
              className={
                  addressText === ''
                    ? 'd-none' : 'mt-3'
                  }
            >
              <b>{Strings.Shop_Address[language]}</b>
              <div
                className="mb-3"
              >
                {addressText}
              </div>
              {
                    failure && (
                    <div
                      className="text-danger text-center fs-6 mb-2"
                    >
                      {Strings.Something_Went_Wrong[language]}
                    </div>
                    )
                    }
              <div
                className="text-center"
              >
                {
                    processing ? (
                      <Spinner
                        animation="border"
                        variant="primary"
                      />
                    ) : (
                      <Button
                        id="add-address-submit"
                        className="fs-2 px-6 w-100"
                        disabled={processing}
                        onClick={this.updateLocation}
                      >
                        <b>{Strings.Update[language]}</b>
                      </Button>
                    )
                    }
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

UpdateLocation.propTypes = {
  patchProfile: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  language: PropTypes.string.isRequired,
  handleChangeLanguage: PropTypes.func.isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default UpdateLocation;
