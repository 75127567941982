import React from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import QueryString from 'query-string';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { List, ErrorHandler } from '../../components/common';
import RetailerProduct from '../../components/derived/product/RetailerProduct';
import {
  getRetailerProducts, patchRetailerProduct, retailerProductBulkEdit,
} from '../../../api/api';
import Strings from '../../Strings';
import { logClevertapEvents } from '../../Utils';
import clevertapEvents from '../../ClevertapEvents';

const { CancelToken } = Axios;
let loadRequest = null;
let lazyLoadRequest = null;
const limit = 20;

class RetailerProductList extends React.Component {
  constructor(props) {
    super(props);
    const { location, setup } = props;
    const params = QueryString.parse(location.search);
    this.state = {
      products: [],
      offset: 0,
      productCount: 0,
      loading: true,
      searchText: '',
      activeTimeout: -1,
      error: false,
      showMessage: params['products-added'] === 't' || setup,
      editingProduct: -1,
      selectedProducts: [],
      mrp: 0,
      sellingPrice: 0,
      markAs: 'none',
      errors: {},
      bulkEditSuccess: false,
    };
    this.updateProduct = this.updateProduct.bind(this);
    this.handleLazyLoad = this.handleLazyLoad.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.loadProducts = this.loadProducts.bind(this);
    this.appendProducts = this.appendProducts.bind(this);
    this.handleSetupStage = this.handleSetupStage.bind(this);
    this.handleEditingProduct = this.handleEditingProduct.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleInStockStatus = this.handleInStockStatus.bind(this);
    this.handleIsFreshStatus = this.handleIsFreshStatus.bind(this);
    this.updateSelectedProducts = this.updateSelectedProducts.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
  }

  componentWillMount() {
    this.loadProducts(true);
    const { showMessage } = this.state;
    if (showMessage) {
      setTimeout(() => {
        this.setState({ showMessage: false });
      }, 5000);
    }
  }

  handleLazyLoad(event) {
    const {
      loading, productCount, offset,
    } = this.state;
    if (loading) return;
    const { offsetHeight, scrollTop, scrollHeight } = event.target;
    /*
    if (
      getIsApp() === 'true'
      && !this.expandingHeader
      && (
        (androidExpandHeader && scrollTop > 30)
        || (!androidExpandHeader && scrollTop < 30)
      )
    ) {
      this.expandingHeader = setTimeout(
        () => {
          this.setState(
            state => ({
              androidExpandHeader: !state.androidExpandHeader,
            }),
            () => {
              const { androidExpandHeader: expand } = this.state;
              window.expandHeader(expand);
              // clearTimeout(this.expandingHeader);
            },
          );
        },
        200,
      );
    } */
    if (scrollHeight - offsetHeight - scrollTop < 100
      || offsetHeight + scrollTop === scrollHeight) {
      if (offset >= productCount) {
        this.setState({ loading: false });
      } else {
        this.appendProducts();
      }
    }
  }

  updateProduct(updatedProduct) {
    const { products } = this.state;
    const index = products.findIndex(product => product.id === updatedProduct.id);
    const {
      libraryProduct,
      sellingPrice,
      displayName,
    } = products[index];
    products[index] = updatedProduct;
    if (libraryProduct) {
      products[index].libraryProduct = libraryProduct;
    }
    logClevertapEvents(
      clevertapEvents.OO_Inventory_DetailPage_ProductUpdate,
      {
        InStock: updatedProduct.inStock,
        'Selling Price Update': sellingPrice !== updatedProduct.sellingPrice ? 'YES' : 'NO',
        'SKU Name': displayName,
      },
    );
    this.setState({ products });
  }

  handleSearch(value) {
    const { activeTimeout } = this.state;
    if (activeTimeout !== -1) {
      clearTimeout(activeTimeout);
    }
    this.setState({
      searchText: value, products: [], productCount: 0, offset: 0, loading: true,
    }, () => {
      const timeoutId = setTimeout(() => {
        this.loadProducts();
        this.setState({ activeTimeout: -1 });
      }, 350);
      this.setState({ activeTimeout: timeoutId });
    });
  }

  loadProducts(triggerEvent = false) {
    if (loadRequest) {
      loadRequest.cancel();
    }
    if (lazyLoadRequest) {
      lazyLoadRequest.cancel();
    }
    loadRequest = CancelToken.source();
    const { productListType } = this.props;
    const { searchText } = this.state;
    getRetailerProducts(0, limit, productListType, searchText, loadRequest)
      .then((res) => {
        loadRequest = null;
        this.setState({
          products: res.data.results,
          productCount: res.data.count,
          offset: limit,
          loading: false,
          error: false,
        }, () => {
          const { productCount } = this.state;
          if (triggerEvent) {
            logClevertapEvents(
              clevertapEvents.OO_Inventory_DetailPage,
              { 'No of products': productCount },
            );
          }
        });
      }).catch((error) => {
        if (Axios.isCancel(error)) {
          return;
        }
        this.setState({ loading: false, error: true });
      });
  }

  appendProducts() {
    if (lazyLoadRequest) {
      lazyLoadRequest.cancel();
    }
    lazyLoadRequest = CancelToken.source();
    const { productListType } = this.props;
    this.setState({ loading: true });
    const { searchText, offset } = this.state;
    getRetailerProducts(offset, limit, productListType, searchText, lazyLoadRequest)
      .then((res) => {
        lazyLoadRequest = null;
        this.setState(state => ({
          products: state.products.concat(res.data.results),
          productCount: res.data.count,
          offset: state.offset + limit,
          loading: false,
        }));
      }).catch((error) => {
        if (Axios.isCancel(error)) {
          return;
        }
        this.setState({ loading: false });
      });
  }

  handleSetupStage() {
    const { patchProfile, history } = this.props;
    patchProfile({ setupStage: 'store-details' }).then(() => {
      history.push('/setup?stage=store-details');
    });
  }

  handleEditingProduct(product) {
    this.setState(product ? ({
      editingProduct: product.id,
      mrp: product.mrp,
      sellingPrice: product.sellingPrice,
    }) : ({
      editingProduct: -1,
      mrp: 0,
      sellingPrice: 0,
    }));
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { errors } = this.state;
    delete errors[name];
    this.setState({ [name]: value, errors });
  }

  handleUpdate(item) {
    const { sellingPrice, mrp } = this.state;
    patchRetailerProduct(item.id, {
      sellingPrice: parseFloat(sellingPrice),
      mrp: item.mrpApplicable ? parseFloat(mrp) : parseFloat(sellingPrice),
    }).then((res) => {
      this.updateProduct(res.data);
      this.handleEditingProduct();
    });
  }

  handleInStockStatus(event, productId) {
    patchRetailerProduct(productId, { inStock: event.target.checked })
      .then((res) => {
        this.updateProduct(res.data);
      });
  }

  handleIsFreshStatus(event, productId) {
    patchRetailerProduct(productId, { isFresh: event.target.checked })
      .then((res) => {
        this.updateProduct(res.data);
      });
  }

  updateSelectedProducts(id, checked) {
    const { selectedProducts } = this.state;
    if (checked) {
      selectedProducts.push(id);
    } else {
      const index = selectedProducts.findIndex(productId => productId === id);
      selectedProducts.splice(index, 1);
    }
    this.setState({ selectedProducts });
  }

  updateStatus() {
    const {
      products, selectedProducts, markAs, errors,
    } = this.state;
    if (markAs === 'none') {
      errors.markAs = Strings.Action_empty_error;
      this.setState({ errors });
      return;
    }
    retailerProductBulkEdit({
      productIds: selectedProducts,
      action: 'UPDATE_STOCK_STATUS',
      field: markAs === 'inStock',
    }).then((res) => {
      const { results } = res.data;
      const updatedProducts = products.map(product => (
        results.find(result => result.id === product.id) || product
      ));
      this.setState({
        products: updatedProducts,
        markAs: 'none',
        bulkEditSuccess: true,
      });
      setTimeout(() => {
        this.setState({
          bulkEditSuccess: false,
          selectedProducts: [],
        });
      }, 2000);
    });
  }

  render() {
    const {
      products, searchText, loading, error, showMessage, bulkEditSuccess,
      editingProduct, selectedProducts, mrp, sellingPrice, markAs, errors,
    } = this.state;
    const {
      language, offerSearch, setup, productListType,
    } = this.props;
    return (
      <Container
        onScroll={this.handleLazyLoad}
        fluid
        className="pb-58p bg-white list-section"
      >
        <div className="sticky-top bg-white">
          <Row
            className={error || offerSearch || setup
              || productListType === 'outOfStock' ? 'd-none' : ''}
          >
            <Col className="p-0">
              <Button
                variant="link rounded-0 py-3 border-bottom-primary"
                block
              >
                {Strings.Products[language]}
              </Button>
            </Col>
            <Col xs="auto" className="px-0 py-2">
              <div className="border h-100" />
            </Col>
            <Col className="p-0">
              <Link to="/products/offers/" replace>
                <Button
                  variant="link text-muted rounded-0 py-3 border-bottom"
                  block
                >
                  {Strings.Offers[language]}
                </Button>
              </Link>
            </Col>
          </Row>
          <Row className="m-0">
            <Col
              xs={24}
              className={`mt-2 form-control ${error ? 'd-none' : 'd-flex align-items-center'}`}
            >
              <img src="/Assets/search.svg" alt="" />
              <input
                type="text"
                className="w-100 border-0 px-2"
                placeholder={Strings.Search_from_products[language]}
                value={searchText}
                onChange={event => this.handleSearch(event.target.value)}
              />
            </Col>
            <ErrorHandler
              show={error}
              language={language}
              handleRetry={() => {
                this.setState({
                  loading: true,
                  error: false,
                }, this.loadProducts);
              }}
            />
          </Row>
          <Row className={showMessage ? 'm-0 pt-1' : 'd-none'}>
            <Col className="bg-success-20 px-3 py-2 text-justify fs-1">
              <b>{Strings.Products_Upload_Success_Message[language]}</b>
            </Col>
          </Row>
        </div>
        <Row className="m-0">
          {
            products.length === 0 ? (
              <div
                className={`text-center text-muted p-4 w-100${loading || error ? ' d-none' : ''}`}
              >
                <i><b>{Strings.No_Products_Found[language]}</b></i>
              </div>
            ) : (
              <List
                {...this.props}
                Component={RetailerProduct}
                idField="id"
                list={products}
                mrp={mrp}
                sellingPrice={sellingPrice}
                clearText={this.handleSearch}
                editingProduct={editingProduct}
                handleEditingProduct={this.handleEditingProduct}
                selectedProducts={selectedProducts}
                handleChange={this.handleChange}
                handleUpdate={this.handleUpdate}
                handleInStockStatus={this.handleInStockStatus}
                handleIsFreshStatus={this.handleIsFreshStatus}
                updateSelectedProducts={this.updateSelectedProducts}
              />
            )
          }
        </Row>
        <Row className={loading ? 'justify-content-center py-4' : 'd-none'}>
          <Col xs="auto" className="spinner-border text-primary p-0" role="status">
            <span className="sr-only">Loading...</span>
          </Col>
        </Row>
        <Row
          className={error || loading || offerSearch || setup || editingProduct !== -1
            || productListType === 'outOfStock' || selectedProducts.length !== 0 ? 'd-none' : ''}
        >
          <div id="product-button" className="text-center">
            <Link to="/products/library">
              <Button
                variant="success"
                className="rounded-pill shadow px-6 py-2"
                onClick={() => logClevertapEvents(clevertapEvents.OO_Inventory_AddProducts_Clicked)}
              >
                <img src="/Assets/add-product.svg" alt="" />
                <b>
                  &nbsp;
                  {Strings.Add_Products[language]}
                </b>
              </Button>
            </Link>
          </div>
        </Row>
        <Row
          className={setup ? 'm-0 fixed-bottom' : 'd-none'}
        >
          <Col
            xs={24}
            className="px-0"
          >
            <Button
              block
              className="rounded-0 py-3 fs-3"
              onClick={this.handleSetupStage}
            >
              {Strings.Next[language]}
            </Button>
          </Col>
        </Row>
        {
          !setup && selectedProducts.length !== 0 && (
            <Row className="fixed-bottom bg-light py-2 mx-0">
              {
                bulkEditSuccess ? (
                  <Col className="text-success text-center">
                    <b>{Strings.Products_Update_Success_Message[language]}</b>
                  </Col>
                ) : (
                  <>
                    <Col className="pr-1 pl-0">
                      <Row className=" mx-0 align-items-center">
                        <Col
                          xs="auto"
                          className="pr-1 font-weight-bold"
                        >
                          {Strings.Mark_As[language]}
                        </Col>
                        <Col
                          className="pl-1 pr-0"
                        >
                          <select
                            name="markAs"
                            className={`form-control rounded-0 text-center fs-2
                              ${errors.markAs && 'border-danger'}`}
                            value={markAs}
                            onChange={this.handleChange}
                          >
                            <option value="none">
                              {Strings.Select_Action[language]}
                            </option>
                            <option value="inStock">
                              {Strings.In_Stock[language]}
                            </option>
                            <option value="outOfStock">
                              {Strings.Out_of_Stock[language]}
                            </option>
                          </select>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      xs="auto"
                      className="pl-1"
                    >
                      <Button
                        className="rounded-0 py-1 fs-3 px-3 px-md-6"
                        onClick={this.updateStatus}
                        // disabled={savingProducts || selectedProducts.length === 0}
                      >
                        {`${Strings.Save[language]} (${selectedProducts.length})`}
                      </Button>
                    </Col>
                  </>
                )
              }
            </Row>
          )
        }
      </Container>
    );
  }
}

RetailerProductList.propTypes = {
  language: PropTypes.string.isRequired,
  offerSearch: PropTypes.func,
  setup: PropTypes.bool,
  location: PropTypes.shape({}).isRequired,
  patchProfile: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  productListType: PropTypes.string,
};

RetailerProductList.defaultProps = {
  offerSearch: null,
  setup: false,
  productListType: 'all',
};

export default RetailerProductList;
