import React from 'react';
import Axios from 'axios';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import { Link } from 'react-router-dom';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import Strings from '../../Strings';
import { List, ErrorHandler } from '../../components/common';
import { Order } from '../../components/derived/orders';
import { getOrders } from '../../../api/api';

const { CancelToken } = Axios;
let orderLoadRequest = null;
let orderLazyLoadRequest = null;
let orderRefreshRequest = null;
const limit = 20;

function toggleNav() {
  const bottomNav = document.getElementById('bottom-nav');
  const navOverlay = document.getElementById('nav-overlay');
  const open = bottomNav.style.height.trim() === '300px';
  if (open) {
    navOverlay.classList.remove('active-nav');
  } else {
    navOverlay.classList.add('active-nav');
  }
  bottomNav.style.height = open ? '0px' : '300px';
  bottomNav.scrollTop = 0;
}

function getStatusMeta(status, language) {
  switch (status) {
    case 'in_progress': return ({
      displayText: Strings.In_Progress[language],
      deliveryStatus: 'pending,modified,changes_rejected,changes_accepted,ready_to_ship,out_for_delivery',
    });
    case 'previous': return ({
      displayText: Strings.Previous_Orders[language],
      deliveryStatus: 'delivered,cancelled',
    });
    case 'pending': return ({
      displayText: Strings.Pending_Orders[language],
      deliveryStatus: 'pending',
    });
    case 'modified': return ({
      displayText: Strings.Awaiting_Customer_Approval[language],
      deliveryStatus: 'modified',
    });
    case 'customer_changes': return ({
      displayText: Strings.Customer_Approved_Order[language],
      deliveryStatus: 'changes_rejected,changes_accepted',
    });
    case 'ready_to_ship': return ({
      displayText: Strings.Awaiting_Dispatch[language],
      deliveryStatus: 'ready_to_ship',
    });
    case 'out_for_delivery': return ({
      displayText: Strings.Out_For_Delivery[language],
      deliveryStatus: 'out_for_delivery',
    });
    case 'delivered': return ({
      displayText: Strings.Delivered_Orders[language],
      deliveryStatus: 'delivered',
    });
    case 'cancelled': return ({
      displayText: Strings.Cancelled_Orders[language],
      deliveryStatus: 'cancelled',
    });
    default: return ({
      displayText: Strings.All_Orders[language],
      deliveryStatus: 'all',
    });
  }
}

class OrderList extends React.Component {
  constructor(props) {
    super(props);
    const { location } = props;
    const status = (QueryString.parse(location.search).status || 'all').toLowerCase();
    this.state = {
      orders: [],
      loading: true,
      error: false,
      offset: 0,
      orderCount: 0,
      status,
    };
    this.handleLoad = this.handleLoad.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.appendOrders = this.appendOrders.bind(this);
    this.handleLazyLoad = this.handleLazyLoad.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentWillMount() {
    this.handleLoad();
    this.refreshOrderList = setInterval(() => {
      this.handleRefresh();
    }, 10000);
  }

  componentWillReceiveProps(nextProps) {
    const { location } = nextProps;
    const { status } = this.state;
    const nextStatus = (QueryString.parse(location.search).status || 'all').toLowerCase();
    if (status !== nextStatus) {
      this.setState({
        status: nextStatus,
        orders: [],
        orderCount: 0,
        offset: 0,
        loading: true,
      }, this.handleLoad);
    }
  }

  componentWillUnmount() {
    clearInterval(this.refreshOrderList);
  }

  handleLoad() {
    if (orderLoadRequest) {
      orderLoadRequest.cancel();
    }
    if (orderLazyLoadRequest) {
      orderLazyLoadRequest.cancel();
    }
    if (orderRefreshRequest) {
      orderRefreshRequest.cancel();
    }
    orderLoadRequest = CancelToken.source();
    const { status, searchText } = this.state;
    getOrders(0, limit, getStatusMeta(status).deliveryStatus, searchText, orderLoadRequest)
      .then((res) => {
        orderLoadRequest = null;
        this.setState({
          orders: res.data.results,
          orderCount: res.data.count,
          offset: limit,
          loading: false,
          error: false,
        });
      }).catch((error) => {
        if (Axios.isCancel(error)) {
          return;
        }
        this.setState({
          loading: false,
          error: true,
        });
      });
  }

  handleSearch(event) {
    const { activeTimeout } = this.state;
    if (activeTimeout !== -1) {
      clearTimeout(activeTimeout);
    }
    this.setState({
      searchText: event.target.value,
      orders: [],
      orderCount: 0,
      offset: 0,
      loading: true,
    }, () => {
      const timeoutId = setTimeout(() => {
        this.handleLoad();
      }, 350);
      this.setState({ activeTimeout: timeoutId });
    });
  }

  handleRefresh() {
    if (orderLazyLoadRequest || orderLoadRequest) {
      return;
    }
    const { searchText } = this.state;
    const { location } = this.props;
    const status = (QueryString.parse(location.search).status || 'all').toLowerCase();
    orderRefreshRequest = CancelToken.source();
    getOrders(0, limit, getStatusMeta(status).deliveryStatus, searchText, orderRefreshRequest)
      .then((res) => {
        orderRefreshRequest = null;
        let { orders } = this.state;
        const { count, results } = res.data;
        let currentFirstOrderIndex = -1;
        if (orders.length < limit) {
          orders = [...results];
        } else {
          currentFirstOrderIndex = results
            .findIndex(order => order.orderId === orders[0].orderId);
          if (currentFirstOrderIndex === -1) {
            orders = [...results];
          } else {
            orders.splice(0, results.length - currentFirstOrderIndex, ...results);
          }
        }
        this.setState(state => ({
          orders,
          orderCount: count,
          offset: currentFirstOrderIndex === -1 ? limit : state.offset + currentFirstOrderIndex,
        }));
      });
  }

  appendOrders() {
    if (orderRefreshRequest) {
      orderRefreshRequest.cancel();
    }
    orderLazyLoadRequest = CancelToken.source();
    const { offset, status, searchText } = this.state;
    this.setState({ loading: true });
    getOrders(offset, limit, getStatusMeta(status).deliveryStatus, searchText, orderLazyLoadRequest)
      .then((res) => {
        orderLazyLoadRequest = null;
        this.setState(state => ({
          orders: state.orders.concat(res.data.results),
          orderCount: res.data.count,
          offset: state.offset + limit,
          loading: false,
        }));
      }).catch((error) => {
        if (Axios.isCancel(error)) {
          return;
        }
        this.setState({
          loading: false,
        });
      });
  }

  handleLazyLoad(event) {
    if (document.getElementById('bottom-nav').style.height.trim() === '300px') {
      return;
    }
    const {
      loading, orderCount, offset,
    } = this.state;
    if (loading) return;
    const { offsetHeight, scrollTop, scrollHeight } = event.target;
    if (scrollHeight - offsetHeight - scrollTop < 100
      || offsetHeight + scrollTop === scrollHeight) {
      if (offset >= orderCount) {
        this.setState({ loading: false });
      } else {
        this.appendOrders();
      }
    }
  }

  render() {
    const { language } = this.props;
    const {
      orders, loading, error, status, searchText,
    } = this.state;
    const statusMeta = getStatusMeta(status, language);
    return (
      <Container onScroll={this.handleLazyLoad} fluid className="bg-light pb-58p list-section">
        <Row>
          <Col xs={24} className="bg-white d-flex align-items-center py-4 px-4 sticky-top mt-0">
            <img src="/Assets/search.svg" alt="" />
            <input
              type="text"
              value={searchText}
              onChange={this.handleSearch}
              className="border-0 bg-transparent w-100 pl-2 fs-1"
              placeholder={Strings.Order_Search_Placeholder[language]}
            />
          </Col>
          <Col xs={24}>
            {
              orders.length === 0
                ? (
                  <div className={`text-center text-muted p-4${loading || error ? ' d-none' : ''}`}>
                    <b>
                      <i>
                        &quot;
                        {Strings.No_Orders_Found[language]}
                        &quot;
                      </i>
                    </b>
                  </div>
                ) : <List {...this.props} Component={Order} idField="orderId" list={orders} />
            }
          </Col>
        </Row>
        <Row className={loading ? 'justify-content-center py-4' : 'd-none'}>
          <Col xs="auto" className="spinner-border text-primary p-0" role="status">
            <span className="sr-only">Loading...</span>
          </Col>
        </Row>
        <ErrorHandler
          show={error}
          language={language}
          handleRetry={() => {
            this.setState({
              loading: true,
              error: false,
            }, this.handleLoad);
          }}
          autoRefresh
        />
        <div id="bottom-nav-toggle" className={loading ? 'd-none' : 'text-center'}>
          <Button
            variant="success"
            className="rounded-pill px-6 py-2 shadow"
            onClick={toggleNav}
          >
            <img src="/Assets/orders.svg" alt="" />
            &nbsp;&nbsp;
            <b>{statusMeta.displayText}</b>
          </Button>
        </div>
        <Row id="bottom-nav" className="m-0">
          <Col
            xs={{ span: 20, offset: 2 }}
            sm={{ span: 16, offset: 4 }}
            className="text-center bg-white pb-2 position-relative"
          >
            <Button block className="p-0 pb-1 rounded-pill sticky-top" onClick={toggleNav} />
            <Link to="/orders" replace>
              <Button
                variant="link"
                className={`${status === 'all'
                  ? 'text-primary' : 'text-muted'} mt-2`}
                block
                onClick={toggleNav}
              >
                {Strings.All_Orders[language]}
              </Button>
            </Link>
            <Link to="/orders?status=in_progress" replace>
              <Button
                variant="link"
                className={`${status === 'in_progress'
                  ? 'text-primary' : 'text-muted'} mt-2`}
                block
                onClick={toggleNav}
              >
                {Strings.In_Progress[language]}
              </Button>
            </Link>
            <Link to="/orders?status=previous" replace>
              <Button
                variant="link"
                className={`${status === 'previous'
                  ? 'text-primary' : 'text-muted'} mt-2`}
                block
                onClick={toggleNav}
              >
                {Strings.Previous_Orders[language]}
              </Button>
            </Link>
            <Link to="/orders?status=pending" replace>
              <Button
                variant="link"
                className={`${status === 'pending'
                  ? 'text-primary' : 'text-muted'} mt-2`}
                block
                onClick={toggleNav}
              >
                {Strings.Pending_Orders[language]}
              </Button>
            </Link>
            <Link to="/orders?status=modified" replace>
              <Button
                variant="link"
                className={`${status === 'modified'
                  ? 'text-primary' : 'text-muted'} mt-2`}
                block
                onClick={toggleNav}
              >
                {Strings.Awaiting_Customer_Approval[language]}
              </Button>
            </Link>
            <Link to="/orders?status=customer_changes" replace>
              <Button
                variant="link"
                className={`${status === 'customer_changes'
                  ? 'text-primary' : 'text-muted'} mt-2`}
                block
                onClick={toggleNav}
              >
                {Strings.Customer_Approved_Order[language]}
              </Button>
            </Link>
            <Link to="/orders?status=ready_to_ship" replace>
              <Button
                variant="link"
                className={`${status === 'ready_to_ship'
                  ? 'text-primary' : 'text-muted'} mt-2`}
                block
                onClick={toggleNav}
              >
                {Strings.Awaiting_Dispatch[language]}
              </Button>
            </Link>
            <Link to="/orders?status=out_for_delivery" replace>
              <Button
                variant="link"
                className={`${status === 'out_for_delivery'
                  ? 'text-primary' : 'text-muted'} mt-2`}
                block
                onClick={toggleNav}
              >
                {Strings.Out_For_Delivery[language]}
              </Button>
            </Link>
            <Link to="/orders?status=delivered" replace>
              <Button
                variant="link"
                className={`${status === 'delivered'
                  ? 'text-primary' : 'text-muted'} mt-2`}
                block
                onClick={toggleNav}
              >
                {Strings.Delivered_Orders[language]}
              </Button>
            </Link>
            <Link to="/orders?status=cancelled" replace>
              <Button
                variant="link"
                className={`${status === 'cancelled'
                  ? 'text-primary' : 'text-muted'} mt-2`}
                block
                onClick={toggleNav}
              >
                {Strings.Cancelled_Orders[language]}
              </Button>
            </Link>
          </Col>
        </Row>
        {/* eslint-disable-next-line */}
        <div id="nav-overlay" onClick={toggleNav} />
      </Container>
    );
  }
}

OrderList.propTypes = {
  location: PropTypes.shape({}).isRequired,
  language: PropTypes.string.isRequired,
};

export default OrderList;
