import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { List, ErrorHandler } from '../../components/common';
import { getReviews } from '../../../api/api';
import Review from '../../components/derived/reviews/Review';
import Strings from '../../Strings';

class Reviews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reviews: [],
      loading: true,
      error: false,
    };
    this.updateReviews = this.updateReviews.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
  }

  componentWillMount() {
    this.handleLoad();
  }

  handleLoad() {
    getReviews().then((res) => {
      this.setState({
        reviews: res.data,
        loading: false,
        error: false,
      });
    }).catch(() => {
      this.setState({ loading: false, error: true });
    });
  }

  updateReviews(reply, reviewId) {
    const { reviews } = this.state;
    const index = reviews.findIndex(review => review.id === reviewId);
    reviews[index].reply = reply;
    this.setState({ reviews });
  }

  render() {
    const { reviews, loading, error } = this.state;
    const { language } = this.props;
    return (
      <Container fluid className="bg-white min-h-100">
        <Row className={loading || error ? 'd-none' : 'm-0'}>
          {
            reviews.length === 0 ? (
              <div className={`text-center text-muted p-4${loading ? ' d-none' : ''}`}>
                <i><b>{Strings.No_Reviews_Found[language]}</b></i>
              </div>
            ) : (
              <List
                {...this.props}
                Component={Review}
                idField="id"
                list={reviews}
                updateReviews={this.updateReviews}
              />
            )
          }
        </Row>
        <Row className={loading ? 'justify-content-center py-4' : 'd-none'}>
          <Col xs="auto" className="spinner-border text-primary p-0" role="status">
            <span className="sr-only">Loading...</span>
          </Col>
        </Row>
        <ErrorHandler
          show={error}
          language={language}
          handleRetry={() => {
            this.setState({
              loading: true,
              error: false,
            }, this.handleLoad);
          }}
        />
      </Container>
    );
  }
}

Reviews.propTypes = {
  language: PropTypes.string.isRequired,
};

export default Reviews;
