import React from 'react';
import PropTypes from 'prop-types';
import BOGOOffer from './BOGOOffer';
import DiscountOffer from './DiscountOffer';

function Offer(props) {
  const { item } = props;
  return (
    item.offerType === 'BOGO_OFFER'
      ? <BOGOOffer {...props} />
      : <DiscountOffer {...props} />
  );
}

Offer.propTypes = {
  item: PropTypes.shape({}).isRequired,
  language: PropTypes.string.isRequired,
};

export default Offer;
