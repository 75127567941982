import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import Strings from '../../../Strings';
import { List } from '../../../components/common';
import Review from '../../../components/derived/dashboard/Review';
import { logClevertapEvents } from '../../../Utils';
import clevertapEvents from '../../../ClevertapEvents';

function Reviews(props) {
  const {
    reviews, reviewsCount, averageStoreRating, history,
    language, loading, retailerReport, guestUser,
  } = props;
  return (
    <Container
      fluid
      className={`bg-white ${retailerReport ? 'shadow-sm' : 'shadow'}`}
    >
      <Row className="align-items-center">
        <Col
          className={`${retailerReport ? '' : 'text-center'} py-3`}
        >
          <b
            className="fs-3 text-black"
          >
            <span
              className="mx-1"
            >
              <img
                src="/Assets/black-smiley-smile.svg"
                alt=""
              />
            </span>
            {Strings.Ratings[language]}
            <span
              className="mx-1"
            >
              &#38;
            </span>
            {Strings.Reviews[language]}
          </b>
        </Col>
        <Col
          xs="auto"
          className={retailerReport ? 'text-right' : 'd-none'}
        >
          {
            guestUser ? (
              <a
                href={
                  `https://${
                    process.env.REACT_APP_ENV === 'release'
                      ? 'retailer-release.mpaani.com'
                      : process.env.REACT_APP_ENV === 'production'
                        ? 'myshop.lovelocal.in'
                        : 'retailer-staging.mpaani.com'
                  }/reviews`
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  variant="link"
                  className="p-0"
                >
                  {Strings.View_All[language]}
                </Button>
              </a>
            ) : (
              <Button
                variant="link"
                className="p-0"
                onClick={() => {
                  history.push('/reviews');
                }}
              >
                {Strings.View_All[language]}
              </Button>
            )
          }
        </Col>
      </Row>
      <Row>
        <Col
          xs={24}
          className="px-0"
        >
          <div className="border" />
        </Col>
      </Row>
      <Row
        className={
          loading || !reviewsCount || guestUser
            ? 'd-none'
            : 'align-items-center justify-content-around py-4'
        }
      >
        <Col
          xs={11}
          className="border-dashed border-radius px-3 py-2 border-secondary"
        >
          <div
            className="fs-1"
          >
            {Strings.Average_Store_Rating[language]}
          </div>
          <div
            className="d-flex"
          >
            <img
              src="/Assets/star-primary.svg"
              alt=""
              className="mr-1 mt-n1 icon"
            />
            <b
              className="text-primary fs-3"
            >
              {averageStoreRating}
            </b>
          </div>
        </Col>
        <Col
          xs={11}
          className="border-dashed border-radius px-3 py-2 border-secondary"
        >
          <div
            className="fs-1"
          >
            {Strings.No_of_Reviews_Rating[language]}
          </div>
          <div>
            <b
              className="text-primary fs-3"
            >
              {reviewsCount}
            </b>
          </div>
        </Col>
      </Row>
      <Row
        className={loading ? 'd-none' : 'mt-4'}
      >
        <Col
          xs={24}
        >
          {
            reviews.length === 0
              ? (
                <div
                  className="text-center text-muted p-4"
                >
                  <i>
                    <b>
                      {Strings.No_Reviews_Found[language]}
                    </b>
                  </i>
                </div>
              ) : (
                <List
                  {...props}
                  Component={Review}
                  idField="id"
                  list={reviews}
                />
              )
          }
        </Col>
        <Col
          xs={24}
          className={`py-2 ${reviews.length === 0 ? 'd-none' : ''}`}
        >
          <Button
            variant="link"
            block
            className="p-0"
            onClick={() => {
              logClevertapEvents(clevertapEvents.OO_Dashboard_Review_ViewAll);
              history.push('/reviews');
            }}
          >
            {Strings.View_All_Reply[language]}
          </Button>
        </Col>
      </Row>
      <Row
        className={
          loading
            ? 'justify-content-center py-8'
            : 'd-none'
        }
      >
        <Col
          xs="auto"
          className="spinner-border text-primary p-0"
          role="status"
        >
          <span
            className="sr-only"
          >
            Loading...
          </span>
        </Col>
      </Row>
    </Container>
  );
}

Reviews.propTypes = {
  reviews: PropTypes.arrayOf(PropTypes.object).isRequired,
  reviewsCount: PropTypes.number.isRequired,
  averageStoreRating: PropTypes.number.isRequired,
  language: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.shape({}).isRequired,
  retailerReport: PropTypes.bool,
  guestUser: PropTypes.bool,
};

Reviews.defaultProps = {
  retailerReport: false,
  guestUser: false,
};

export default Reviews;
