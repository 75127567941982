import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import Strings from '../../../Strings';
import { longDateOptions } from '../../../Utils';

function BOGOOffer(props) {
  const { item, language } = props;
  return (
    <Col xs={24} lg={12} className="px-4 px-md-6 py-2">
      <Row>
        <Col xs={6} sm={5} className="text-center product-image-div">
          <img
            src={
              // eslint-disable-next-line no-nested-ternary
              item.primaryProduct ? item.primaryProduct.image === ''
                ? '/Assets/thumbnail.svg' : item.primaryProduct.image
                : '/Assets/thumbnail.svg'
            }
            alt=""
            className="product-image"
          />
        </Col>
        <Col xs={18} sm={19}>
          <Row>
            <Col xs={24} className="text-danger">
              <b>
                {
                  language === 'en'
                    ? `Buy ${item.primaryProductCount} Get ${item.freeProductCount} Free`
                    : `${item.primaryProductCount} की खरीदी पर पायें ${item.freeProductCount} मुफ्त`
                }
              </b>
            </Col>
            <Col xs={24} className="py-1">
              <b>{item.primaryProduct.displayName}</b>
            </Col>
            <Col xs={24}>
              <b>
                <span className="text-success mr-4">{`\u20B9 ${item.primaryProduct.sellingPrice}`}</span>
                <span className={`text-secondary${item.primaryProduct.mrp === -1 || item.primaryProduct.mrp === item.primaryProduct.sellingPrice ? ' d-none' : ''}`}>
                  <del>{`\u20B9 ${item.primaryProduct.mrp}`}</del>
                  <span className="ml-4 text-danger">
                    {
                      item.primaryProduct.mrp !== -1 ? (
                        ` ${Math.round((100 - (item.primaryProduct.sellingPrice * 100 / item.primaryProduct.mrp)) * 10) / 10}%`
                      ) : ''
                    }
                  </span>
                </span>
              </b>
            </Col>
            <Col xs={24} className={item.primaryProduct.id === item.freeProduct.id ? 'd-none' : ''}>
              <Row className="align-items-center">
                <Col xs={4} className="text-center product-image-div py-1 pr-0">
                  <img
                    src={
                      // eslint-disable-next-line no-nested-ternary
                      item.freeProduct ? item.freeProduct.image === ''
                        ? '/Assets/thumbnail.svg' : item.freeProduct.image
                        : '/Assets/thumbnail.svg'
                    }
                    alt=""
                    className="product-image"
                  />
                </Col>
                <Col xs={18} className="fs-1">
                  {
                    language === 'en'
                      ? `Buy ${item.primaryProductCount} pack and Get ${item.freeProductCount}`
                      : `${item.primaryProductCount} पैक की खरीदी पर पायें ${item.freeProductCount}`
                  }
                  <div><u><b>{item.freeProduct.displayName}</b></u></div>
                </Col>
              </Row>
            </Col>
            <Col xs={24} className="py-1 text-muted fs-1">
              {`${Strings.Valid_Till[language]}: ${new Date(item.expiryDate).toLocaleDateString(`${language}-in`, longDateOptions)}`}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="m-0">
        <Col className="border mt-2" />
      </Row>
    </Col>
  );
}

BOGOOffer.propTypes = {
  item: PropTypes.shape({}).isRequired,
  language: PropTypes.string.isRequired,
};

export default BOGOOffer;
