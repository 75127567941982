import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import CustomCarousel from '../carousel/CustomCarousel';

const isMobile = window.screen.width < 992;

const Banners = ({ banners }) => (
  <Col
    xs={{
      span: 24,
      order: 0,
    }}
    className="my-2"
  >
    <CustomCarousel
      itemsPerCarousel={isMobile ? 1 : 2}
      list={banners}
      idField="uid"
      interval={3000}
      CarouselItem={({ item, itemsPerCarousel, idField }) => (
        <div
          key={item[idField]}
          style={{
            width: `${100 / itemsPerCarousel}%`,
          }}
          className="d-inline-block margin-0 p-2"
        >
          <a
            className="h-100 d-flex align-items-center cursor-pointer"
            href={item.web_deep_link !== '' ? item.web_deep_link : null}
          >
            <img
              className="w-100"
              src={item.banner_image_url}
              alt=""
            />
          </a>
        </div>
      )}
    />
  </Col>
);

Banners.propTypes = {
  banners: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default React.memo(Banners);
