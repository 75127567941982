import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { shortDateOptions } from '../../../Utils';

function Review(props) {
  const { item, language } = props;
  const rating = [0, 1, 2, 3, 4]
    .map(rate => (
      <img
        key={rate}
        src={
          rate < item.rating
            ? '/Assets/star.svg'
            : '/Assets/star-outline.svg'
        }
        alt=""
      />
    ));

  return (
    <Col
      xs={24}
      lg={12}
      className="px-7 py-2"
    >
      <Row>
        <Col
          xs={6}
          sm={4}
          lg={5}
          xl={4}
          className="text-center pr-0"
        >
          <img
            src={
              item.customer_image_url === ''
                ? '/Assets/member.svg'
                : item.customer_image_url
            }
            alt=""
            className="rounded-circle w-90p mw-100"
          />
        </Col>
        <Col
          xs={18}
          sm={20}
          lg={19}
          xl={20}
        >
          <Row>
            <Col
              xs={24}
              sm={12}
              className="pb-1"
            >
              <b>
                {item.customer_name}
              </b>
            </Col>
            <Col
              xs={24}
              sm={12}
              className="pb-1 text-muted text-sm-right"
            >
              {
                new Date(item.updated_at)
                  .toLocaleDateString(`${language}-in`, shortDateOptions)
              }
            </Col>
            <Col
              xs={24}
              className="pb-1"
            >
              {rating}
            </Col>
            <Col
              xs={24}
              className="pb-1"
            >
              {item.content}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col
          className="border my-4"
        />
      </Row>
    </Col>
  );
}

Review.propTypes = {
  item: PropTypes.shape({}).isRequired,
  language: PropTypes.string.isRequired,
};

export default Review;
