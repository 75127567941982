import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'react-bootstrap';
import Strings from '../../../Strings';
import './styles.scss';

function OrderProduct(props) {
  const {
    item, markOutOfStock, unFulfiledProductIds, language,
    updateOrderItemQty, newlyAddedProduct, orderDetails,
  } = props;
  const itemOutOfStock = unFulfiledProductIds ? unFulfiledProductIds.includes(item.id) : false;
  let tag = null;
  if (itemOutOfStock || item.tag === 'MARKED_UNSERVICEABLE') {
    tag = {
      variant: 'danger',
      text: Strings.Out_of_Stock[language],
    };
  } else {
    switch (item.tag) {
      case 'NEWLY_ADDED':
        tag = {
          variant: 'danger-light',
          text: Strings.Suggested_Product[language],
        };
        break;
      case 'QUANTITY_ALTERED':
        tag = {
          variant: 'primary',
          text: Strings.Qty_Updated[language],
        };
        break;
      default: break;
    }
  }
  return (
    <Col xs={24} className={itemOutOfStock || item.tag === 'MARKED_UNSERVICEABLE' ? 'opacity-50' : ''}>
      <div className="py-2">
        <Row className="mx-0">
          <Col xs={4} className="p-1 text-center product-image-div">
            <img src={item.image === '' ? '/Assets/thumbnail.svg' : item.image} alt="" className="product-image" />
          </Col>
          <Col className="py-1 pl-1 pr-4">
            <Row className="m-0">
              {
                tag && (
                  <Col xs="auto" className={`bg-${tag.variant} text-white fs-1`}><b>{tag.text}</b></Col>
                )
              }
            </Row>
            <div className="pb-1"><b>{item.displayName}</b></div>
            <div className="pb-1">
              <b>
                <span className="text-success mr-4">{`\u20B9 ${item.sellingPrice}`}</span>
                <span className={`text-secondary${item.mrp === -1 || item.sellingPrice === item.mrp ? ' d-none' : ''}`}><del>{`\u20B9 ${item.mrp}`}</del></span>
              </b>
            </div>
          </Col>
          <Col xs="auto" className="pl-0 pr-2 pt-2 text-right">
            {
              (orderDetails.deliveryStatus === 'PENDING' || orderDetails.deliveryStatus === 'MODIFIED') && !itemOutOfStock && <Button variant="link" className="p-0" onClick={() => markOutOfStock(item.id)}><img src="/Assets/bin.svg" alt="" /></Button>
            }
          </Col>
          <Col xs="auto" className="px-0 pt-3">
            {
              orderDetails.deliveryStatus === 'PENDING' || orderDetails.deliveryStatus === 'MODIFIED' ? (
                <div className="btn btn-outline-success w-85p bg-transparent btn-block rounded-0 py-0 px-2">
                  <Row className="m-0">
                    <Col xs="auto" className="p-0">
                      <Button
                        variant="link"
                        className="p-0 fs-4 text-success text-decoration-none"
                        onClick={() => (
                          updateOrderItemQty(item.id, item.quantity - 1, newlyAddedProduct))}
                        disabled={item.tag === 'MARKED_UNSERVICEABLE'}
                      >
                        -
                      </Button>
                    </Col>
                    <Col className="p-1 text-center text-success">{item.quantity < 10 ? `0${item.quantity}` : item.quantity}</Col>
                    <Col xs="auto" className="p-0">
                      <Button
                        variant="link"
                        className="p-0 fs-4 text-success text-decoration-none"
                        onClick={() => (
                          updateOrderItemQty(item.id, item.quantity + 1, newlyAddedProduct))}
                        disabled={item.tag === 'MARKED_UNSERVICEABLE'}
                      >
                        +
                      </Button>
                    </Col>
                  </Row>
                </div>
              ) : (
                <div>
                  &times;&nbsp;
                  <span className="border border-primary text-primary py-1 px-2 rounded fs-3"><b>{item.quantity}</b></span>
                </div>
              )
            }
          </Col>
        </Row>
      </div>
    </Col>
  );
}

OrderProduct.propTypes = {
  item: PropTypes.shape({}).isRequired,
  markOutOfStock: PropTypes.func,
  unFulfiledProductIds: PropTypes.arrayOf(PropTypes.number),
  language: PropTypes.string.isRequired,
  updateOrderItemQty: PropTypes.func,
  newlyAddedProduct: PropTypes.bool,
  orderDetails: PropTypes.shape({}).isRequired,
};

OrderProduct.defaultProps = {
  markOutOfStock: null,
  unFulfiledProductIds: null,
  updateOrderItemQty: null,
  newlyAddedProduct: false,
};

export default OrderProduct;
