import React from 'react';
import Axios from 'axios';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import Strings from '../../Strings';
import {
  Reviews, StoreCard, OrderList, InventoryStats, OnlineStoreReport,
} from '../../layout/Dashboard/index';
import {
  getDashboardStats, getInventoryStats, getReviews,
  syncRetailer, getOrders, getBanners,
} from '../../../api/api';
import './styles.scss';
import { logFirebaseEvent, logClevertapEvents } from '../../Utils';
import events from '../../FirebaseEvents';
import clevertapEvents from '../../ClevertapEvents';
import { ErrorHandler } from '../../components/common';
import Banners from '../../layout/Dashboard/Banners';

const limit = 20;
const { CancelToken } = Axios;
let orderLoadRequest = null;
let orderLazyLoadRequest = null;
let orderRefreshRequest = null;
let dashboardStatsRequest = null;

const getDate = (filter, start) => {
  const date = new Date();
  switch (true) {
    default:
    case filter === 'today':
      return date;
    case filter === 'week' && start:
      date.setDate(date.getDate() - (date.getDay() === 0 ? 6 : date.getDay() - 1));
      return date;
    case filter === 'week' && !start:
      date.setDate(7 + date.getDate() - date.getDay());
      return date;
    case filter === 'month' && start:
      date.setDate(1);
      return date;
    case filter === 'month' && !start:
      date.setMonth(date.getMonth() + 1);
      date.setDate(0);
      return date;
  }
};

const setDate = (filter, start) => {
  const date = getDate(filter, start);
  if (start) {
    date.setHours(0, 0, 0, 0);
  } else {
    date.setHours(23, 59, 59, 999);
  }
  return date;
};

const dateRange = {
  today: {
    start: setDate('today', true),
    end: setDate('today', false),
  },
  week: {
    start: setDate('week', true),
    end: setDate('week', false),
  },
  month: {
    start: setDate('month', true),
    end: setDate('month', false),
  },
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    const { storeProfile } = props;
    this.state = {
      dashboardStats: {
        orderAggregations: [],
        stats: [],
        accountsSettlement: null,
      },
      inventoryStats: {
        productBeingSold: 0,
        offerLive: 0,
        outOfStock: 0,
        lastUpdated: '',
      },
      reviews: [],
      reviewsCount: 0,
      averageStoreRating: 0,
      orders: [],
      orderCount: 0,
      offset: 0,
      searchText: '',
      orderStatusType: 'inProgress',
      loadingOrder: true,
      error: false,
      dateFilter: 'today',
      start: dateRange.today.start,
      end: dateRange.today.end,
      selectingDateFilter: false,
      loadingInventoryStats: true,
      loadingReportStats: true,
      loadedAlready: false,
      activeTimeout: -1,
      isDelivering: storeProfile.isDelivering,
      banners: [],
    };
    this.handleLoad = this.handleLoad.bind(this);
    this.handleChangeSearchText = this.handleChangeSearchText.bind(this);
    this.handleChangeOrderStatusType = this.handleChangeOrderStatusType.bind(this);
    this.toggleDateFilterSelector = this.toggleDateFilterSelector.bind(this);
    this.handleDateFilterChange = this.handleDateFilterChange.bind(this);
    this.loadOrders = this.loadOrders.bind(this);
    this.handleLazyLoad = this.handleLazyLoad.bind(this);
    this.refreshOrders = this.refreshOrders.bind(this);
    this.refreshInventoryStats = this.refreshInventoryStats.bind(this);
    this.refreshDashboardStats = this.refreshDashboardStats.bind(this);
    this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
  }

  componentWillMount() {
    getReviews().then((res) => {
      let sumOfRating = 0;
      let averageRating = 0;
      res.data.forEach((element) => {
        sumOfRating += (element.rating || 0);
      });
      averageRating = Math.round((sumOfRating / res.data.length) * 10) / 10;
      this.setState({
        reviews: res.data.slice(0, 6),
        reviewsCount: res.data.length,
        averageStoreRating: averageRating < 1 && averageRating > 0 ? 1 : averageRating,
      });
    });
    getBanners().then((res) => {
      this.setState({
        banners: res.data.results,
      });
    });
    this.handleLoad();
    this.refreshDashboard = setInterval(() => {
      const { loadedAlready } = this.state;
      if (!loadedAlready) {
        return;
      }
      this.refreshOrders();
      this.refreshInventoryStats();
      this.refreshDashboardStats(true);
    }, 10000);
  }

  componentWillReceiveProps(nextProps) {
    const { storeProfile } = nextProps;
    const { isDelivering } = this.state;
    if (storeProfile && storeProfile.isDelivering !== isDelivering) {
      this.setState({ isDelivering: storeProfile.isDelivering });
    }
  }

  componentWillUnmount() {
    clearInterval(this.refreshDashboard);
  }

  handleLoad() {
    const {
      searchText, orderStatusType, start, end,
    } = this.state;
    syncRetailer();
    const status = orderStatusType === 'inProgress'
      ? 'PENDING,READY_TO_SHIP,OUT_FOR_DELIVERY,CHANGES_ACCEPTED,CHANGES_REJECTED,MODIFIED' : 'DELIVERED,CANCELLED';
    Axios.all([
      getDashboardStats(start.getTime(), end.getTime()),
      getOrders(0, limit, status, searchText),
      getInventoryStats(),
    ]).then(Axios.spread((statsRes, orderRes, inventoryRes) => {
      this.setState({
        dashboardStats: statsRes.data,
        inventoryStats: inventoryRes.data,
        orders: orderRes.data.results,
        orderCount: orderRes.data.count,
        offset: limit,
        loadingOrder: false,
        loadingInventoryStats: false,
        loadingReportStats: false,
        error: false,
        loadedAlready: true,
      });
    })).catch(() => {
      this.setState({ error: true });
      setTimeout(() => {
        this.handleLoad();
      }, 10000);
    });
  }

  handleChangeSearchText(event) {
    const { activeTimeout } = this.state;
    if (activeTimeout !== -1) {
      clearTimeout(activeTimeout);
    }
    this.setState({
      searchText: event.target.value,
      orders: [],
      orderCount: 0,
      offset: 0,
      loadingOrder: true,
    }, () => {
      const timeoutId = setTimeout(() => {
        this.loadOrders(true);
        this.setState({ activeTimeout: -1 });
      }, 350);
      this.setState({ activeTimeout: timeoutId });
    });
  }

  handleChangeOrderStatusType(status) {
    const { orderStatusType } = this.state;
    if (status === 'previous') {
      logClevertapEvents(clevertapEvents.OO_Dashboard_PreviousOrders);
    }
    if (status === 'inProgress') {
      logClevertapEvents(clevertapEvents.OO_Dashboard_InProgress);
    }
    if (orderStatusType !== status) {
      this.setState({
        orderStatusType: status,
        orders: [],
        orderCount: 0,
        offset: 0,
        loadingOrder: true,
        searchText: '',
      }, this.loadOrders);
    }
  }

  handleLazyLoad(event) {
    const {
      loadingOrder, orderCount, offset, orderStatusType, searchText,
    } = this.state;
    if (loadingOrder) return;
    const { offsetHeight, scrollTop, scrollHeight } = event.target;
    if (scrollHeight - offsetHeight - scrollTop < 100
      || offsetHeight + scrollTop === scrollHeight) {
      if (offset >= orderCount) {
        this.setState({ loadingOrder: false });
      } else {
        this.setState({ loadingOrder: true });
        if (orderRefreshRequest) {
          orderRefreshRequest.cancel();
        }
        orderLazyLoadRequest = CancelToken.source();
        const status = orderStatusType === 'inProgress'
          ? 'PENDING,READY_TO_SHIP,OUT_FOR_DELIVERY,CHANGES_ACCEPTED,CHANGES_REJECTED,MODIFIED' : 'DELIVERED,CANCELLED';
        getOrders(offset, limit, status, searchText, orderLazyLoadRequest).then((res) => {
          orderLazyLoadRequest = null;
          const { count, results } = res.data;
          this.setState(state => ({
            orders: state.orders.concat(results),
            orderCount: count,
            offset: state.offset + limit,
            loadingOrder: false,
          }));
        }).catch((error) => {
          if (Axios.isCancel(error)) {
            return;
          }
          this.setState({ loadingOrder: false });
        });
      }
    }
  }

  loadOrders(isSearchRequest = false) {
    if (orderLoadRequest) {
      orderLoadRequest.cancel();
    }
    if (orderLazyLoadRequest) {
      orderLazyLoadRequest.cancel();
    }
    if (orderRefreshRequest) {
      orderRefreshRequest.cancel();
    }
    orderLoadRequest = CancelToken.source();
    const { orderStatusType, searchText } = this.state;
    const status = orderStatusType === 'inProgress'
      ? 'PENDING,READY_TO_SHIP,OUT_FOR_DELIVERY,CHANGES_ACCEPTED,CHANGES_REJECTED,MODIFIED' : 'DELIVERED,CANCELLED';
    getOrders(0, limit, status, searchText, orderLoadRequest).then((res) => {
      orderLoadRequest = null;
      const { count, results } = res.data;
      this.setState({
        orders: results,
        orderCount: count,
        offset: limit,
        loadingOrder: false,
      });
      if (isSearchRequest) {
        logClevertapEvents(
          clevertapEvents.OO_Dashboard_Search,
          { 'Result Found': count ? 'YES' : 'NO' },
        );
      }
    }).catch((error) => {
      if (Axios.isCancel(error)) {
        return;
      }
      this.setState({ loadingOrder: false });
    });
  }

  refreshOrders() {
    if (orderLazyLoadRequest || orderLoadRequest) {
      return;
    }
    const { orderStatusType, searchText } = this.state;
    const status = orderStatusType === 'inProgress'
      ? 'PENDING,READY_TO_SHIP,OUT_FOR_DELIVERY,CHANGES_ACCEPTED,CHANGES_REJECTED,MODIFIED' : 'DELIVERED,CANCELLED';
    orderRefreshRequest = CancelToken.source();
    getOrders(0, limit, status, searchText, orderRefreshRequest).then((res) => {
      orderRefreshRequest = null;
      let { orders } = this.state;
      const { count, results } = res.data;
      let currentFirstOrderIndex = -1;
      if (orders.length < limit) {
        orders = [...results];
      } else {
        currentFirstOrderIndex = results
          .findIndex(order => order.orderId === orders[0].orderId);
        if (currentFirstOrderIndex === -1) {
          orders = [...results];
        } else {
          orders.splice(0, results.length - currentFirstOrderIndex, ...results);
        }
      }
      this.setState(state => ({
        orders,
        orderCount: count,
        offset: currentFirstOrderIndex === -1 ? limit : state.offset + currentFirstOrderIndex,
      }));
    }).catch((error) => {
      if (Axios.isCancel(error)) {
        return;
      }
      this.setState({ loadingOrder: false });
    });
  }

  refreshInventoryStats() {
    getInventoryStats().then((inventoryRes) => {
      this.setState({
        inventoryStats: inventoryRes.data,
        loadingInventoryStats: false,
      });
    }).catch(() => {
      this.setState({ loadingInventoryStats: false });
    });
  }

  refreshDashboardStats(isRefreshing = false) {
    const { start, end } = this.state;
    if (dashboardStatsRequest) {
      if (isRefreshing) {
        return;
      }
      dashboardStatsRequest.cancel();
    }
    dashboardStatsRequest = CancelToken.source();
    getDashboardStats(
      start.getTime(),
      end.getTime(),
      dashboardStatsRequest,
    ).then((statsRes) => {
      dashboardStatsRequest = null;
      this.setState({
        dashboardStats: statsRes.data,
        loadingReportStats: false,
      });
    }).catch((error) => {
      if (Axios.isCancel(error)) {
        return;
      }
      this.setState(state => ({
        loadingReportStats: false,
        dateFilter: state.dateFilter,
      }));
    });
  }

  handleDateFilterChange(dateFilter) {
    this.setState({
      loadingReportStats: true,
      dateFilter,
      selectingDateFilter: false,
      start: dateRange[dateFilter].start,
      end: dateRange[dateFilter].end,
    }, this.refreshDashboardStats);
  }

  toggleDateFilterSelector() {
    this.setState(state => ({ selectingDateFilter: !state.selectingDateFilter }));
  }

  handleDateRangeChange(isForward = false) {
    const { dateFilter, start, end } = this.state;
    const date = new Date();
    const newStart = new Date(start);
    const newEnd = new Date(end);
    const currentDate = date.getDate();
    const currentMonth = date.getMonth();
    const startDate = newStart.getDate();
    const endDate = newEnd.getDate();
    const month = newStart.getMonth();
    const isDateChanged = () => {
      switch (true) {
        default: return false;
        case dateFilter === 'today' && isForward:
          date.setHours(23, 59, 59, 999);
          if (newEnd.getTime() === date.getTime()) {
            return false;
          }
          newStart.setDate(startDate + 1);
          newEnd.setDate(endDate + 1);
          return true;
        case dateFilter === 'today' && !isForward:
          newStart.setDate(startDate - 1);
          newEnd.setDate(endDate - 1);
          return true;
        case dateFilter === 'week' && isForward:
          newStart.setDate(startDate + 7);
          newEnd.setDate(endDate + 7);
          date.setDate(currentDate + 7);
          date.setHours(23, 59, 59, 999);
          if (newEnd.getTime() > date.getTime()) {
            return false;
          }
          return true;
        case dateFilter === 'week' && !isForward:
          newStart.setDate(startDate - 7);
          newEnd.setDate(endDate - 7);
          return true;
        case dateFilter === 'month' && isForward:
          if (currentMonth === month) {
            return false;
          }
          newStart.setMonth(month + 1);
          newStart.setDate(1);
          newEnd.setMonth(month + 2);
          newEnd.setDate(0);
          return true;
        case dateFilter === 'month' && !isForward:
          newStart.setMonth(month - 1);
          newStart.setDate(1);
          newEnd.setDate(0);
          return true;
      }
    };
    if (isDateChanged()) {
      this.setState({
        start: newStart,
        end: newEnd,
        loadingReportStats: true,
      }, this.refreshDashboardStats);
    }
  }

  render() {
    const {
      dashboardStats, reviews, orders, orderCount, loadingOrder, error, searchText, orderStatusType,
      inventoryStats, reviewsCount, averageStoreRating, selectingDateFilter, dateFilter,
      loadingInventoryStats, loadingReportStats, isDelivering, start, end, banners,
    } = this.state;
    const { storeProfile, language, handleChangeDeliveryStatus } = this.props;
    return (
      <React.Fragment>
        <ErrorHandler
          show={error}
          language={language}
          handleRetry={() => {
            this.setState({
              loadingOrder: true,
              loadingReportStats: true,
              loadingInventoryStats: true,
              error: false,
            }, this.handleLoad);
          }}
          autoRefresh
        />
        <Container fluid className={error ? 'd-none' : 'pb-58p'}>
          <Row>
            {
              banners.length > 0 ? (
                <Banners
                  banners={banners}
                />
              ) : ''
            }
            <Col xs={{ span: 24, order: 1 }} className={`my-2 d-lg-none ${storeProfile ? '' : 'd-none'}`}>
              <StoreCard
                {...this.props}
                isDelivering={isDelivering}
                onChange={handleChangeDeliveryStatus}
              />
            </Col>
            <Col xs={{ span: 24, order: 2 }} lg={{ span: 12, order: 2 }} className="px-4 pr-lg-2 dashboard-fixed-height-section my-3">
              <OrderList
                {...this.props}
                loading={loadingOrder}
                error={error}
                orders={orders}
                orderCount={orderCount}
                orderStatusType={orderStatusType}
                searchText={searchText}
                handleChangeSearchText={this.handleChangeSearchText}
                handleChangeOrderStatusType={this.handleChangeOrderStatusType}
                handleLazyLoad={this.handleLazyLoad}
              />
            </Col>
            <Col xs={{ span: 24, order: 3 }} lg={{ span: 12, order: 3 }} className="px-4 pl-lg-2 dashboard-fixed-height-section my-3">
              <Container fluid>
                <Row>
                  <Col xs={24} className="px-0 mb-2 shadow dashboard-inventory-stats">
                    <InventoryStats
                      {...this.props}
                      inventoryStats={inventoryStats}
                      loading={loadingInventoryStats}
                    />
                  </Col>
                  <Col xs={24} className="px-0 mt-2 shadow dashboard-online-store-report">
                    <OnlineStoreReport
                      {...this.props}
                      loading={loadingReportStats}
                      stats={dashboardStats.stats}
                      accountsSettlement={dashboardStats.accountsSettlement}
                      selectingDateFilter={selectingDateFilter}
                      dateFilter={dateFilter}
                      dateRange={dateRange}
                      toggleDateFilterSelector={this.toggleDateFilterSelector}
                      handleDateFilterChange={this.handleDateFilterChange}
                      start={start}
                      end={end}
                      handleDateRangeChange={this.handleDateRangeChange}
                    />
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col xs={{ span: 24, order: 4 }} lg={{ span: 24, order: 4 }} className="my-3 px-4">
              <Reviews
                {...this.props}
                reviews={reviews}
                reviewsCount={reviewsCount}
                averageStoreRating={averageStoreRating}
                loading={loadingInventoryStats}
              />
            </Col>
          </Row>
          <Row className={loadingInventoryStats ? 'd-none' : ''}>
            <div id="product-button" className="text-center">
              <Link to="/products">
                <Button
                  variant="success"
                  className="rounded-pill shadow px-6 py-2 fs-3"
                  onClick={() => {
                    logFirebaseEvent(events.OO_dash_ProductList, null);
                    logClevertapEvents(clevertapEvents.OO_Dashboard_InventoryClicked);
                  }}
                >
                  <b>{`${Strings.Your_Products[language]} (${inventoryStats.productBeingSold})`}</b>
                </Button>
              </Link>
            </div>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

Dashboard.propTypes = {
  storeProfile: PropTypes.shape({}),
  patchProfile: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  handleChangeDeliveryStatus: PropTypes.func.isRequired,
};

Dashboard.defaultProps = {
  storeProfile: null,
};

export default Dashboard;
