export default {
  Order_Online_Menu: 'Order_Online_Menu',
  Click_Online_Ordering: 'Click_Online_Ordering',
  Terms_Conditions_Click: 'Terms_and_Conditions_Click',
  Privacy_Policy_Click: 'Privacy_Policy_Click',
  GetStarted_OnlineDeliverySetup: 'GetStarted_OnlineDeliverySetup',
  OO_Setup2_Time: 'OO_Setup2_Time',
  OO_Setup2_Radius: 'OO_Setup2_Radius',
  OO_Setup2_MinAmt: 'OO_Setup2_MinAmt',
  OO_setup3_bankDetails: 'OO_setup3_bankDetails',
  OO_setup3_upload_Cancelled_Cheque: 'OO_setup3_upload_Cancelled_Cheque',
  OO_setup3_panCard: 'OO_setup3_panCard',
  OO_setup3_GSTnumber: 'OO_setup3_GSTnumber',
  OO_setup4_bulk_upload_click: 'OO_setup4_bulk_upload_click',
  OO_setup4_Pick_List_click: 'OO_setup4_Pick_List_click',
  OO_setup5_SKU_bulkUpload: 'OO_setup5_SKU_bulkUpload',
  OO_setup6_SKU_search: 'OO_setup6_SKU_search',
  OO_setup6_category_click: 'OO_setup6_category_click',
  OO_setup6_SKU_scanBarCode: 'OO_setup6_SKU_scanBarCode',
  OO_setup6_SKU_Pick_List_Upload: 'OO_setup6_SKU_Pick_List_Upload',
  OO_ChangeLanguage: 'OO_ChangeLanguage',
  OO_settings: 'OO_settings',
  OO_settings_change: 'OO_settings_change',
  OO_dash_notification_clicked: 'OO_dash_notification',
  OO_dash_switch_Off: 'OO_dash_switch_Off',
  OO_dash_switch_On: 'OO_dash_switch_On',
  OO_dash_view_Orders: 'OO_dash_view',
  OO_dash_ProductList: 'OO_dash_ProductList',
  OO_dash_ViewOrdersSettled: 'OO_dash_ViewOrdersSettled',
  OO_dash_ViewPendingSettlements: 'OO_dash_ViewPendingSettlements',
  OO_dash_replyReview: 'OO_dash_replyReview',
  OO_dash_viewallReviews: 'OO_dash_viewallReviews',
  OO_dash_ChangeOnlineLoyalty: 'OO_dash_ChangeOnlineLoyalty',
  OO_od_CallCustomer: 'OO_od_CallCustomer',
  OO_od_readOut: 'OO_od_readOut',
  OO_od_UnserviceableProduct: 'OO_od_UnserviceableProduct',
  OO_od_mark_order_preparing: 'OO_od_mark_order_preparing',
  OO_od_mark_order_packed: 'OO_od_mark_order_packed',
  OO_od_sendSMS: 'OO_od_sendSMS',
  OO_od_AttachInvoice: 'OO_od_AttachInvoice',
  OO_od_mark_order_out_for_delivery: 'OO_od_Mark_Order_Out_For_Delivery',
  OO_od_mark_order_delivered: 'OO_od_mark_order_delivered',
  OO_successful_settlements_details: 'OO_successful_settlements_details',
  OO_pending_settlements_details: 'OO_pending_settlements_details',
  OO_return_requests: 'OO_return_requests',
  OO_return_requests_CallCustomer: 'OO_return_requests_CallCustomer',
  OO_return_requests_view_image: 'OO_return_requests_view_image',
  OO_return_requests_hide_image: 'OO_return_requests_hide_image',
};
