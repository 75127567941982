import Highcharts from 'highcharts/highstock';

const screenWidth = window.screen.width;

const getTopLocalityChart = data => ({
  chart: {
    type: 'bar',
    height: '55%',
    style: {
      fontFamily: 'Lato, sans-serif',
    },
    spacing: [0, 0, 0, 0],
  },
  title: {
    text: '',
  },
  xAxis: {
    type: 'category',
    labels: {
      style: {
        fontSize: '1rem',
      },
    },
  },
  yAxis: {
    visible: false,
    endOnTick: false,
    maxPadding: 0.3,
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      color: {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 1,
        },
        stops: [
          [0, '#2b2ba080'],
          [0.3, '#2b2ba0'],
        ],
      },
    },
  },
  series: [{
    name: 'Number of Deliveries',
    data: data.map(item => (
      {
        name: item.key,
        y: item.value,
      }
    )),
    dataLabels: {
      enabled: true,
      crop: false,
      style: {
        fontSize: '0.8rem',
        fontFamily: 'Lato, sans-serif',
      },
    },
  }],
});

const getTransactionReportChart = data => ({
  chart: {
    height: screenWidth < 1400 ? '70%' : '55%',
    style: {
      fontFamily: 'Lato, sans-serif',
    },
    spacing: [0, 0, 0, 0],
    backgroundColor: 'none',
  },
  title: {
    text: '',
  },
  tooltip: {
    pointFormat: 'Value: <b>\u20B9 {point.y}</b><br>Percentage: <b>{point.percentage:.1f}%</b><br>Count: <b>{point.count}</b>',
    backgroundColor: '#ffffff',
  },
  legend: {
    enabled: true,
    labelFormat: '{name}\u2002\u2002<span style="font-weight: normal;">\u20B9 {y}</span>',
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'middle',
    itemStyle: {
      fontSize: screenWidth < 1200 ? '0.8rem' : '1rem',
    },
    itemMarginBottom: 16,
    symbolRadius: 0,
  },
  plotOptions: {
    pie: {
      dataLabels: {
        enabled: false,
      },
      colors: ['#2b2ba0', '#b8238e', '#F9ED32', '#E34C64'],
      startAngle: 0,
      endAngle: 360,
      center: ['50%', '50%'],
      size: '100%',
      showInLegend: true,
    },
  },
  series: [{
    type: 'pie',
    innerSize: '60%',
    data: data.map(item => (
      {
        name: item.key,
        y: item.value,
        count: item.count,
      }
    )),
  }],
});

const getDeliveryTimeDailyChart = data => ({
  chart: {
    type: 'areaspline',
    height: '100',
    style: {
      fontFamily: 'Lato, sans-serif',
    },
    spacing: [0, 0, 0, 0],
  },
  title: {
    text: '',
  },
  xAxis: {
    type: 'category',
    labels: {
      autoRotation: 0,
    },
  },
  yAxis: {
    visible: false,
    endOnTick: false,
    maxPadding: 0.3,
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    areaspline: {
      color: {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 1,
        },
        stops: [
          [0, '#2b2ba0'],
          [0.8, '#2b2ba050'],
        ],
      },
    },
  },
  series: [{
    name: 'Average Delivery Time',
    data: data.map(item => (
      {
        name: item.key,
        y: item.value,
      }
    )),
    dataLabels: {
      enabled: true,
      formatter: function formatter() {
        return this.point.y === 0 ? null : this.point.y;
      },
    },
  }],
});

const getDailyOrdersChart = data => ({
  chart: {
    type: 'column',
    height: '100',
    style: {
      fontFamily: 'Lato, sans-serif',
    },
    spacing: [0, 0, 0, 0],
  },
  title: {
    text: '',
  },
  xAxis: {
    type: 'category',
  },
  yAxis: {
    visible: false,
    endOnTick: false,
    maxPadding: 0.3,
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    column: {
      pointPadding: 0.05,
      groupPadding: 0,
      borderWidth: 0,
      shadow: false,
      color: {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 1,
        },
        stops: [
          [0, '#2b2ba0'],
          [0.6, '#2b2ba080'],
        ],
      },
    },
  },
  series: [{
    data,
    dataLabels: {
      enabled: true,
      formatter: function formatter() {
        return this.point.y === 0 ? null : this.point.y;
      },
    },
  }],
});

const getDailyGmvChart = data => ({
  chart: {
    type: 'column',
    height: '100',
    style: {
      fontFamily: 'Lato, sans-serif',
    },
    spacing: [0, 0, 0, 0],
  },
  title: {
    text: '',
  },
  xAxis: {
    type: 'category',
  },
  yAxis: {
    visible: false,
    endOnTick: false,
    maxPadding: 0.3,
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    column: {
      pointPadding: 0.05,
      groupPadding: 0,
      borderWidth: 0,
      shadow: false,
      color: {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 1,
        },
        stops: [
          [0, '#2b2ba0'],
          [0.6, '#2b2ba080'],
        ],
      },
    },
  },
  series: [{
    data,
    dataLabels: {
      enabled: true,
      formatter: function formatter() {
        return this.point.y === 0 ? null : this.point.y;
      },
    },
  }],
});

const getHourlyOrdersChart = data => ({
  chart: {
    type: 'column',
    height: '100',
    style: {
      fontFamily: 'Lato, sans-serif',
    },
    spacing: [0, 0, 0, 0],
  },
  title: {
    text: '',
  },
  xAxis: {
    type: 'category',
  },
  yAxis: {
    visible: false,
    endOnTick: false,
    maxPadding: 0.3,
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    column: {
      pointPadding: 0.05,
      groupPadding: 0,
      borderWidth: 0,
      shadow: false,
      color: {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 1,
        },
        stops: [
          [0, '#2b2ba0'],
          [0.6, '#2b2ba080'],
        ],
      },
    },
  },
  series: [{
    data,
    dataLabels: {
      enabled: true,
      formatter: function formatter() {
        return this.point.y === 0 ? null : this.point.y;
      },
    },
  }],
});

const getTopProductsChart = data => ({
  chart: {
    type: 'column',
    height: '250',
    style: {
      fontFamily: 'Lato, sans-serif',
    },
    spacing: [0, 0, 0, 0],
  },
  title: {
    text: '',
  },
  xAxis: {
    type: 'category',
    labels: {
      autoRotation: 0,
      format: '<b>{value}</b>',
    },
  },
  yAxis: {
    visible: false,
    endOnTick: false,
    maxPadding: 0.2,
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    column: {
      pointPadding: 0.07,
      groupPadding: 0,
      borderWidth: 0,
      shadow: false,
      color: {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 1,
        },
        stops: [
          [0, '#F9ED32'],
          [0.6, '#F9ED3280'],
        ],
      },
    },
  },
  series: [{
    name: 'Items Sold',
    data: data.map(item => (
      {
        name: item.key,
        y: item.value,
      }
    )),
    dataLabels: {
      enabled: true,
    },
  }],
});


const getTopCategoriesChart = data => ({
  chart: {
    type: 'column',
    height: '250',
    style: {
      fontFamily: 'Lato, sans-serif',
    },
    spacing: [0, 0, 0, 0],
  },
  title: {
    text: '',
  },
  xAxis: {
    type: 'category',
    labels: {
      autoRotation: 0,
      format: '<b>{value}</b>',
    },
  },
  yAxis: {
    visible: false,
    endOnTick: false,
    maxPadding: 0.2,
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    column: {
      pointPadding: 0.07,
      groupPadding: 0,
      borderWidth: 0,
      shadow: false,
      color: {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 1,
        },
        stops: [
          [0, '#2b2ba0'],
          [0.6, '#2b2ba080'],
        ],
      },
    },
  },
  series: [{
    name: 'Items Sold',
    data: data.map(item => (
      {
        name: item.key,
        y: item.value,
      }
    )),
    dataLabels: {
      enabled: true,
    },
  }],
});

const getWeeklyOrdersChart = data => ({
  chart: {
    type: 'areaspline',
    height: '250',
    style: {
      fontFamily: 'Lato, sans-serif',
    },
    spacing: [8, 0, 0, 0],
  },
  title: {
    text: '',
  },
  xAxis: {
    labels: {
      format: 'Week {value}',
    },
    tickAmount: data.length,
    tickInterval: 1,
  },
  yAxis: {
    title: '',
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    areaspline: {
      color: {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 1,
        },
        stops: [
          [0, '#2b2ba0'],
          [0.8, '#2b2ba050'],
        ],
      },
    },
  },
  series: [{
    data,
  }],
});

const getWeeklyGmvChart = data => ({
  chart: {
    type: 'areaspline',
    height: '250',
    style: {
      fontFamily: 'Lato, sans-serif',
    },
    spacing: [8, 0, 0, 0],
  },
  title: {
    text: '',
  },
  xAxis: {
    labels: {
      format: 'Week {value}',
    },
    tickAmount: data.length,
    tickInterval: 1,
  },
  yAxis: {
    title: '',
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    areaspline: {
      color: {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 1,
        },
        stops: [
          [0, '#2b2ba0'],
          [0.8, '#2b2ba050'],
        ],
      },
    },
  },
  series: [{
    data,
  }],
});

const getWeeklyCancelledOrdersChart = data => ({
  chart: {
    type: 'areaspline',
    height: '250',
    style: {
      fontFamily: 'Lato, sans-serif',
    },
    spacing: [8, 0, 0, 0],
  },
  title: {
    text: '',
  },
  xAxis: {
    labels: {
      format: 'Week {value}',
    },
    tickAmount: data.length,
    tickInterval: 1,
  },
  yAxis: {
    title: '',
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    areaspline: {
      color: {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 1,
        },
        stops: [
          [0, '#E34C64'],
          [0.8, '#E34C6450'],
        ],
      },
    },
  },
  series: [{
    data,
  }],
});

function getChart(id, data) {
  switch (id) {
    default: return null;
    case 'top-delivery-localities-chart': return getTopLocalityChart(data);
    case 'transaction-report-chart': return getTransactionReportChart(data);
    case 'delivery-time-per-day-chart': return getDeliveryTimeDailyChart(data);
    case 'daily-orders-chart': return getDailyOrdersChart(data);
    case 'daily-gmv-chart': return getDailyGmvChart(data);
    case 'hourly-orders-chart': return getHourlyOrdersChart(data);
    case 'top-products-chart': return getTopProductsChart(data);
    case 'top-categories-chart': return getTopCategoriesChart(data);
    case 'weekly-orders-chart': return getWeeklyOrdersChart(data);
    case 'weekly-gmv-chart': return getWeeklyGmvChart(data);
    case 'weekly-cancelled-orders-chart': return getWeeklyCancelledOrdersChart(data);
  }
}

export default (id, data) => (
  Highcharts.chart(id, getChart(id, data)));
