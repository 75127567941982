import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Row, Form, Container, Button,
} from 'react-bootstrap';
import Strings from '../../Strings';
import {
  formatDate, getIsApp, getAppClientVersion, getUUID,
  launchImageClicker, launchImagePicker, dataURLtoFile,
} from '../../Utils';
import { getProductCategories, imageUpload, postSpecialOffers } from '../../../api/api';
import { CustomModal } from '../../components/common';

function notValid(name, value) {
  switch (name) {
    case 'unitQty': {
      if (value.match('^[0-9]{0,6}$') === null) {
        return true;
      }
      return false;
    }
    case 'mrp': {
      if (value.toString().match('^[0-9]{0,6}([.][0-9]{0,2})?$') === null) {
        return true;
      }
      return false;
    }
    case 'sellingPrice': {
      if (value.toString().match('^[0-9]{0,6}([.][0-9]{0,2})?$') === null) {
        return true;
      }
      return false;
    }
    case 'spendAmount': {
      if (value.toString().match('^[0-9]{0,6}([.][0-9]{0,2})?$') === null) {
        return true;
      }
      return false;
    }
    default: return false;
  }
}

class OfferAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offerName: '',
      description: '',
      spendAmount: '',
      validTill: new Date(),
      sellingPrice: '',
      unit: 'Units',
      unitQty: '',
      errors: {},
      show: false,
      image: null,
      imageUrl: '',
      categories: [],
      primaryCategory: '',
      secondaryCategory: '',
    };
    this.handleLoad = this.handleLoad.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.reset = this.reset.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
    this.handleAddProduct = this.handleAddProduct.bind(this);
  }

  componentDidMount() {
    this.handleLoad(3);
  }

  getOfferRequestBody() {
    const {
      offerName, description, sellingPrice, validTill, imageUrl,
      primaryCategory, unit, unitQty, spendAmount, secondaryCategory,
    } = this.state;
    const { offerType } = this.props;
    validTill.setHours(23, 59, 59, 999);
    if (offerType === 'bogo') {
      return {
        offerType: 'BOGO_OFFER',
        storeId: getUUID(),
        name: offerName,
        description,
        validUntil: validTill.getTime(),
        sellingPrice,
        uom: unit,
        unit: unitQty,
        category: secondaryCategory === '' ? primaryCategory : secondaryCategory,
        image: imageUrl,
      };
    }
    return {
      offerType: 'SPEND_OFFER',
      storeId: getUUID(),
      name: offerName,
      description,
      validUntil: validTill.getTime(),
      spendAmount,
      image: imageUrl,
    };
  }

  handleSubmit(event) {
    event.preventDefault();
    if (!this.validated()) {
      return;
    }
    const { imageUrl, image } = this.state;
    if (imageUrl === '' && image) {
      const formData = new FormData();
      formData.append('img', image);
      imageUpload(formData).then((res) => {
        this.setState({ imageUrl: res.data.url }, this.handleAddProduct);
      }).catch(() => {
        const { errors } = this.state;
        errors.nonFieldError = Strings.Image_Not_Upload;
        this.setState({ errors });
      });
      return;
    }
    this.handleAddProduct();
  }

  handleComplete() {
    const { history } = this.props;
    history.push('/products/offers');
  }

  reset() {
    this.setState({
      offerName: '',
      description: '',
      spendAmount: '',
      validTill: new Date(),
      sellingPrice: '',
      unit: 'Units',
      unitQty: '',
      errors: {},
      show: false,
      image: null,
      imageUrl: '',
      primaryCategory: '',
      secondaryCategory: '',
    });
  }

  handleChangeDate(event) {
    this.setState({ validTill: new Date(event.target.value) });
  }

  handleChange(event) {
    const { name, value } = event.target;
    if (notValid(name, value)) {
      return;
    }
    const { errors } = this.state;
    errors[name] = null;
    this.setState({
      [name]: value, errors,
    });
  }

  handleLoad(retry) {
    getProductCategories().then((res) => {
      this.setState({
        categories: res.data.results,
      });
    }).catch(() => {
      let retryCount = retry;
      if (retryCount !== 0) {
        retryCount -= 1;
        this.handleLoad(retryCount);
      }
    });
  }

  handleAddProduct() {
    postSpecialOffers(this.getOfferRequestBody())
      .then(() => {
        this.setState({ show: true });
      }).catch(() => {
        const { errors } = this.state;
        errors.nonFieldError = Strings.Something_Went_Wrong;
        this.setState({ errors });
      });
  }

  handleImageChange(image, isNotApp = false) {
    this.setState({
      image: isNotApp
        ? image
        : dataURLtoFile(image.byteString, image.filepath),
    });
  }

  validated() {
    const {
      offerName, sellingPrice, primaryCategory, unitQty,
      spendAmount, description, validTill,
    } = this.state;
    const { offerType } = this.props;
    const errors = {};
    if (offerName === '') {
      errors.offerName = Strings.Offer_Name_empty_error;
    } else if (offerName.trim().length < 3) {
      errors.offerName = Strings.Offer_Name_short_error;
    }
    if (description === '') {
      errors.description = Strings.Description_empty_error;
    } else if (description.trim().length < 3) {
      errors.description = Strings.Description_short_error;
    }
    if (offerType === 'bogo') {
      if (sellingPrice === '' || parseFloat(sellingPrice) === 0) {
        errors.sellingPrice = Strings.Selling_Price_empty_error;
      }
      if (primaryCategory === '') {
        errors.primaryCategory = Strings.Select_Category_empty_error;
      }
      if (unitQty === '') {
        errors.unitQty = Strings.Unit_Quantity_empty_error;
      }
      // if (unit === 'Units') {
      //   errors.unit = Strings.Unit_Parameter_empty_error;
      // }
    }
    if ((spendAmount === '' || parseFloat(sellingPrice) === 0) && offerType === 'spend') {
      errors.spendAmount = Strings.Spend_Amount_empty_error;
    }
    if (isNaN(validTill)) {
      errors.validTill = Strings.Vaild_Till_time_error;
    }
    if (Object.entries(errors).length === 0) {
      return true;
    }
    this.setState({ errors });
    return false;
  }

  render() {
    const {
      offerName, description, spendAmount, image,
      validTill, unitQty, unit, sellingPrice, categories,
      primaryCategory, errors, secondaryCategory, show,
    } = this.state;
    let secondaryCategories = [];
    if (primaryCategory !== '') {
      secondaryCategories = categories.find(category => (
        category.categoryId === primaryCategory)).children;
    }
    const {
      language, offerType, appDisplay, history,
    } = this.props;
    const body = (
      <Row>
        <Col xs={24} className="pb-4">{Strings.Offer_Add_Request_Message[language]}</Col>
        <Col xs={24} className="text-right">
          <Button variant="light mr-1" onClick={this.handleComplete}>{Strings.Done[language]}</Button>
          <Button onClick={this.reset}>{Strings.Add_Another_Offer[language]}</Button>
        </Col>
      </Row>
    );
    const isApp = getIsApp() === 'true';

    return (
      <Container fluid className="bg-white min-h-100">
        <CustomModal
          show={show}
          title={<span className="text-success">{Strings.Offer_Add_Request[language]}</span>}
          body={body}
          reset={this.reset}
          onHide={this.handleComplete}
          closeButton
        />
        <Row
          className="sticky-top bg-white"
        >
          <Col
            xs={24}
            className="py-2 fs-5 mt-2 d-flex align-items-center"
          >
            {
              appDisplay ? (
                <Button
                  variant="link"
                  className="p-0 d-flex align-items-center"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <img
                    src="/Assets/left-arrow.svg"
                    alt=""
                    className="rotate-180"
                    width="18px"
                  />
                  &nbsp;&nbsp;
                </Button>
              ) : ''
            }
            <h5 className="m-0">
              {
                offerType === 'bogo'
                  ? `${Strings.Bogo_Offer[language]}`
                  : `${Strings.Spend_Offer[language]}`
              }
            </h5>
          </Col>
          <Col xs={24}>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col xs={24} className="my-3 bg-light p-4 text-justify">
            <Row className="m-0">
              <Col xs="auto" className="p-0">
                <img src="/Assets/info.svg" alt="" />
              </Col>
              <Col className="pr-0">{offerType === 'bogo' ? `${Strings.Bogo_Offer_Info[language]}` : `${Strings.Spend_Offer_Info[language]}`}</Col>
            </Row>
          </Col>
          <Col xs={24} className="pt-3">
            <Form.Group controlId="formOfferName">
              <Form.Label className="fs-3">
                <b>{Strings.Offer_Name[language]}</b>
                <span className="text-danger">&nbsp;*</span>
              </Form.Label>
              <Form.Control
                autoFocus
                size="lg"
                name="offerName"
                type="text"
                value={offerName}
                onChange={this.handleChange}
                className={`setup-input rounded-0${errors.offerName && ' border-danger'}`}
              />
              <div
                className="fs-1 d-block text-secondary pt-1 setup-input"
              >
                {(!errors.offerName && offerType === 'bogo')
                  ? Strings.Bogo_Offer_Name_Info[language]
                  : (!errors.offerName && offerType === 'spend') && Strings.Spend_Offer_Name_Info[language]
                }
              </div>
              <div
                className="fs-1 d-block text-danger mb-4 pt-1 setup-input"
              >
                {errors.offerName && errors.offerName[language]}
              </div>
            </Form.Group>
          </Col>
          <Col xs={24} className="pt-3">
            <Form.Group controlId="formDescription">
              <Form.Label className="fs-3">
                <b>{Strings.Description[language]}</b>
                <span className="text-danger">&nbsp;*</span>
              </Form.Label>
              <Form.Control
                autoFocus
                size="lg"
                name="description"
                type="text"
                value={description}
                onChange={this.handleChange}
                className={`setup-input rounded-0${errors.description && ' border-danger'}`}
              />
              <div
                className="fs-1 d-block text-secondary pt-1 setup-input"
              >
                {(!errors.description && offerType === 'bogo')
                  ? Strings.Bogo_Description_Info[language]
                  : (!errors.description && offerType === 'spend') && Strings.Spend_Description_Info[language]
                }
              </div>
              <div
                className="fs-1 d-block text-danger mb-4 pt-1 setup-input"
              >
                {errors.description && errors.description[language]}
              </div>
            </Form.Group>
          </Col>
          <Col xs={24} className="pt-3">
            <Form.Group controlId="formSellingAndSpendAmount">
              <Form.Label className="fs-3">
                <b>{offerType === 'bogo' ? Strings.Selling_Price[language] : Strings.Spend_Amount[language]}</b>
                <span className="text-danger">&nbsp;*</span>
              </Form.Label>
              <Form.Control
                autoFocus
                size="lg"
                name={offerType === 'bogo' ? 'sellingPrice' : 'spendAmount'}
                type="text"
                value={offerType === 'bogo' ? sellingPrice : spendAmount}
                onChange={this.handleChange}
                className={`setup-input rounded-0${(errors.sellingPrice || errors.spendAmount) && ' border-danger'}`}
              />
              <div
                className="fs-1 d-block text-secondary pt-1 setup-input"
              >
                {(!errors.sellingPrice && offerType === 'bogo')
                  ? Strings.Bogo_Selling_Price_Info[language]
                  : (!errors.spendAmount && offerType === 'spend') && Strings.Spend_Offer_Spend_Amount_Info[language]
                }
              </div>
              <div
                className="fs-1 d-block text-danger mb-4 pt-1 setup-input"
              >
                {(errors.sellingPrice && offerType === 'bogo') && errors.sellingPrice[language]}
                {(errors.spendAmount && offerType === 'spend') && errors.spendAmount[language]}
              </div>
            </Form.Group>
          </Col>
          <Col xs={24} className={offerType === 'bogo' ? 'py-3' : 'd-none'}>
            <Row>
              <Col xs={12} sm="auto" className="pr-1">
                <Form.Group controlId="formProductUnitQty">
                  <Form.Label className="fs-3">
                    <b>{Strings.Unit_Quantity[language]}</b>
                    <span className="text-danger">&nbsp;*</span>
                  </Form.Label>
                  <Form.Control
                    size="lg"
                    name="unitQty"
                    type="text"
                    pattern="\d*"
                    value={unitQty}
                    onChange={this.handleChange}
                    className={`setup-input-2 rounded-0${errors.unitQty && ' border-danger'}`}
                  />
                  <div className="fs-1 d-block text-secondary pt-1 setup-input-2">{!errors.unitQty && Strings.Bogo_UOM_Info[language]}</div>
                  <div className="fs-1 d-block text-danger mb-4 pt-1 setup-input-2">{errors.unitQty && errors.unitQty[language]}</div>
                </Form.Group>
              </Col>
              <Col xs={12} className="pl-1">
                <Form.Group controlId="formProductUnit">
                  <Form.Label className="fs-3">
                    <b>{Strings.Unit[language]}</b>
                    <span className="text-danger">&nbsp;*</span>
                  </Form.Label>
                  <select
                    name="unit"
                    value={unit}
                    onChange={this.handleChange}
                    className={`form-control form-control-lg setup-input-2 rounded-0${errors.unit && ' border-danger'}`}
                  >
                    <option value="GMs">GM</option>
                    <option value="KGs">KG</option>
                    <option value="ML">ML</option>
                    <option value="L">L</option>
                    <option value="CMS">CM</option>
                    <option value="Inch">INCH</option>
                    <option value="Mtrs">MTR</option>
                    <option value="Units">Unit</option>
                  </select>
                  <div className="fs-1 d-block text-danger mb-4 pt-1 setup-input-2">{errors.unit && errors.unit[language]}</div>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col xs={24} className={offerType === 'bogo' ? 'py-3' : 'd-none'}>
            <Form.Group controlId="formPrimaryCategory">
              <Form.Label className="fs-3">
                <b>{Strings.Primary_Category[language]}</b>
                <span className="text-danger">&nbsp;*</span>
              </Form.Label>
              <select
                name="primaryCategory"
                value={primaryCategory}
                onChange={this.handleChange}
                className={`form-control form-control-lg setup-input rounded-0${errors.primaryCategory && ' border-danger'}`}
              >
                <option value="">
                  {Strings.Select_Category[language]}
                </option>
                {
                      categories.map(category => (
                        <option
                          key={category.categoryId}
                          value={category.categoryId}
                        >
                          {category.displayName}
                        </option>
                      ))
                    }
              </select>
              <div
                className="fs-1 d-block text-danger mb-4 pt-1 setup-input"
              >
                {errors.primaryCategory && errors.primaryCategory[language]}
              </div>
            </Form.Group>
          </Col>
          <Col xs={24} className={offerType === 'bogo' ? 'py-3' : 'd-none'}>
            <Form.Group controlId="formSecondaryCategory">
              <Form.Label className="fs-3">
                <b>{Strings.Secondary_Category[language]}</b>
              </Form.Label>
              <select
                name="secondaryCategory"
                value={secondaryCategory}
                onChange={this.handleChange}
                className="form-control form-control-lg setup-input rounded-0"
                disabled={primaryCategory === ''}
              >
                <option value="">
                  {Strings.Select_Category[language]}
                </option>
                {
                  secondaryCategories.map(category => (
                    <option
                      key={category.categoryId}
                      value={category.categoryId}
                    >
                      {category.displayName}
                    </option>
                  ))
                }
              </select>
            </Form.Group>
          </Col>
          <Col xs={24} className="pt-3">
            <Form.Group controlId="formProductName">
              <Form.Label className="fs-3">
                <b>{Strings.Valid_Till[language]}</b>
                <span className="text-danger">&nbsp;*</span>
              </Form.Label>
              <Form.Control
                autoFocus
                size="lg"
                name="validTill"
                type="date"
                value={formatDate(validTill)}
                onChange={this.handleChangeDate}
                className={`setup-input rounded-0${errors.validTill && ' border-danger'}`}
              />
              <div
                className="fs-1 d-block text-danger mb-4 pt-1 setup-input"
              >
                {errors.validTill && errors.validTill[language]}
              </div>
            </Form.Group>
          </Col>
          <Col xs={24} className={getIsApp() === 'true' && getAppClientVersion() === '-1' ? 'd-none' : 'pt-3'}>
            <Form.Group controlId="formGridProductImage" className="setup-input">
              <Form.Label className="fs-3">
                <b>{Strings.Product_Image[language]}</b>
              </Form.Label>
              <Row className="text-center">
                {
                  isApp ? (
                    <>
                      <Col xs={12} className="pr-1">
                        <Button
                          variant="outline-primary border-dashed border-radius"
                          block
                          onClick={() => launchImageClicker(this.handleImageChange)}
                        >
                          <img src="/Assets/camera.svg" alt="" />
                              &nbsp;
                          {Strings.Camera[language]}
                        </Button>
                      </Col>
                      <Col xs={12} className="pl-1">
                        <Button
                          variant="outline-primary border-dashed border-radius"
                          block
                          onClick={() => launchImagePicker(this.handleImageChange)}
                        >
                          <img src="/Assets/gallery.svg" alt="" />
                              &nbsp;
                          {Strings.Gallery[language]}
                        </Button>
                      </Col>
                    </>
                  ) : (
                    <Col
                      xs={24}
                    >
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={event => this.handleImageChange(event.target.files[0], !isApp)}
                      />
                    </Col>
                  )
                }
                <Col xs={24} className="pt-3">
                  {
                    image && <img src={URL.createObjectURL(image)} alt="" className="w-100" />
                  }
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col xs={24}>
            <div className="fs-1 d-block text-danger mb-4 setup-input">
              {errors.nonFieldError ? errors.nonFieldError[language] : ''}
            </div>
          </Col>
          <Col xs={24} className="py-3">
            <Button
              size="lg"
              variant="success rounded-0 fs-3"
              onClick={this.handleSubmit}
            >
              <b>{Strings.Add_Offer[language]}</b>
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

OfferAdd.propTypes = {
  language: PropTypes.string.isRequired,
  offerType: PropTypes.string.isRequired,
  history: PropTypes.shape({}).isRequired,
  appDisplay: PropTypes.bool,
};

OfferAdd.defaultProps = {
  appDisplay: false,
};

export default OfferAdd;
