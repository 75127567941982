import 'rc-time-picker/assets/index.css';
import React from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import { Link } from 'react-router-dom';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import Strings from '../../Strings';
import './styles.scss';
import {
  getIsApp, logFirebaseEvent, getIsAdmin, logClevertapEvents,
} from '../../Utils';
import events from '../../FirebaseEvents';
import clevertapEvents from '../../ClevertapEvents';
import { CustomModal } from '../../components/common';

const storeIcon = 'https://saggregation.mpaani.com/20191228-031749.svg';

function notValid(name, value) {
  switch (name) {
    case 'radius': {
      if (value.match('^[0-9]{0,3}([.][0-9]{0,1})?$') === null) {
        return true;
      }
      return false;
    }
    case 'amount': {
      if (value.match('^[0-9]{0,3}$') === null) {
        return true;
      }
      return false;
    }
    case 'pincode': {
      if (value.match('^[0-9]{0,6}$') === null) {
        return true;
      }
      return false;
    }
    case 'minimumShoppingAmount': {
      if (value.match('^[0-9]{0,4}$') === null) {
        return true;
      }
      return false;
    }
    case 'minDeliveryPenalty': {
      if (value.match('^[0-9]{0,3}$') === null) {
        return true;
      }
      return false;
    }
    case 'alternatePhoneNumber': {
      if (value.match('^[0-9]{0,10}$') === null) {
        return true;
      }
      return false;
    }
    case 'accountName': {
      if (value.match('^[a-zA-Z0-9 ]{0,40}$') === null) {
        return true;
      }
      return false;
    }
    case 'accountNumber': {
      if (value.match('^[0-9]{0,18}$') === null) {
        return true;
      }
      return false;
    }
    case 'confirmAccountNumber': {
      if (value.match('^[0-9]{0,18}$') === null) {
        return true;
      }
      return false;
    }
    case 'ifscCode': {
      if (value.match('^[0-9a-zA-Z]{0,11}$') === null) {
        return true;
      }
      return false;
    }
    case 'panNumber': {
      if (value.match('^[0-9a-zA-Z]{0,10}$') === null) {
        return true;
      }
      return false;
    }
    case 'gstNumber': {
      if (value.match('^[0-9a-zA-Z]{0,15}$') === null) {
        return true;
      }
      return false;
    }
    default: return false;
  }
}

let isOptional = false;

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      deliveryTimeFrom: moment('10:00:00', 'HH:mm:ss'),
      deliveryTimeTo: moment('21:00:00', 'HH:mm:ss'),
      minimumShoppingAmount: '',
      paymentMode: [],
      alternatePhoneNumber: '',
      accountName: '',
      accountNumber: '',
      confirmAccountNumber: '',
      ifscCode: '',
      panNumber: '',
      gstNumber: '',
      showSuccessMessage: false,
      errors: {},
      showDialog: false,
      isDelivering24hrs: false,
      center: null,
      address: '',
      nonServiceableAreasPolygons: [],
      minDeliveryPenalty: '',
      deliveryRadii: [],
      playVideo: false,
      pincode: '',
      landmark: '',
    };
    this.handleLoad = this.handleLoad.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeRadii = this.handleChangeRadii.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleSubmitStoreDetails = this.handleSubmitStoreDetails.bind(this);
    this.handleSubmitAccountDetails = this.handleSubmitAccountDetails.bind(this);
    this.validatedStoreDetails = this.validatedStoreDetails.bind(this);
    this.validatedAccountDetails = this.validatedAccountDetails.bind(this);
    this.handleChangePaymentMode = this.handleChangePaymentMode.bind(this);
    this.checkAccountDetails = this.checkAccountDetails.bind(this);
    this.handle24hourDelivery = this.handle24hourDelivery.bind(this);
    this.handleExtendRadii = this.handleExtendRadii.bind(this);
    this.handleDeleteRadius = this.handleDeleteRadius.bind(this);
  }

  componentWillMount() {
    logFirebaseEvent(events.OO_settings, null);
    this.handleLoad(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const { paymentMode } = this.state;
    if (paymentMode.length === 0) {
      this.handleLoad(nextProps);
    }
  }

  handleLoad(props) {
    const { storeProfile, location } = props;
    if (storeProfile) {
      const cashPaymentMode = storeProfile.paymentMode.find(mode => mode.code === 'CASH');
      const selectedPaymentModes = storeProfile.paymentMode.filter(mode => mode.isEnabled === true);
      isOptional = cashPaymentMode && cashPaymentMode.isEnabled
        && selectedPaymentModes.length === 1;

      this.setState(
        location.updatedProfile || {
          loading: false,
          deliveryTimeFrom: storeProfile.deliveryTiming.startTime === ''
            ? moment('10:00:00', 'HH:mm:ss')
            : moment(storeProfile.deliveryTiming.startTime, 'HH:mm:ss'),
          deliveryTimeTo: storeProfile.deliveryTiming.endTime === ''
            ? moment('21:00:00', 'HH:mm:ss')
            : moment(storeProfile.deliveryTiming.endTime, 'HH:mm:ss'),
          isDelivering24hrs: storeProfile.isDelivering24hrs,
          // deliveryRadius: storeProfile.radius / 1000,
          minimumShoppingAmount: storeProfile.homeDeliveryMinAmount,
          paymentMode: storeProfile.paymentMode,
          alternatePhoneNumber: storeProfile.alternatePhoneNumber,
          accountName: storeProfile.account.accountName || '',
          accountNumber: storeProfile.account.accountNumber || '',
          confirmAccountNumber: storeProfile.account.accountNumber || '',
          ifscCode: storeProfile.account.ifscCode || '',
          panNumber: storeProfile.account.panNumber || '',
          gstNumber: storeProfile.account.gstNumber || '',
          center: { lat: storeProfile.latitude, lng: storeProfile.longitude },
          address: storeProfile.address,
          pincode: storeProfile.pinCode,
          landmark: storeProfile.landmark,
          minDeliveryPenalty: storeProfile.minDeliveryPenalty,
          deliveryRadii: storeProfile.deliveryRadii.map(radii => (
            {
              ...radii,
              radius: radii.radius / 1000,
            }
          )),
          nonServiceableAreasPolygons: storeProfile.nonServiceableAreasPolygons,
        },
        this.initMap,
      );
    }
  }

  initMap() {
    if (!window.google) {
      return;
    }
    const { deliveryRadii, nonServiceableAreasPolygons, center } = this.state;
    function getZoomLevel(radius) {
      const zoomLevel = Math.round(14 - Math.log(radius) / Math.LN2);
      if (zoomLevel > 18) {
        return 18;
      }
      return zoomLevel;
    }
    if (center.lat !== 0 && center.lng !== 0) {
      const mapDiv = document.getElementById('view-map');
      const mapOption = {
        center: center || { lat: 0, lng: 0 },
        zoom: deliveryRadii.slice(-1)[0]
          ? getZoomLevel(deliveryRadii.slice(-1)[0].radius) : 12,
        disableDefaultUI: true,
      };
      const map = new window.google.maps.Map(mapDiv, mapOption);

      const circleOption = {
        strokeColor: '#2b2ba0',
        strokeOpacity: 0.5,
        strokeWeight: 2,
        fillColor: '#2b2ba0',
        fillOpacity: 0.2,
        map,
      };

      const polygonOption = {
        strokeColor: '#FD003A',
        strokeOpacity: 0.7,
        strokeWeight: 2,
        fillColor: '#FD003A',
        fillOpacity: 0.3,
        map,
      };

      deliveryRadii.map(radii => new window.google.maps.Circle({
        ...circleOption,
        center,
        radius: radii.radius * 1000,
      }));

      if (nonServiceableAreasPolygons.length !== 0) {
        nonServiceableAreasPolygons.map(coordinate => new window.google.maps.Polygon({
          ...polygonOption,
          path: coordinate,
        }));
      }

      // eslint-disable-next-line no-unused-vars
      const storeMarker = new window.google.maps.Marker({
        position: center,
        map,
        icon: storeIcon,
      });
    }
  }

  handleChange(event) {
    const { name, value } = event.target;
    if (notValid(name, value)) {
      return;
    }
    const { errors } = this.state;
    errors[name] = null;
    this.setState({ [name]: value, errors });
  }

  handleChangeRadii(index, event) {
    const { name, value } = event.target;
    if (notValid(name, value)) {
      return;
    }
    const { deliveryRadii, errors } = this.state;
    errors[name] = null;
    const newDeliveryRedii = deliveryRadii.map((delivery, newIndex) => {
      if (index !== newIndex) {
        return delivery;
      }
      return { ...delivery, [name]: value };
    });
    this.setState(
      {
        deliveryRadii: newDeliveryRedii,
        errors,
      }, this.initMap,
    );
  }

  handleExtendRadii(radii) {
    const { deliveryRadii: newDelivery } = this.state;
    newDelivery.push(radii);
    this.setState({
      deliveryRadii: newDelivery,
    }, this.initMap);
  }

  handleDeleteRadius(index) {
    const { deliveryRadii: newDelivery } = this.state;
    newDelivery.splice(index, 1);
    this.setState({
      deliveryRadii: newDelivery,
    }, this.initMap);
  }

  handleTimeChange(name, time) {
    this.setState({ [name]: time });
  }

  handleChangePaymentMode(code) {
    const { paymentMode, errors } = this.state;
    const selectedMode = paymentMode.find(mode => mode.code === code);
    selectedMode.isEnabled = !selectedMode.isEnabled;
    errors.paymentMode = null;
    this.setState({ paymentMode, errors });
  }

  handle24hourDelivery() {
    const { isDelivering24hrs } = this.state;
    this.setState({ isDelivering24hrs: !isDelivering24hrs });
  }

  checkAccountDetails() {
    if (!this.validatedStoreDetails()) {
      return;
    }
    const {
      paymentMode, accountName, accountNumber, ifscCode, panNumber,
    } = this.state;
    const cashPaymentMode = paymentMode.find(mode => mode.code === 'CASH');
    const selectedPaymentModes = paymentMode.filter(mode => mode.isEnabled === true);
    const accountDetailsOptional = (
      cashPaymentMode && cashPaymentMode.isEnabled && selectedPaymentModes.length === 1
    ) || (
      accountName !== '' && accountNumber !== '' && ifscCode !== '' && panNumber !== ''
    );
    if (accountDetailsOptional) {
      this.handleSubmitStoreDetails();
    } else {
      this.setState({ showDialog: true });
    }
  }

  validatedStoreDetails() {
    const {
      alternatePhoneNumber, paymentMode, deliveryRadii, pincode,
    } = this.state;
    const selectedPaymentModes = paymentMode.filter(mode => mode.isEnabled === true);
    const errors = {};
    deliveryRadii.forEach((radii) => {
      if (radii.radius === '') {
        errors.radius = Strings.Delivery_Radius_empty_error;
      } else if (radii.radius.toString().match('^[0-9]{1,3}([.][0-9]{1})?$') === null) {
        errors.radius = Strings.Delivery_Radius_format_error;
      } else if (parseFloat(radii.radius) === 0) {
        errors.radius = Strings.Delivery_Radius_empty_error;
      }
      if (radii.amount === '') {
        errors.amount = Strings.Delivery_Amount_empty_error;
      }
    });
    if (alternatePhoneNumber === '') {
      errors.alternatePhoneNumber = Strings.Alternate_Mobile_Number_empty_error;
    } else if (alternatePhoneNumber.toString().length < 10) {
      errors.alternatePhoneNumber = Strings.Alternate_Mobile_Number_format_error;
    } else if (alternatePhoneNumber.toString().match('^[1-9]{1}[0-9]{9}$') === null) {
      errors.alternatePhoneNumber = Strings.Alternate_Mobile_Number_validation_error;
    }
    if (selectedPaymentModes.length === 0) {
      errors.paymentMode = Strings.Accepted_Payment_Methods_empty_error;
    }
    if (pincode === '') {
      errors.pincode = Strings.Pincode_Empty_Error;
    } else if (pincode.toString().length < 6) {
      errors.pincode = Strings.Pincode_Format_Error;
    }
    if (Object.entries(errors).length === 0) {
      return true;
    }
    this.setState({ errors });
    return false;
  }

  validatedAccountDetails(required) {
    const {
      accountName, accountNumber, confirmAccountNumber, ifscCode, panNumber, gstNumber,
    } = this.state;
    const errors = {};
    if (required && accountName === '') {
      errors.accountName = Strings.Account_Name_empty_error;
    } else if (accountName !== '' && accountName.length < 3) {
      errors.accountName = Strings.Account_Name_short_error;
    }
    if (required && accountNumber === '') {
      errors.accountNumber = Strings.Account_Number_empty_error;
    } else if (accountNumber !== '' && accountNumber.length < 5) {
      errors.accountNumber = Strings.Account_Number_short_error;
    }
    if (required && confirmAccountNumber === '') {
      errors.confirmAccountNumber = Strings.Confirm_Account_Number_empty_error;
    } else if (confirmAccountNumber !== accountNumber) {
      errors.confirmAccountNumber = Strings.Confirm_Account_Number_Not_Matching;
    }
    if (required && ifscCode === '') {
      errors.ifscCode = Strings.IFSC_Code_empty_error;
    } else if (ifscCode !== '' && ifscCode.match('^[a-zA-Z]{4}[0-9a-zA-Z]{7}$') === null) {
      errors.ifscCode = Strings.IFSC_Code_format_error;
    }
    if (required && panNumber === '') {
      errors.panNumber = Strings.PAN_Number_empty_error;
    } else if (panNumber !== '' && panNumber.match('^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$') === null) {
      errors.panNumber = Strings.PAN_Number_format_error;
    }
    if (gstNumber !== '' && gstNumber.match('^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[0-9a-zA-Z]{1}$') === null) {
      errors.gstNumber = Strings.GST_Number_format_error;
    }
    if (Object.entries(errors).length === 0) {
      return true;
    }
    this.setState({ errors });
    return false;
  }

  handleSubmitStoreDetails() {
    logFirebaseEvent(events.OO_settings_change, null);
    const {
      deliveryTimeFrom, deliveryTimeTo, deliveryRadii, minDeliveryPenalty,
      alternatePhoneNumber, minimumShoppingAmount, paymentMode,
      isDelivering24hrs, nonServiceableAreasPolygons, pincode, landmark,
    } = this.state;
    const newDeliveryRadii = deliveryRadii.map(radii => (
      {
        ...radii,
        radius: parseFloat(radii.radius) * 1000,
        amount: parseInt(radii.amount, 10),
      }
    ));
    const { patchProfile, history } = this.props;
    let patchProfilePayload = {
      deliveryRadii: newDeliveryRadii,
      minDeliveryPenalty: (parseInt(minimumShoppingAmount, 10) === 0 || !minDeliveryPenalty)
        ? 0 : parseInt(minDeliveryPenalty, 10),
      homeDeliveryMinAmount: !minimumShoppingAmount ? 0 : parseInt(minimumShoppingAmount, 10),
      paymentMode,
      alternatePhoneNumber,
      isDelivering24hrs,
      nonServiceableAreasPolygons,
      pinCode: pincode,
      landmark,
    };
    if (!isDelivering24hrs) {
      patchProfilePayload = {
        ...patchProfilePayload,
        deliveryTiming: {
          startTime: deliveryTimeFrom.format('HH:mm:ss'),
          endTime: deliveryTimeTo.format('HH:mm:ss'),
        },
      };
    }
    patchProfile(patchProfilePayload).then(() => {
      this.setState({
        showSuccessMessage: true,
      },
      () => {
        setTimeout(() => {
          const isAdmin = getIsAdmin() === 'true';
          if (isAdmin) {
            this.setState({
              showSuccessMessage: false,
            });
          } else {
            history.push('/');
          }
        }, 2000);
      });
      logClevertapEvents(clevertapEvents.OO_Settings_Updated);
    });
  }

  handleSubmitAccountDetails(isModal) {
    const {
      accountName, accountNumber, ifscCode, panNumber, gstNumber,
    } = this.state;
    if (!this.validatedAccountDetails(!isOptional || isModal)) {
      return;
    }
    const { patchProfile, history } = this.props;
    patchProfile({
      account: {
        accountName,
        accountNumber,
        ifscCode,
        panNumber,
        gstNumber,
        cancelledChequeUrl: '',
      },
    }).then(() => {
      if (isModal) {
        this.handleSubmitStoreDetails();
      } else {
        logClevertapEvents(clevertapEvents.OO_Settings_Updated);
        this.setState({ showSuccessMessage: true }, () => {
          setTimeout(() => {
            const isAdmin = getIsAdmin() === 'true';
            if (isAdmin) {
              this.setState({
                showSuccessMessage: false,
              });
            } else {
              history.push('/');
            }
          }, 2000);
        });
      }
    });
  }

  render() {
    const {
      loading, deliveryTimeFrom, deliveryTimeTo, paymentMode,
      alternatePhoneNumber, showSuccessMessage, errors,
      minimumShoppingAmount, accountName, accountNumber,
      confirmAccountNumber, ifscCode, panNumber, gstNumber,
      showDialog, isDelivering24hrs, minDeliveryPenalty,
      deliveryRadii, playVideo, pincode, center, address, landmark,
    } = this.state;
    const {
      language, handleChangeLanguage, storeProfile, location,
    } = this.props;
    const params = QueryString.parse(location.search);
    const state = (params.state || 'store-details').toLowerCase();
    const isAppOrAdmin = getIsApp() === 'true' || getIsAdmin() === 'true';

    const getAccountDetailsBody = isModal => (
      <Container fluid>
        <Row>
          <Col xs={24} className="my-3 bg-light p-4 text-justify">
            <Row className="m-0">
              <Col xs="auto" className="p-0">
                <img src="/Assets/info.svg" alt="" />
              </Col>
              <Col className="pr-0">{Strings.Why_Account_Details[language]}</Col>
            </Row>
          </Col>
          <Col xs={24}>
            <Row className="mt-4">
              <Col>
                <div className="mb-2">
                  <b>
                    {`1. ${Strings.Account_Name[language]} `}
                    {
                      isOptional && !isModal ? `(${Strings.Optional[language]})` : <span className="text-danger">*&nbsp;&nbsp;</span>
                    }
                  </b>
                  {/* <img src={currentState > 1 ? '/Assets/check-circle.svg'
                    : '/Assets/check-circle-light.svg'} alt="" /> */}
                </div>
                <input
                  required
                  type="text"
                  name="accountName"
                  className={`form-control bg-white setup-input${errors.accountName ? ' border-danger' : ''}`}
                  value={accountName}
                  onChange={this.handleChange}
                />
                <div className="fs-1 text-danger mb-4 pt-1 setup-input">{errors.accountName ? errors.accountName[language] : ''}</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-2">
                  <b>
                    {`2. ${Strings.Account_Number[language]} `}
                    {
                      isOptional && !isModal ? `(${Strings.Optional[language]})` : <span className="text-danger">*&nbsp;&nbsp;</span>
                    }
                  </b>
                  {/* <img src={currentState > 2 ? '/Assets/check-circle.svg'
                    : '/Assets/check-circle-light.svg'} alt="" /> */}
                </div>
                <input
                  required
                  type="text"
                  name="accountNumber"
                  className={`form-control bg-white setup-input${errors.accountNumber ? ' border-danger' : ''}`}
                  value={accountNumber}
                  onChange={this.handleChange}
                />
                <div className="fs-1 text-danger mb-4 pt-1 setup-input">{errors.accountNumber ? errors.accountNumber[language] : ''}</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-2">
                  <b>
                    {`3. ${Strings.Confirm_Account_Number[language]} `}
                    {
                      isOptional && !isModal ? `(${Strings.Optional[language]})` : <span className="text-danger">*&nbsp;&nbsp;</span>
                    }
                  </b>
                  {/* <img src={currentState > 3 ? '/Assets/check-circle.svg'
                    : '/Assets/check-circle-light.svg'} alt="" /> */}
                </div>
                <input
                  required
                  type="text"
                  name="confirmAccountNumber"
                  className={`form-control bg-white setup-input${errors.confirmAccountNumber ? ' border-danger' : ''}`}
                  value={confirmAccountNumber}
                  onChange={this.handleChange}
                />
                <div className="fs-1 text-danger mb-4 pt-1 setup-input">{errors.confirmAccountNumber ? errors.confirmAccountNumber[language] : ''}</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-2">
                  <b>
                    {`4. ${Strings.IFSC_Code[language]} `}
                    {
                      isOptional && !isModal ? `(${Strings.Optional[language]})` : <span className="text-danger">*&nbsp;&nbsp;</span>
                    }
                  </b>
                  {/* <img src={currentState > 4 ? '/Assets/check-circle.svg'
                    : '/Assets/check-circle-light.svg'} alt="" /> */}
                </div>
                <input
                  required
                  type="text"
                  name="ifscCode"
                  className={`form-control bg-white setup-input${errors.ifscCode ? ' border-danger' : ''}`}
                  value={ifscCode}
                  onChange={this.handleChange}
                />
                <div className="fs-1 text-danger mb-4 pt-1 setup-input">{errors.ifscCode ? errors.ifscCode[language] : ''}</div>
              </Col>
            </Row>
            {/* <Row className={currentState === 5 ? '' : 'opacity-50'}>
              <Col xs={24}>
                <div className="mb-2">
                  <b>{`5. ${Strings.Upload_Cancel_Cheque[language]} `}</b>
                  <img src={currentState > 5 ? '/Assets/check-circle.svg'
                    : '/Assets/check-circle-light.svg'} alt="" />
                </div>
              </Col>
              <Col xs={12} className="mb-4">
                <Button variant="outline-primary border-dashed" block>
                  <img src="/Assets/camera.svg" alt="" />
                  &nbsp;
                  {Strings.Camera[language]}
                </Button>
              </Col>
              <Col xs={12} className="mb-4">
                <Button variant="outline-primary border-dashed" block>
                  <img src="/Assets/gallery.svg" alt="" />
                  &nbsp;
                  {Strings.Gallery[language]}
                </Button>
              </Col>
              <Col>
                <Button
                  className={currentState === 5 ? 'px-6 rounded-0 mb-4' : 'd-none'}
                  onClick={() => {
                    this.handleChangeState(6);
                    logFirebaseEvent(events.OO_setup3_upload_Cancelled_Cheque, null);
                  }}
                >
                  {Strings.Next[language]}
                </Button>
              </Col>
            </Row> */}
            <Row>
              <Col>
                <div className="mb-2">
                  <b>
                    {`5. ${Strings.PAN_Number[language]} `}
                    {
                      isOptional && !isModal ? `(${Strings.Optional[language]})` : <span className="text-danger">*&nbsp;&nbsp;</span>
                    }
                  </b>
                  {/* <img src={currentState > 5 ? '/Assets/check-circle.svg'
                    : '/Assets/check-circle-light.svg'} alt="" /> */}
                </div>
                <input
                  required
                  type="text"
                  name="panNumber"
                  className={`form-control bg-white setup-input${errors.panNumber ? ' border-danger' : ''}`}
                  value={panNumber}
                  onChange={this.handleChange}
                />
                <div className="fs-1 text-danger mb-4 pt-1 setup-input">{errors.panNumber ? errors.panNumber[language] : ''}</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-2">
                  <b>
                    {`6. ${Strings.GST_Number[language]} (${Strings.Optional[language]})`}
                    &nbsp;&nbsp;
                  </b>
                  {/* <img src={currentState > 6 ? '/Assets/check-circle.svg'
                    : '/Assets/check-circle-light.svg'} alt="" /> */}
                </div>
                <input
                  type="text"
                  name="gstNumber"
                  className={`form-control bg-white setup-input${errors.gstNumber ? ' border-danger' : ''}`}
                  value={gstNumber}
                  onChange={this.handleChange}
                />
                <div className="fs-1 text-danger mb-4 pt-1 setup-input">{errors.gstNumber ? errors.gstNumber[language] : ''}</div>
              </Col>
            </Row>
            {
              showSuccessMessage ? (
                <Row className="m-0 mb-4 justify-content-center">
                  <Col xs="auto" className="bg-success-20 rounded py-2">
                    <b>{Strings.Settings_Saved[language]}</b>
                  </Col>
                </Row>
              ) : (
                <Row className="m-0 mb-4">
                  <Col className="p-0">
                    <Button
                      className="px-6 rounded-0"
                      onClick={() => this.handleSubmitAccountDetails(isModal)}
                    >
                      {Strings.Save[language]}
                    </Button>
                  </Col>
                </Row>
              )
            }
          </Col>
        </Row>
      </Container>
    );

    return (
      <Container
        fluid
        className="bg-white pt-8 pt-lg-1 h-100 overflow-y-scroll"
      >
        <CustomModal
          show={showDialog}
          title={Strings.Account_Details[language]}
          body={(<div className="account-details-modal">{getAccountDetailsBody(true)}</div>)}
          onHide={() => this.setState({ showDialog: false })}
        />
        <CustomModal
          show={playVideo}
          title={Strings.Help_Video[language]}
          body={(
            <iframe
              title="Help Video"
              className="help-video"
              allow="autoplay; encrypted-media"
              width="100%"
              height="100%"
              frameBorder="0"
              src="https://www.youtube.com/embed/2fbk6MbM5Us?autoplay=1&playlist=2fbk6MbM5Us&loop=1"
            />
          )}
          onHide={() => this.setState({ playVideo: false })}
          closeButton={playVideo}
        />
        <Row
          className={`bg-light py-2 d-lg-none settings-header
            ${isAppOrAdmin ? ' app' : ''}`}
        >
          <Col className="pr-1">
            <b>{Strings.Change_Settings[language]}</b>
          </Col>
          <Col xs="auto" className="text-right pl-1">
            <div className="bg-secondary d-inline-block rounded">
              <Button
                variant={language === 'en' ? 'primary shadow-sm' : 'secondary'}
                className="text-white py-0 px-4 shadow-none"
                onClick={() => handleChangeLanguage('en')}
              >
                ENG
              </Button>
              <Button
                variant={language === 'hi' ? 'primary shadow-sm' : 'secondary'}
                className="text-white py-0 px-4 shadow-none"
                onClick={() => handleChangeLanguage('hi')}
              >
                हिंदी
              </Button>
            </div>
          </Col>
        </Row>
        {
          loading ? (
            <Row className="justify-content-center">
              <Col xs="auto" className="spinner-border text-primary p-0" role="status">
                <span className="sr-only">Loading...</span>
              </Col>
            </Row>
          ) : (
            <React.Fragment>
              <Row className="settings-nav bg-white">
                <Col className="p-0">
                  <Link to="/settings?state=store-details" replace>
                    <Button variant={`link rounded-0 py-3 ${state === 'store-details' ? 'border-bottom-primary' : 'text-muted border-bottom'}`} block>
                      {Strings.Store_Details[language]}
                    </Button>
                  </Link>
                </Col>
                <Col xs="auto" className="px-0 py-2">
                  <div className="border h-100" />
                </Col>
                <Col className="p-0">
                  <Link to="/settings?state=account-details" replace>
                    <Button variant={`link rounded-0 py-3 ${state === 'account-details' ? 'border-bottom-primary' : 'text-muted border-bottom'}`} block>
                      {Strings.Account_Details[language]}
                    </Button>
                  </Link>
                </Col>
              </Row>
              <Row className={state === 'store-details' ? '' : 'd-none'}>
                {/* <Col className="my-3 bg-light p-4 text-justify">
                  <Row className="m-0">
                    <Col xs="auto" className="p-0">
                      <img src="/Assets/info.svg" alt="" />
                    </Col>
                    <Col className="pr-0">
                      <b className="d-block">{Strings.Promise_To_retailers[language]}</b>
                      {Strings.Digital_Exclusivity[language]}
                    </Col>
                  </Row>
                </Col> */}
                <Col xs={24}>
                  <Row>
                    <Col>
                      <div className="mb-2 mt-3">
                        <b>{`1. ${Strings.Store_Type[language]} `}</b>
                        <span className="text-danger">*</span>
                      </div>
                      <input
                        readOnly
                        type="text"
                        name="storeType"
                        className="form-control rounded-0 setup-input"
                        value={storeProfile ? storeProfile.storeType.displayName : ''}
                        // onChange={this.handleChange}
                      />
                      <div className="fs-1 d-block text-muted mb-4 pt-1 setup-input">{Strings.Change_Store_Type_Message[language]}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mt-3">
                        <b>{`2. ${Strings.Shop_Address[language]} `}</b>
                        <span className="text-danger">*</span>
                      </div>
                      <div className="d-block mb-3 pt-1 setup-input">{address}</div>
                      <div className="mb-4">
                        <Link
                          to={{
                            pathname: '/update-location',
                            setup: false,
                            updatedProfile: this.state,
                          }}
                        >
                          <Button
                            variant="outline-primary"
                            className="px-4"
                          >
                            <img src="/Assets/pin.svg" alt="" className="pb-1" />
                          &nbsp;
                            <b>{Strings.Change_Location[language]}</b>
                          </Button>
                        </Link>
                      </div>
                      <span className="d-flex border border-radius p-2 mb-3 align-items-center setup-input">
                        <b className="text-muted border-right px-2">{Strings.Landmark[language]}</b>
                        <input
                          name="landmark"
                          className="border-0 font-weight-bold text-primary px-4 bg-white setup-input"
                          type="text"
                          value={landmark}
                          onChange={this.handleChange}
                        />
                      </span>

                      <span className={`d-flex border border-radius p-2 align-items-center setup-input ${errors.pincode ? ' border-danger' : ''}`}>
                        <b className="text-muted border-right px-2">{Strings.Pin_Code[language]}</b>
                        <input
                          name="pincode"
                          className="border-0 font-weight-bold text-primary px-4 bg-white setup-input"
                          type="text"
                          value={pincode}
                          onChange={this.handleChange}
                        />
                      </span>
                      <div className="fs-1 text-danger mb-4 pt-1 setup-input">{errors.pincode ? errors.pincode[language] : ''}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mb-2">
                        <b>{`3. ${Strings.Delivery_Timings[language]} `}</b>
                        <span className="text-danger">*</span>
                      </div>
                      <Row className="mb-3">
                        <Col xs="auto" className="pr-1 d-flex align-items-center" onClick={this.handle24hourDelivery}>
                          <div className={`border rounded checkbox ${isDelivering24hrs ? 'checked' : ''}`} />
                          <div className="px-3">{Strings.Delivery_Timing_24_Hrs[language]}</div>
                        </Col>
                      </Row>

                      <Row className={`mb-4 ${isDelivering24hrs ? 'opacity-50' : ''}`}>
                        <Col xs="auto" className="pr-1">
                          <div className={`d-flex border border-radius p-2 align-items-center ${language === 'en' ? '' : 'flex-row-reverse text-right'}`}>
                            <b className="w-50p text-muted">{Strings.From[language]}</b>
                            <TimePicker
                              className="bg-success text-success"
                              showSecond={false}
                              onChange={time => this.handleTimeChange('deliveryTimeFrom', time)}
                              format="hh:mm a"
                              value={deliveryTimeFrom}
                              use12Hours
                              inputReadOnly
                              allowEmpty={false}
                              disabled={isDelivering24hrs}
                            />
                          </div>
                        </Col>
                        <Col xs="auto" className="pl-1">
                          <div className={`d-flex border border-radius p-2 align-items-center ${language === 'en' ? '' : 'flex-row-reverse text-right'}`}>
                            <b className="w-50p text-muted">{Strings.To[language]}</b>
                            <TimePicker
                              showSecond={false}
                              onChange={time => this.handleTimeChange('deliveryTimeTo', time)}
                              format="hh:mm a"
                              value={deliveryTimeTo}
                              use12Hours
                              inputReadOnly
                              allowEmpty={false}
                              disabled={isDelivering24hrs}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mb-2 d-flex align-items-center">
                        <span>
                          <b>{`4. ${Strings.Delivery_Radius[language]} `}</b>
                          <span className="text-danger">*</span>
                        </span>
                        <span className="pl-3">
                          <Button
                            variant="primary-dark"
                            className="px-4 d-flex align-items-center py-1"
                            onClick={() => this.setState({ playVideo: true })}
                          >
                            <b className="fs-1">{Strings.Help_Video[language]}</b>
                            &nbsp;&nbsp;
                            <img src="/Assets/help-video.svg" alt="" />
                          </Button>
                        </span>
                      </div>
                      <Row>
                        <Col xs="auto" className="pr-1">
                          {deliveryRadii.map((radii, index) => (
                            <div key={index} className="d-flex">
                              <span className="pt-2">
                                <p className="fs-1">{`${index === 0 ? Strings.Free_Delivery_Radius[language] : Strings.Add_Delivery_Fees[language]} `}</p>
                              </span>
                              <span className={`d-flex pb-4 ${index === 0 ? 'pl-2' : 'pl-5'}`}>
                                <span className={`d-flex border border-radius p-2 align-items-center${errors.radius ? ' border-danger' : ''}`}>
                                  <input
                                    name="radius"
                                    className="border-0 font-weight-bold text-primary w-50p bg-white"
                                    type="text"
                                    value={radii.radius}
                                    onChange={event => this.handleChangeRadii(index, event)}
                                  />
                                  <b className="text-muted border-left px-2">KM</b>
                                </span>
                                {index !== 0
                                  && (
                                  <span className={`d-flex border border-radius ml-2 p-2 align-items-center${errors.amount ? ' border-danger' : ''}`}>
                                    <input
                                      name="amount"
                                      className="border-0 font-weight-bold text-primary w-50p bg-white"
                                      type="text"
                                      value={radii.amount}
                                      onChange={event => this.handleChangeRadii(index, event)}
                                    />
                                    <b className="text-muted border-left px-2">{'\u20B9'}</b>
                                  </span>
                                  )}
                                {index > 0
                                && (
                                <span className="pt-2 pl-3">
                                  <Button
                                    className="rounded-button text-white p-0"
                                    variant="secondary"
                                    onClick={() => this.handleDeleteRadius(index)}
                                  >
                                    &times;
                                  </Button>
                                </span>
                                )}
                              </span>
                            </div>
                          ))}
                        </Col>
                      </Row>
                      <div className="fs-1 d-block text-danger pt-1 setup-input">{errors.radius ? errors.radius[language] : ''}</div>
                      <div className="fs-1 d-block text-danger pt-1 setup-input">{errors.amount ? errors.amount[language] : ''}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pb-4">
                      <Button
                        className="p-0 text-success"
                        variant="link"
                        onClick={() => this.handleExtendRadii(
                          {
                            radius: deliveryRadii.slice(-1)[0]
                              ? parseInt(deliveryRadii.slice(-1)[0].radius, 10) + 2 : 2,
                            amount: deliveryRadii.slice(-1)[0]
                              ? parseInt(deliveryRadii.slice(-1)[0].amount, 10) + 10 : 0,
                          },
                        )}
                      >
                        {Strings.Extend_Radius[language]}
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mb-2">
                        <b
                          className={`${(center.lat === 0 && center.lng === 0)
                        && 'text-secondary'}`}
                        >
                          {`5. ${Strings.Setting_Exclusions[language]} `}
                        </b>
                      </div>
                      {center.lat === 0 && center.lng === 0 ? <Row className="mb-2" /> : (
                        <>
                          <Row className="mb-2">
                            <Col xs="auto" id="view-map" className="ml-3 border google-map-div" />
                            <Col xs="auto" className="ml-3 map-div-override" />
                          </Row>
                          <div className="mb-4">
                            <Link
                              to={{
                                pathname: '/update-exclusion-area',
                                setup: false,
                                updatedProfile: this.state,
                              }}
                            >
                              <Button
                                variant="outline-primary"
                                className="rounded-0 px-6"
                              >
                                {Strings.Updating_Exclusions[language]}
                              </Button>
                            </Link>
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mb-2">
                        <b>{`6. ${Strings.Minimum_Amount[language]} `}</b>
                        {/* <span className="text-danger">*</span> */}
                      </div>
                      <Row>
                        <Col xs="auto" className="pr-1">
                          <div className="d-flex">
                            <span className="pt-2 pr-3">
                              <p className="fs-1">{`${Strings.Min_Order_Value[language]} `}</p>
                            </span>
                            <span className={`d-flex border border-radius p-2 align-items-center${errors.minimumShoppingAmount ? ' border-danger' : ''}`}>
                              <b className="text-muted border-right px-2">{'\u20B9'}</b>
                              <input
                                name="minimumShoppingAmount"
                                className="border-0 font-weight-bold text-primary w-100p px-4 bg-white"
                                type="text"
                                value={minimumShoppingAmount}
                                onChange={this.handleChange}
                              />
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <div className="fs-1 text-danger mb-4 pt-1 setup-input">{errors.minimumShoppingAmount ? errors.minimumShoppingAmount[language] : ''}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mb-2">
                        <b>{`${Strings.If_Not_Meet[language]} `}</b>
                      </div>
                      <Row>
                        <Col xs="auto" className="pr-1">
                          <div className="d-flex">
                            <span className="pt-2 pr-3">
                              <p className="fs-1">{`${Strings.Delivery_Charges[language]} `}</p>
                            </span>
                            <span className={'d-flex border border-radius p-2 align-items-center}'}>
                              <b className="text-muted border-right px-2">{'\u20B9'}</b>
                              <input
                                name="minDeliveryPenalty"
                                disabled={
                                  parseInt(minimumShoppingAmount, 10) === 0
                                  || !minimumShoppingAmount
                                }
                                className={
                                  `border-0 font-weight-bold w-100p px-4 bg-white 
                                  ${(parseInt(minimumShoppingAmount, 10) === 0 || !minimumShoppingAmount)
                                    ? 'text-secondary' : 'text-primary'}`
                                  }
                                type="text"
                                value={
                                  (
                                    parseInt(minimumShoppingAmount, 10) === 0
                                      || !minimumShoppingAmount
                                  )
                                    ? '0' : minDeliveryPenalty
                                }
                                onChange={this.handleChange}
                              />
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <div className="mb-4 pt-1" />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mb-2">
                        <b>{`7. ${Strings.Accepted_Payment_Methods[language]} `}</b>
                        <span className="text-danger">*</span>
                      </div>
                      <Row>
                        {
                          paymentMode.map(mode => (
                            <Col xs="auto" className="pr-1 d-flex align-items-center" key={mode.code} onClick={() => this.handleChangePaymentMode(mode.code)}>
                              <div className={`border rounded checkbox${mode.isEnabled ? ' checked' : ''}${errors.paymentMode ? ' border-danger' : ''}`} />
                              <div className="px-3">{mode.name}</div>
                            </Col>
                          ))
                        }
                      </Row>
                      <div className="fs-1 d-block text-danger mb-4 pt-1 setup-input">{errors.paymentMode ? errors.paymentMode[language] : ''}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mb-2">
                        <b>{`8. ${Strings.Alternate_Mobile_Number[language]} `}</b>
                        <span className="text-danger">*</span>
                      </div>
                      <input
                        type="text"
                        name="alternatePhoneNumber"
                        // placeholder={Strings.Alternate_Mobile_Number[language]}
                        className={`form-control bg-white rounded-0 setup-input${errors.alternatePhoneNumber ? ' border-danger' : ''}`}
                        value={alternatePhoneNumber}
                        onChange={this.handleChange}
                      />
                      <div className="fs-1 d-block text-danger mb-4 pt-1 setup-input">{errors.alternatePhoneNumber ? errors.alternatePhoneNumber[language] : ''}</div>
                    </Col>
                  </Row>
                  {
                    showSuccessMessage ? (
                      <Row className="m-0 mb-4 justify-content-center">
                        <Col xs="auto" className="bg-success-20 rounded py-2">
                          <b>{Strings.Settings_Saved[language]}</b>
                        </Col>
                      </Row>
                    ) : (
                      <Row className="m-0 mb-4">
                        <Col className="p-0">
                          <Button
                            className="px-6 rounded-0"
                            onClick={this.checkAccountDetails}
                          >
                            {Strings.Save[language]}
                          </Button>
                        </Col>
                      </Row>
                    )
                  }
                </Col>
              </Row>
              <Row className={state === 'account-details' ? '' : 'd-none'}>
                {getAccountDetailsBody(false)}
              </Row>
            </React.Fragment>
          )
        }
      </Container>
    );
  }
}

Settings.propTypes = {
  patchProfile: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  handleChangeLanguage: PropTypes.func.isRequired,
  storeProfile: PropTypes.shape({}),
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
};

Settings.defaultProps = {
  storeProfile: null,
};

export default Settings;
