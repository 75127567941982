import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Container, Row, Col, Spinner, Button,
} from 'react-bootstrap';
import Strings from '../../../Strings';
import {
  logFirebaseEvent, longDateOptions, dateMonthOptions, monthOptions, logClevertapEvents,
} from '../../../Utils';
import events from '../../../FirebaseEvents';
import clevertapEvents from '../../../ClevertapEvents';


const getSelectedDateString = (dateFilter, start, end, language) => {
  let weekStartDate = start.getDate();
  weekStartDate = weekStartDate < 10 ? `0${weekStartDate}` : weekStartDate;
  switch (dateFilter) {
    default:
    case 'today':
      return start.toLocaleDateString(`${language}-in`, dateMonthOptions);
    case 'week':
      return `${
        start.getMonth() === end.getMonth()
          ? weekStartDate : start.toLocaleDateString(`${language}-in`, dateMonthOptions)
      } - ${end.toLocaleDateString(`${language}-in`, dateMonthOptions)}`;
    case 'month':
      return start.toLocaleDateString(`${language}-in`, monthOptions);
  }
};

const getDaterangeStrings = (dateRange, language) => {
  const { today, week, month } = dateRange;
  let weekStartDate = week.start.getDate();
  weekStartDate = weekStartDate < 10 ? `0${weekStartDate}` : weekStartDate;
  return ({
    today: {
      selectedText: Strings.Daily[language],
      displayText: Strings.Today[language],
      date: today.start.toLocaleDateString(`${language}-in`, dateMonthOptions),
    },
    week: {
      selectedText: Strings.Weekly[language],
      displayText: Strings.This_Week[language],
      date: `${
        week.start.getMonth() === week.end.getMonth()
          ? weekStartDate : week.start.toLocaleDateString(`${language}-in`, dateMonthOptions)
      } - ${week.end.toLocaleDateString(`${language}-in`, dateMonthOptions)}`,
    },
    month: {
      selectedText: Strings.Monthly[language],
      displayText: Strings.This_Month[language],
      date: month.end.toLocaleDateString(`${language}-in`, monthOptions),
    },
  });
};

function OnlineStoreReport(props) {
  const {
    loading, language, stats, accountsSettlement, selectingDateFilter, dateFilter,
    dateRange, handleDateFilterChange, toggleDateFilterSelector, start, end, handleDateRangeChange,
  } = props;
  const dateRangeStrings = getDaterangeStrings(dateRange, language);
  const selectedDateString = getSelectedDateString(dateFilter, start, end, language);
  const orderStatsField = [
    {
      id: 1,
      displayText: Strings.Orders[language],
      dataShowcase: (
        <div className="d-flex align-items-center justify-content-between">
          <b className="text-primary fs-2">{stats.length ? stats[0].value : 0}</b>
          <Link to="/orders" className={stats.length && stats[0].value ? '' : 'd-none'}>
            <img src="/Assets/green-arrow-circle.svg" alt="" className="icon" />
          </Link>
        </div>
      ),
    },
    {
      id: 2,
      displayText: Strings.Total_Earning[language],
      dataShowcase: (
        <div>
          <b className="text-primary fs-2">
            <span>&#8377;</span>
            {stats.length ? stats[1].value + stats[2].value : 0}
          </b>
        </div>
      ),
    },
    {
      id: 3,
      displayText: Strings.Cash[language],
      dataShowcase: (
        <div className="fs-2">
          <b>
            <span>&#8377;</span>
            {stats.length ? stats[1].value : 0}
          </b>
        </div>
      ),
    },
    {
      id: 4,
      displayText: Strings.Digital_Payment[language],
      dataShowcase: (
        <div className="fs-2">
          <b>
            <span>&#8377;</span>
            {stats.length ? stats[2].value : 0}
          </b>
        </div>
      ),
    },
  ];
  return (
    <Container fluid className="bg-white h-100">
      <Row>
        <Col xs={24} className="text-center pt-3">
          <b className="fs-3">
            <span className="mx-1"><img src="/Assets/graph.svg" alt="" /></span>
            {Strings.Online_Store_Report[language]}
          </b>
        </Col>
        <Col
          xs={24}
          className="text-center pb-1"
        >
          <Row className="align-items-center">
            <Col
              xs="auto"
              className="text-center"
              onClick={() => handleDateRangeChange()}
            >
              <img src="/Assets/arrow-down.svg" alt="" className="rotate-90" />
            </Col>
            <Col onClick={toggleDateFilterSelector}>
              <div className="fs-3 py-2">
                <b className="text-success">
                  {dateRangeStrings[dateFilter].selectedText}
                  <img
                    src="/Assets/arrow-down.svg"
                    alt=""
                    className={selectingDateFilter ? 'rotate-180' : 'transition-all'}
                  />
                </b>
              </div>
              <div className="fs-1"><b>{selectedDateString}</b></div>
            </Col>
            <Col
              xs="auto"
              className="text-center"
              onClick={() => handleDateRangeChange(true)}
            >
              <img src="/Assets/arrow-down.svg" alt="" className="rotate-270" />
            </Col>
          </Row>
        </Col>
        <Col
          xs={24}
          className="position-relative d-flex justify-content-center text-muted px-0 pb-2"
        >
          <div
            className={`shadow py-3 px-6 position-absolute zindex-dropdown
              bg-white ${selectingDateFilter ? '' : 'd-none'}`}
          >
            <Row
              className="align-items-center py-1 m-0"
              onClick={() => handleDateFilterChange('today')}
            >
              <Col xs={13} className="pl-0 pr-1"><b>{dateRangeStrings.today.displayText}</b></Col>
              <Col xs={11} className="fs-1 pl-2 pr-0"><b>{dateRangeStrings.today.date}</b></Col>
            </Row>
            <Row
              className="align-items-center py-1 m-0"
              onClick={() => handleDateFilterChange('week')}
            >
              <Col xs={13} className="pl-0 pr-1"><b>{dateRangeStrings.week.displayText}</b></Col>
              <Col xs={11} className="fs-1 pl-2 pr-0"><b>{dateRangeStrings.week.date}</b></Col>
            </Row>
            <Row
              className="align-items-center py-1 m-0"
              onClick={() => handleDateFilterChange('month')}
            >
              <Col xs={13} className="pl-0 pr-1"><b>{dateRangeStrings.month.displayText}</b></Col>
              <Col xs={11} className="fs-1 pl-2 pr-0"><b>{dateRangeStrings.month.date}</b></Col>
            </Row>
          </div>
        </Col>
        <Col xs={24}>
          <div className="border border-medium" />
        </Col>
        <Col xs={24}>
          <div className={`text-center py-8 ${loading ? '' : 'd-none'}`}>
            <Spinner animation="border" variant="primary" />
          </div>
          <Container className={!loading ? '' : 'd-none'}>
            <Row className="align-items-center justify-content-around py-2">
              {orderStatsField.map(item => (
                <Col key={item.id} xs={11} className="border-dashed border-radius my-2 p-2 border-secondary">
                  <div className="fs-1">{item.displayText}</div>
                  <b className="text-primary">{item.dataShowcase}</b>
                </Col>
              ))}
            </Row>
          </Container>
        </Col>
      </Row>
      <Row className={loading ? 'd-none' : 'py-2'}>
        <Col xs={24} className="text-center">
          <b className="fs-2 text-primary">
            {Strings.Account_Settlement[language]}
          </b>
        </Col>
        <Col xs={24}>
          <div className={(accountsSettlement && (accountsSettlement.latestPayout
            || accountsSettlement.pendingSettlements.count !== 0))
            ? 'd-none' : 'text-center text-muted p-4 w-100'}
          >
            <i><b>{Strings.No_Settlements_Found[language]}</b></i>
          </div>
          <div>
            { accountsSettlement
              ? (
                <Container className="px-0 py-3">
                  { accountsSettlement.latestPayout
                    ? (
                      <Row className="py-2 border mx-2 mb-1">
                        <Col xs={5} className="pr-0">
                          <b className="fs-2 text-primary">
                            {`\u20B9 ${accountsSettlement.latestPayout.amount}`}
                          </b>
                        </Col>
                        <Col className="px-2">
                          <span className="d-block">
                            {`${Strings.Credited_For[language]}
                              ${accountsSettlement.latestPayout.orderCount}
                              ${accountsSettlement.latestPayout.orderCount === 1
                              ? Strings.Order[language] : Strings.Orders[language]} `}
                          </span>
                          <span className="d-block text-muted">
                            {new Date(accountsSettlement.latestPayout.date)
                              .toLocaleDateString(`${language}-in`, longDateOptions)}
                          </span>
                        </Col>
                        <Col xs="auto pl-0">
                          <Link to="/settlements">
                            <Button
                              variant="outline-primary"
                              className="rounded-0"
                              onClick={() => {
                                logFirebaseEvent(
                                  events.OO_dash_ViewOrdersSettled, null,
                                );
                                logClevertapEvents(
                                  clevertapEvents.OO_Dashboard_SettlementCompleted,
                                  {
                                    'Last Settlement Date': new Date(accountsSettlement.latestPayout.date).toLocaleString(),
                                    'Value Due': accountsSettlement.latestPayout.amount,
                                    'No of orders': accountsSettlement.latestPayout.orderCount,
                                  },
                                );
                              }}
                            >
                              {Strings.View[language]}
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    ) : '' }
                  <Row
                    className={`border mx-2 mt-1
                      ${accountsSettlement.pendingSettlements.count !== 0 ? 'py-2' : 'd-none'}`}
                  >
                    <Col xs={5} className="pr-0">
                      <b className="fs-2 text-success">
                        {`\u20B9 ${accountsSettlement.pendingSettlements.amount}`}
                      </b>
                    </Col>
                    <Col className="px-2">
                      <span className="d-block">
                        {`${accountsSettlement.pendingSettlements.count}
                          ${Strings.Pending_Settlement[language]}`}
                      </span>
                    </Col>
                    <Col xs="auto pl-0">
                      <Link to="/pending-settlements">
                        <Button
                          variant="outline-success"
                          className="rounded-0"
                          onClick={() => {
                            logFirebaseEvent(
                              events.OO_dash_ViewPendingSettlements, null,
                            );
                            logClevertapEvents(
                              clevertapEvents.OO_Dashboard_SettlementDueClicked,
                              {
                                'Value Due': accountsSettlement.pendingSettlements.amount,
                                'No of orders': accountsSettlement.pendingSettlements.count,
                              },
                            );
                          }}
                        >
                          {Strings.View[language]}
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </Container>
              ) : '' }
          </div>
        </Col>
      </Row>
    </Container>
  );
}

OnlineStoreReport.defaultProps = {
  accountsSettlement: null,
};

OnlineStoreReport.propTypes = {
  loading: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  stats: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  accountsSettlement: PropTypes.shape({}),
  selectingDateFilter: PropTypes.bool.isRequired,
  dateFilter: PropTypes.string.isRequired,
  dateRange: PropTypes.shape({}).isRequired,
  handleDateFilterChange: PropTypes.func.isRequired,
  toggleDateFilterSelector: PropTypes.func.isRequired,
  start: PropTypes.instanceOf(Date).isRequired,
  end: PropTypes.instanceOf(Date).isRequired,
  handleDateRangeChange: PropTypes.func.isRequired,
};

export default OnlineStoreReport;
