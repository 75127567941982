import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import Strings from '../../../Strings';

function DiscountOffer(props) {
  const { item, language } = props;
  return (
    <Col xs={24} lg={12} className="px-4 px-md-6 py-2">
      <Row>
        <Col xs={6} sm={5} className="text-center product-image-div">
          <img
            src={
              // eslint-disable-next-line no-nested-ternary
              item.primaryProduct ? item.primaryProduct.image === ''
                ? '/Assets/thumbnail.svg' : item.primaryProduct.image
                : '/Assets/thumbnail.svg'
            }
            alt=""
            className="product-image"
          />
        </Col>
        <Col xs={18} sm={19}>
          <Row>
            <Col xs={24} className="py-1">
              <b>
                {item.primaryProduct.displayName}
                <span className="text-success">
                  {
                    item.primaryProduct.mrp !== -1 ? (
                      ` ${Math.round(((((item.primaryProduct.mrp - item.primaryProduct.sellingPrice) / (item.primaryProduct.mrp)) * 100) * 100)) / 100}% ${Strings.off[language]} `
                    ) : ''
                  }
                </span>
              </b>
            </Col>
            <Col xs={24}>
              <b>
                <span className="text-success mr-4">{`\u20B9 ${item.primaryProduct.sellingPrice}`}</span>
                <span className={`text-secondary${item.primaryProduct.mrp === -1 || item.primaryProduct.mrp === item.primaryProduct.sellingPrice ? ' d-none' : ''}`}>
                  <del>{`\u20B9 ${item.primaryProduct.mrp}`}</del>
                </span>
              </b>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="m-0">
        <Col className="border mt-2" />
      </Row>
    </Col>
  );
}

DiscountOffer.propTypes = {
  item: PropTypes.shape({}).isRequired,
  language: PropTypes.string.isRequired,
};

export default DiscountOffer;
