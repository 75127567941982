import React from 'react';
import PropTypes from 'prop-types';
// import { SayButton } from 'react-say';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import {
  OrderList, CustomerDetails, PaymentPreference, Timer, OrderProduct,
} from '../../components/derived/orders';
import {
  patchOrderDetails, postLibraryRetailerProduct, patchRetailerProduct,
} from '../../../api/api';
import {
  getAddressString, getDateDiff, logFirebaseEvent, getUUID,
} from '../../Utils';
import Strings from '../../Strings';
import events from '../../FirebaseEvents';
import { CustomModal, List, CustomStepper } from '../../components/common';
import AlternateProductSuggestion from './AlternateProductSuggestion';
import './styles.scss';

let unFulfiledIds = [];

function notValid(name, value) {
  switch (name) {
    case 'mrp':
    case 'sellingPrice': {
      if (value.match('^[0-9]{0,6}([.][0-9]{0,2})?$') === null) {
        return true;
      }
      return false;
    }
    default: return false;
  }
}

class PendingOrderDetails extends React.Component {
  constructor(props) {
    super(props);
    const { orderDetails } = props;
    unFulfiledIds = orderDetails.orderItems.reduce((accumulator, orderItem) => {
      if (orderItem.tag === 'MARKED_UNSERVICEABLE') {
        return accumulator.concat(orderItem.id);
      }
      return accumulator;
    }, []);
    this.state = {
      orderItemList: [...orderDetails.orderItems],
      unFulfiledProductIds: [...unFulfiledIds],
      addingMoreProducts: false,
      newlyAddedProducts: [],
      selectedProductId: -2,
      selectedProduct: null,
      productName: '',
      mrp: '',
      sellingPrice: '',
      qty: 1,
      remSec: 0,
      remMin: 0,
      showOrderCancelDialog: '',
      selectedCancellationReason: '',
      otherCancellationReason: '',
      errors: {},
    };
    this.markOutOfStock = this.markOutOfStock.bind(this);
    this.toggleDialog = this.toggleDialog.bind(this);
    this.cancelOrder = this.cancelOrder.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateOrderItemQty = this.updateOrderItemQty.bind(this);
    this.deleteNewlyAdded = this.deleteNewlyAdded.bind(this);
    this.checkOrderStatus = this.checkOrderStatus.bind(this);
    this.submitOrderModified = this.submitOrderModified.bind(this);
    this.submitOrderPacked = this.submitOrderPacked.bind(this);
    this.resetTimings = this.resetTimings.bind(this);
    this.addNewProduct = this.addNewProduct.bind(this);
    this.refreshOrder = this.refreshOrder.bind(this);
    this.addToList = this.addToList.bind(this);
    this.selectProduct = this.selectProduct.bind(this);
    this.patchOrderPacked = this.patchOrderPacked.bind(this);
  }

  componentWillMount() {
    this.resetTimings();
  }

  componentWillReceiveProps(nextProps) {
    const { orderDetails } = this.props;
    const { orderDetails: nextOrderDetails } = nextProps;
    if (orderDetails.deliveryStatus !== nextOrderDetails.deliveryStatus) {
      this.refreshOrder(nextOrderDetails);
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  resetTimings() {
    const { orderDetails } = this.props;
    let ms = 900000 - getDateDiff(new Date(), new Date(orderDetails.modifiedOn));
    if (ms < 0) {
      return;
    }
    const mm = Math.floor(ms / 1000 / 60);
    ms -= mm * 1000 * 60;
    const ss = Math.floor(ms / 1000);
    this.setState({ remSec: ss, remMin: mm }, () => {
      this.timer = setInterval(() => {
        const { remSec, remMin } = this.state;
        if (remMin === 0 && remSec === 0) {
          clearInterval(this.timer);
          return;
        }
        if (remSec === 0) {
          this.setState({
            remMin: remMin === 0 ? 0 : remMin - 1,
            remSec: 59,
          });
        } else {
          this.setState({ remSec: remSec - 1 });
        }
      }, 1000);
    });
  }

  refreshOrder(orderDetails) {
    unFulfiledIds = orderDetails.orderItems.reduce((accumulator, orderItem) => {
      if (orderItem.tag === 'MARKED_UNSERVICEABLE') {
        return accumulator.concat(orderItem.id);
      }
      return accumulator;
    }, []);
    this.setState({
      orderItemList: [...orderDetails.orderItems],
      unFulfiledProductIds: [...unFulfiledIds],
    });
    this.resetTimings();
  }

  toggleDialog(dialogStr = '') {
    this.setState({
      showOrderCancelDialog: dialogStr,
    });
  }

  markOutOfStock(productId) {
    logFirebaseEvent(events.OO_od_UnserviceableProduct, JSON.stringify({
      product_id: productId,
    }));
    const { orderDetails } = this.props;
    const { unFulfiledProductIds, orderItemList } = this.state;
    const product = orderItemList.find(orderItem => orderItem.id === productId);
    product.quantity = orderDetails.orderItems.find(
      orderItem => orderItem.id === productId,
    ).quantity;
    product.tag = 'MARKED_UNSERVICEABLE';
    unFulfiledProductIds.push(productId);
    this.setState({ unFulfiledProductIds, orderItemList });
  }

  submitOrderModified(event, packOrder = false) {
    const { orderDetails, orderDetailsChange } = this.props;
    const { orderItemList, newlyAddedProducts } = this.state;
    const updatedList = orderItemList.reduce((accumulator, orderItem) => {
      if (orderItem.tag !== 'MARKED_UNSERVICEABLE') {
        return accumulator.concat({
          productId: orderItem.id,
          quantity: orderItem.quantity,
        });
      }
      return accumulator;
    }, []);
    const newItemList = newlyAddedProducts.reduce((accumulator, orderItem) => accumulator.concat({
      productId: orderItem.id,
      quantity: orderItem.quantity,
    }), updatedList);
    patchOrderDetails(orderDetails.orderId, {
      action: 'PATCH_ORDER_WITH_ALTERNATIVE',
      body: newItemList,
    }).then((res) => {
      if (packOrder) {
        this.patchOrderPacked();
        return;
      }
      this.setState({
        unFulfiledProductIds: [],
        addingMoreProducts: false,
        newlyAddedProducts: [],
        selectedProductId: -2,
        selectedProduct: null,
        productName: '',
        mrp: '',
        sellingPrice: '',
        qty: 1,
      });
      orderDetailsChange(res.data);
      this.refreshOrder(res.data);
    });
  }

  patchOrderPacked() {
    const { orderDetails, orderDetailsChange } = this.props;
    patchOrderDetails(orderDetails.orderId, {
      action: 'PATCH_DELIVERY_STATUS',
      body: { deliveryStatus: 'READY_TO_SHIP' },
    }).then((res) => {
      this.setState({ unFulfiledProductIds: [] });
      orderDetailsChange(res.data);
    });
  }

  submitOrderPacked() {
    logFirebaseEvent(events.OO_od_mark_order_packed, null);
    const { orderDetails } = this.props;
    const { unFulfiledProductIds } = this.state;
    if (unFulfiledProductIds.length !== 0 && orderDetails.deliveryStatus === 'PENDING') {
      this.submitOrderModified(null, true);
      return;
    }
    this.patchOrderPacked();
  }

  cancelOrder() {
    const { orderDetails, orderDetailsChange } = this.props;
    const { selectedCancellationReason, otherCancellationReason } = this.state;
    patchOrderDetails(orderDetails.orderId, {
      action: 'PATCH_ORDER_WITH_ALTERNATIVE',
      body: [],
    })
      .then(() => {
        patchOrderDetails(orderDetails.orderId, {
          action: 'PATCH_DELIVERY_STATUS',
          body: {
            deliveryStatus: 'CANCELLED',
            comment: otherCancellationReason || selectedCancellationReason,
          },
        }).then((res) => {
          orderDetailsChange(res.data);
        });
      });
  }

  handleChange(event) {
    const { name, value } = event.target;
    if (notValid(name, value)) {
      return;
    }
    const { errors } = this.state;
    errors[name] = null;
    this.setState(
      name === 'productName'
        ? ({
          errors: {},
          [name]: value,
          mrp: '',
          sellingPrice: '',
          qty: 1,
          selectedProductId: -2,
          selectedProduct: null,
        })
        : ({ errors, [name]: value }),
    );
  }

  updateOrderItemQty(productId, qty, newlyAddedProduct = false) {
    if (qty === 0) {
      if (newlyAddedProduct) {
        this.deleteNewlyAdded(productId);
      } else {
        this.markOutOfStock(productId);
      }
      return;
    }
    const { orderItemList, newlyAddedProducts } = this.state;
    const { orderDetails } = this.props;
    let product;
    if (newlyAddedProduct) {
      product = newlyAddedProducts.find(orderItem => orderItem.id === productId);
      product.quantity = qty;
      this.setState({ newlyAddedProducts });
      return;
    }
    const productIndex = orderItemList.findIndex(orderItem => orderItem.id === productId);
    product = { ...orderItemList[productIndex] };
    product.quantity = qty;
    if (orderDetails.orderItems[productIndex].quantity === qty) {
      product.tag = 'UNALTERED';
    } else {
      product.tag = 'QUANTITY_ALTERED';
    }
    this.setState({
      orderItemList: Object.assign([...orderItemList], { [productIndex]: product }),
    });
  }

  selectProduct(product) {
    this.setState({
      selectedProductId: product.id,
      selectedProduct: product,
      productName: product.displayName,
      mrp: product.mrp,
      sellingPrice: product.sellingPrice,
    });
  }

  addNewProduct(product) {
    this.setState(state => ({
      newlyAddedProducts: state.newlyAddedProducts.concat(product),
      addingMoreProducts: false,
      selectedProductId: -2,
      selectedProduct: null,
      productName: '',
      mrp: '',
      sellingPrice: '',
      qty: 1,
    }));
  }

  addToList() {
    const {
      selectedProductId, mrp, sellingPrice, productName, qty, selectedProduct,
    } = this.state;
    if (!this.validated() || selectedProductId === -2) {
      return;
    }
    if (selectedProductId === -1) {
      postLibraryRetailerProduct({
        mrp: parseFloat(mrp),
        sellingPrice: parseFloat(sellingPrice),
        name: productName,
        storeId: getUUID(),
      }).then((res) => {
        const productDetails = res.data;
        this.addNewProduct({
          id: productDetails.LibraryProductID.id,
          retailerProductId: productDetails.StoreProductID.id,
          displayName: productDetails.StoreProductID.displayName,
          mrp: productDetails.StoreProductID.mrp,
          sellingPrice: productDetails.StoreProductID.sellingPrice,
          quantity: qty,
          image: productDetails.LibraryProductID.image,
        });
      });
      return;
    }
    if (!selectedProduct.inInventory
      && (mrp !== selectedProduct.mrp || sellingPrice !== selectedProduct.sellingPrice)) {
      patchRetailerProduct(selectedProduct.retailerProductId, {
        sellingPrice: parseFloat(sellingPrice),
        mrp: parseFloat(mrp),
      }).then(() => {
        this.addNewProduct({
          ...selectedProduct,
          mrp,
          sellingPrice,
        });
      });
      return;
    }
    this.addNewProduct(selectedProduct);
  }

  deleteNewlyAdded(productId) {
    const { newlyAddedProducts } = this.state;
    const productIndex = newlyAddedProducts.findIndex(product => productId === product.id);
    if (productIndex !== -1) {
      newlyAddedProducts.splice(productIndex, 1);
    }
    this.setState({ newlyAddedProducts });
  }

  checkOrderStatus() {
    const { newlyAddedProducts, unFulfiledProductIds, orderItemList } = this.state;
    const { orderDetails } = this.props;
    const newlyAddedCount = newlyAddedProducts.length;
    const unfulfiledCount = unFulfiledProductIds.length;
    let quantityUpdated = false;
    for (let i = 0; i < orderItemList.length; i += 1) {
      if (orderDetails.orderItems[i].quantity !== orderItemList[i].quantity) {
        quantityUpdated = true;
        break;
      }
    }
    if (newlyAddedCount === 0 && unfulfiledCount === orderItemList.length) {
      return 'CANCELLABLE';
    }
    // if (newlyAddedCount === 0 && unfulfiledCount !== unFulfiledIds.length && !quantityUpdated) {
    //   return 'COMPLETE';
    // }
    if (newlyAddedCount !== 0 || quantityUpdated || (orderDetails.deliveryStatus === 'MODIFIED'
      && unfulfiledCount !== unFulfiledIds.length)) {
      return 'MODIFIED';
    }
    if (orderDetails.deliveryStatus === 'CHANGES_ACCEPTED'
      || orderDetails.deliveryStatus === 'CHANGES_REJECTED'
      || (orderDetails.deliveryStatus === 'PENDING'
        && newlyAddedCount === 0)) {
      return 'COMPLETE';
    }
    return 'AWAITING_CONFIRMATION';
  }

  validated() {
    const { productName, sellingPrice, mrp } = this.state;
    const errors = {};
    if (productName === '') {
      errors.productName = Strings.Product_Name_empty_error;
    } else if (productName.trim().length < 5) {
      errors.productName = Strings.Product_Name_short_error;
    }
    if (mrp === '' || parseFloat(mrp) === 0) {
      errors.mrp = Strings.MRP_empty_error;
    }
    if (sellingPrice === '' || parseFloat(sellingPrice) === 0) {
      errors.sellingPrice = Strings.Selling_Price_empty_error;
    } else if (parseFloat(sellingPrice) > parseFloat(mrp)) {
      errors.sellingPrice = Strings.SP_GT_MRP_error;
    }
    if (Object.entries(errors).length === 0) {
      return true;
    }
    this.setState({ errors });
    return false;
  }

  render() {
    const {
      orderDetails, language, orderHistory,
    } = this.props;
    const {
      orderItemList, unFulfiledProductIds, remMin, remSec,
      showOrderCancelDialog, addingMoreProducts, errors,
      productName, mrp, sellingPrice, qty, selectedProductId,
      newlyAddedProducts, selectedProduct,
      selectedCancellationReason, otherCancellationReason,
    } = this.state;
    const orderStatus = this.checkOrderStatus();


    const cancellationReasons = [
      {
        id: 0,
        displayText: Strings.CANCEL_ORDER_REASON1[language],
        value: Strings.CANCEL_ORDER_REASON1.en,
      },
      {
        id: 1,
        displayText: Strings.CANCEL_ORDER_REASON2[language],
        value: Strings.CANCEL_ORDER_REASON2.en,
      },
      {
        id: 2,
        displayText: Strings.CANCEL_ORDER_REASON3[language],
        value: Strings.CANCEL_ORDER_REASON3.en,
      },
      {
        id: 3,
        displayText: Strings.CANCEL_ORDER_REASON4[language],
        value: Strings.CANCEL_ORDER_REASON4.en,
      },
      {
        id: 4,
        displayText: Strings.CANCEL_ORDER_REASON5[language],
        value: Strings.CANCEL_ORDER_REASON5.en,
      },
      {
        id: 5,
        displayText: Strings.CANCEL_ORDER_REASON6[language],
        value: Strings.CANCEL_ORDER_REASON6.en,
      },
      {
        id: 6,
        displayText: Strings.OTHER[language],
        value: Strings.OTHER.en,
      },
    ];

    return (
      <Container fluid className="bg-light position-relative py-3">
        <CustomModal
          show={showOrderCancelDialog === 'secondaryOrderCancelDialog'}
          title={(
            <span className="text-primary">
              {Strings.IMPORTANT[language]}
            </span>
          )}
          body={(
            <Row>
              <Col xs={24} className="pb-4">{Strings.ORDER_CANCEL_DESCRIPTION2[language]}</Col>
              <Col xs={24} className="text-right">
                <Button
                  onClick={this.toggleDialog}
                >
                  {Strings.GO_BACK[language]}
                </Button>
                <span className="pl-3">
                  <Button
                    variant="danger-light"
                    onClick={this.cancelOrder}
                  >
                    {Strings.I_KNOW_THE_RISK[language]}

                  </Button>
                </span>
              </Col>
            </Row>
          )}
          onHide={this.toggleDialog}
          closeButton
        />
        <CustomModal
          show={showOrderCancelDialog === 'cancellationReasonDialog'}
          title={(
            <span className="text-primary">
              {Strings.CANCEL_ORDER_REASON_HEADER[language]}
            </span>
          )}
          body={(
            <Row className="px-4">
              {
                cancellationReasons.map(item => (
                  <Col
                    xs={24}
                    className="border-bottom px-0 py-2 d-flex align-items-center"
                    onClick={() => {
                      this.setState({
                        selectedCancellationReason: item.value,
                      });
                    }}
                  >
                    <input
                      type="radio"
                      value={item.value}
                      checked={selectedCancellationReason === item.value}
                      className="mx-2"
                    />
                    {item.displayText}
                  </Col>
                ))
              }
              {
                selectedCancellationReason === 'Other' && (
                  <Col xs={24} className="py-2">
                    <textarea
                      type="text"
                      value={otherCancellationReason}
                      onChange={(event) => {
                        this.setState({ otherCancellationReason: event.target.value });
                      }}
                      rows={3}
                      cols={48}
                      maxLength={60}
                      placeholder={Strings.WRITE_YOUR_REASON_HERE[language]}
                    />
                  </Col>
                )
              }
              <Col xs={24} className="text-right pt-4">
                <Button
                  variant="danger-light"
                  onClick={() => {
                    this.toggleDialog('secondaryOrderCancelDialog');
                  }}
                  disabled={
                    !selectedCancellationReason
                    || (selectedCancellationReason === 'Other' && !otherCancellationReason)
                    || (selectedCancellationReason === 'Other' && otherCancellationReason.length < 10)
                  }
                >
                  {Strings.REJECT_ORDER[language]}
                </Button>
              </Col>
            </Row>
          )}
          onHide={this.toggleDialog}
          closeButton
        />
        <CustomModal
          show={showOrderCancelDialog === 'primaryOrderCancelDialog'}
          title={(
            <span className="text-primary">
              {Strings.ARE_YOU_SURE[language]}
            </span>
          )}
          body={(
            <Row>
              <Col xs={24} className="pb-4">{Strings.ORDER_CANCEL_DESC[language]}</Col>
              <Col xs={24} className="text-right">
                <Button
                  variant="danger-light"
                  onClick={() => {
                    this.toggleDialog('cancellationReasonDialog');
                  }}
                >
                  {Strings.Cancel_Order[language]}

                </Button>
                <span className="pl-3"><Button onClick={this.toggleDialog}>{Strings.MODIFY_ORDER[language]}</Button></span>
              </Col>
            </Row>
          )}
          onHide={this.toggleDialog}
          closeButton
        />
        <Row className="mb-4">
          <Col>
            <div className="border shadow bg-white">
              <Col xs={24} className="text-muted text-center pt-2">{`${Strings.Order_Id[language]}: #${orderDetails.orderId}`}</Col>
              <Timer
                {...this.props}
                orderTime={orderDetails.createdOn}
                timeLeft={`${remMin < 10 ? `0${remMin}` : remMin}:${remSec < 10 ? `0${remSec}` : remSec}`}
                remMin={remMin}
              />
              <Col className="border my-2 no-print" />
              <CustomStepper
                {...this.props}
                orderHistory={orderHistory}
              />
            </div>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <div className="shadow bg-white">
              <CustomerDetails
                customer={orderDetails.customer}
                address={
                  orderDetails.pickupFromStore
                    ? null
                    : getAddressString(orderDetails.deliveryDetails.deliveryAddress)
                }
                description={orderDetails.description}
                language={language}
              />
            </div>
          </Col>
        </Row>
        <PaymentPreference
          payment={orderDetails.payment}
          language={language}
          settlement={orderDetails.settlement}
        />
        {/* <Row className="my-4">
          <Col id="read-button" className="px-7 py-2 bg-white">
            <SayButton
              onClick={() => {
                logFirebaseEvent(events.OO_od_readOut, null);
              }}
              className="py-2 btn btn-primary btn-block"
              speak={readOutString}
              voice={voices => [].find.call(voices, v => v.lang === 'hi-IN')}
              pitch={1}
              rate={0.8}
              volume={1}
            >
              <img src="/Assets/speaker-volume-high.svg" alt="" />
              &nbsp;
              {Strings.Read_Out_Full_Order[language]}
            </SayButton>
          </Col>
        </Row> */}
        <Row className="m-0"><Col xs={24} className="zig-zag" /></Row>
        <Row className="m-0 shadow">
          <Col xs={24} className="bg-white">
            <Row>
              <Col xs={24} className="px-0">
                <OrderList
                  {...this.props}
                  orderDetails={orderDetails}
                  orderItemList={orderItemList}
                  updateOrderItemQty={this.updateOrderItemQty}
                  markOutOfStock={this.markOutOfStock}
                  unFulfiledProductIds={unFulfiledProductIds}
                />
              </Col>
            </Row>
            <Row
              className={`px-4 ${orderDetails.deliveryStatus === 'PENDING'
                || orderDetails.deliveryStatus === 'MODIFIED' ? '' : 'd-none'}`}
            >
              <Col
                xs={24}
                className={addingMoreProducts || newlyAddedProducts.length !== 0
                  ? 'bg-light py-4' : ''}
              >
                <List
                  {...this.props}
                  Component={OrderProduct}
                  idField="id"
                  list={newlyAddedProducts}
                  markOutOfStock={this.deleteNewlyAdded}
                  updateOrderItemQty={this.updateOrderItemQty}
                  newlyAddedProduct
                />
                <Row
                  className={addingMoreProducts ? '' : 'd-none'}
                >
                  <Col xs={24} className="mb-3"><b>{Strings.Add_a_Product[language]}</b></Col>
                  <Col xs={24}>
                    <div
                      id="alternativeProductName"
                      className={`border d-flex bg-white px-3
                        ${errors.productName ? ' border-danger' : ''}`}
                    >
                      <img
                        src="/Assets/search.svg"
                        alt=""
                        className={productName.length === 0 ? '' : 'd-none'}
                      />
                      <input
                        id="alternateProductName"
                        name="productName"
                        className="form-control border-0 shadow-none px-0"
                        placeholder={Strings.Product_Name[language]}
                        value={productName}
                        onChange={this.handleChange}
                        onFocus={() => {
                          document.getElementById('alternativeProductName').classList.add('focus-shadow');
                        }}
                        onBlur={() => {
                          document.getElementById('alternativeProductName').classList.remove('focus-shadow');
                        }}
                      />
                    </div>
                    <div className="fs-1 d-block text-danger">
                      {errors.productName ? errors.productName[language] : ''}
                    </div>
                  </Col>
                  <Col xs={24} className="position-relative mb-3 pt-1">
                    <div
                      className={`alternative-product-suggesions bg-white
                      ${productName.length === 0 || selectedProductId !== -2 ? 'd-none' : ''}`}
                    >
                      <AlternateProductSuggestion
                        productName={productName}
                        language={language}
                        orderId={orderDetails.orderId}
                        selectProduct={this.selectProduct}
                      />
                      <Button
                        variant="link py-3"
                        block
                        onClick={() => {
                          this.setState({ selectedProductId: -1 });
                          document.getElementById('alternativeProductMrp').focus();
                        }}
                      >
                        {Strings.Add_New_Product[language]}
                      </Button>
                    </div>
                  </Col>
                  <Col xs={8} className={`mb-3 pr-1 ${productName.length === 0 ? 'd-none' : ''}`}>
                    <input
                      id="alternativeProductMrp"
                      name="mrp"
                      className={`form-control rounded-0${errors.mrp ? ' border-danger' : ''}`}
                      placeholder={Strings.MRP[language]}
                      value={mrp}
                      onChange={this.handleChange}
                      disabled={selectedProduct && selectedProduct.inInventory}
                    />
                    <div className="fs-1 d-block text-danger pt-1 pb-2">{errors.mrp ? errors.mrp[language] : ''}</div>
                  </Col>
                  <Col xs={8} className={`mb-3 px-1 ${productName.length === 0 ? 'd-none' : ''}`}>
                    <input
                      name="sellingPrice"
                      className={`form-control rounded-0${errors.sellingPrice ? ' border-danger' : ''}`}
                      placeholder={Strings.Selling_Price[language]}
                      value={sellingPrice}
                      onChange={this.handleChange}
                      disabled={selectedProduct && selectedProduct.inInventory}
                    />
                    <div
                      className="fs-1 d-block text-danger pt-1 pb-2"
                    >
                      {errors.sellingPrice ? errors.sellingPrice[language] : ''}
                    </div>
                  </Col>
                  <Col xs={8} className={`mb-3 pl-1 ${productName.length === 0 ? 'd-none' : ''}`}>
                    <div
                      className="form-control border-success bg-transparent btn-block rounded-0 py-0 px-2"
                    >
                      <Row className="m-0">
                        <Col xs="auto" className="p-0">
                          <Button
                            variant="link"
                            className="p-0 fs-5 text-success text-decoration-none"
                            disabled={qty < 2}
                            onClick={() => {
                              this.setState({
                                qty: qty - 1,
                                selectedProduct: { ...selectedProduct, quantity: qty - 1 },
                              });
                            }}
                          >
                            -
                          </Button>
                        </Col>
                        <Col className="p-1 text-center text-success">{qty < 10 ? `0${qty}` : qty}</Col>
                        <Col xs="auto" className="p-0">
                          <Button
                            variant="link"
                            className="p-0 fs-5 text-success text-decoration-none"
                            onClick={() => {
                              this.setState({
                                qty: qty + 1,
                                selectedProduct: { ...selectedProduct, quantity: qty + 1 },
                              });
                            }}
                          >
                            +
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col xs={24} className="mb-3">
                    <Button
                      block
                      className="rounded-0"
                      onClick={this.addToList}
                    >
                      {Strings.Add_To_List[language]}
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col className="no-print">
                    <Button
                      variant="outline-primary"
                      block
                      className="rounded-0"
                      onClick={(event) => {
                        event.currentTarget.blur();
                        this.setState({ addingMoreProducts: true }, () => {
                          document.getElementById('alternateProductName').focus();
                        });
                      }}
                      disabled={addingMoreProducts}
                    >
                      {Strings.Add_More_Products[language]}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            {
              orderDetails.deliveryStatus === 'MODIFIED' && (
                <div className="px-4 pt-4 text-justify">
                  <Row className="bg-light m-0 p-2">
                    <Col xs="auto" className="p-0">
                      <img src="/Assets/info.svg" alt="" />
                    </Col>
                    <Col className="pr-0">{Strings.Awaiting_Customer_Approval_Message[language]}</Col>
                  </Row>
                </div>
              )
            }
            {
              orderDetails.deliveryStatus === 'PENDING' && (
                <Row className="px-4 pt-4">
                  <Col className="no-print">
                    <Button
                      variant="outline-danger"
                      block
                      className="rounded-0"
                      onClick={() => {
                        this.toggleDialog('primaryOrderCancelDialog');
                      }}
                      disabled={addingMoreProducts}
                    >
                      {Strings.REJECT_ORDER[language]}
                    </Button>
                  </Col>
                </Row>
              )
            }
            <Row className="px-4">
              <Col xs={24} className="py-4 no-print">
                <Button
                  variant="primary"
                  block
                  className="py-3 fs-3"
                  onClick={
                    // eslint-disable-next-line
                    orderStatus === 'CANCELLABLE' ? () => { this.toggleDialog('primaryOrderCancelDialog'); }
                      : orderStatus === 'MODIFIED' ? this.submitOrderModified
                        : this.submitOrderPacked
                  }
                  disabled={orderStatus === 'AWAITING_CONFIRMATION'}
                >
                  {
                    // eslint-disable-next-line
                    orderStatus === 'CANCELLABLE' ? Strings.Cancel_Order[language]
                      : orderStatus === 'MODIFIED' ? Strings.Customer_Approval[language]
                        : Strings.Accept_Order[language]
                  }
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="m-0"><Col xs={24} className="zig-zag bottom" /></Row>
      </Container>
    );
  }
}

PendingOrderDetails.propTypes = {
  orderDetails: PropTypes.shape({}).isRequired,
  orderDetailsChange: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  orderHistory: PropTypes.arrayOf(PropTypes.object).isRequired,
  // readOutString: PropTypes.string.isRequired,
};

export default PendingOrderDetails;
