import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import Strings from '../../Strings';
import { getOrderDetails, syncRetailer } from '../../../api/api';
import {
  PendingOrderDetails, ReadyOrderDetails, DispatchedOrderDetails,
  DeliveredOrderDetails, CancelledOrderDetails,
} from '../../layout/Orders';
import { logClevertapEvents } from '../../Utils';
import clevertapEvents from '../../ClevertapEvents';

let currentStatus = '';

function getOrderHistory(language, orderDetails) {
  return ([
    {
      status: {
        name: 'received',
        displayName: Strings.Order_Received[language],
        status: 'active',
      },
      createdOn: '',
    },
    {
      status: {
        name: 'packed',
        displayName: Strings.Order_Accepted[language],
        status: 'incomplete',
      },
      createdOn: '',
    },
    {
      status: {
        name: 'out_for_delivery',
        displayName: orderDetails.pickupFromStore
          ? Strings.Ready_To_Pickup[language]
          : Strings.Out_For_Delivery[language],
        status: 'incomplete',
      },
      createdOn: '',
    },
    {
      status: {
        name: 'delivered',
        displayName: orderDetails.pickupFromStore
          ? Strings.Order_Picked_Up[language]
          : Strings.Order_Delivered[language],
        status: 'incomplete',
      },
      createdOn: '',
    },
  ]);
}

function clevertapEvent(orderDetails, isStatusChanged) {
  const { couponAppliedStatus, couponApplied } = orderDetails.coupon;
  const data = {
    'Order Id': orderDetails.orderId,
    'Order Status': orderDetails.deliveryStatus,
    AmountNet: orderDetails.payment.billAmount,
    AmountDelivery: orderDetails.payment.payableAmount,
    PaymentMode: orderDetails.payment.mode,
    PaymentStatus: orderDetails.payment.status,
    DicountCode: couponAppliedStatus ? couponApplied.couponCode : 'NOT APPLIED',
    DiscountAmount: orderDetails.payment.couponDiscount,
    'Order Date & Time': new Date(orderDetails.createdOn).toLocaleString(),
    'State Change Date & Time': new Date(orderDetails.modifiedOn).toLocaleString(),
  };
  if (isStatusChanged) {
    data['Is Delivered'] = orderDetails.deliveryStatus === 'DELIVERED' ? 'YES' : 'NO';
    logClevertapEvents(clevertapEvents.OO_OrderDetails_StatusChanged, data);
    return;
  }
  logClevertapEvents(clevertapEvents.OO_OrderDetails, data);
}

function manageOrderHistory(orderDetails, props) {
  if (!orderDetails) return [];
  // const historyData = orderHistory;
  const historyData = getOrderHistory(props.language, orderDetails);
  const { history } = orderDetails;
  const pastHistory = history.reverse().reduce(
    (acc, historyObj) => [...acc, [historyObj.status.toLowerCase(), historyObj]], [],
  );
  const pastHistoryMap = new Map(pastHistory);
  const [pending, readyToShip, outForDelivery, delivered] = [
    pastHistoryMap.get('pending'), pastHistoryMap.get('ready_to_ship'), pastHistoryMap.get('out_for_delivery'), pastHistoryMap.get('delivered')];
  if (pending) {
    historyData[0].createdOn = orderDetails.createdOn;
    historyData[0].status.status = 'completed';
    if (readyToShip) {
      historyData[1].status.status = 'completed';
      historyData[1].createdOn = readyToShip.modifiedOn;
      if (outForDelivery) {
        historyData[2].status.status = 'completed';
        historyData[2].createdOn = outForDelivery.modifiedOn;
        if (delivered) {
          historyData[3].status.status = 'completed';
          historyData[3].createdOn = delivered.modifiedOn;
        } else {
          historyData[3].status.status = 'active';
        }
      } else {
        historyData[2].status.status = 'active';
      }
    } else {
      historyData[1].status.status = 'active';
    }
  }
  return historyData;
}

function renderComponent(state, orderDetailsChange, props) {
  const { deliveryStatus } = state.orderDetails;
  if (currentStatus !== deliveryStatus) {
    currentStatus = deliveryStatus;
    document.body.scrollTop = 0;
    document.getElementsByClassName('main-section')[0].scrollTop = 0;
  }
  const history = manageOrderHistory(state.orderDetails, props);
  switch (state.orderDetails.deliveryStatus) {
    default:
    case 'PENDING':
    case 'MODIFIED':
    case 'CHANGES_ACCEPTED':
    case 'CHANGES_REJECTED': return (
      <PendingOrderDetails
        {...props}
        {...state}
        orderDetailsChange={orderDetailsChange}
        orderHistory={history}
      />
    );
    case 'READY_TO_SHIP': return (
      <ReadyOrderDetails
        {...props}
        {...state}
        orderDetailsChange={orderDetailsChange}
        orderHistory={history}
      />
    );
    case 'OUT_FOR_DELIVERY': return (
      <DispatchedOrderDetails
        {...props}
        {...state}
        orderDetailsChange={orderDetailsChange}
        orderHistory={history}
      />
    );
    case 'DELIVERED': return (
      <DeliveredOrderDetails
        {...props}
        {...state}
        orderHistory={history}
      />
    );
    case 'CANCELLED': return (
      <CancelledOrderDetails
        {...props}
        {...state}
      />
    );
  }
}

class OrderDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetails: null,
      // readOutString: '',
    };
    this.orderDetailsChange = this.orderDetailsChange.bind(this);
  }

  componentWillMount() {
    const { match, history } = this.props;
    const { id } = match.params;
    syncRetailer();
    getOrderDetails(id).then((res) => {
      clevertapEvent(res.data, false);
      this.setState({
        orderDetails: res.data,
      });
    }).catch(() => {
      history.push('/orders');
    });
    this.refreshOrderDetails = setInterval(() => {
      getOrderDetails(id).then((res) => {
        this.setState({
          orderDetails: res.data,
        });
      });
    }, 10000);
    // getReadOutString(id).then((res) => {
    //   this.setState({
    //     readOutString: res.data.orderString,
    //   });
    // });
  }

  componentWillUnmount() {
    clearInterval(this.refreshOrderDetails);
  }

  orderDetailsChange(newOrderDetails) {
    const { orderDetails } = this.state;
    if (orderDetails.deliveryStatus !== newOrderDetails.deliveryStatus) {
      clevertapEvent(newOrderDetails, true);
    }
    this.setState({ orderDetails: newOrderDetails });
  }

  render() {
    const { orderDetails } = this.state;
    // const { language } = this.props;
    // const orderHistory = getOrderHistory(language, orderDetails);
    return (
      orderDetails
        ? renderComponent(this.state, this.orderDetailsChange, this.props) : (
          <Row className="justify-content-center py-4">
            <Col xs="auto" className="spinner-border text-primary p-0" role="status">
              <span className="sr-only">Loading...</span>
            </Col>
          </Row>
        )
    );
  }
}

OrderDetails.propTypes = {
  history: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired,
  // language: PropTypes.string.isRequired,
};

export default OrderDetails;
