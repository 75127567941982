export default {
  OO_Request_Submitted: 'OO_Request_Submitted',
  OO_Request_Accepted: 'OO_Request_Accepted',
  OO_SetupStart: 'OO_SetupStart',
  OO_SetupStart_ProductAdding_Skipped: 'OO_SetupStart_ProductAdding_Skipped',
  OO_SetupStart_ProductAdding_Selected: 'OO_SetupStart_ProductAdding_Selected',
  OO_SetupStart_ProductAdding_Successful: 'OO_SetupStart_ProductAdding_Successful',
  OO_SetupStart_DetailsAdded_Successful: 'OO_SetupStart_DetailsAdded_Successful',
  OO_SetupStart_BankDetails_Successful: 'OO_SetupStart_BankDetails_Successful',
  OO_Dashboard_LanguageSwitch: 'OO_Dashboard_LanguageSwitch',
  OO_Dashboard_IsDelivering: 'OO_Dashboard_IsDelivering',
  OO_Dashboard_Search: 'OO_Dashboard_Search',
  OO_Dashboard_Review_ViewAll: 'OO_Dashboard_Review_ViewAll',
  OO_Dashboard_Review_Reply_Successful: 'OO_Dashboard_Review_Reply_Successful',
  OO_Dashboard_PreviousOrders: 'OO_Dashboard_PreviousOrders',
  OO_Dashboard_InProgress: 'OO_Dashboard_InProgress',
  OO_OrderDetails: 'OO_OrderDetails',
  OO_OrderDetails_StatusChanged: 'OO_OrderDetails_StatusChanged',
  OO_OrderDetails_SMStoDelivery: 'OO_OrderDetails_SMStoDelivery',
  OO_Dashboard_SettlementDueClicked: 'OO_Dashboard_SettlementDueClicked',
  OO_Dashboard_SettlementCompleted: 'OO_Dashboard_SettlementCompleted',
  OO_Settings_Updated: 'OO_Settings_Updated',
  OO_Dashboard_InventoryClicked: 'OO_Dashboard_InventoryClicked',
  OO_Inventory_DetailPage: 'OO_Inventory_DetailPage',
  OO_Inventory_DetailPage_ProductUpdate: 'OO_Inventory_DetailPage_ProductUpdate',
  OO_Inventory_AddProducts_Clicked: 'OO_Inventory_AddProducts_Clicked',
  OO_Inventory_Products_Added: 'OO_Inventory_Products_Added',
};
