import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Container } from 'react-bootstrap';
import Strings from '../../../Strings';
import { List } from '../../common';
import OrderProduct from './OrderProduct';
import SpecialBogoOffer from '../offer/SpecialBogoOffer';
import SpecialSpendOffer from '../offer/SpecialSpendOffer';

function OrderList(props) {
  const { orderItemList, orderDetails, language } = props;
  return (
    <Container fluid className="py-2 bg-white">
      {
        (orderDetails.payment.overallDiscount !== 0
        || orderDetails.loyalty.pointsDiscount !== 0
        || orderDetails.loyalty.pointsEarned !== 0
        || orderDetails.payment.deliveryAmount !== 0)
        && (
          <>
            <Row className="mb-1">
              <Col xs={12} className="pr-1">
                {Strings.Bill_Amount[language]}
              </Col>
              <Col xs={12} className="text-right pl-1">
                {`\u20B9 ${orderDetails.payment.payableAmount
                  + orderDetails.payment.overallDiscount
                  - orderDetails.payment.deliveryAmount}`}
              </Col>
            </Row>
            {
              orderDetails.payment.couponDiscount !== 0 && (
                <Row className="mb-1 text-success">
                  <Col xs={12} className="pr-1">
                    {Strings.Coupon_Discount[language]}
                  </Col>
                  <Col xs={12} className="text-right pl-1">
                    {`- \u20B9 ${orderDetails.payment.couponDiscount}`}
                  </Col>
                </Row>
              )
            }
            {
              orderDetails.payment.appExclusiveDiscount !== 0 && (
                <Row className="mb-1 text-success">
                  <Col xs={12} className="pr-1">
                    {Strings.App_Exlusive_Discount[language]}
                  </Col>
                  <Col xs={12} className="text-right pl-1">
                    {`- \u20B9 ${orderDetails.payment.appExclusiveDiscount}`}
                  </Col>
                </Row>
              )
            }
            {
              orderDetails.payment.overallDiscount
              - orderDetails.payment.appExclusiveDiscount
              - orderDetails.payment.couponDiscount > 0
              && (
                <Row className="mb-1 text-danger">
                  <Col xs={12} className="pr-1">
                    {Strings.Mpaani_Adjustments[language]}
                  </Col>
                  <Col xs={12} className="text-right pl-1">
                    {`- \u20B9 ${
                      orderDetails.payment.overallDiscount
                      - orderDetails.payment.appExclusiveDiscount
                      - orderDetails.payment.couponDiscount
                    }`}
                  </Col>
                </Row>
              )
            }
            {
              orderDetails.payment.deliveryAmount !== 0 && (
                <Row className="mb-1">
                  <Col xs={12} className="pr-1">
                    {Strings.Delivery_Charges[language]}
                  </Col>
                  <Col xs={12} className="text-right pl-1">
                    <b>{`+ \u20B9 ${orderDetails.payment.deliveryAmount}`}</b>
                  </Col>
                </Row>
              )
            }
            {
              orderDetails.loyalty.pointsDiscount !== 0 && (
                <Row className="mb-1">
                  <Col xs={12} className="pr-1 text-danger-light">
                    {Strings.Paid_With_Points[language]}
                  </Col>
                  <Col xs={12} className="text-right pl-1">
                    {`- \u20B9 ${orderDetails.loyalty.pointsDiscount} `}
                    <span className="text-primary">
                      (
                      <img src="/Assets/drop-primary.svg" alt="" className="drop-icon" />
                      {` ${orderDetails.loyalty.pointsUsed})`}
                    </span>
                  </Col>
                </Row>
              )
            }
            {
              orderDetails.loyalty.pointsEarned !== 0 && (
                <Row className="mb-1 text-primary">
                  <Col xs={12} className="pr-1">
                    {Strings.Points_Given[language]}
                  </Col>
                  <Col xs={12} className="text-right pl-1">
                    <img src="/Assets/drop-primary.svg" alt="" className="drop-icon" />
                    &nbsp;
                    {orderDetails.loyalty.pointsEarned}
                  </Col>
                </Row>
              )
            }
            <Row className="mx-0 mb-1">
              <Col className="border" />
            </Row>
          </>
        )
      }
      <Row className="fs-4 mb-2">
        <Col xs={12} className="pr-1">
          <b>{Strings.Total_Amount[language]}</b>
        </Col>
        <Col xs={12} className="text-right pl-1">
          <b>{`\u20B9 ${orderDetails.payment.payableAmount}`}</b>
        </Col>
      </Row>
      <List
        {...props}
        Component={OrderProduct}
        idField="id"
        list={orderItemList || orderDetails.orderItems}
      />
      {orderDetails.offersApplicable
      && (
      <Row>
        {orderDetails.offersApplicable.map(specialOffer => (specialOffer.offerType === 'SPECIAL_BOGO_OFFER'
          ? (
            <SpecialBogoOffer
              {...props}
              item={specialOffer}
              mode="order"
              idField="id"
            />
          )
          : (
            <SpecialSpendOffer
              {...props}
              item={specialOffer}
              mode="order"
              idField="id"
            />
          )))}
      </Row>
      )}
    </Container>
  );
}

OrderList.propTypes = {
  orderDetails: PropTypes.shape({}).isRequired,
  language: PropTypes.string.isRequired,
  orderItemList: PropTypes.arrayOf(PropTypes.object),
};

OrderList.defaultProps = {
  orderItemList: null,
};

export default OrderList;
