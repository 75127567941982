import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'react-bootstrap';
import { getAlternateProductSuggestions, retailerProductMapping } from '../../../api/api';
import Strings from '../../Strings';
import { getUUID } from '../../Utils';

const limit = 20;

class AlternateProductSuggestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productSuggestions: [],
      productCount: 0,
      offset: 0,
      loading: false,
      activeTimeout: -1,
    };
    this.handleLoad = this.handleLoad.bind(this);
    this.handleAppend = this.handleAppend.bind(this);
    this.handleLazyLoad = this.handleLazyLoad.bind(this);
  }

  componentWillMount() {
    const { productName } = this.props;
    if (productName === '') {
      return;
    }
    this.handleLoad(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const { productName: nextProductName } = nextProps;
    const { productName } = this.props;
    if (nextProductName === '') {
      this.setState({
        productSuggestions: [],
        productCount: 0,
        offset: 0,
      });
      return;
    }
    if (nextProductName !== productName) {
      this.setState({
        productSuggestions: [],
        productCount: 0,
        offset: 0,
      }, () => this.handleLoad(nextProps));
    }
  }

  handleLoad(props) {
    this.setState({ loading: true });
    const { activeTimeout } = this.state;
    if (activeTimeout !== -1) {
      clearTimeout(activeTimeout);
    }
    const { orderId, productName } = props;
    const { offset } = this.state;
    const timeoutId = setTimeout(() => {
      getAlternateProductSuggestions(orderId, productName, offset, limit)
        .then((res) => {
          this.setState({
            offset: limit,
            productSuggestions: res.data.results,
            productCount: res.data.count,
            loading: false,
          });
        }).catch(() => {
          this.setState({ loading: false });
        });
    }, 350);
    this.setState({ activeTimeout: timeoutId });
  }

  handleAppend(props) {
    this.setState({ loading: true });
    const { activeTimeout } = this.state;
    if (activeTimeout !== -1) {
      clearTimeout(activeTimeout);
    }
    const { orderId, productName } = props;
    const { offset } = this.state;
    const timeoutId = setTimeout(() => {
      getAlternateProductSuggestions(orderId, productName, offset, limit)
        .then((res) => {
          this.setState(state => ({
            offset: state.offset + limit,
            productSuggestions: state.productSuggestions.concat(res.data.results),
            productCount: res.data.count,
            loading: false,
          }));
        }).catch(() => {
          this.setState({ loading: false });
        });
    }, 350);
    this.setState({ activeTimeout: timeoutId });
  }

  handleLazyLoad(event) {
    const {
      loading, productCount, offset,
    } = this.state;
    if (loading) return;
    const { offsetHeight, scrollTop, scrollHeight } = event.target;
    if (scrollHeight - offsetHeight - scrollTop < 50
      || offsetHeight + scrollTop === scrollHeight) {
      if (offset >= productCount) {
        this.setState({ loading: false });
      } else {
        this.handleAppend(this.props);
      }
    }
  }

  handleAddToList(product) {
    const { selectProduct } = this.props;
    if (product.inInventory) {
      selectProduct({
        id: product.id,
        displayName: product.name,
        mrp: product.mrp,
        sellingPrice: product.sellingPrice,
        quantity: 1,
        image: product.image,
        inInventory: product.inInventory,
      });
    } else {
      retailerProductMapping({
        storeId: getUUID(),
        productIds: [product.id],
      }).then((res) => {
        const productDetails = res.data.results[0];
        selectProduct({
          id: productDetails.LibraryProductID.id,
          retailerProductId: productDetails.StoreProductID.id,
          displayName: productDetails.StoreProductID.displayName,
          mrp: productDetails.StoreProductID.mrp,
          sellingPrice: productDetails.StoreProductID.sellingPrice,
          quantity: 1,
          image: productDetails.LibraryProductID.image,
          inInventory: product.inInventory,
        });
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  render() {
    const { language } = this.props;
    const { productSuggestions, loading } = this.state;
    return (
      <div onScroll={this.handleLazyLoad} className="product-suggestion-div no-print">
        {
          productSuggestions.map(product => (
            <Row key={product.id} className="m-0 pt-2">
              <Col xs={4} className="p-1 text-center product-image-div">
                <img
                  src={product.image === '' ? '/Assets/thumbnail.svg' : product.image}
                  alt=""
                  className="product-image"
                />
              </Col>
              <Col className="py-1 pl-1 pr-4">
                <div className="pb-1"><b>{product.name}</b></div>
                <div className="pb-1">
                  {
                    product.inInventory ? (
                      <b>
                        <span className="text-success mr-4">{`\u20B9 ${product.sellingPrice}`}</span>
                        <span
                          className={`text-secondary${product.mrp === -1
                            || product.sellingPrice === product.mrp ? ' d-none' : ''}`}
                        >
                          <del>{`\u20B9 ${product.mrp}`}</del>
                        </span>
                      </b>
                    ) : (
                      <b className="text-primary">{`\u20B9 ${product.mrp}`}</b>
                    )
                  }
                </div>
              </Col>
              <Col xs="auto" className="py-1">
                <Button
                  variant={`outline-${product.inInventory ? 'success' : 'primary'}`}
                  className="w-85p rounded-0 py-0 px-3"
                  onClick={() => this.handleAddToList(product)}
                >
                  {product.inInventory ? `+ ${Strings.Add[language]}` : '+'}
                </Button>
              </Col>
            </Row>
          ))
        }
        {
          loading && (
            <Row className="justify-content-center py-4 m-0">
              <Col xs="auto" className="spinner-border text-primary p-0" role="status">
                <span className="sr-only">Loading...</span>
              </Col>
            </Row>
          )
        }
      </div>
    );
  }
}

AlternateProductSuggestion.propTypes = {
  language: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  selectProduct: PropTypes.func.isRequired,
};

export default AlternateProductSuggestion;
