import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Button } from 'react-bootstrap';
import Strings from '../../../Strings';
import { replyToReview } from '../../../../api/api';
import { shortDateOptions, logClevertapEvents } from '../../../Utils';
import clevertapEvent from '../../../ClevertapEvents';

class Review extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      replying: false,
      content: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    const { content } = this.state;
    const { item, updateReviews } = this.props;
    replyToReview({ content, review: item.id }).then((res) => {
      logClevertapEvents(clevertapEvent.OO_Dashboard_Review_Reply_Successful);
      updateReviews(res.data, item.id);
    });
  }

  render() {
    const { item, language, storeProfile } = this.props;
    const { replying, content } = this.state;
    const rating = [];
    for (let i = 0; i < 5; i += 1) {
      if (i < item.rating) {
        rating.push(<img key={i} src="/Assets/star.svg" alt="" />);
      } else {
        rating.push(<img key={i} src="/Assets/star-outline.svg" alt="" />);
      }
    }
    return (
      <Col xs={24} lg={12} className="px-7 py-2">
        <Row>
          <Col xs={6} sm={4} lg={5} xl={4} className="text-center">
            <img src={item.customer_image_url === '' ? '/Assets/member.svg' : item.customer_image_url} alt="" className="rounded-circle w-90p mw-100" />
          </Col>
          <Col xs={18} sm={20} lg={19} xl={20}>
            <Row>
              <Col className="pl-0 pr-1 pb-1">
                <b>{item.customer_name}</b>
              </Col>
              <Col xs="auto" className="text-muted text-right pl-1 pr-0 pb-1">
                {new Date(item.updated_at).toLocaleDateString(`${language}-in`, shortDateOptions)}
              </Col>
            </Row>
            <Row>
              <Col xs={24} className="pb-1 px-0">
                {rating}
              </Col>
            </Row>
            <Row>
              <Col xs={24} className="pb-1 px-0">
                {item.content}
              </Col>
            </Row>
            <Row className={item.reply ? 'd-none' : ''}>
              <Col xs={24} className="px-0 pb-1">
                {
                  replying ? (
                    <React.Fragment>
                      <textarea
                        type="text"
                        placeholder={Strings.Add_Comment[language]}
                        className="form-control"
                        value={content}
                        onChange={event => this.setState({ content: event.target.value })}
                      />
                      <Button
                        className="mt-1"
                        disabled={content.length < 5}
                        onClick={this.handleSubmit}
                      >
                        {Strings.Send_Reply[language]}
                      </Button>
                    </React.Fragment>
                  ) : (
                    <Button
                      variant="link"
                      className="p-0 d-flex align-items-center"
                      onClick={() => {
                        this.setState({ replying: true });
                      }}
                    >
                      <img src="/Assets/reply.svg" alt="" />
                      {Strings.Reply[language]}
                    </Button>
                  )
                }
              </Col>
            </Row>
            <Row className={item.reply ? '' : 'd-none'}>
              <Col xs={{ span: 20, offset: 4 }} className="py-2 text-right bg-light">
                <div className="text-justify">{item.reply && item.reply.content}</div>
                <div><b>{storeProfile ? storeProfile.displayName : ''}</b></div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="border my-4" />
        </Row>
      </Col>
    );
  }
}

Review.propTypes = {
  item: PropTypes.shape({}).isRequired,
  language: PropTypes.string.isRequired,
  storeProfile: PropTypes.shape({}).isRequired,
  updateReviews: PropTypes.func.isRequired,
};

export default Review;
