import Cookies from 'universal-cookie';

const cookies = new Cookies();

export function getToken() {
  return cookies.get('token');
}

export function setToken(token) {
  cookies.set('token', token);
}

export function setUUID(uuid) {
  cookies.set('code', uuid);
}

export function getUUID() {
  return cookies.get('code');
}

export function isAuthenticated() {
  const token = getToken();
  return !!token;
}

export function removeAuthentication() {
  window.localStorage.removeItem('profiles');
  window.localStorage.removeItem('retailer');
  cookies.remove('token');
  cookies.remove('code');
}

export function setLanguage(language) {
  cookies.set('language', language);
}

export function getLanguage() {
  return cookies.get('language');
}

export function setIsApp(isApp) {
  cookies.set('is-app', isApp);
}

export function getIsApp() {
  return cookies.get('is-app');
}

export function setIsAdmin(isAdmin) {
  cookies.set('is-admin', isAdmin);
}

export function getIsAdmin() {
  return cookies.get('is-admin');
}

export function setAppClientVersion(clientVersion) {
  cookies.set('android-client-version', clientVersion);
}

export function getAppClientVersion() {
  return cookies.get('android-client-version') || '-1';
}

export function getAddressString(address) {
  const {
    flatNumber, buildingName, streetName, localityName, landmark, googleAddress,
  } = address;
  return [flatNumber, buildingName, streetName, localityName, landmark, googleAddress].join(', ');
}

export function getDateDiff(date1, date2) {
  return date1 - date2;
}

export function formatDate(date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
}

export const shortDateOptions = { year: 'numeric', month: 'short', day: '2-digit' };
export const longDateOptions = { year: 'numeric', month: 'long', day: '2-digit' };
export const fullDateOptions = {
  year: 'numeric', month: 'long', day: '2-digit', weekday: 'short',
};
export const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
export const dateMonthOptions = { month: 'short', day: '2-digit' };
export const monthOptions = { month: 'long' };

export function logFirebaseEvent(event, data) {
  if (getIsApp() === 'true') {
    window.logFirebaseEvent(event, data);
  }
}

export function launchImageClicker(callback) {
  if (getIsApp() === 'true') {
    window.launchImageClicker(callback);
  }
}

export function launchImagePicker(callback) {
  if (getIsApp() === 'true') {
    window.launchImagePicker(callback);
  }
}

export function logClevertapEvents(event, data = {}) {
  const eventData = { storeId: getUUID(), ...data };
  window.clevertap.event.push(event, eventData);
}

export function logClevertapProfile(data) {
  window.clevertap.profile.push({
    Site: data,
  });
}

export function logClevertapOnUserLogin(data) {
  window.clevertap.onUserLogin.push({
    Site: data,
  });
}

function getType(path) {
  if (path.match(/\.png$/)) {
    return 'image/png';
  }
  if (path.match(/\.jpg$/) || path.match(/\.jpeg$/)) {
    return 'image/jpeg';
  }
  if (path.match(/\.csv$/)) {
    return 'application/csv';
  }
  return 'application/octet-stream';
}

export function dataURLtoFile(base64, filepath) {
  const byteString = atob(base64);
  const intArray = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i += 1) {
    intArray[i] = byteString.charCodeAt(i);
  }
  const filename = filepath.slice(filepath.lastIndexOf('/') + 1, filepath.length);
  return new File([intArray], filename, { type: getType(filename) });
}

function s4() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

export function genUUID() {
  return 'ss-s-s-s-sss'.replace(/s/g, s4);
}
