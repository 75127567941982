import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import './styles.scss';

function LibraryProduct(props) {
  const {
    product, updateSelectedProducts, selectedProducts,
  } = props;
  const checked = selectedProducts.includes(product.id);
  if (product.alreadyMapped && checked) {
    updateSelectedProducts(product.id, !checked);
  }
  return (
    <Col
      xs={24}
      md={12}
      className="px-1 my-1"
      onClick={product.alreadyMapped ? null : () => updateSelectedProducts(product.id, !checked)}
    >
      <div className={`h-100 ${product.alreadyMapped ? 'opacity-50' : ''}`}>
        <Row className="py-2 m-0">
          <Col xs="auto" className="px-1">
            <div
              className={`border rounded checkbox ${checked ? 'checked' : ''}
                ${product.alreadyMapped ? 'checked-grey' : ''}`}
            />
          </Col>
          <Col xs={5} className="p-1 text-center product-image-div border">
            <img
              // eslint-disable-next-line
              src={product.image ? product.image === ''
                ? '/Assets/thumbnail.svg' : product.image : '/Assets/thumbnail.svg'}
              alt=""
              className="product-image"
            />
          </Col>
          <Col className="py-1 pl-3 pr-1">
            <div><b>{product.displayName}</b></div>
            {
              product.showMrp && (
                <div className="py-1 text-muted mr-4">
                  {`\u20B9 ${product.mrp}`}
                </div>
              )
            }
          </Col>
        </Row>
      </div>
    </Col>
  );
}

LibraryProduct.propTypes = {
  product: PropTypes.shape({}).isRequired,
  updateSelectedProducts: PropTypes.func.isRequired,
  selectedProducts: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default LibraryProduct;
