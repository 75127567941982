import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Strings from '../../Strings';
import {
  getUUID,
  launchImageClicker,
  dataURLtoFile,
  launchImagePicker,
  getIsApp,
} from '../../Utils';
import {
  postRetailerProduct,
  imageUpload,
  getProductCategories,
} from '../../../api/api';
import { CustomModal, ErrorHandler } from '../../components/common';

function notValid(name, value) {
  switch (name) {
    case 'barcode': {
      if (value.match('^[0-9]{0,15}$') === null) {
        return true;
      }
      return false;
    }
    case 'unitQty': {
      if (value.match('^[0-9]{0,6}$') === null) {
        return true;
      }
      return false;
    }
    case 'mrp': {
      if (value.toString().match('^[0-9]{0,6}([.][0-9]{0,2})?$') === null) {
        return true;
      }
      return false;
    }
    case 'sellingPrice': {
      if (value.toString().match('^[0-9]{0,6}([.][0-9]{0,2})?$') === null) {
        return true;
      }
      return false;
    }
    default:
      return false;
  }
}

class ProductAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayName: '',
      description: '',
      barcode: '',
      mrp: '',
      show: false,
      sellingPrice: '',
      errors: {},
      image: null,
      imageUrl: '',
      categories: [],
      primaryCategory: '',
      secondaryCategory: '',
      unit: 'Units',
      unitQty: '',
      loading: true,
      errorLoadingCategories: false,
    };
    this.handleLoad = this.handleLoad.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.reset = this.reset.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleAddProduct = this.handleAddProduct.bind(this);
  }

  componentDidMount() {
    this.handleLoad();
  }

  handleLoad(retryCount = 2) {
    getProductCategories()
      .then((res) => {
        this.setState({
          categories: res.data.results,
          loading: false,
        });
      })
      .catch(() => {
        if (retryCount > 0) {
          this.handleLoad(retryCount - 1);
          return;
        }
        this.setState({
          loading: false,
          errorLoadingCategories: true,
        });
      });
  }

  handleComplete() {
    const { history, setup } = this.props;
    if (setup) {
      history.push('/setup?stage=product-retailer');
    } else {
      history.push('/products');
    }
  }

  handleChange(event) {
    const { name, value } = event.target;
    if (notValid(name, value)) {
      return;
    }
    const { errors } = this.state;
    errors[name] = null;
    this.setState({
      [name]: value,
      errors,
    });
  }

  handleImageChange(image, isNotApp = false) {
    this.setState({
      image: isNotApp ? image : dataURLtoFile(image.byteString, image.filepath),
    });
  }

  handleAddProduct() {
    const {
      displayName,
      description,
      barcode,
      mrp,
      sellingPrice,
      imageUrl,
      primaryCategory,
      secondaryCategory,
      unit,
      unitQty,
    } = this.state;
    postRetailerProduct({
      storeId: getUUID(),
      displayName,
      description,
      barcode,
      mrp: parseFloat(mrp),
      sellingPrice: parseFloat(sellingPrice),
      imageUrl,
      categories: secondaryCategory === ''
        ? [primaryCategory] : [secondaryCategory],
      unitOfMeasurement: unit,
      unit: unitQty,
    }).then(() => {
      this.setState({ show: true });
    }).catch(() => {
      const { errors } = this.state;
      errors.nonFieldError = Strings.Something_Went_Wrong;
      this.setState({ errors });
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (!this.validated()) {
      return;
    }
    const { imageUrl, image } = this.state;
    if (imageUrl === '' && image) {
      const formData = new FormData();
      formData.append('img', image);
      imageUpload(formData)
        .then((res) => {
          this.setState(
            {
              imageUrl: res.data.url,
            },
            this.handleAddProduct,
          );
        })
        .catch(() => {
          const { errors } = this.state;
          errors.nonFieldError = Strings.Image_Not_Upload;
          this.setState({ errors });
        });
      return;
    }
    this.handleAddProduct();
  }

  reset() {
    this.setState({
      displayName: '',
      description: '',
      barcode: '',
      mrp: '',
      show: false,
      sellingPrice: '',
      errors: {},
      image: null,
      imageUrl: '',
      primaryCategory: '',
      secondaryCategory: '',
      unit: 'Units',
      unitQty: '',
    });
  }

  validated() {
    const {
      displayName,
      sellingPrice,
      mrp,
      primaryCategory,
      unitQty,
    } = this.state;
    const errors = {};
    if (displayName === '') {
      errors.displayName = Strings.Product_Name_empty_error;
    } else if (displayName.trim().length < 3) {
      errors.displayName = Strings.Product_Name_short_error;
    }
    if (mrp === '' || parseFloat(mrp) === 0) {
      errors.mrp = Strings.MRP_empty_error;
    }
    if (sellingPrice === '' || parseFloat(sellingPrice) === 0) {
      errors.sellingPrice = Strings.Selling_Price_empty_error;
    } else if (parseFloat(sellingPrice) > parseFloat(mrp)) {
      errors.sellingPrice = Strings.SP_GT_MRP_error;
    }
    if (primaryCategory === '') {
      errors.primaryCategory = Strings.Select_Category_empty_error;
    }
    if (unitQty === '') {
      errors.unitQty = Strings.Unit_Quantity_empty_error;
    }
    if (Object.entries(errors).length === 0) {
      return true;
    }
    this.setState({ errors });
    return false;
  }

  render() {
    const {
      displayName,
      description,
      barcode,
      mrp,
      sellingPrice,
      categories,
      primaryCategory,
      secondaryCategory,
      show,
      errors,
      image,
      unit,
      unitQty,
      loading,
      errorLoadingCategories,
    } = this.state;
    let secondaryCategories = [];
    if (primaryCategory !== '') {
      secondaryCategories = categories.find(
        category => category.categoryId === primaryCategory,
      ).children;
    }
    const { language, appDisplay, history } = this.props;
    const body = (
      <Row>
        <Col
          xs={24}
          className="pb-4"
        >
          {Strings.Product_Add_Request_Message[language]}
        </Col>
        <Col
          xs={24}
          className="text-right"
        >
          <Button
            variant="light mr-1"
            onClick={this.handleComplete}
          >
            {Strings.Done[language]}
          </Button>
          <Button
            onClick={this.reset}
          >
            {Strings.Add_Another_Product[language]}
          </Button>
        </Col>
      </Row>
    );
    const isApp = getIsApp() === 'true';

    if (loading) {
      return (
        <Col
          xs={24}
          className="text-center py-8"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </Col>
      );
    }

    if (errorLoadingCategories) {
      return (
        <ErrorHandler
          show={errorLoadingCategories}
          language={language}
          handleRetry={() => {
            this.setState(
              {
                loading: true,
                errorLoadingCategories: false,
              },
              this.handleLoad,
            );
          }}
        />
      );
    }

    return (
      <Container
        fluid
        className="bg-white min-h-100"
      >
        <CustomModal
          show={show}
          title={(
            <span
              className="text-success"
            >
              {Strings.Product_Add_Request[language]}
            </span>
          )}
          body={body}
          reset={this.reset}
          onHide={this.handleComplete}
          closeButton
        />
        <Row>
          {
            appDisplay && (
              <Col
                xs={24}
                className="d-flex align-items-center sticky-top bg-white pt-4 pb-2 fs-3"
              >
                <Button
                  variant="link"
                  className="p-0 d-flex align-items-center"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <img
                    src="/Assets/left-arrow.svg"
                    alt=""
                    className="rotate-180"
                    width="18px"
                  />
                </Button>
                &nbsp;&nbsp;
                <b>Add New Product</b>
              </Col>
            )
          }
          <Col
            xs={24}
            className="pt-3 pb-4"
          >
            <Form
              autoComplete="off"
            >
              <Row>
                <Col
                  xs={24}
                >
                  <Form.Group
                    controlId="formProductName"
                  >
                    <Form.Label
                      className="fs-3"
                    >
                      {Strings.Product_Name[language]}
                      <span
                        className="text-danger"
                      >
                        &nbsp;*
                      </span>
                    </Form.Label>
                    <Form.Control
                      autoFocus
                      size="lg"
                      name="displayName"
                      type="text"
                      value={displayName}
                      onChange={this.handleChange}
                      className={`setup-input rounded-0 ${
                        errors.displayName ? 'border-danger' : ''
                      }`}
                    />
                    <div
                      className="fs-1 d-block text-danger mb-4 pt-1 setup-input"
                    >
                      {
                        errors.displayName
                        && errors.displayName[language]
                      }
                    </div>
                  </Form.Group>
                </Col>
                <Col
                  xs={24}
                >
                  <Form.Group
                    controlId="formPrimaryCategory"
                  >
                    <Form.Label
                      className="fs-3"
                    >
                      {Strings.Primary_Category[language]}
                      <span
                        className="text-danger"
                      >
                        &nbsp;*
                      </span>
                    </Form.Label>
                    <select
                      name="primaryCategory"
                      value={primaryCategory}
                      onChange={this.handleChange}
                      className={`form-control form-control-lg setup-input rounded-0 ${
                        errors.primaryCategory ? 'border-danger' : ''
                      }`}
                    >
                      <option
                        value=""
                      >
                        {Strings.Select_Category[language]}
                      </option>
                      {
                        categories.map(category => (
                          <option
                            key={category.categoryId}
                            value={category.categoryId}
                          >
                            {category.displayName}
                          </option>
                        ))
                      }
                    </select>
                    <div
                      className="fs-1 d-block text-danger mb-4 pt-1 setup-input"
                    >
                      {
                        errors.primaryCategory
                        && errors.primaryCategory[language]}
                    </div>
                  </Form.Group>
                </Col>
                <Col
                  xs={24}
                >
                  <Form.Group
                    controlId="formSecondaryCategory"
                  >
                    <Form.Label
                      className="fs-3"
                    >
                      {Strings.Secondary_Category[language]}
                    </Form.Label>
                    <select
                      name="secondaryCategory"
                      value={secondaryCategory}
                      onChange={this.handleChange}
                      className="form-control form-control-lg setup-input rounded-0"
                      disabled={
                        primaryCategory === ''
                        || secondaryCategories.length === 0
                      }
                    >
                      <option
                        value=""
                      >
                        {Strings.Select_Category[language]}
                      </option>
                      {
                        secondaryCategories.map(category => (
                          <option
                            key={category.categoryId}
                            value={category.categoryId}
                          >
                            {category.displayName}
                          </option>
                        ))
                      }
                    </select>
                  </Form.Group>
                </Col>
                <Col
                  xs={24}
                >
                  <Form.Group
                    controlId="formProductDescription"
                  >
                    <Form.Label
                      className="fs-3"
                    >
                      {Strings.Description[language]}
                    </Form.Label>
                    <Form.Control
                      size="lg"
                      name="description"
                      type="text"
                      value={description}
                      onChange={this.handleChange}
                      className="setup-input rounded-0"
                    />
                  </Form.Group>
                </Col>
                <Col
                  xs={24}
                >
                  <Form.Group
                    controlId="formProductBarcode"
                  >
                    <Form.Label
                      className="fs-3"
                    >
                      {Strings.Barcode[language]}
                    </Form.Label>
                    <Form.Control
                      size="lg"
                      name="barcode"
                      type="text"
                      value={barcode}
                      onChange={this.handleChange}
                      className="setup-input rounded-0"
                    />
                  </Form.Group>
                </Col>
                <Col
                  xs={24}
                >
                  <Row>
                    <Col
                      xs={12}
                      sm="auto"
                      className="pr-1"
                    >
                      <Form.Group
                        controlId="formProductUnitQty"
                      >
                        <Form.Label
                          className="fs-3"
                        >
                          {Strings.Unit_Quantity[language]}
                          <span
                            className="text-danger"
                          >
                            &nbsp;*
                          </span>
                        </Form.Label>
                        <Form.Control
                          size="lg"
                          name="unitQty"
                          type="text"
                          pattern="\d*"
                          value={unitQty}
                          onChange={this.handleChange}
                          className={`setup-input-2 rounded-0${
                            errors.unitQty && ' border-danger'
                          }`}
                        />
                        <div
                          className="fs-1 d-block text-danger mb-4 pt-1 setup-input-2"
                        >
                          {
                            errors.unitQty
                            && errors.unitQty[language]
                          }
                        </div>
                      </Form.Group>
                    </Col>
                    <Col
                      xs={12}
                      className="pl-1"
                    >
                      <Form.Group
                        controlId="formProductUnit"
                      >
                        <Form.Label
                          className="fs-3"
                        >
                          {Strings.Unit[language]}
                          <span
                            className="text-danger"
                          >
                            &nbsp;*
                          </span>
                        </Form.Label>
                        <select
                          name="unit"
                          value={unit}
                          onChange={this.handleChange}
                          className={`form-control form-control-lg rounded-0 ${
                            errors.unit ? 'border-danger' : ''} setup-input-2`}
                        >
                          <option
                            value="GMs"
                          >
                            GM
                          </option>
                          <option
                            value="KGs"
                          >
                            KG
                          </option>
                          <option
                            value="ML"
                          >
                            ML
                          </option>
                          <option
                            value="L"
                          >
                            L
                          </option>
                          <option
                            value="CMS"
                          >
                            CM
                          </option>
                          <option
                            value="Inch"
                          >
                            INCH
                          </option>
                          <option
                            value="Mtrs"
                          >
                            MTR
                          </option>
                          <option
                            value="Units"
                          >
                            Unit
                          </option>
                        </select>
                        <div
                          className="fs-1 d-block text-danger mb-4 pt-1 setup-input-2"
                        >
                          {
                            errors.unit
                            && errors.unit[language]
                          }
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={24}
                >
                  <Row>
                    <Col
                      xs={12}
                      sm="auto"
                      className="pr-1"
                    >
                      <Form.Group
                        controlId="formProductMRP"
                      >
                        <Form.Label
                          className="fs-3"
                        >
                          {Strings.MRP[language]}
                          <span
                            className="text-danger"
                          >
                            &nbsp;*
                          </span>
                        </Form.Label>
                        <Form.Control
                          size="lg"
                          name="mrp"
                          type="text"
                          pattern="\d*"
                          value={mrp}
                          onChange={this.handleChange}
                          className={`setup-input-2 rounded-0 ${
                            errors.mrp ? 'border-danger' : ''
                          }`}
                        />
                        <div
                          className="fs-1 d-block text-danger mb-4 pt-1 setup-input-2"
                        >
                          {
                            errors.mrp ? errors.mrp[language] : ''
                          }
                        </div>
                      </Form.Group>
                    </Col>
                    <Col
                      xs={12}
                      sm="auto"
                      className="pl-1"
                    >
                      <Form.Group
                        controlId="formProductSP"
                      >
                        <Form.Label
                          className="fs-3"
                        >
                          {Strings.Selling_Price[language]}
                          <span
                            className="text-danger"
                          >
                            &nbsp;*
                          </span>
                        </Form.Label>
                        <Form.Control
                          size="lg"
                          name="sellingPrice"
                          type="text"
                          pattern="\d*"
                          value={sellingPrice}
                          onChange={this.handleChange}
                          className={`setup-input-2 rounded-0 ${
                            errors.sellingPrice ? 'border-danger' : ''
                          }`}
                        />
                        <div
                          className="fs-1 d-block text-danger mb-4 pt-1 setup-input-2"
                        >
                          {
                            errors.sellingPrice
                              ? errors.sellingPrice[language]
                              : ''
                          }
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={24}
                >
                  <Form.Group
                    controlId="formGridProductImage"
                    className="setup-input"
                  >
                    <Form.Label
                      className="fs-3"
                    >
                      {Strings.Product_Image[language]}
                    </Form.Label>
                    <Row
                      className="text-center"
                    >
                      {
                        isApp ? (
                          <>
                            <Col
                              xs={12}
                              className="pr-1"
                            >
                              <Button
                                variant="outline-primary border-dashed border-radius"
                                block
                                onClick={() => launchImageClicker(this.handleImageChange)}
                              >
                                <img
                                  src="/Assets/camera.svg"
                                  alt=""
                                />
                                &nbsp;
                                {Strings.Camera[language]}
                              </Button>
                            </Col>
                            <Col
                              xs={12}
                              className="pl-1"
                            >
                              <Button
                                variant="outline-primary border-dashed border-radius"
                                block
                                onClick={() => launchImagePicker(this.handleImageChange)}
                              >
                                <img
                                  src="/Assets/gallery.svg"
                                  alt=""
                                />
                                &nbsp;
                                {Strings.Gallery[language]}
                              </Button>
                            </Col>
                          </>
                        ) : (
                          <Col
                            xs={24}
                          >
                            <input
                              type="file"
                              onChange={event => (
                                this.handleImageChange(
                                  event.target.files[0],
                                  !isApp,
                                )
                              )}
                            />
                          </Col>
                        )
                      }
                      <Col
                        xs={24}
                        className="pt-3"
                      >
                        {
                          image && (
                            <img
                              src={URL.createObjectURL(image)}
                              alt=""
                              className="w-100"
                            />
                          )
                        }
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
                <Col
                  xs={24}
                >
                  <div
                    className="fs-1 d-block text-danger mb-4 pt-1 setup-input"
                  >
                    {
                      errors.nonFieldError
                        ? errors.nonFieldError[language] : ''
                    }
                  </div>
                </Col>
                <Col
                  xs={24}
                >
                  <Button
                    size="lg"
                    variant="success rounded-0 fs-3 font-weight-bold"
                    onClick={this.handleSubmit}
                  >
                    {Strings.Add_a_Product[language]}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

ProductAdd.propTypes = {
  language: PropTypes.string.isRequired,
  setup: PropTypes.bool,
  appDisplay: PropTypes.bool,
  history: PropTypes.shape({}).isRequired,
};

ProductAdd.defaultProps = {
  setup: false,
  appDisplay: false,
};

export default ProductAdd;
