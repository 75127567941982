import React from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import { Redirect, Link } from 'react-router-dom';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import Strings from './Strings';
import events from './FirebaseEvents';
import {
  getStoreProfile, patchStore, getDeliveryRequests, syncRetailer,
} from '../api/api';
import {
  isAuthenticated, getIsApp, logFirebaseEvent, getIsAdmin, setToken, setUUID, setIsAdmin,
  logClevertapEvents, logClevertapProfile,
} from './Utils';
import Sidebar from './layout/sidebar/Sidebar';
import { CustomSwitch, ErrorHandler } from './components/common';
import clevertapEvents from './ClevertapEvents';


function toggleSideNav() {
  const sideNav = document.getElementById('side-nav');
  const open = sideNav.style.width.trim() === '300px';
  sideNav.style.width = open ? '0px' : '300px';
}

class Wrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      storeProfile: null,
      acceptedTnC: false,
      deliveryRequests: null,
      requestDelivery: false,
      error: false,
    };
    this.handleLoad = this.handleLoad.bind(this);
    this.patchProfile = this.patchProfile.bind(this);
    this.setRequestInterval = this.setRequestInterval.bind(this);
    this.updateDeliveryRequests = this.updateDeliveryRequests.bind(this);
    this.handleChangeDeliveryStatus = this.handleChangeDeliveryStatus.bind(this);
  }

  componentWillMount() {
    const { history, location } = this.props;
    const params = QueryString.parse(location.search);
    setIsAdmin(params['is-admin'] || false);
    if (
      !!params.token
      && !!params.code
      && params.token === 'e5ad88aa-d0c0-4fcb-952c-22a661bb865b'
    ) {
      setToken(params.token);
      setUUID(params.code);
      if (window.localStorage) {
        window.localStorage.removeItem('profiles');
        window.localStorage.removeItem('retailer');
      }
      history.push(location.pathname);
      syncRetailer().then(this.handleLoad).catch(this.handleLoad);
      return;
    }
    if (!isAuthenticated()) {
      history.push(`/login?n=${location.pathname}&s=${location.search}`);
      return;
    }
    syncRetailer().then(this.handleLoad).catch(this.handleLoad);
  }

  setRequestInterval() {
    this.refreshDeliveryRequests = setInterval(() => {
      getDeliveryRequests().then((res) => {
        this.setState({
          deliveryRequests: res.data.results,
          requestDelivery: res.data.results.length !== 0,
        }, () => {
          const { requestDelivery, deliveryRequests } = this.state;
          if (requestDelivery && deliveryRequests[0].status === 'ACCEPTED') {
            logClevertapEvents(clevertapEvents.OO_Request_Accepted);
            clearInterval(this.refreshDeliveryRequests);
          }
        });
      });
    }, 10000);
  }

  updateRetailer = (res) => {
    const retailer = JSON.parse(window.localStorage.getItem('retailer'));
    const updatedAddress = { street_address: res.data.address };
    const updatedNewRetailerAddress = { ...retailer.address, ...updatedAddress };
    window.localStorage.setItem(
      'retailer',
      JSON.stringify(
        {
          ...retailer,
          address: { ...updatedNewRetailerAddress },
        },
      ),
    );
  }

  handleLoad() {
    this.refreshStoreProfile = setInterval(() => {
      if (!isAuthenticated()) {
        const { history } = this.props;
        clearInterval(this.refreshStoreProfile);
        history.push('/login');
        return;
      }
      getStoreProfile().then((res) => {
        const statePayload = {
          storeProfile: res.data,
          acceptedTnC: res.data.acceptedTnC,
          error: false,
        };
        const { deliveryRequests } = this.state;
        if (res.data.onHomeDelivery || deliveryRequests) {
          this.setState(statePayload);
        } else {
          getDeliveryRequests().then((reqRes) => {
            this.setState({
              ...statePayload,
              deliveryRequests: reqRes.data.results,
              requestDelivery: reqRes.data.results.length !== 0,
            });
          });
        }
      });
    }, 10000);
    getStoreProfile().then((res) => {
      this.setState({
        storeProfile: res.data,
        acceptedTnC: res.data.acceptedTnC,
        error: false,
      }, () => {
        const {
          code,
          displayName,
          phoneNumber,
        } = res.data;
        logClevertapProfile({
          Identity: code,
          Name: displayName,
          Phone: phoneNumber,
        });
      });
    }).catch(() => {
      this.setState({ error: true });
    });
    getDeliveryRequests().then((res) => {
      this.setState({
        deliveryRequests: res.data.results,
        requestDelivery: res.data.results.length !== 0,
      });
    });
  }

  updateDeliveryRequests(request) {
    this.setState({
      deliveryRequests: [request],
      requestDelivery: true,
    });
  }

  patchProfile(data) {
    return patchStore(data).then((res) => {
      if (getIsApp() === 'false') {
        this.updateRetailer(res);
      }
      this.setState({
        storeProfile: res.data,
        acceptedTnC: res.data.acceptedTnC,
      });
    });
  }

  handleChangeDeliveryStatus(event) {
    const { checked } = event.target;
    if (checked) {
      logFirebaseEvent(events.OO_dash_switch_On, null);
    } else {
      logFirebaseEvent(events.OO_dash_switch_Off, null);
    }
    this.patchProfile({ isDelivering: checked })
      .then(() => {
        logClevertapEvents(
          clevertapEvents.OO_Dashboard_IsDelivering,
          { State: checked ? 'OPEN' : 'CLOSED' },
        );
      });
  }

  render() {
    const {
      Component, heading, language, handleChangeLanguage,
      setup, location, appDisplay,
    } = this.props;
    // if (!isAuthenticated()) {
    //   return (
    //     <Redirect
    //       to={`/login?n=${location.pathname}&s=${location.search}`}
    //     />
    //   );
    // }
    const {
      storeProfile, deliveryRequests, requestDelivery, error,
    } = this.state;
    if (error) {
      return (
        <ErrorHandler
          show={error}
          language={language}
          handleRetry={() => {
            this.setState({
              error: false,
            }, this.handleLoad);
          }}
          autoRefresh
        />
      );
    }
    if (
      !storeProfile
      || (
        storeProfile
        && !storeProfile.onHomeDelivery
        && !deliveryRequests
      )
    ) {
      return (
        <Row
          className="justify-content-center py-4"
        >
          <ErrorHandler />
          <Col
            xs="auto"
            className="spinner-border text-primary p-0"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </Col>
        </Row>
      );
    }
    if (
      storeProfile
      && !storeProfile.onHomeDelivery
      && requestDelivery
      && deliveryRequests[0].status === 'CREATED'
      && !this.refreshDeliveryRequests
    ) {
      this.setRequestInterval();
    }
    if (!setup && storeProfile && !storeProfile.onHomeDelivery) {
      return (
        <Redirect
          to="/online-request"
        />
      );
    }
    const isAppOrAdmin = getIsApp() === 'true' || getIsAdmin() === 'true';
    const dashboard = location.pathname === '/';
    return (
      <Container fluid className="h-100">
        <Row className="h-100">
          <Col
            xs={24}
            className="no-print"
          >
            <div id="side-nav">
              {
                isAppOrAdmin
                  ? ''
                  : (
                    <Sidebar
                      {...this.props}
                      toggleSideNav={toggleSideNav}
                    />
                  )
              }
            </div>
          </Col>
          <Col
            lg={5}
            className={`d-none ${isAppOrAdmin ? '' : 'd-lg-block'} p-0 h-100 no-print`}
          >
            {
              isAppOrAdmin
                ? ''
                : <Sidebar {...this.props} />
            }
          </Col>
          <Col
            xs={24}
            lg={isAppOrAdmin ? 24 : 19}
            className="p-0 h-100 position-relative"
          >
            <Row
              className={
                isAppOrAdmin ? 'd-none'
                  : 'm-0 py-5 bg-primary text-white wrapper-header align-items-center border-bottom no-print'
              }
            >
              <Col
                xs="auto"
                className={`pr-0 d-lg-none ${!dashboard && 'd-none'}`}
              >
                <Button
                  variant="link"
                  onClick={toggleSideNav}
                  className="p-0"
                >
                  <img src="/Assets/menu.svg" alt="" />
                </Button>
              </Col>
              <Col
                xs="auto"
                className={`pr-0 ${(dashboard || setup) && 'd-none'}`}
              >
                <Link to="/">
                  <Button
                    variant="link"
                    className="p-0"
                  >
                    <img
                      src={
                        window.screen.width >= 992
                          ? '/Assets/left-arrow.svg'
                          : '/Assets/left-arrow-light.svg'
                      }
                      alt=""
                      className="rotate-180"
                    />
                  </Button>
                </Link>
              </Col>
              <Col
                className="fs-4"
              >
                <b>{heading}</b>
              </Col>
              <Col
                xs="auto"
                className={`d-none my-n1 ${storeProfile && 'd-lg-block'}`}
              >
                <div className="text-muted text-center">
                  <CustomSwitch
                    {...this.props}
                    id="home-delivery"
                    displayTextLeft={Strings.Closed[language]}
                    displayTextRight={Strings.Open[language]}
                    onChange={this.handleChangeDeliveryStatus}
                    checked={storeProfile ? storeProfile.isDelivering : false}
                  />
                </div>
              </Col>
              <Col xs="auto" className="d-none pl-1 d-lg-block">
                <div className="bg-secondary d-inline-block rounded">
                  <Button
                    variant={language === 'en' ? 'primary shadow-sm' : 'secondary'}
                    className="text-white py-0 px-4"
                    onClick={() => handleChangeLanguage('en')}
                  >
                    ENG
                  </Button>
                  <Button
                    variant={language === 'hi' ? 'primary shadow-sm' : 'secondary'}
                    className="text-white py-0 px-4"
                    onClick={() => handleChangeLanguage('hi')}
                  >
                    हिंदी
                  </Button>
                </div>
              </Col>
            </Row>
            <Row className={`h-100 m-0 ${!isAppOrAdmin && 'main-content'} print-full`}>
              {
                setup || appDisplay ? (
                  <Component
                    {...this.props}
                    {...this.state}
                    patchProfile={this.patchProfile}
                    updateDeliveryRequests={this.updateDeliveryRequests}
                  />
                ) : (
                  <React.Fragment>
                    <Col xs={24} className="py-2 h-42p d-lg-none no-print">
                      <Row>
                        <Col>
                          <div className="bg-secondary d-inline-block rounded">
                            <Button
                              variant={language === 'en' ? 'primary shadow-sm' : 'secondary'}
                              className="text-white py-0 px-4"
                              onClick={() => handleChangeLanguage('en')}
                            >
                              ENG
                            </Button>
                            <Button
                              variant={language === 'hi' ? 'primary shadow-sm' : 'secondary'}
                              className="text-white py-0 px-4"
                              onClick={() => handleChangeLanguage('hi')}
                            >
                              हिंदी
                            </Button>
                          </div>
                        </Col>
                        <Col className="text-right">
                          <Link to="/settings">
                            <span className="text-black">{Strings.Setting[language]}</span>
                            &nbsp;
                            <Button variant="primary py-0 px-1">
                              <img src="/Assets/settings.svg" alt="" />
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24} className="p-0 main-section print-full">
                      <Component
                        {...this.props}
                        {...this.state}
                        patchProfile={this.patchProfile}
                        handleChangeDeliveryStatus={this.handleChangeDeliveryStatus}
                      />
                    </Col>
                  </React.Fragment>
                )
              }
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

Wrapper.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  heading: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  handleChangeLanguage: PropTypes.func.isRequired,
  setup: PropTypes.bool,
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  appDisplay: PropTypes.bool,
};

Wrapper.defaultProps = {
  setup: false,
  appDisplay: false,
};

export default Wrapper;
