import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import Strings from '../../Strings';
import { getIsApp } from '../../Utils';

const storeIcon = 'https://saggregation.mpaani.com/20191228-031749.svg';

class SetExclusionArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coordinate: [],
      errors: {},
      show: false,
      viewMap: true,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    if (location.updatedProfile) {
      this.initMap();
    }
  }

  handleEdit() {
    this.setState({
      viewMap: false,
    }, this.initMap);
  }

  handleSubmit() {
    const { coordinate, errors } = this.state;
    const { history, patchProfile, location } = this.props;
    if (coordinate.length === 0) {
      errors.areaNotSelected = Strings.Area_Not_Selected;
      this.setState({ errors });
      return;
    }
    const newDeliveryRadii = location.updatedProfile.deliveryRadii.map(radii => (
      {
        ...radii,
        radius: parseFloat(radii.radius) * 1000,
        amount: parseInt(radii.amount, 10),
      }
    ));
    patchProfile({
      nonServiceableAreasPolygons: coordinate,
      deliveryRadii: newDeliveryRadii,
    })
      .then(() => {
        this.setState({ show: true });
        setTimeout(() => {
          history.push({
            pathname: location.setup ? '/setup' : '/settings',
            search: location.setup ? '?stage=store-details' : '',
            updatedProfile: {
              ...location.updatedProfile,
              nonServiceableAreasPolygons: coordinate,
            },
          });
        }, 2000);
      }).catch(() => {
        errors.nonFieldError = Strings.Something_Went_Wrong;
        this.setState({ errors });
      });
  }

  initMap() {
    const {
      storeProfile, location,
    } = this.props;
    const { viewMap } = this.state;

    storeProfile.deliveryRadii = location.updatedProfile.deliveryRadii
      .map(radii => ({
        ...radii,
        radius: radii.radius * 1000,
      }));

    function getZoomLevel(radius) {
      const zoomLevel = Math.round(14 - Math.log(radius) / Math.LN2);
      if (zoomLevel > 18) {
        return 18;
      }
      return zoomLevel;
    }

    const mapDiv = document.getElementById('map');
    const mapOption = {
      center: { lat: storeProfile.latitude, lng: storeProfile.longitude },
      zoom: getZoomLevel(storeProfile.deliveryRadii.slice(-1)[0].radius / 1000),
      mapTypeId: 'terrain',
      mapTypeControl: false,
      streetViewControl: false,
    };
    const map = new window.google.maps.Map(mapDiv, mapOption);

    const circleOption = {
      strokeColor: '#2b2ba0',
      strokeOpacity: 0.5,
      strokeWeight: 2,
      fillColor: '#2b2ba0',
      fillOpacity: 0.2,
      map,
    };

    const polygonOption = {
      strokeColor: '#FD003A',
      strokeOpacity: 0.7,
      strokeWeight: 2,
      fillColor: '#FD003A',
      fillOpacity: 0.3,
      map,
    };

    const areaOptions = {
      strokeColor: '#FD003A',
      strokeOpacity: 0.5,
      strokeWeight: 2,
      fillColor: '#FD003A',
      fillOpacity: 0.2,
      clickable: true,
      editable: true,
      zIndex: 1,
    };
    const drawingManager = new window.google.maps.drawing.DrawingManager({
      drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.BOTTOM_CENTER,
        drawingModes: ['polygon'],
      },
      polygonOptions: {
        ...areaOptions,
      },
    });
    const updatedLatLngs = [];
    window.google.maps.event.addListener(drawingManager, 'overlaycomplete', (e) => {
      const { errors } = this.state;
      errors.areaNotSelected = null;
      const newLatLngs = [];
      const latLngs = e.overlay.getPath().getArray();
      for (let index = 0; index < latLngs.length; index += 1) {
        newLatLngs.push({ lat: latLngs[index].lat(), lng: latLngs[index].lng() });
      }
      updatedLatLngs.push(newLatLngs);
      this.setState({
        coordinate: updatedLatLngs,
        errors,
      });
    });
    drawingManager.setMap(viewMap ? null : map);

    storeProfile.deliveryRadii.map(radii => new window.google.maps.Circle({
      ...circleOption,
      center: { lat: storeProfile.latitude, lng: storeProfile.longitude },
      radius: radii.radius,
    }));

    // eslint-disable-next-line no-unused-vars
    const storeMarker = new window.google.maps.Marker({
      position: { lat: storeProfile.latitude, lng: storeProfile.longitude },
      map,
      icon: storeIcon,
    });

    if (viewMap && location.updatedProfile.nonServiceableAreasPolygons.length > 0) {
      location.updatedProfile.nonServiceableAreasPolygons.map(
        coordinate => new window.google.maps.Polygon({
          ...polygonOption,
          path: coordinate,
        }),
      );
    }
  }

  render() {
    const {
      language, history, handleChangeLanguage, location,
    } = this.props;
    const { errors, show, viewMap } = this.state;

    if (!location.updatedProfile) {
      return <Redirect to="/" />;
    }

    return (
      <Container fluid className="bg-white">
        <Row className={`bg-light py-2 d-lg-none settings-header${getIsApp() === 'true' ? ' app' : ''}`}>
          <Col className="pr-1">
            <b>{Strings.Set_Exclusions[language]}</b>
          </Col>
          <Col xs="auto" className="text-right pl-1">
            <div className="bg-secondary d-inline-block rounded">
              <Button
                variant={language === 'en' ? 'primary shadow-sm' : 'secondary'}
                className="text-white py-0 px-4 shadow-none"
                onClick={() => handleChangeLanguage('en')}
              >
                ENG
              </Button>
              <Button
                variant={language === 'hi' ? 'primary shadow-sm' : 'secondary'}
                className="text-white py-0 px-4 shadow-none"
                onClick={() => handleChangeLanguage('hi')}
              >
                हिंदी
              </Button>
            </div>
          </Col>
        </Row>

        <Row className="h-75 m-0">
          <Col xs={24} className="text-center pt-3">
            <b>{Strings.Set_Exclusions_Area[language]}</b>
          </Col>

          <Col id="map" xs={24} className="py-3 mt-3 h-100 w-100" />

          <Col xs={24}>
            <div className="fs-1 d-block text-danger my-4 setup-input">
              {errors.nonFieldError ? errors.nonFieldError[language] : ''}
            </div>
            <div className="fs-1 d-block text-danger my-4 setup-input">
              {errors.areaNotSelected ? errors.areaNotSelected[language] : ''}
            </div>
          </Col>
          <Col xs={24} className="pb-3">
            {show
              ? (
                <Row className="mt-2 justify-content-center">
                  <Col xs="auto" className="bg-success-20 rounded py-2">
                    <b>{Strings.Area_Saved[language]}</b>
                  </Col>
                </Row>
              ) : (
                <>
                  <span>
                    <Button
                      className="px-6 rounded-0"
                      variant="primary"
                      onClick={viewMap ? this.handleEdit : this.handleSubmit}
                    >
                      {viewMap ? Strings.Edit[language] : Strings.Save[language]}
                    </Button>
                  </span>
                  <span className={`${location.viewMap ? '' : 'pl-3'}`}>
                    <Button
                      className="px-6 rounded-0"
                      variant="outline-primary"
                      onClick={() => {
                        history.push({
                          pathname: location.setup ? '/setup' : '/settings',
                          search: location.setup ? '?stage=store-details' : '',
                          updatedProfile: location.updatedProfile,
                        });
                      }}
                    >
                      {Strings.Cancel[language]}
                    </Button>
                  </span>
                </>
              )}
          </Col>
        </Row>
      </Container>
    );
  }
}

SetExclusionArea.propTypes = {
  language: PropTypes.string.isRequired,
  history: PropTypes.shape({}).isRequired,
  patchProfile: PropTypes.func.isRequired,
  storeProfile: PropTypes.shape({}).isRequired,
  handleChangeLanguage: PropTypes.func.isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default SetExclusionArea;
